import React from 'react';
import './Footer.scss';
import Link from '../Link/Link';

class Footer extends React.Component {
  render() {
    return (
      <div className={'footer-root'}>
        <div className={'container'}>
          <span className={'text'}>© Inova</span>
          <span className={'spacer'}>·</span>
          <Link className={'link'} to="/">
            Home
          </Link>
          <span className={'spacer'}>·</span>
          <Link className={'link'} to="/contact">
            Bize Ulaşın
          </Link>
          <span className={'spacer'}>·</span>
          <Link className={'link'} to="/about">
            Hakkımızda
          </Link>
        </div>
      </div>
    );
  }
}

export default Footer;
