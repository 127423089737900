import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';
import createSentryMiddleware from 'redux-sentry-middleware';
import rootReducer from '../reducers';
import createLogger from './logger/logger.client';

const __DEV__ = process.env.NODE_ENV === 'development';

const middleware = [thunk, createSentryMiddleware(Sentry)];
let enhancer;

if (__DEV__) {
  middleware.push(createLogger());

  // https://github.com/zalmoxisus/redux-devtools-extension#redux-devtools-extension
  let devToolsExtension = (f) => f;
  if (process.env.BROWSER && window.devToolsExtension) {
    devToolsExtension = window.devToolsExtension();
  }

  enhancer = compose(applyMiddleware(...middleware), devToolsExtension);
} else {
  enhancer = applyMiddleware(...middleware);
}

// See https://github.com/rackt/redux/releases/tag/v3.1.0
const store = createStore(rootReducer, enhancer);

// Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
if (__DEV__ && module.hot) {
  module.hot.accept('../reducers', () => store.replaceReducer(require('../reducers').default));
}

export default store;
