import InjuredPersonResponse from './InjuredPersonResponse';
import { convertNumToPercentage, dateFormat } from '../../../utils/javascript-object-util';

export default class ChildResponse extends InjuredPersonResponse {
  constructor(config) {
    super(config);
    if (typeof config !== 'object') return;
    this.disability = convertNumToPercentage(config.disability);
    this.educationalStatus = config.educationalStatus;
    this.employmentStatus = config.employmentStatus || 'unemployed';
    this.dateOfGraduation = dateFormat(config.dateOfGraduation);
    this.dateOfEmployment = dateFormat(config.dateOfEmployment);
    this.dateOfMarriage = dateFormat(config.dateOfMarriage);
  }
}
