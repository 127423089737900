import { setAppData } from '../../actions/appdata';
import { SALARY_LIST } from '../../constants';
import Connection from './Connection';
import SalaryResponse from '../model/response/SalaryResponse';

export default class SalaryApi extends Connection {
  static list(dispatch, incidentId) {
    return this._getSecurely(`/incidents/${incidentId}/salaries`)
      .then(...this.justData)
      .then((r) =>
        dispatch(
          setAppData({
            name: SALARY_LIST,
            value: r ? r.bindList(SalaryResponse) : [],
          }),
        ),
      )
      .catch(this.catchError);
  }

  static find(dispatch, id) {
    return this._getSecurely(`/salaries/${id}`)
      .then(...this.justData)
      .catch(this.catchError);
  }

  static update(incidentId, id, data) {
    return this._patch(`/salaries/${id}`, data).catch(this.catchError);
  }

  static create(data, incidentId) {
    return this._post(`/incidents/${incidentId}/salaries`, data).catch(this.catchError);
  }

  static delete(incidentId, id) {
    return this._delete(`/salaries/${id}`).catch(this.catchError);
  }
}
