import SimpleUserResponse from './SimpleUserResponse';
import SimpleCompanyResponse from './SimpleCompanyResponse';

export default class InvitationResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.hash = config.hash;
    this.email = config.email;
    this.timestamp = config.timestamp;
    this.user = new SimpleUserResponse(config.user);
    this.company = new SimpleCompanyResponse(config.company);
  }
}
