import moment from 'moment';
import Gender from '../Gender';
import { convertToDate, dateFormat } from '../../../utils/javascript-object-util';

export default class PersonResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.personId = config.personId;
    this.personalIdentificationNumber =
      config.personalIdentificationNumber === 'unknown'
        ? undefined
        : config.personalIdentificationNumber;
    this.id = config.id;
    this.name = config.name;
    this.surname = config.surname;
    this.fullName = `${this.name} ${this.surname}`;
    this.dateOfBirth = dateFormat(config.dateOfBirth);
    this.endOfSupportToOverride = dateFormat(config.endOfSupportToOverride);
    this.simulatedAt = dateFormat(config.simulatedAt);
    if (this.simulatedAt) {
      this.fullName = 'Öngörülen';
    }
    this.gender = config.gender;
    this.hideShare = config.hideShare;
  }

  get getGender() {
    return Gender[this.gender];
  }

  get getIsChild() {
    const age = moment.duration(moment().diff(moment(convertToDate(this.dateOfBirth)))).years();
    return age < this.getStartWorkingAge();
  }

  get getStartWorkingDate() {
    return dateFormat(
      moment(convertToDate(this.dateOfBirth)).add(this.getStartWorkingAge(), 'years'),
    );
  }

  get getStartWorkingDateForCalculation() {
    return moment(moment(convertToDate(this.dateOfBirth)).add(this.getStartWorkingAge(), 'years'));
  }

  get getPersonId() {
    return this.id;
  }

  getStartWorkingAge() {
    return 18;
  }
}
