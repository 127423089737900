import React from 'react';
import { useHistory } from 'react-router';

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

function Link({ to, children, ...props }) {
  const history = useHistory();

  const handleClick = (event) => {
    if (props.onClick) {
      props.onClick(event);
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (event.defaultPrevented === true) {
      return;
    }

    event.preventDefault();
    history.push(to);
  };
  return (
    <a href={to} {...props} onClick={handleClick}>
      {children}
    </a>
  );
}

export default Link;
