import { DISABLED } from '../../../data/model/IncidentType';

export default function FileInformation(isNurse = false) {
  const { casualty } = this.injuredParty;
  const casualtyBasic = casualty.basic;
  const companyName = this.company.name;
  const {
    damageFileIdentifier,
    courtFileIdentifier,
    arbitrationFileIdentifier,
    dateOfIssue,
    dateOfIncident,
    dateOfReport,
    incidentType,
    isBeforeCircular2015,
    isBeforeCircular2020,
  } = this.incident;
  const identificationNumber = casualtyBasic.personalIdentificationNumber;
  const { fullName } = casualtyBasic;
  const gender = casualtyBasic.getGender;
  const { dateOfBirth } = casualtyBasic;
  const maritalStatus = this.injuredParty.getMaritalStatus;
  const incomeCategory = this.detailedIncident.getIncomeCategory;
  const { faultRatio, isNurseDocumented, disabilityRatio } = this.config;

  const buildItem = (label, text) => [
    label,
    { columns: [{ text: text || '-', alignment: 'right' }] },
  ];
  const body = [];
  body.push(buildItem('Şirket Adı', companyName));
  if (damageFileIdentifier) {
    body.push(buildItem('Hasar Dosya Numarası', damageFileIdentifier));
  }
  if (courtFileIdentifier) {
    body.push(buildItem('Hukuk Dosya Numarası', courtFileIdentifier));
  }
  if (arbitrationFileIdentifier) {
    body.push(buildItem('Tahkim Dosya Numarası', arbitrationFileIdentifier));
  }
  body.push(buildItem('Poliçe Tanzim Tarihi', dateOfIssue));
  body.push(buildItem('Kaza/Ölüm Tarihi', dateOfIncident));
  body.push(buildItem('Rapor Tarihi', dateOfReport));
  body.push(buildItem('T.C. Kimlik No.', identificationNumber));
  body.push(buildItem('Adı Soyadı', fullName));
  body.push(buildItem('Cinsiyeti', gender));
  body.push(buildItem('Doğum Tarihi', dateOfBirth));
  body.push(buildItem('Medeni Hali', maritalStatus));
  if (isNurse)
    body.push(
      buildItem(
        'Aylık Gelir Tipi',
        `${
          isBeforeCircular2015 || (!isBeforeCircular2020 && isNurseDocumented) ? 'Brüt' : 'Net'
        } Asgari Ücret`,
      ),
    );
  else body.push(buildItem('Aylık Net Gelir Tipi', incomeCategory));
  body.push(buildItem('Kusur Oranı', `${faultRatio}%`));
  if (incidentType === DISABLED) {
    body.push(
      buildItem('Sürekli Sakatlık Oranı', `${isNurse || this.onlyGig ? 100 : disabilityRatio}%`),
    );
  }

  return [
    {
      text: 'Dosya Bilgileri',
      margin: [0, 10, 0, 10],
      style: 'h2',
    },
    {
      margin: [63, 5, 10, 10],
      style: { fontSize: 10, bold: true },
      table: {
        heights: 8,
        widths: [150, 220],
        body,
      },
      layout: {
        hLineColor: '#CCC',
        vLineColor: '#CCC',
      },
    },
  ];
}
