import React from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  RaisedButton,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui';
import { useHistory } from 'react-router';
import SpecialPeriodType from '../../../data/model/SpecialPeriodType';
import { INIT_SPECIAL_PERIOD } from '../../../constants';
import { setAppData } from '../../../actions/appdata';
import { WriteIncident } from '../../../data/model/Privilege';
import Authorization from '../../Authorization/Authorization';
import EmptyView from '../../Functional/EmptyView';

const SpecialPeriodViewHolder = ({ specialPeriod }) => (
  <TableRow>
    <TableRowColumn>{specialPeriod.from}</TableRowColumn>
    <TableRowColumn>{specialPeriod.till}</TableRowColumn>
    <TableRowColumn>{SpecialPeriodType[specialPeriod.category]}</TableRowColumn>
  </TableRow>
);

function SpecialPeriodViewList({ dispatch, detailedIncident }) {
  const history = useHistory();
  const handleOpenUpdatePage = () => {
    dispatch(
      setAppData({
        name: INIT_SPECIAL_PERIOD,
        value: {
          specialPeriods: detailedIncident.specialPeriods,
        },
      }),
    );
    history.push(`/reports/${detailedIncident.incident.id}/special-periods/update`);
  };

  const { specialPeriods } = detailedIncident;
  return (
    <Paper>
      <div style={{ paddingBottom: 16, paddingTop: 1 }}>
        {specialPeriods.length > 0 ? (
          <Table
            className={'incidentTables'}
            fixedHeader={false}
            fixedFooter={false}
            selectable={false}
          >
            <TableHeader adjustForCheckbox={false} displaySelectAll={false} enableSelectAll={false}>
              <TableRow>
                <TableHeaderColumn>Dönem Başlangıcı</TableHeaderColumn>
                <TableHeaderColumn>Dönem Sonu</TableHeaderColumn>
                <TableHeaderColumn>Dönem Tipi</TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              {specialPeriods &&
                specialPeriods.map((p) => <SpecialPeriodViewHolder key={p.id} specialPeriod={p} />)}
            </TableBody>
          </Table>
        ) : (
          <EmptyView
            className={'hide'}
            style={{ marginTop: 16 }}
            icon="wheelchair"
            label="Özel Durum Yok"
          />
        )}
        <Authorization allowedRoles={[WriteIncident]}>
          <RaisedButton
            className={`hide upsertButton`}
            onClick={handleOpenUpdatePage}
            label="Ekle / Güncelle"
            primary
          />
        </Authorization>
      </div>
    </Paper>
  );
}

export default connect()(SpecialPeriodViewList);
