import InjuredPersonResponse from './InjuredPersonResponse';
import { dateFormat } from '../../../utils/javascript-object-util';
import DivorceResponse from './DivorceResponse';

export default class SpouseResponse extends InjuredPersonResponse {
  constructor(config) {
    super(config);
    if (typeof config !== 'object') return;
    this.dateOfMarriage = dateFormat(config.dateOfMarriage);
    this.dateOfRetirement = dateFormat(config.dateOfRetirement);
    this.status = config.status;
    this.employmentStatus = config.employmentStatus;
    this.divorce = config.divorce && new DivorceResponse(config.divorce);
  }
}
