import PersonCreateRequest from './PersonCreateRequest';

export default class ParentCreateRequest {
  constructor(config, { dateOfIssue = null }) {
    if (typeof config !== 'object') return;
    this.basic = new PersonCreateRequest(config.basic, {
      dateOfBirth: dateOfIssue,
    });
  }
}
