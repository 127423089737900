import React from 'react';
import Avatar from 'material-ui/Avatar';
import { Paper } from 'material-ui';
import { intToRGB } from '../../utils/javascript-object-util';
import Clear from '../../components/Functional/Clear';

class CustomerCardView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
    this.mouseOver = this.mouseOver.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
  }

  mouseOver() {
    this.setState({ hover: true });
  }

  mouseOut() {
    this.setState({ hover: false });
  }

  render() {
    const { customer } = this.props;
    const style = this.state.hover ? { backgroundColor: '#f1f5f3' } : {};

    return (
      <Paper
        style={{
          ...style,
          minWidth: 600,
          transition: 'all 100ms ease-in-out',
          position: 'relative',
          padding: '8px 8px 8px 4px',
          marginBottom: 2,
          display: 'flex',
        }}
        onMouseEnter={this.mouseOver}
        onMouseLeave={this.mouseOut}
      >
        <div
          style={{
            float: 'left',
            marginTop: 10,
            marginLeft: 12,
          }}
        >
          <Avatar backgroundColor={intToRGB(customer.id.hashCode(), 0.75)}>
            {customer.officialName.toUpperCase().charAt(0)}
          </Avatar>
        </div>
        <div style={{ float: 'left', marginLeft: 16, padding: '8px 0' }}>
          <div>
            <span style={{ fontWeight: 400 }}>{customer.officialName}</span>
          </div>
          <span>{`Adres : ${customer.address || '-'}`}</span>
          <br />
          <span>{`Telefon: ${customer.phoneNumber || '-'}`}</span>
        </div>
        <Clear />
      </Paper>
    );
  }
}

export default CustomerCardView;
