import intersection from 'lodash/intersection';
import Language from './Language';
import { ACTUARY, BASIC, CUSTOMER } from './Privilege';
import CompanyResponse from './response/CompanyResponse';

export default class User {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.id = config.id;
    this.firstName = config.firstName;
    this.surName = config.surName;
    this.email = config.email;
    this.emailConfirmationDate = config.emailConfirmationDate;
    this.avatarURL = config.avatarURL;
    this.dateOfBirth = config.dateOfBirth;
    this.customer = config.customer ? new CompanyResponse(config.customer) : undefined;
    this.actuery = config.actuery ? new CompanyResponse(config.actuery) : undefined;
    this.phoneNumber = config.phoneNumber;
    this.language = new Language(config.language);
    this.roles = [];
    if (this.actuery) {
      this.roles.push(ACTUARY);
      this.roles.push(...this.actuery.privileges);
    }
    if (this.customer) {
      this.roles.push(CUSTOMER);
      this.roles.push(...this.customer.privileges);
    }

    this.roles.push(BASIC);
  }

  get fullName() {
    return `${this.firstName} ${this.surName}`;
  }

  get getRoles() {
    return this.roles;
  }

  isAuthorized(roles, and) {
    const intersectionRoles = intersection(roles, this.roles);
    return and ? roles.length === intersectionRoles.length : intersectionRoles.length > 0;
  }

  get getDefaultRole() {
    return this.roles[0];
  }

  get getCompanyId() {
    if (this.actuery) return this.actuery.companyId;
    if (this.customer) return this.customer.companyId;
    return undefined;
  }
}
