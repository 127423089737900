import React from 'react';
import { FloatingActionButton } from 'material-ui';
import ContentAdd from 'material-ui/svg-icons/content/add';

export default function ListAddFAB({ disabled, onClick }) {
  return (
    <FloatingActionButton
      style={{
        position: 'absolute',
        bottom: -20,
        left: '50%',
        transform: 'translate(-50%,0)',
      }}
      disabled={disabled}
      mini
      onClick={onClick}
    >
      <ContentAdd />
    </FloatingActionButton>
  );
}
