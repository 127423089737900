import React from 'react';
import { connect } from 'react-redux';
import { Paper } from 'material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hidePB, showPB } from '../../actions/progressbarAction';
import Link from '../../components/Link/Link';
import AccountApi from '../../data/api/AccountApi';
import BaseLayout from '../../components/BaseLayout/BaseLayout';

class ConfirmMail extends React.Component {
  constructor(props) {
    super(props);
    this.state = { status: 'inProgress' };
  }

  componentDidMount() {
    const { tokenId, dispatch } = this.props;
    if (tokenId) {
      showPB(dispatch, 'Mail aktivasyonu tamamlanıyor.');
      AccountApi.confirmMail(tokenId).then(
        (r) => {
          this.setState({ status: 'confirmed' });
          hidePB(dispatch);
        },
        (r) => {
          this.setState({ status: 'rejected' });
          hidePB(dispatch);
        },
      );
    } else {
      this.setState({ status: 'invalidToken' });
    }
  }

  render() {
    return (
      <BaseLayout>
        <div className={'root'}>
          <div className={`container max380`}>
            <Paper
              className={'center'}
              style={{
                padding: 32,
              }}
            >
              {
                {
                  inProgress: <p>Mail Aktive Ediliyor...</p>,
                  confirmed: (
                    <div>
                      <div
                        style={{
                          textAlign: 'center',
                          fontSize: '42px',
                          color: 'green',
                        }}
                      >
                        <FontAwesomeIcon fixedWidth icon="check-circle" />
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        Aktivasyon Tamamlandı <br />
                        <br />
                        <Link to="/">Ana Sayfa</Link>
                      </div>
                    </div>
                  ),
                  rejected: (
                    <div>
                      <div
                        style={{
                          textAlign: 'center',
                          fontSize: '42px',
                          color: 'orange',
                        }}
                      >
                        <FontAwesomeIcon fixedWidth icon="exclamation-triangle" />
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        Geçersiz token. Maili tekrar yollamak için tıklayın;
                        <br />
                        <br />
                        <Link to="/confirm">Aktivasyon maili yollamak istiyorum.</Link>
                      </div>
                    </div>
                  ),
                  invalidToken: (
                    <div>
                      <div
                        style={{
                          textAlign: 'center',
                          fontSize: '42px',
                          color: 'red',
                        }}
                      >
                        <FontAwesomeIcon fixedWidth icon="times-circle" />
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        Geçersiz token. Maili tekrar yollamak için tıklayın;
                        <br />
                        <br />
                        <Link to="/confirm">Aktivasyon maili yollamak istiyorum.</Link>
                      </div>
                    </div>
                  ),
                }[this.state.status]
              }
            </Paper>
          </div>
        </div>
      </BaseLayout>
    );
  }
}

export default connect()(ConfirmMail);
