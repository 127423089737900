import React from 'react';
import { useHistory } from 'react-router';
import ColorPalette from '../ColorPalette';

function RouteLocationViewHolder({ location, locations }) {
  const history = useHistory();
  const depth = locations.indexOf(location);
  function onClick() {
    if (depth !== 0) history.go(-depth);
  }
  return (
    <span>
      <span
        onClick={onClick}
        style={{
          fontWeight: 500,
          fontSize: '14px',
          color: depth === 0 ? ColorPalette.primary2Color : ColorPalette.primary1Color,
          cursor: depth === 0 ? 'inherit' : 'pointer',
        }}
      >
        {location.label}
      </span>
      {depth !== 0 && <span>&nbsp;&gt;&nbsp;</span>}
    </span>
  );
}

export class RouteLocationStory extends React.Component {
  render() {
    const locations = this.props.locations.filter((loc) => loc !== null);
    const reverseLocation = locations.slice().reverse();
    return (
      <div style={{ display: 'block', marginBottom: 8 }}>
        {locations.map((location) => (
          <RouteLocationViewHolder
            key={location.label.hashCode()}
            location={location}
            locations={reverseLocation}
          />
        ))}
      </div>
    );
  }
}

export class RouteLocation {
  constructor(config) {
    this.label = config.label;
    this.link = config.link;
    this.back = config.back || 1;
  }
}
export default {
  reports: () =>
    new RouteLocation({
      label: 'Raporlar',
    }),
  report: ({ text, args }) =>
    new RouteLocation({
      label: text,
    }),
  config: () =>
    new RouteLocation({
      label: 'Hesaplama Ayarı',
    }),
  salaryCreateUpdate: () =>
    new RouteLocation({
      label: 'Maaş Güncelle',
    }),
  injuredPartyCreateUpdate: () =>
    new RouteLocation({
      label: 'Pay Sahiplerini Güncelle',
    }),
  paymentCreateUpdate: () =>
    new RouteLocation({
      label: 'Ödemeleri Güncelle',
    }),
  specialPeriodsPartyCreateUpdate: () =>
    new RouteLocation({
      label: 'Özel Durumları Güncelle',
    }),
  configCreateUpdate: (update) =>
    new RouteLocation({
      label: update ? 'Güncelle' : 'Oluştur',
    }),
  incidentUpdate: () =>
    new RouteLocation({
      label: 'Rapor Bilgilerini Güncelle',
    }),
};
