/* eslint-disable react/prop-types,no-prototype-builtins */
import React, { Component } from 'react';
import ComboBox from './ComboBox';

const states = (obj) => {
  if (!obj) return [];

  const data = obj.map((k) => ({
    value: k.basic.id || k.basic.tempId,
    text: (k.basic && k.basic.fullName) || 'Eş İsmi Girilmemiş',
  }));
  return data;
};

class ParentComboBox extends Component {
  render() {
    return <ComboBox {...this.props} data={states(this.props.data)} />;
  }
}

export default ParentComboBox;
