import TagResponse from './TagResponse';
import RoleResponse from './RoleResponse';

export default class DetailedRoleResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.tag = new TagResponse(config.tag);
    this.role = new RoleResponse(config.role);
    this.privileges = config.privileges || [];
  }
}
