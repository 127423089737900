import { setAppData } from '../../actions/appdata';
import { INVITATIONS_LIST } from '../../constants';
import Connection from './Connection';
import InvitationResponse from '../model/response/InvitationResponse';

export default class CompanyInvitationApi extends Connection {
  static list(dispatch, companyId) {
    return this._getSecurely(`/companies/${companyId}/invitations`)
      .then(...this.justData)
      .then((r) =>
        dispatch(
          setAppData({
            name: INVITATIONS_LIST,
            value: r ? r.bindList(InvitationResponse) : [],
          }),
        ),
      )
      .catch(this.catchError);
  }

  static create(companyId, data) {
    return this._post(`/companies/${companyId}/invitations`, data);
  }

  static delete(companyId, hash) {
    return this._delete(`/companies/${companyId}/invitations?hash=${hash}`);
  }
}
