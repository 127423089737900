import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Paper, RaisedButton } from 'material-ui';
import isEmpty from 'lodash/isEmpty';
import { renderTextField } from '../../utils/redux-metarial-ui-util';
import { clearAppData, setAppData } from '../../actions/appdata';
import { INIT_COMPANY, USER } from '../../constants';
import Clear from '../../components/Functional/Clear';
import { hidePB, showPB } from '../../actions/progressbarAction';
import CompanyApi from '../../data/api/CompanyApi';
import { validateAmount } from '../../utils/validation';
import CustomerApi from '../../data/api/CustomerApi';
import { openSnackbar } from '../../actions/snackbarActions';
import Layout from '../../components/Layout/Layout';
import Authorization from '../../components/Authorization/Authorization';
import { ACTUARY, ReadCompany } from '../../data/model/Privilege';

class CompanyCreate extends React.Component {
  fetchData = (props) => {
    const { dispatch, companyId } = props;
    if (!companyId) return;
    showPB(dispatch);
    CompanyApi.find(companyId).then((r) => {
      dispatch(setAppData({ name: INIT_COMPANY, value: r }));
      hidePB(dispatch);
    });
  };

  onSubmit = (values) => {
    const { dispatch } = this.props;
    showPB(dispatch);
    CustomerApi.create(values).then(
      (r) => {
        dispatch(openSnackbar('Şirket Oluşturuldu'));
        hidePB(dispatch);
      },
      (r) => {
        dispatch(openSnackbar('Bir Hata Oluştu'));
        hidePB(dispatch);
      },
    );
  };

  componentDidMount() {
    this.fetchData(this.props);
  }

  componentWillUnmount() {
    this.props.dispatch(clearAppData(INIT_COMPANY));
  }

  render() {
    const { handleSubmit, title } = this.props;
    return (
      <Layout>
        <Authorization allowedRoles={[ReadCompany, ACTUARY]} force>
          <div className={'root'}>
            <div className={`container max380`}>
              <h1>{title}</h1>
              <Paper>
                <form onSubmit={handleSubmit(this.onSubmit)} style={{ padding: '0 32px' }}>
                  <Field
                    name="officialName"
                    label="Şirket Adı"
                    component={renderTextField}
                    fullWidth
                  />
                  <Field
                    name="taxOffice"
                    label="Vergi Dairesi"
                    component={renderTextField}
                    fullWidth
                  />
                  <Field
                    name="taxNo"
                    label="Vergi Numarası"
                    component={renderTextField}
                    fullWidth
                  />
                  <Field name="address" label="Adres" component={renderTextField} fullWidth />
                  <Field name="postCode" label="Posta Kodu" component={renderTextField} fullWidth />
                  <Field
                    name="phoneNumber"
                    label="Telefon Numarası"
                    component={renderTextField}
                    fullWidth
                  />
                  <Field
                    name="faxNumber"
                    label="Fax Numarası"
                    component={renderTextField}
                    fullWidth
                  />
                  <Field name="web" label="Web Sitesi" component={renderTextField} fullWidth />
                  <RaisedButton
                    className={'right'}
                    style={{ margin: '32px 0' }}
                    primary
                    label="Oluştur"
                    type="submit"
                  />
                  <Clear />
                </form>
              </Paper>
            </div>
          </div>
        </Authorization>
      </Layout>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const required = 'Zorunlu Alan';

  const requiredFields = ['officialName', 'postCode', 'taxOffice', 'taxNo', 'phoneNumber'];
  requiredFields.forEach((f) => {
    if (isEmpty(values[f])) {
      errors[f] = required;
    }
  });
  errors.postCode = validateAmount(values.postCode);

  return errors;
};
CompanyCreate = reduxForm({ form: 'companyDetail', validate })(CompanyCreate);

export default connect((state) => ({
  user: state.appData[USER],
  initialValues: state.appData[INIT_COMPANY],
}))(CompanyCreate);
