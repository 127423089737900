import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment/moment';
import Clear from '../../components/Functional/Clear';
import { clean, dateFormat, toMoment } from '../../utils/javascript-object-util';
import { DISABLED } from '../../data/model/IncidentType';
import { REQUIRED, validateDate, validateIncidentLogical } from '../../utils/validation';
import { openSnackbar } from '../../actions/snackbarActions';
import { hidePB, showPB } from '../../actions/progressbarAction';
import { clearAppData } from '../../actions/appdata';
import { CIRCULAR_2021_MOMENT, DETAILED_INCIDENT_CC, INIT_BASIC_REPORT } from '../../constants';
import RouteStory, { RouteLocationStory } from '../../utils/RouteStory';
import IncidentForm from './IncidentForm';
import IncidentUpdateRequest from '../../data/model/request/update/IncidentUpdateRequest';
import IncidentApi from '../../data/api/IncidentApi';
import CalculationConfigApi from '../../data/api/CalculationConfigApi';
import { TRH } from '../../data/model/MortalityTable';

class BasicReport extends React.Component {
  onSubmit = (values) => {
    const obj = JSON.parse(JSON.stringify(values.incident));
    if (obj.incidentType === DISABLED) obj.dateOfDeath = undefined;

    const { incidentId, dispatch, detailedIncident } = this.props;

    showPB(dispatch, 'Rapor Güncelleniyor');
    IncidentApi.update(incidentId, new IncidentUpdateRequest(obj)).then(
      () => {
        hidePB(dispatch);

        /*        const isBefore2021 = obj.dateOfIssue
          ? toMoment(obj.dateOfIssue).isBefore(CIRCULAR_2021_MOMENT)
          : null;
        if (!isBefore2021) {
          detailedIncident.configs.forEach((config) =>
            CalculationConfigApi.update(config.id, {
              useTerminalAge: obj.incidentType === DISABLED && config.mortalityTable === TRH,
            }),
          );
        } */

        dispatch(openSnackbar('Rapor Bilgileri Güncellendi'));
        dispatch(clearAppData(INIT_BASIC_REPORT));
        this.props.history.goBack();
        this.props.history.push(`/reports/${incidentId}`);
      },
      () => {
        hidePB(dispatch);
        dispatch(openSnackbar('Rapor güncelleme sırasında hata oluştu'));
      },
    );
  };

  componentDidMount() {
    if (!this.props.detailedIncident) this.props.history.push('/reports');
  }

  handleQuickEditDateOfDeath() {
    this.props.dispatch(this.props.change(`incident.dateOfDeath`, this.props.dateOfIncident));
  }

  handleQuickEditDateOfReport() {
    this.props.dispatch(this.props.change(`incident.dateOfReport`, dateFormat(moment())));
  }

  render() {
    const { handleSubmit, detailedIncident } = this.props;
    if (!detailedIncident) return <div />;
    return (
      <div className={'root'}>
        <div className={'container'}>
          <div className={'max1024'}>
            <h1>Rapor Güncelle</h1>
            <RouteLocationStory
              locations={[
                RouteStory.reports(),
                RouteStory.report({
                  text: detailedIncident.injuredParty.casualty.basic.fullName,
                }),
                RouteStory.incidentUpdate(),
              ]}
            />
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <IncidentForm update />

              <RaisedButton
                style={{ float: 'right' }}
                type="submit"
                label="Rapor Güncelle"
                primary
              />
            </form>
            <Clear />

            <div style={{ marginTop: 32 }} className={'smallInfo'}>
              <b>Not: </b>Bu sayfadaki bilgiler raporlarda kullanılır ve tarih bilgileri hesaplama
              sonuçlarını etkiler. Değiştirilen bilgilerin raporlara yansıması için hesaplamaları
              tekrarlamanız gerekir.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const validate = (values, events) => {
  if (isEmpty(values)) return;
  const errors = { incident: {} };

  const requiredFields = [
    'dateOfIssue',
    'dateOfReport',
    'dateOfIncident',
    'dateOfDeath',
    'companyId',
    'incidentType',
    'damageFileIdentifier',
  ];
  requiredFields.forEach((f) => {
    if (isEmpty(values.incident[f])) {
      errors.incident[f] = REQUIRED;
    }
  });
  errors.incident.dateOfIssue = validateDate(values.incident.dateOfIssue);
  errors.incident.dateOfReport = validateDate(values.incident.dateOfReport);
  errors.incident.dateOfIncident = validateDate(values.incident.dateOfIncident);
  errors.incident.dateOfRetirement = validateDate(values.incident.dateOfRetirement, false);
  errors.incident.dateOfDeath = validateDate(values.incident.dateOfDeath);
  errors._error = validateIncidentLogical(values.incident, errors.incident);
  if (events.error) {
    errors._error = {
      ...errors._error,
      fileIdentifier: events.error.fileIdentifier,
    };
  }
  const cleanedError = clean(errors._error);
  errors._error = isEmpty(cleanedError) ? undefined : cleanedError;
  return errors;
};

BasicReport = reduxForm({ form: 'basicReport', validate })(BasicReport);

const selector = formValueSelector('basicReport');
export default connect((state) => ({
  incidentType: selector(state, 'incident.incidentType'),
  dateOfIncident: selector(state, 'incident.dateOfIncident'),
  dateOfReport: selector(state, 'incident.dateOfReport'),
  initialValues: state.appData[INIT_BASIC_REPORT] || { incident: {} },
  detailedIncident: state.appData[DETAILED_INCIDENT_CC],
}))(BasicReport);
