import React from 'react';
import { Field, FormSection } from 'redux-form';
import {
  renderCheckbox,
  renderEducationalStatus,
  renderEmployedStatus,
  renderGender,
  renderMaritalStatus,
  renderParentComboBox,
  renderTextField,
} from '../../utils/redux-metarial-ui-util';
import { fieldStyle } from '../../utils/material-ui-style-util';
import { normalizeDate } from '../../utils/redux-normalize';
import { getOppositeGender, MALE } from './Gender';

export const personalIdentificationNumber = {
  name: 'personalIdentificationNumber',
  component: (props) => (
    <Field
      style={fieldStyle}
      component={renderTextField}
      name="personalIdentificationNumber"
      label="Kimlik Numarası"
      {...props}
    />
  ),
};

export const fullName = {
  name: 'fullName',
  component: (props) => (
    <Field
      style={fieldStyle}
      component={renderTextField}
      name="fullName"
      label="İsim Soyisim"
      {...props}
    />
  ),
};
export const tempParentId = {
  name: 'tempParentId',
  component: ({ data, gender, custom }) => (
    <Field
      style={fieldStyle}
      component={renderParentComboBox}
      data={data}
      name="tempParentId"
      label={`${getOppositeGender(gender) === MALE ? 'Baba' : 'Anne'} Adı`}
      {...custom}
    />
  ),
};
export const dateOfBirth = {
  name: 'dateOfBirth',
  component: (props) => (
    <Field
      style={fieldStyle}
      component={renderTextField}
      normalize={normalizeDate}
      name="dateOfBirth"
      label="Doğum Tarihi"
      {...props}
    />
  ),
};
export const gender = {
  name: 'gender',
  component: (props) => (
    <Field style={fieldStyle} component={renderGender} name="gender" label="Cinsiyeti" {...props} />
  ),
};
export const endOfSupportToOverride = {
  name: 'basic.endOfSupportToOverride',
  component: (props) => (
    <FormSection name="basic">
      <Field
        style={fieldStyle}
        floatingLabelStyle={{ fontSize: 14 }}
        component={renderTextField}
        normalize={normalizeDate}
        name="endOfSupportToOverride"
        label="Destekten Çıkma Tarihi"
        {...props}
      />
    </FormSection>
  ),
};
export const hideShare = {
  name: 'basic.hideShare',
  component: (props) => (
    <FormSection name="basic">
      <Field
        style={{ ...fieldStyle, marginTop: 38 }}
        floatingLabelStyle={{ fontSize: 14 }}
        component={renderCheckbox}
        labelStyle={{
          lineHeight: '16px',
          marginTop: '-5px',
          width: '100%',
          color: '#7f7f7f',
          fontWeight: '400',
        }}
        name="hideShare"
        label="Pay Hakkını Sakla"
        {...props}
      />
    </FormSection>
  ),
};
export const martialStatus = {
  name: 'martialStatus',
  component: (props) => (
    <Field
      style={fieldStyle}
      component={renderMaritalStatus}
      name="martialStatus"
      label="Medeni Hali"
      {...props}
    />
  ),
};
export const disability = {
  name: 'disability',
  component: (props) => (
    <Field
      style={fieldStyle}
      component={renderTextField}
      name="disability"
      label="Sürekli Sakatlık"
      hintText="Oran"
      {...props}
    />
  ),
};
export const disabilityForChild = {
  name: 'disability',
  component: (props) => (
    <Field
      style={fieldStyle}
      component={renderTextField}
      name="disability"
      label="Engel Oranı (%)"
      {...props}
    />
  ),
};
export const dateOfMarriage = {
  name: 'dateOfMarriage',
  component: (props) => (
    <Field
      style={fieldStyle}
      component={renderTextField}
      normalize={normalizeDate}
      name="dateOfMarriage"
      label="Evlenme Tarihi"
      {...props}
    />
  ),
};
export const dateOfDivorce = {
  name: 'dateOfDivorce',
  component: (props) => (
    <Field
      style={fieldStyle}
      component={renderTextField}
      normalize={normalizeDate}
      name="dateOfDivorce"
      label="Boşanma Tarihi"
      {...props}
    />
  ),
};
export const educationalStatus = {
  name: 'educationalStatus',
  component: (props) => (
    <Field
      style={fieldStyle}
      component={renderEducationalStatus}
      name="educationalStatus"
      label="Öğrenci Mi?"
      {...props}
    />
  ),
};
export const dateOfGraduation = {
  name: 'dateOfGraduation',
  component: (props) => (
    <Field
      style={fieldStyle}
      component={renderTextField}
      normalize={normalizeDate}
      name="dateOfGraduation"
      label="Mezuniyet Yılı"
      {...props}
    />
  ),
};
export const dateOfEmployment = {
  name: 'dateOfGraduation',
  component: (props) => (
    <Field
      style={fieldStyle}
      component={renderTextField}
      normalize={normalizeDate}
      name="dateOfEmployment"
      label="İşe başlama Tarihi"
      {...props}
    />
  ),
};
export const employmentStatus = {
  name: 'employmentStatus',
  component: (props) => (
    <Field
      style={fieldStyle}
      component={renderEmployedStatus}
      name="employmentStatus"
      label="Çalışıyor Mu?"
      {...props}
    />
  ),
};
export const dateOfRetirement = {
  name: 'dateOfRetirement',
  component: (props) => (
    <Field
      style={fieldStyle}
      component={renderTextField}
      normalize={normalizeDate}
      name="dateOfRetirement"
      label="Emeklilik Tarihi"
      hintStyle={{ fontSize: 14 }}
      hintText="Varsa Emeklilik Tarihi"
      {...props}
    />
  ),
};
export const dateOfDeath = {
  name: 'dateOfDeath',
  component: (props) => (
    <Field
      style={fieldStyle}
      component={renderTextField}
      normalize={normalizeDate}
      name="dateOfDeath"
      label="Vefat Tarihi"
      {...props}
    />
  ),
};
export const alimonyAmount = {
  name: 'alimonyAmount',
  component: (props) => (
    <Field
      style={fieldStyle}
      component={renderTextField}
      name="alimonyAmount"
      label="Nafaka Ücreti"
      {...props}
    />
  ),
};
