/* eslint-disable no-prototype-builtins */
const messages = {
  'user.exists': 'Bu mail adresi ile kayıtlı kullanıcı var.',
  'auth.currentpwd.incorrect': 'Mevcut Şifre geçersiz.',
  'access.denied': 'İçerik Bulunamadı',
  'not.authenticated': 'Bu işlemi gerçekleştirmek için giriş yapmalısınız',
};

export function getErrorMessage(key) {
  if (!messages.hasOwnProperty(key)) return;
  return messages[key];
}
