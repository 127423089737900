import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import store from './store/configureStore';
import ColorPalette from './ColorPalette';
import About from './routes/about';
import Circular2020_04_01 from './routes/Circular2020_04_01';
import Home from './routes/home';
import NotFound from './routes/not-found';
import CalculationConfig from './routes/calculationConfig';
import Changelog from './routes/changeLog';
import CompanyCreate from './routes/company/CompanyCreate';
import CompanyDetail from './routes/company/CompanyDetail';
import CompanyWebhook from './routes/company/CompanyWebhook';
import EventDetail from './routes/company/EventDetail';
import CompanyUsers from './routes/company/CompanyUsers';
import RoleManagement from './routes/roleManagement/RoleManagement';
import UserInvite from './routes/company/UserInvite';
import CustomerList from './routes/company/CustomerList';
import ConfirmationMailResend from './routes/confirmationMailResend/ConfirmationMailResend';
import Contact from './routes/contact/Contact';
import CustomerPricingPage from './routes/customerPricing/CustomerPricingPage';
import ForgotPassword from './routes/forgotPassword/ForgotPassword';
import InvoiceListPage from './routes/invoice/InvoiceListPage';
import InvoiceDraftPage from './routes/invoice/InvoiceDraftPage';
import InvoicePage from './routes/invoice/InvoicePage';
import Login from './routes/login/Login';
import ConfirmMail from './routes/mailActivation/ConfirmMail';
import PricingPage from './routes/pricing/PricingPage';
import Register from './routes/register/Register';
import ReportList from './routes/report/ReportList';
import BasicReport from './routes/report/BasicReport';
import ResetPassword from './routes/resetPassword/ResetPassword';
import DocumentReport from './routes/report/DocumentReport';
import CalculationConfigCreateForm from './components/Report/CalculationConfig/CalculationConfigCreateForm';
import InjuredPartyCreateUpdateForm from './components/Report/InjuredParty/InjuredPartyCreateUpdateForm';
import Layout from './components/Layout/Layout';
import Authorization from './components/Authorization/Authorization';
import { WriteIncident } from './data/model/Privilege';
import PaymentCreateUpdateForm from './components/Report/Payments/PaymentCreateUpdateForm';
import SpecialPeriodCreateUpdateForm from './components/Report/SpecialPeriods/SpecialPeriodCreateUpdateForm';
import SalaryCreateUpdateForm from './components/Report/Salaries/SalaryCreateUpdateForm';
import BasicReportUpdateForm from './routes/report/BasicReportUpdateForm';
import ReportDetail from './routes/report/ReportDetail';
import ProfileDetail from './routes/user/ProfileDetail';
import Account from './routes/user/Account';
import Invitations from './components/User/Invitations';
import { RouteTracker } from './utils/google-analytics';
import './fas';

function muiTheme() {
  return getMuiTheme({
    userAgent: 'all',
    fontFamily: 'Roboto, HelveticaNeue-Light, sans-serif',
    palette: ColorPalette,
    datePicker: {
      color: ColorPalette.primary2Color,
      selectColor: ColorPalette.primary2Color,
    },
    flatButton: {
      textColor: ColorPalette.textColor,
      primaryTextColor: ColorPalette.primary1Color,
      secondaryTextColor: ColorPalette.accent1Color,
    },
    snackbar: {
      actionColor: ColorPalette.primary2Color,
    },
    radioButton: {
      labelColor: '#00000080',
      labelDisabledColor: '#00000040',
    },
  });
}

function App() {
  return (
    <Provider store={store}>
      <MuiThemeProvider muiTheme={muiTheme()}>
        <Router>
          <RouteTracker />
          <Switch>
            <Route exact path="/" component={Home} />

            <Route exact path="/about" component={About} />
            <Route exact path="/01-04-2020" component={Circular2020_04_01} />

            <Route exact path="/calculation-configs/:id/update" component={CalculationConfig} />

            <Route exact path="/change-log" component={Changelog} />

            <Route exact path="/company/detail" component={CompanyDetail} />
            <Route exact path="/company/list" component={CustomerList} />
            <Route exact path="/company/create" component={CompanyCreate} />
            <Route exact path="/company/integration" component={CompanyWebhook} />
            <Route exact path="/company/events/:id" component={EventDetail} />
            <Route exact path="/company/user/list" component={CompanyUsers} />
            <Route exact path="/company/user/role" component={RoleManagement} />
            <Route exact path="/company/user/invite" component={UserInvite} />

            <Route exact path="/confirm" component={ConfirmationMailResend} />

            <Route exact path="/contact" component={Contact} />

            <Route exact path="/customers/pricing" component={CustomerPricingPage} />

            <Route exact path="/invoices" component={InvoiceListPage} />
            <Route exact path="/invoices/drafts" component={InvoiceDraftPage} />
            <Route exact path="/invoices/:invoiceId" component={InvoicePage} />

            <Route exact path="/login" component={Login} />
            <Route exact path="/password/reset" component={ResetPassword} />
            <Route exact path="/me/activate" component={ConfirmMail} />
            <Route exact path="/forgotPassword" component={ForgotPassword} />

            <Route exact path="/pricing" component={PricingPage} />
            <Route exact path="/register" component={Register} />

            <Route exact path="/reports" component={ReportList} />
            <Route exact path="/reports/create/basic" component={BasicReport} />
            <Route exact path="/reports/create/document" component={DocumentReport} />
            <Route
              exact
              path="/reports/:incidentId/calculation-configs/create"
              render={(props) => (
                <CalculationConfigCreateForm
                  incidentId={props.match.params.incidentId}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/reports/:incidentId/injured-party/update"
              render={(props) => (
                <InjuredPartyCreateUpdateForm
                  incidentId={props.match.params.incidentId}
                  {...props}
                />
              )}
            />

            <Route
              exact
              path="/reports/:incidentId/payments/update"
              render={(props) => (
                <Layout>
                  <Authorization allowedRoles={[WriteIncident]} force>
                    <PaymentCreateUpdateForm
                      incidentId={props.match.params.incidentId}
                      {...props}
                    />
                  </Authorization>
                </Layout>
              )}
            />

            <Route
              exact
              path="/reports/:incidentId/special-periods/update"
              render={(props) => (
                <Layout>
                  <Authorization allowedRoles={[WriteIncident]} force>
                    <SpecialPeriodCreateUpdateForm
                      incidentId={props.match.params.incidentId}
                      {...props}
                    />
                  </Authorization>
                </Layout>
              )}
            />
            <Route
              exact
              path="/reports/:incidentId/salaries/update"
              render={(props) => (
                <Layout>
                  <Authorization allowedRoles={[WriteIncident]} force>
                    <SalaryCreateUpdateForm incidentId={props.match.params.incidentId} {...props} />
                  </Authorization>
                </Layout>
              )}
            />
            <Route
              exact
              path="/reports/:incidentId/update"
              render={(props) => (
                <Layout>
                  <Authorization allowedRoles={[WriteIncident]} force>
                    <BasicReportUpdateForm incidentId={props.match.params.incidentId} {...props} />
                  </Authorization>
                </Layout>
              )}
            />

            <Route
              exact
              path="/reports/:incidentId"
              render={(props) => (
                <Layout>
                  <Authorization allowedRoles={[WriteIncident]} force>
                    <ReportDetail incidentId={props.match.params.incidentId} {...props} />
                  </Authorization>
                </Layout>
              )}
            />

            <Route exact path="/me/detail" component={ProfileDetail} />
            <Route exact path="/me/account" component={Account} />
            <Route exact path="/me/invitations" component={Invitations} />

            <Route exact path="/*" component={NotFound} />
          </Switch>
        </Router>
      </MuiThemeProvider>
    </Provider>
  );
}

export default App;
