/* eslint-disable import/prefer-default-export */
import { SET_APP_DATA } from '../constants';

export function setAppData({ name, value }) {
  return {
    type: SET_APP_DATA,
    payload: {
      name,
      value,
    },
  };
}

export function clearAppData(name) {
  return {
    type: SET_APP_DATA,
    payload: {
      name,
      value: undefined,
    },
  };
}
