import React from 'react';
import Avatar from 'material-ui/Avatar';
import { connect } from 'react-redux';
import { Dialog, FlatButton, Paper } from 'material-ui';
import { intToRGB } from '../../utils/javascript-object-util';
import Clear from '../../components/Functional/Clear';
import Authorization from '../../components/Authorization/Authorization';
import { WriteRole } from '../../data/model/Privilege';
import RoleComboBox from '../../components/ComboBox/RoleComboBox';
import { USER } from '../../constants';
import { hidePB, showPB } from '../../actions/progressbarAction';
import CompanyUserApi from '../../data/api/CompanyUserApi';

class UserCardView extends React.Component {
  handleDeleteUser = () => {
    this.props.removeItem(this.props.user.id);
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = () => {
    this.handleDeleteUser();
    this.setState({ open: false });
  };

  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      open: false,
      roleId: null,
      initialRoleId: null,
    };
    this.mouseOver = this.mouseOver.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
  }

  mouseOver() {
    this.setState({ hover: true });
  }

  mouseOut() {
    this.setState({ hover: false });
  }

  handleRoleChange = (roleId) => {
    this.setState({ roleId });
  };

  handleRoleChangeClick = () => {
    const { user, companyUser, dispatch } = this.props;
    const { roleId } = this.state;
    showPB(dispatch);
    CompanyUserApi.update(user.getCompanyId, companyUser.id, {
      roleId,
    }).then(
      (r) => {
        this.setState({ initialRoleId: roleId });
        hidePB(dispatch);
      },
      () => hidePB(dispatch),
    );
  };

  componentDidMount() {
    const roleId = this.props.companyUser.role.id;
    this.setState({ roleId });
    this.setState({ initialRoleId: roleId });
  }

  render() {
    const { companyUser, user } = this.props;
    const style = this.state.hover ? { backgroundColor: '#f1f5f3' } : {};
    const { roleId, initialRoleId } = this.state;
    const roleChanged = roleId !== initialRoleId;
    const actions = [
      <FlatButton label="İptal" primary keyboardFocused onClick={this.handleClose} />,
      <FlatButton label="Çıkar" primary onClick={this.handleSubmit} />,
    ];

    return (
      <Paper
        style={{
          ...style,
          minWidth: 600,
          transition: 'all 100ms ease-in-out',
          position: 'relative',
          padding: '8px 8px 8px 4px',
          marginBottom: 2,
        }}
        onMouseEnter={this.mouseOver}
        onMouseLeave={this.mouseOut}
      >
        {/* <div style={{ position: 'absolute', top: 0, right: 0 }}> */}
        {/* <IconMenu */}
        {/* iconButtonElement={ */}
        {/* <IconButton> */}
        {/* <MoreVert /> */}
        {/* </IconButton> */}
        {/* } */}
        {/* anchorOrigin={{ horizontal: 'right', vertical: 'top' }} */}
        {/* targetOrigin={{ horizontal: 'right', vertical: 'top' }} */}
        {/* > */}
        {/* <MenuItem primaryText="Şirketten Çıkar" onClick={this.handleOpen} /> */}
        {/* <MenuItem primaryText="İptal" /> */}
        {/* </IconMenu> */}
        {/* </div> */}
        <Authorization allowedRoles={[WriteRole]}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 8,
              fontSize: '14px',
              color: 'gray',
              fontWeight: '400',
            }}
          >
            {user && user.getCompanyId && (
              <div>
                <FlatButton
                  label="Güncelle"
                  style={{
                    visibility: roleChanged ? 'visible' : 'hidden',
                    transition: 'opacity 300ms, visibility 300ms',
                    opacity: roleChanged ? 1 : 0,
                    float: 'left',
                    marginTop: 28,
                    marginRight: 8,
                  }}
                  onClick={this.handleRoleChangeClick}
                />
                <RoleComboBox
                  label="Rol"
                  style={{ width: 128, float: 'left' }}
                  disabled={user.id === companyUser.id}
                  companyId={user.getCompanyId}
                  value={companyUser.role.id}
                  onChange={(r) => this.handleRoleChange(r)}
                />
              </div>
            )}
          </div>
        </Authorization>
        <div
          style={{
            float: 'left',
            marginTop: 10,
            marginLeft: 12,
          }}
        >
          <Avatar backgroundColor={intToRGB(companyUser.id.hashCode(), 0.75)}>
            {companyUser.user.fullName.toUpperCase().charAt(0)}
          </Avatar>
        </div>
        <div style={{ float: 'left', marginLeft: 16, padding: '8px 0' }}>
          <div>
            <span style={{ fontWeight: 400 }}>{companyUser.user.fullName}</span>
          </div>
          <span>{companyUser.user.email}</span>
        </div>
        <Clear />
        <Dialog
          title="İşten Çıkarmak istediğinize emin misiniz?"
          actions={actions}
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
        >
          Bu işlem tamamlandığında;&nbsp;<strong>{companyUser.user.fullName}</strong>&nbsp; isimli
          çalışan, şirketin imkanlarından faydalanamayacaktır.
          <br />
          Çalışanı şirkete eklemek istediğinizde tekrar davet etmeniz gerekir.
        </Dialog>
      </Paper>
    );
  }
}

export default connect((state) => ({
  user: state.appData[USER],
}))(UserCardView);
