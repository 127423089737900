import React from 'react';
import BaseLayout from '../../components/BaseLayout/BaseLayout';
import ChangeLogPage from './ChangeLogPage';

const title = 'Güncellemeler';

export default function Changelog() {
  return (
    <BaseLayout>
      <ChangeLogPage title={title} />
    </BaseLayout>
  );
}
