import { USER } from '../constants';
import { clearAppData } from './appdata';
import { openSnackbar } from './snackbarActions';
import AccountApi from '../data/api/AccountApi';

const IPIFY_URL = 'https://api.ipify.org/?format=json';

export function clearUserData(dispatch) {
  localStorage.removeItem('authToken');
  localStorage.removeItem('user');
  if (dispatch) dispatch(clearAppData(USER));
}

export function logout(dispatch) {
  AccountApi.signOut();
  clearUserData(dispatch);
}

export function login(values, dispatch) {
  return AccountApi.login(values).then(
    (json) => {
      const { token } = json;
      if (token) {
        localStorage.setItem('authToken', token);
        return AccountApi.getMe(dispatch)
          .then(() => {
            window.location = '/';
            return Promise.resolve(fetch(IPIFY_URL));
          })
          .then((res) => res.json())
          .then((res) => localStorage.setItem('ip', res.ip));
      }
    },
    (e) => {
      dispatch(openSnackbar(e.errors.error));
      return Promise.reject(e);
    },
  );
}

/** *
 * This function should not be called without the component mounted.
 * @returns {boolean}
 */
export function isLogin() {
  const token = localStorage.getItem('authToken');
  return !!token;
}

/** *
 * This function should not be called without the component mounted.
 * @returns {boolean}
 */
export function loginRequire() {
  if (isLogin()) return true;

  window.url = '/login';
  return false;
}

export function isLocalStorageAccessible() {
  return typeof localStorage !== 'undefined';
}

export function getViewIfUnauthorized(user, view, roles, and, force) {
  if (!isLocalStorageAccessible()) return null;
  if (!loginRequire()) return null;
  if (!user) return null;
  if (!roles) return null;

  const pass = user.isAuthorized(roles, and);
  if (!pass && force) window.url = '/not-found';
  return pass ? view : null;
}
