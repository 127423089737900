import React from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-quill/dist/quill.snow.css';
import { Dialog, FlatButton, RaisedButton } from 'material-ui';
import { Field, reduxForm } from 'redux-form';
import {
  renderActionCategoryActuary,
  renderActionCategoryCustomer,
} from '../../utils/redux-metarial-ui-util';
import { openSnackbar } from '../../actions/snackbarActions';
import { hidePB, showPB } from '../../actions/progressbarAction';
import { ACTUARY, CUSTOMER, WriteCalculation } from '../../data/model/Privilege';
import Authorization from '../Authorization/Authorization';
import ActionCategory, {
  MARK_AS_RESOLVED,
  REQUEST_REVIEW,
  SELF_PROCESSED,
} from '../../data/model/ActionCategory';
import Clear from '../Functional/Clear';
import { setAppData } from '../../actions/appdata';
import { LAST_VISITED_TAB_REPORT_DETAIL, USER } from '../../constants';
import ActionApi from '../../data/api/ActionApi';
import IncidentApi from '../../data/api/IncidentApi';
import FileApi from '../../data/api/FileApi';

class ActionCreate extends React.Component {
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  approvalRequest = () => {
    const { incidentId } = this.props;
    const { dispatch } = this.props;
    showPB(dispatch);
    this.handleClose();
    const { text } = this.state;
    const plainText = this.state.plainText.trim();

    const data = { category: REQUEST_REVIEW };
    if (!isEmpty(plainText)) {
      data.message = text;
    }

    ActionApi.create(incidentId, data).then(
      () => {
        dispatch(openSnackbar('İstek Gönderildi'));
        dispatch(
          setAppData({
            name: LAST_VISITED_TAB_REPORT_DETAIL,
            value: 'details',
          }),
        );
        IncidentApi.find(dispatch, incidentId);
        ActionApi.list(dispatch, incidentId, 0, 20);
        hidePB(dispatch);
      },
      (r) => {
        dispatch(openSnackbar(`Bir hata oluştu ${r.message}`));
        hidePB(dispatch);
      },
    );
  };

  actions = [
    <FlatButton keyboardFocused label="İptal" primary onClick={this.handleClose} />,
    <FlatButton label="İstek Gönder" primary onClick={() => this.approvalRequest()} />,
  ];

  onSubmit = (values) => {
    const { dispatch } = this.props;
    const { incidentId } = this.props;
    const { text } = this.state;
    const plainText = this.state.plainText.trim();

    const data = { category: values.category };
    if (!isEmpty(plainText)) {
      data.message = text;
    }
    if (values.category === null && !data.message) {
      dispatch(openSnackbar('Yorum alanı boş bırakılamaz.'));
      return;
    }
    showPB(dispatch);
    ActionApi.create(incidentId, data).then(
      () => {
        this.setState({ plainText: '' });
        this.setState({ text: '' });
        this.props.reset();
        if (values.category) {
          IncidentApi.find(dispatch, incidentId);
        }
        if (values.category === MARK_AS_RESOLVED) {
          FileApi.list(dispatch, incidentId);
          dispatch(
            setAppData({
              name: LAST_VISITED_TAB_REPORT_DETAIL,
              value: 'details',
            }),
          );
        }
        ActionApi.list(dispatch, incidentId, 0, 20);
        dispatch(openSnackbar('Durum güncellemesi başarılı'));
        hidePB(dispatch);
      },
      (r) => {
        dispatch(openSnackbar(r.errors.error));
        hidePB(dispatch);
      },
    );
  };

  modules = {
    toolbar: [
      [{ header: [2, 3, 4, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['clean'],
    ],
  };

  formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ];

  constructor(props) {
    super(props);
    this.state = {
      text: '',
      plainText: '',
      open: false,
    }; // You can also pass a Quill Delta here
    if (document) {
      this.quill = require('react-quill');
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(content, delta, source, editor) {
    console.log(editor.getHTML());
    console.log(editor.getText());
    this.setState({ text: content });
    this.setState({ plainText: editor.getText() });
  }

  render() {
    const Quill = this.quill;
    const { user } = this.props;
    const writeCalculation = user && user.isAuthorized([CUSTOMER, WriteCalculation], true);
    if (Quill) {
      return (
        <div
          style={{
            minWidth: 380,
            position: 'relative',
            marginTop: 4,
          }}
        >
          <form
            onSubmit={this.props.handleSubmit(this.onSubmit)}
            style={{
              boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 5px',
            }}
          >
            <Quill
              style={{ background: 'white' }}
              onChange={this.handleChange}
              theme="snow"
              value={this.state.text}
              placeholder="Durumu anlatan bir not giriniz..."
              modules={this.modules}
              formats={this.formats}
            />

            {/* Todo Buraya daha iyi bi çözüm üret */}
            <Authorization allowedRoles={[ACTUARY]}>
              <div>
                <RaisedButton
                  style={{ float: 'right', marginTop: 16 }}
                  label="Gönder"
                  primary
                  type="submit"
                />
                <Field
                  name="category"
                  component={renderActionCategoryActuary}
                  label="Yeni Durum"
                  style={{
                    float: 'right',
                    margin: '-14px 16px 0 0',
                    width: 216,
                  }}
                />
              </div>
            </Authorization>
            <Authorization allowedRoles={[CUSTOMER]}>
              {this.props.actionCategory === SELF_PROCESSED ? (
                <div>
                  {writeCalculation ? (
                    <div style={{ float: 'right', marginTop: 16 }}>
                      <RaisedButton label="Aktüer Onayı İste" primary onClick={this.handleOpen} />
                    </div>
                  ) : (
                    <div style={{ float: 'right', marginTop: 16 }}>
                      <RaisedButton type="submit" label="Gönder" primary />
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <RaisedButton
                    style={{ float: 'right', marginTop: 16 }}
                    label="Gönder"
                    primary
                    type="submit"
                  />
                  <Field
                    name="category"
                    component={renderActionCategoryCustomer}
                    label="Yeni Durum"
                    style={{
                      float: 'right',
                      margin: '-14px 16px 0 0',
                      width: 216,
                    }}
                  />
                </div>
              )}
            </Authorization>
          </form>
          <Clear />
          <Dialog
            title="Rapor Onay Bekleniyor Şeklinde İşaretlensin Mi?"
            actions={this.actions}
            modal={false}
            open={this.state.open}
            onRequestClose={this.handleClose}
          >
            <b>
              <p>
                Rapor Aktüer onayından geçirilmek üzere{' '}
                <strong>{ActionCategory.requestReview}</strong> şeklinde işaretlenecek.
              </p>
              <p className={'warnInfo'}>
                <FontAwesomeIcon icon="exclamation" fixedWidth /> İsteği yollamadan önce poliçe ile
                ilgili ek dosyaları yüklediğinizden emin olun.
              </p>
            </b>
          </Dialog>
        </div>
      );
    }
    return null;
  }
}

ActionCreate = reduxForm({ form: 'reportActionCreate' })(ActionCreate);
export default connect((state) => ({
  initialValues: { category: null },
  user: state.appData[USER],
}))(ActionCreate);
