import React from 'react';
import { connect } from 'react-redux';
import { Paper, Subheader } from 'material-ui';
import { FormSection } from 'redux-form';
import { DEATH } from '../../data/model/IncidentType';
import { paperStyle } from '../../utils/material-ui-style-util';
import Clear from '../Functional/Clear';
import PersonForm from './PersonForm';

class CasualtyForm extends React.Component {
  render() {
    return (
      <Paper style={paperStyle}>
        <Subheader className={'basicReportSubheader'}>
          {`${this.props.incidentType === DEATH ? 'Müteveffanın' : 'Mağdurun'} Bilgileri`}
        </Subheader>
        <Clear />
        <FormSection style={{ padding: '0 0 16px 16px' }} name="injuredParty.casualty">
          <PersonForm />
          <Clear />
        </FormSection>
      </Paper>
    );
  }
}

export default connect()(CasualtyForm);
