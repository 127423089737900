import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import appData from './appdata';
import runtime from './runtime';
import snackbar from './snackbar';

export default combineReducers({
  runtime,
  appData,
  snackbar,
  form: formReducer,
});
