import React from 'react';
import { connect } from 'react-redux';
import { Field, FormSection } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlatButton } from 'material-ui';
import { renderSpecialPeriod, renderTextField } from '../../utils/redux-metarial-ui-util';
import Clear from '../Functional/Clear';
import { fieldStyle, getFieldArrayItemStyle } from '../../utils/material-ui-style-util';
import { normalizeDate } from '../../utils/redux-normalize';
import { DETAILED_INCIDENT_CC } from '../../constants';

class SpecialPeriodForm extends React.Component {
  render() {
    const { index, fields } = this.props;
    return (
      <FormSection style={getFieldArrayItemStyle(index, fields.length)} name={this.props.name}>
        <Field
          style={fieldStyle}
          component={renderTextField}
          normalize={normalizeDate}
          name="from"
          label="Başlangıc"
        />
        <Field
          style={fieldStyle}
          component={renderTextField}
          normalize={normalizeDate}
          name="till"
          label="Bitiş"
        />
        <Field
          style={fieldStyle}
          component={renderSpecialPeriod}
          name="category"
          label="Kategori"
        />
        <Clear />
        <div>
          <FlatButton
            style={{ position: 'absolute', minWidth: 36, top: 0, right: 0 }}
            className="material-icons"
            tooltip="Kaldır"
            icon={<FontAwesomeIcon fixedWidth icon="trash" />}
            onClick={() => fields.remove(index)}
          />
        </div>
      </FormSection>
    );
  }
}

export default connect((state) => ({
  detailedIncident: state.appData[DETAILED_INCIDENT_CC],
}))(SpecialPeriodForm);
