import IncidentSummary from './IncidentSummary';
import IssuerResponse from './IssuerResponse';

export default class PricingIncident {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.summary = new IncidentSummary(config.summary);
    this.issuer = new IssuerResponse(config.issuer);
  }
}
