import React from 'react';
import BaseLayout from '../../components/BaseLayout/BaseLayout';

function NotFound() {
  return (
    <BaseLayout>
      <div className={'root'}>
        <div className={'container'} style={{ textAlign: 'center' }}>
          <h1 className={'headerUnderline'}>Sayfa Bulunamadı</h1>
          <p>Maalesef, görüntülemeye çalıştığınız sayfa mevcut değil.</p>
        </div>
      </div>
    </BaseLayout>
  );
}

export default NotFound;
