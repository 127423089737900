import React from 'react';
import RCPagination from 'rc-pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Pagination({ name, ...props }) {
  return (
    <RCPagination
      style={props.style}
      showTotal={(total, range) => `${total} ${name} ${range[0]} - ${range[1]} arası listeleniyor`}
      total={props.total}
      onChange={props.onChange}
      showTitle={false}
      current={props.current}
      prevIcon={<FontAwesomeIcon fixedWidth icon="caret-left" />}
      nextIcon={<FontAwesomeIcon fixedWidth icon="caret-right" />}
      jumpPrevIcon={<FontAwesomeIcon fixedWidth icon="ellipsis-h" />}
      jumpNextIcon={<FontAwesomeIcon fixedWidth icon="ellipsis-h" />}
    />
  );
}
