import { SALARY_TYPE_GROSS, SALARY_TYPE_MINIMUM_WAGE, SALARY_TYPE_NET } from '../SalaryType';

export default class SalaryResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.id = config.id;
    this.net = config.net;
    this.gross = config.gross;
    this.year = config.year && config.year.toString();
    this.month = config.month && config.month.toString();
    this.workingDays = config.workingDays && config.workingDays.toString();
    if (this.net && this.net !== -1) this.salaryType = SALARY_TYPE_NET;
    else if (this.gross && this.gross !== -1) this.salaryType = SALARY_TYPE_GROSS;
    else this.salaryType = SALARY_TYPE_MINIMUM_WAGE;
  }
}
