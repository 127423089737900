import React from 'react';
import { reduxForm } from 'redux-form';
import { Paper } from 'material-ui';
import MultipleInfoText from '../../components/Functional/MultipleInfoText';
import BaseLayout from '../../components/BaseLayout/BaseLayout';

class Contact extends React.Component {
  render() {
    const contact = [
      {
        title: 'Adres',
        content: (
          <address>
            Kasımpatı Sok. No:4 – 1. <br /> Levent-Beşiktaş İSTANBUL
          </address>
        ),
      },
      {
        title: 'Telefon',
        content: <a href="tel:+90 (212) 270 13 02 ">+90 (212) 270 13 02</a>,
      },
      {
        title: 'E-mail',
        content: <a href="mailto:info@inovayonetim.com">info@inovayonetim.com</a>,
      },
    ];
    return (
      <BaseLayout>
        <div className={'max380'} style={{ marginTop: 64 }}>
          <h1
            className={'headerUnderline'}
            style={{
              textAlign: 'center',
              display: 'table',
              margin: 'auto',
              marginBottom: 24,
            }}
          >
            İletişim Bilgileri
          </h1>

          <Paper>
            <MultipleInfoText style={{ padding: 16 }} data={contact} labelStyle={{ width: 50 }} />
            <a
              target="_blank"
              href="https://www.google.com/maps/dir//41.0757412,29.0181547/@41.0757381,29.0176433,18.79z/data=!4m2!4m1!3e3"
            >
              <img
                alt="https://www.google.com/maps/@41.0757518,29.0181748,20z"
                src="https://maps.googleapis.com/maps/api/staticmap?center=41.07577,29.01837&zoom=15&size=380x380&key=AIzaSyCR-K_EUtYDutLpUtqvRy6T5vTgY3V14nQ"
              />
            </a>
          </Paper>
        </div>
      </BaseLayout>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const requiredFields = ['İsim', 'Email', 'Konu', 'İletiniz'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Bu alanı doldurmak zorunlu';
    }
  });
  if (values.Email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.Email)) {
    errors.Email = 'Geçersiz Email';
  }
  return errors;
};

Contact = reduxForm({ form: 'ContactForm', validate })(Contact);

export default Contact;
