import PersonUpdateRequest from './PersonUpdateRequest';
import { convertToDate } from '../../../../utils/javascript-object-util';
import DivorceUpdateRequest from './DivorceUpdateRequest';
import { CLEANER_DATETIME } from '../../../../utils/CleanerValues';

export default class SpouseUpdateRequest {
  constructor(config, { casualtyDateOfBirth }) {
    if (typeof config !== 'object') return;
    this.basic = new PersonUpdateRequest(config.basic, {
      dateOfBirth: casualtyDateOfBirth,
    });
    this.dateOfMarriage = config.dateOfMarriage
      ? convertToDate(config.dateOfMarriage)
      : CLEANER_DATETIME;
    this.dateOfRetirement = config.dateOfRetirement
      ? convertToDate(config.dateOfRetirement)
      : CLEANER_DATETIME;
    this.employmentStatus = config.employmentStatus;
    this.status = config.status;
    this.divorce = new DivorceUpdateRequest(config.divorce);
  }
}
