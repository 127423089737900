export default class CustomerPricingResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.process = config.process;
    this.review = config.review;
    this.comparison = config.comparison;
    this.revision = config.revision;
    this.reviewRevision = config.reviewRevision;
    this.comparisonRevision = config.comparisonRevision;
  }
}
