import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import Link from '../../components/Link/Link';
import ForgotPasswordForm from './ForgotPasswordForm';
import BaseLayout from '../../components/BaseLayout/BaseLayout';

function ForgotPassword() {
  return (
    <BaseLayout>
      <div className={'max380'} style={{ textAlign: 'center', marginTop: 64 }}>
        <h1>Şifremi Unuttum</h1>
        <ForgotPasswordForm form="forgotPassword" />
        <Link to="/login">
          <FlatButton
            style={{ marginTop: 16 }}
            labelStyle={{ textTransform: 'none' }}
            fullWidth
            label="Şifreni hatırladın mı? Giriş Yap."
          />
        </Link>
      </div>
    </BaseLayout>
  );
}

export default ForgotPassword;
