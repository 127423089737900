import React from 'react';
import { connect } from 'react-redux';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import { useHistory, useLocation } from 'react-router';
import qs from 'qs';
import Link from '../../components/Link/Link';
import { DIALOG_OPEN } from '../../constants/index';
import { setAppData } from '../../actions/appdata';
import RegisterForm from './RegisterForm';
import BaseLayout from '../../components/BaseLayout/BaseLayout';

function Register({ dispatch, isDialogOpen }) {
  const history = useHistory();
  const { search } = useLocation();

  const query = qs.parse(search, { ignoreQueryPrefix: true });

  const handleClose = () => {
    dispatch(setAppData({ name: DIALOG_OPEN, value: false }));
    history.push('/login');
  };

  const actions = [
    <FlatButton label="Anladım" secondary keyboardFocused onClick={() => handleClose()} />,
  ];

  return (
    <BaseLayout>
      <div className={'max380'} style={{ textAlign: 'center' }}>
        <h1>Kayıt Ol</h1>
        <RegisterForm query={query} />
        <div>
          <Dialog
            title="Kayıt İşlemi Başarılı"
            actions={actions}
            modal={false}
            open={isDialogOpen}
            onRequestClose={handleClose}
          >
            Mail adresinize bir aktivasyon bağlantısı gönderdik bu bağlantıya tıklayarak üyelik
            işlemlerinizi tamamlayabilirsiniz.
            <br />
            <br />
            <p>
              Gereksiz postalar kısmını kontrol etmeyi unutmayın. Mail&nbsp; gelmediyse&nbsp;
              <strong>Aktivasyon Maili Gelmedi mi?</strong> seçeneğinden tekrar mail
              gönderebilirsiniz.
            </p>
          </Dialog>

          <Link className={'link'} to="/login">
            <FlatButton
              style={{ marginTop: 16 }}
              labelStyle={{ textTransform: 'none' }}
              fullWidth
              label="Bir hesabın var mı? Giriş Yap"
            />
          </Link>

          <Link className={'link'} to="/confirm">
            <FlatButton
              style={{ marginTop: 16 }}
              labelStyle={{ textTransform: 'none' }}
              fullWidth
              label="Aktivasyon Maili Gelmedi Mi?"
            />
          </Link>
        </div>
      </div>
    </BaseLayout>
  );
}

export default connect((state) => ({
  isDialogOpen: state.appData[DIALOG_OPEN],
}))(Register);
