import React from 'react';
import { connect } from 'react-redux';
import {  Paper, RaisedButton } from 'material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEmpty from 'lodash/isEmpty';
import { INVOICE } from '../../constants';
import { hidePB, showPB } from '../../actions/progressbarAction';
import { clearAppData } from '../../actions/appdata';
import InvoiceApi from '../../data/api/InvoiceApi';
import InvoiceItemViewHolder from './InvoiceItemViewHolder';
import IncidentSummary from '../../data/model/response/IncidentSummary';
import IssuerResponse from '../../data/model/response/IssuerResponse';
import { openSnackbar } from '../../actions/snackbarActions';
import InvoiceCancelForm from './InvoiceCancelForm';
import Clear from '../../components/Functional/Clear';
import { ACTUARY, ReadPricing } from '../../data/model/Privilege';
import Authorization from '../../components/Authorization/Authorization';
import { toMoney } from '../../utils/javascript-object-util';
import Layout from '../../components/Layout/Layout';
import Dialog from "../../components/Functional/Dialog";

class InvoicePage extends React.Component {
  fetchData = () => {
    const { dispatch, match } = this.props;

    showPB(dispatch, 'Fatura Detayı Getiriliyor');
    dispatch(clearAppData(INVOICE));
    InvoiceApi.find(dispatch, match.params.invoiceId).then(
      () => {
        hidePB(dispatch);
      },
      () => {
        hidePB(dispatch);
      },
    );
  };

  cancel = () => {
    const { detailedInvoice, dispatch } = this.props;
    showPB(dispatch);
    InvoiceApi.cancel(detailedInvoice.id, 'test').then(
      () => {
        dispatch(openSnackbar('Fatura İptal Edildi'));
        hidePB(dispatch);
      },
      () => {
        dispatch(openSnackbar('Bir Hata Oluştu'));
        hidePB(dispatch);
      },
    );
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    const { detailedInvoice } = this.props;

    if (!detailedInvoice) return <div />;
    const { invoice, cancellation } = detailedInvoice;
    const isCancelled = !isEmpty(cancellation);
    const data = [];
    data.push({
      title: 'İşlem Tarihleri',
      content: (
        <span>
          {invoice.from} <FontAwesomeIcon icon="arrows-alt-h" /> {invoice.till}
        </span>
      ),
    });
    data.push({
      title: 'Oluşturulma Tarihi',
      content: invoice.createdAt,
    });
    data.push({
      title: 'Son Ödeme Tarihi',
      content: invoice.dueDate,
    });
    if (isCancelled) {
      const { details, issuer } = cancellation;
      const color = 'rgb(198, 40, 40)';
      data.push({
        title: 'İptal Tarihi',
        content: details.createdAt,
        color,
      });
      data.push({
        title: 'İptal Nedeni',
        content: details.reason,
        color,
      });
      data.push({
        title: 'İptal Eden',
        content: issuer.fullName,
        color,
      });
    }
    return (
      <Layout>
        <Authorization allowedRoles={[ReadPricing]} force>
          <div className={'root'}>
            <div className={`${'container'} ${'max1024'}`}>
              <h1>Faturala Detayı</h1>
              <div
                style={{
                  marginBottom: 16,
                  display: 'flex',
                }}
              >
                <Paper
                  style={{
                    padding: 16,
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  {isCancelled && <div className={'cancelSign'}>İPTAL</div>}
                  {data.map((d) => (
                    <div
                      style={{
                        fontSize: '17px',
                        fontWeight: 400,
                        lineHeight: 1.5,
                        color: d.color,
                      }}
                    >
                      <span>
                        <span style={{ display: 'inline-block', width: 170 }}>{d.title}</span>
                        :&nbsp;
                      </span>
                      <span> {d.content}</span>
                    </div>
                  ))}
                </Paper>
                <Paper
                  style={{
                    padding: '10px 0',
                    marginLeft: 16,
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <div className={'invoice-price-container'}>
                    <span className={'invoice-price-label'}>Ara Toplam</span>
                    <span className={'invoice-price-amount'}>
                      {toMoney(invoice.dueAmount - invoice.taxAmount, '')}
                      <span className={'invoice-price-symbol'}>₺</span>
                    </span>
                    <Clear />
                  </div>
                  {invoice.stoppage !== 0 && (
                    <div className={'invoice-price-container'}>
                      <span className={'invoice-price-label'}>Vergi Tevkifatı</span>
                      <span className={'invoice-price-amount'} style={{ color: '#AAA' }}>
                        {toMoney(invoice.stoppage, '')}
                        <span className={'invoice-price-symbol'}>₺</span>
                      </span>
                      <Clear />
                    </div>
                  )}
                  <div className={'invoice-price-container'}>
                    <span className={'invoice-price-label'}>Vergiler</span>
                    <span className={'invoice-price-amount'}>
                      {toMoney(invoice.taxAmount, '')}
                      <span className={'invoice-price-symbol'}>₺</span>
                    </span>
                    <Clear />
                  </div>
                  <div
                    className={'invoice-price-container'}
                    style={{ marginTop: 6, borderTop: '1px solid #e0e0e0' }}
                  >
                    <span
                      className={'invoice-price-label'}
                      style={{
                        fontSize: '20px',
                      }}
                    >
                      Genel Toplam
                    </span>
                    <span
                      className={'invoice-price-amount'}
                      style={{
                        fontSize: '34px',
                        marginRight: 0,
                      }}
                    >
                      {toMoney(invoice.dueAmount, '')}
                      <span className={'invoice-price-symbol'}>₺</span>
                    </span>
                    <Clear />
                  </div>
                </Paper>
              </div>
              <Paper className={'invoiceItemsContainer'} style={{ padding: '0 8px 16px 8px' }}>
                <InvoiceItemViewHolder
                  item={{
                    price: 'Tutar',
                    incident: {
                      summary: new IncidentSummary({
                        fileIdentifier: 'Dosya Numarası',
                        casualtyName: 'Mağdur/Müteveffa',
                        casualtySurname: '',
                      }),
                      issuer: new IssuerResponse({
                        name: 'Oluşturan',
                        surname: '',
                      }),
                    },
                  }}
                  title
                />
                {detailedInvoice.items.map((i) => (
                  <InvoiceItemViewHolder item={i} isInvoice />
                ))}
              </Paper>
              {!isCancelled && (
                <Authorization allowedRoles={[ACTUARY]}>
                  <RaisedButton
                    style={{ float: 'right', marginTop: 16 }}
                    label="Faturayı İptal Et"
                    secondary
                    onClick={this.handleOpen}
                  />
                </Authorization>
              )}
              <Clear />
              {!isCancelled && (
                <Authorization allowedRoles={[ACTUARY]}>
                  <Dialog
                    title="Faturayı İptal Et"
                    modal={false}
                    contentStyle={{ width: 304 }}
                    open={this.state.open}
                  >
                    <InvoiceCancelForm
                      onSubmitSuccess={() => {
                        this.fetchData();
                      }}
                      onRequestClose={this.handleClose}
                      invoiceId={detailedInvoice.invoice.id}
                    />
                  </Dialog>
                </Authorization>
              )}
            </div>
          </div>
        </Authorization>
      </Layout>
    );
  }
}

export default connect((state) => ({
  detailedInvoice: state.appData[INVOICE],
}))(InvoicePage);
