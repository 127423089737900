import find from 'lodash/find';
import { datePreciseDiff } from '../../../utils/javascript-object-util';

export default function CalculationInfo() {
  const endOfSupports = this.calculation.details.buildEndOfSupportPeriods(this.injuredParty);
  const { casualty } = this.injuredParty;
  const casualtyBasic = casualty.basic;
  const isChild = casualtyBasic.getIsChild;
  const { dateOfReport } = this.incident;
  const workingDate = casualtyBasic.getStartWorkingDate;
  const endOfSupport = find(endOfSupports, (p) => p.personId === casualtyBasic.id).period.end;
  const unknownSupportYear = datePreciseDiff(dateOfReport, endOfSupport);
  const body = [
    [
      'ÖLÜM TABLOSU',
      {
        columns: [
          {
            text: ':',
            width: 10,
          },
          {
            text: this.config.getMortalityTableForReport,
            width: '*',
          },
        ],
      },
    ],
    [
      this.config.isTechnicalInterest ? 'TEKNİK FAİZ' : 'İSKONTO FAİZİ',
      {
        columns: [
          {
            text: ':',
            width: 10,
          },
          {
            text: `%${this.config.interest}`,
            width: '*',
          },
        ],
      },
    ],
    [
      'BİLİNMEYEN DÖNEM DESTEK SÜRESİ',
      {
        columns: [
          {
            text: ':',
            width: 10,
          },
          {
            text: `${unknownSupportYear || '0 yıl'}`,
            width: '*',
          },
        ],
      },
    ],
  ];
  if (isChild) {
    body.push([
      'ÇALIŞMA TARİHİ',
      {
        columns: [
          {
            text: ':',
            width: 10,
          },
          {
            text: workingDate,
            width: '*',
          },
        ],
      },
    ]);
  }
  return [
    {
      text: 'Hesaplamaya Dair Bilgiler',
      style: 'h2',
      margin: [0, 20, 0, 0],
    },
    {
      margin: [63, 10, 20, 0],
      table: {
        width: ['*', '200'],
        body,
      },
      layout: 'noBorders',
    },
  ];
}
