/* eslint-disable no-underscore-dangle,no-param-reassign,consistent-return,prefer-destructuring,prefer-template,no-return-await,valid-typeof,prefer-promise-reject-errors,radix */
import { setAppData } from '../../actions/appdata';
import { FILE, FILE_LIST } from '../../constants';
import Connection from './Connection';
import { fileToFileList } from '../../utils/file-upload-util';
import FileResponse from '../model/response/FileResponse';

export default class FileApi extends Connection {
  static list(dispatch, incidentId) {
    return this._getSecurely(`/incidents/${incidentId}/files`)
      .then(...this.justData)
      .then((r) => {
        const data = r ? r.bindList(FileResponse) : [];
        dispatch(setAppData({ name: FILE_LIST, value: data }));
        return data;
      })
      .catch(this.catchError);
  }

  static find(dispatch, incidentId, id) {
    return this._getSecurely(`/incidents/${incidentId}/files/${id}`)
      .then(...this.justData)
      .then((r) => {
        dispatch(setAppData({ name: FILE, value: r }));
      })
      .catch(this.catchError);
  }

  static getSignedUrl(incidentId, id) {
    return this._getSecurely(`/incidents/${incidentId}/files/${id}`)
      .then(...this.justData)
      .then((r) => (r ? r.bindClass(FileResponse) : {}))
      .catch(this.catchError);
  }

  static update(incidentId, id, data) {
    return this._patch(`/incidents/${incidentId}/files/${id}`, data);
  }

  static create(fileList, incidentId, categoryId) {
    const data = fileToFileList(fileList);
    return this._postFormData(`/incidents/${incidentId}/files/categories/${categoryId}`, data);
  }

  static delete(incidentId, id) {
    return this._delete(`/incidents/${incidentId}/files/${id}`);
  }
}
