import React, { useState } from 'react';
import { FlatButton, Paper } from 'material-ui';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sumBy from 'lodash/sumBy';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router';
import TransactionViewHolder from './TransactionViewHolder';
import Clear from '../../components/Functional/Clear';
import InvoiceDraftApi from '../../data/api/InvoiceDraftApi';
import { hidePB, showPB } from '../../actions/progressbarAction';
import { openSnackbar } from '../../actions/snackbarActions';
import { ACTUARY, ReadIncident } from '../../data/model/Privilege';
import Authorization from '../../components/Authorization/Authorization';
import TransactionForm from './TransactionForm';
import { InfoTextReportViewHolder } from '../report/ReportViewHolder';
import Dialog from "../../components/Functional/Dialog";

function PricingViewHolder({ dispatch, pricing, companyId }) {
  const history = useHistory();
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const [expand, setExpand] = useState(false);
  const mouseOver = () => {
    setHover(true);
  };

  const mouseOut = () => {
    setHover(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClick = () => {
    setExpand(!expand);
  };

  const addItemToDraft = () => {
    showPB(dispatch);
    InvoiceDraftApi.addItem(companyId, pricing.incident.summary.id).then(
      () => {
        dispatch(openSnackbar('Dosya Mutabakata Eklendi'));
        hidePB(dispatch);
      },
      (r) => {
        dispatch(openSnackbar(r.errors.error));
        hidePB(dispatch);
      },
    );
  };

  const data = [];
  const { incident, draft } = pricing;
  const { summary } = incident;
  const hasDraft = !isEmpty(draft);
  data.push({
    title: 'Dosya Numarası',
    content: summary.fileIdentifier,
    width: 120,
  });
  data.push({
    title: 'Mağdur/Müteveffa',
    content: summary.fullName,
    width: 216,
  });
  return (
    <Paper style={{ marginTop: 5, position: 'relative' }}>
      <div
        style={{
          padding: '8px 16px',
          borderBottom: '1px solid gainsboro',
          cursor: 'pointer',
          position: 'relative',
          background: hover ? '#f3f3f3' : 'white',
        }}
        onMouseEnter={mouseOver}
        onMouseLeave={mouseOut}
        onClick={onClick}
      >
        {!hasDraft && <div className={'draftSign'} />}
        {data.map((d) => (
          <InfoTextReportViewHolder
            style={{ width: d.width, float: 'left' }}
            title={d.title}
            content={d.content}
          />
        ))}

        <div
          style={{
            position: 'absolute',
            top: '50%',
            right: '16px',
            transform: 'translate(0,-50%)',
            fontSize: '20px',
            fontWeight: '400',
          }}
        >
          {sumBy(
            pricing.transactions,
            (t) =>
              (t.category === 'withdrawal' || t.category === 'correctionWithdrawal' ? -1 : 1) *
              t.amount,
          )}
          ₺
        </div>
        <Clear />
      </div>

      <div
        style={{
          position: 'relative',
          display: expand ? 'block' : 'none',
          padding: '8px 16px 8px',
          backgroundColor: '#FAFAFA',
        }}
      >
        <TransactionViewHolder
          title
          transaction={{
            amount: 'Tutar',
            message: 'Açıklama',
            createdAt: 'Tarih',
          }}
        />
        {pricing.transactions.map((t) => (
          <TransactionViewHolder transaction={t} />
        ))}
        <Authorization allowedRoles={[ACTUARY]}>
          <div style={{ float: 'right' }}>
            <FlatButton
              primary
              style={{ float: 'left', marginRight: 16 }}
              label="Ücreti Düzenle"
              onClick={handleOpen}
            />
            {hasDraft ? (
              <FlatButton primary style={{ float: 'left' }} label="Mutabakata Eklenmiş" disabled />
            ) : (
              <FlatButton
                primary
                style={{ float: 'left' }}
                label="Mutabakata Ekle"
                onClick={addItemToDraft}
              />
            )}
            <Clear />
          </div>
        </Authorization>

        <Authorization allowedRoles={[ReadIncident]}>
          <FlatButton
            label="Dosyayı Görüntüle"
            style={{
              display: 'table',
              margin: 'auto',
            }}
            onClick={() => history.push(`reports/${summary.id}`)}
          />
        </Authorization>
        <Clear />

        <Dialog
          title={
            <div style={{ position: 'relative' }}>
              Ücreti Düzenle
              <div
                className={`tooltipv2 questionMark`}
                style={{
                  top: 16,
                  right: 16,
                }}
              >
                <FontAwesomeIcon fixedWidth icon="question-circle" />
                <div
                  className={'tooltipTextv2'}
                  style={{
                    lineHeight: '18px',
                  }}
                >
                  Raporla ilgili olarak mutabık olunmayan bir durum yaşandığında; açıklamasıyla
                  birlikte düzenleme yapmaya imkan sağlayan formdur. <br /> Rapor tekrar incelenir
                  ve gerekli durumda ücreti güncellenir.
                </div>
              </div>
            </div>
          }
          modal={false}
          contentStyle={{ width: 304 }}
          open={open}
        >
          <TransactionForm
            onSubmitSuccess={() => {}}
            onRequestClose={handleClose}
            pricing={pricing}
          />
        </Dialog>
      </div>
    </Paper>
  );
}

export default connect()(PricingViewHolder);
