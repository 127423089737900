import PersonUpdateRequest from './PersonUpdateRequest';

export default class ParentUpdateRequest {
  constructor(config, { dateOfIssue = null }) {
    if (typeof config !== 'object') return;
    this.basic = new PersonUpdateRequest(config.basic, {
      dateOfBirth: dateOfIssue,
    });
  }
}
