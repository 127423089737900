export const ADMIN = 'Admin';
export const ACTUARY = 'Actuary';
export const CUSTOMER = 'Customer';
export const BASIC = 'Basic';
export const ReadCompany = 'ReadCompany';
export const WriteCompany = 'WriteCompany';
export const ReadRole = 'ReadRole';
export const WriteRole = 'WriteRole';
export const ReadIncident = 'ReadIncident';
export const WriteIncident = 'WriteIncident';
export const ReadCalculation = 'ReadCalculation';
export const WriteCalculation = 'WriteCalculation';
export const WriteAction = 'WriteAction';
export const ReadPricing = 'ReadPricing';
export const WritePricing = 'WritePricing';
export const ReadCompanyPricingTable = 'ReadCompanyPricingTable';
export const WriteCompanyPricingTable = 'WriteCompanyPricingTable';

export default {
  ReadCompany: {
    text: 'Şirket Bilgilerini Görüntüleme',
    allowedRoles: [ACTUARY, CUSTOMER],
  },
  WriteCompany: {
    text: 'Şirket Bilgilerini Güncelleme',
    allowedRoles: [ACTUARY, CUSTOMER],
  },
  ReadIncident: {
    text: 'Rapor Görüntüleme',
    allowedRoles: [ACTUARY, CUSTOMER],
  },
  WriteIncident: { text: 'Rapor Oluşturma', allowedRoles: [ACTUARY, CUSTOMER] },
  ReadCalculation: {
    text: 'Hesaplamaları Görüntüleme',
    allowedRoles: [ACTUARY, CUSTOMER],
  },
  WriteCalculation: {
    text: 'Hesaplama Oluşturma',
    allowedRoles: [ACTUARY, CUSTOMER],
  },
  WriteAction: { text: 'Yorum Oluşturma', allowedRoles: [ACTUARY, CUSTOMER] },
  ReadRole: { text: 'Rol Görüntüleme', allowedRoles: [ACTUARY, CUSTOMER] },
  WriteRole: { text: 'Rol Oluşturma', allowedRoles: [ACTUARY, CUSTOMER] },
  ReadPricing: {
    text: 'Muhasebe/Mutabak bilgilerini Görüntüleme',
    allowedRoles: [ACTUARY, CUSTOMER],
  },
  WritePricing: {
    text: 'Muhasebe/Mutabak bilgilerini Güncelleme',
    allowedRoles: [ACTUARY, CUSTOMER],
  },
  ReadCompanyPricingTable: {
    text: 'Fiyatları Görüntüleme',
    allowedRoles: [ACTUARY],
  },
  WriteCompanyPricingTable: {
    text: 'Fiyatları Güncelleme',
    allowedRoles: [ACTUARY],
  },
};
