import { setRuntimeVariable } from './runtime';

export const showPB = (dispatch, text) => {
  dispatch(
    setRuntimeVariable({
      name: 'isProgressbarOpen',
      value: true,
    }),
  );
  dispatch(
    setRuntimeVariable({
      name: 'progressLabel',
      value: text,
    }),
  );
};
export const hidePB = (dispatch) => {
  dispatch(
    setRuntimeVariable({
      name: 'isProgressbarOpen',
      value: false,
    }),
  );
  dispatch(
    setRuntimeVariable({
      name: 'progressLabel',
      value: undefined,
    }),
  );
};
