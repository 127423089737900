import { CLOSE_SNACKBAR, OPEN_SNACKBAR } from '../constants';

export default function (state = {}, action) {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return { ...state, open: true, message: action.message };
    case CLOSE_SNACKBAR:
      return { ...state, open: false };
    default:
      return state;
  }
}
