import React from 'react';
import { FlatButton } from 'material-ui';
import LoginForm from './LoginForm';
import Link from '../../components/Link/Link';
import BaseLayout from '../../components/BaseLayout/BaseLayout';

class Login extends React.Component {
  render() {
    return (
      <BaseLayout>
        <div className={'max380'} style={{ textAlign: 'center', marginTop: 64 }}>
          <h1>Giriş Yap</h1>
          <LoginForm form="loginForm" />
          <Link to="/forgotPassword">
            <FlatButton
              style={{ marginTop: 16 }}
              labelStyle={{ textTransform: 'none' }}
              fullWidth
              label="Şifreni mi unuttun?"
            />
          </Link>

          <Link to="/register">
            <FlatButton
              style={{ marginTop: 4 }}
              labelStyle={{ textTransform: 'none' }}
              fullWidth
              label="Kayıt Ol"
            />
          </Link>
        </div>
      </BaseLayout>
    );
  }
}

export default Login;
