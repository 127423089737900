/* eslint-disable react/prop-types */
import React from 'react';
import { Divider, Paper, Subheader } from 'material-ui';
import { connect } from 'react-redux';
import { paperStyle } from '../../../utils/material-ui-style-util';
import ListAddFAB from '../../Functional/ListAddFAB';
import { FieldArrayEmptyView } from '../../Functional/FieldArrayEmptyView';
import CalculationConfigForm from './CalculationConfigForm';

function CalculationConfigListForm({ fields, single }) {
  if (fields.length === 0) fields.push();
  return (
    <Paper style={paperStyle}>
      <Subheader className={'basicReportSubheader'}>Hesaplamaya İlişkin Bilgiler</Subheader>
      {fields.map((name, index) => (
        <div key={index}>
          <CalculationConfigForm name={name} index={index} fields={fields} single />
          <Divider style={{ marginTop: 0 }} />
        </div>
      ))}
      {!single && <FieldArrayEmptyView fields={fields} />}
      {!single && <ListAddFAB onClick={() => fields.push({})} />}
    </Paper>
  );
}
export default connect()(CalculationConfigListForm);
