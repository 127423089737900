/* eslint-disable react/prop-types,no-prototype-builtins */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import ComboBox from './ComboBox';
import { TAGS } from '../../constants';
import TagApi from '../../data/api/TagApi';

const states = (obj) => {
  if (!obj) return [];
  const items = obj.map((k) => ({ value: k.id, text: k.name }));
  items.unshift({ value: null, text: 'Seçiniz' });
  return items;
};

class TagComboBox extends Component {
  componentDidMount() {
    if (isEmpty(this.props.tags)) TagApi.list(this.props.dispatch);
  }

  render() {
    return <ComboBox {...this.props} data={states(this.props.tags)} />;
  }
}

export default connect((state) => ({
  tags: state.appData[TAGS],
}))(TagComboBox);
