import sum from 'lodash/sum';
import { roundPrecision } from '../../../utils/javascript-object-util';
import TermAmounts from './TermAmounts';

export default class CompensationByPerson {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.personId = config.personId;
    this.calculatedAmount = roundPrecision(config.calculatedAmount);
    this.deductions = config.deductions;
    this.deductedPayment = sum(config.deductions.map((d) => roundPrecision(d.amount)));
    this.cappedAmount = roundPrecision(config.cappedAmount);
    this.termAmounts = new TermAmounts(config.termAmounts);
  }
}
