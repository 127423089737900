import { toMoney } from '../../../utils/javascript-object-util';

export default function PaymentInfo() {
  const people = this.injuredParty.hashSet;

  function tr(payment) {
    return [
      payment.date,
      payment.recipients.map((p) => people[p].basic.fullName).join('\n'),
      payment.getCategory,
      {
        text: toMoney(payment.amount),
        alignment: 'right',
      },
    ];
  }

  const body = [];
  const costume = { fillColor: '#CCCCCC', style: { bold: true } };
  body.push([
    { text: 'Ödeme Tarihi', ...costume },
    { text: 'Ödeme Yapılan Kişi/Kişiler', ...costume },
    { text: 'Ödeme Türü', ...costume },
    { text: 'Ödeme Miktarı', ...costume },
  ]);
  body.push(...this.detailedIncident.payments.map(tr));
  return [
    {
      headlineLevel: 1,
      stack: [
        {
          text: 'Önceden Ödeme Bilgisi',
          margin: [0, 10, 0, 5],
          style: 'h2',
        },
        {
          text:
            'Sigorta şirketi tarafından ödenen meblağ, ödeme tarihi ile rapor tarihi arasında yasal faiz işletilerek, aşağıda formülü verilen şekilde güncellenir.',
          margin: [20, 10, 10, 0],
          style: { italic: true, fontSize: 10 },
        },
        {
          text: '[Daha önce yapılan ödeme * (1 + (i * (Hesap Tarihi - Ödeme Tarihi) / 365))]',
          margin: [20, 5, 10, 5],
          style: { italic: true, fontSize: 10 },
        },
        {
          margin: [0, 15, 0, 10],
          style: { fontSize: 10 },
          table: {
            dontBreakRows: true,
            keepWithHeaderRows: 1,
            headerRows: 1,
            heights: 10,
            widths: ['auto', '*', '*', 'auto'],
            body,
          },
        },
      ],
    },
  ];
}
