import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Divider, Paper, RaisedButton, Subheader } from 'material-ui';
import { renderTextField } from '../../utils/redux-metarial-ui-util';
import { setAppData } from '../../actions/appdata';
import { INIT_ME, USER } from '../../constants';
import Clear from '../../components/Functional/Clear';
import { validateDate } from '../../utils/validation';
import { normalizeDate } from '../../utils/redux-normalize';
import { hidePB, showPB } from '../../actions/progressbarAction';
import { openSnackbar } from '../../actions/snackbarActions';
import AccountApi from '../../data/api/AccountApi';
import Layout from '../../components/Layout/Layout';

class ProfileDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(setAppData({ name: INIT_ME, value: this.props.user }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      prevProps.dispatch(setAppData({ name: INIT_ME, value: this.props.user }));
    }
  }

  resendActivationMail = () => {
    const { dispatch } = this.props;
    showPB(dispatch, 'Aktivasyon Maili Yollanıyor');
    AccountApi.resendConfirmationMail(this.props.email).then(
      (r) => {
        hidePB(dispatch);
        dispatch(openSnackbar('Mail Gönderildi'));
        this.setState({ sent: true });
      },
      (r) => {
        hidePB(dispatch);
        dispatch(openSnackbar('Mail Gönderilemesırasında hata'));
      },
    );
  };

  onSubmit = (values, dispatch) => {};

  render() {
    return (
      <Layout>
        <div className={'root'}>
          <div className={`${'container'} ${'max380'}`}>
            <h1>Profilim</h1>
            <Paper>
              <Subheader>Kullanıcı Bilgileri</Subheader>
              <form style={{ padding: '0 32px' }} onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <Field name="firstName" label="İsim" component={renderTextField} fullWidth />
                <Field name="surName" label="Soyisim" component={renderTextField} fullWidth />
                <Field name="email" label="Email" component={renderTextField} disabled fullWidth />
                <Field
                  name="dateOfBirth"
                  label="Doğum Tarihi"
                  component={renderTextField}
                  normalize={normalizeDate}
                  fullWidth
                />
                <Field
                  name="phoneNumber"
                  label="Telefon Numarası"
                  component={renderTextField}
                  fullWidth
                />
                <Clear />
              </form>
            </Paper>

            {this.props.user && !this.props.user.emailConfirmationDate && (
              <Paper style={{ background: '#e9eeeb', marginTop: 32 }}>
                <Divider />
                <div style={{ margin: 16, display: 'inline-block' }}>
                  <div style={{ display: 'flex', margin: '0 0 16px 0' }}>
                    <p
                      style={{
                        margin: 'auto',
                        fontSize: '14px',
                        textAlign: 'center',
                        fontWeight: 400,
                      }}
                    >
                      Mail adresiniz henüz onaylanmamış. Aşağıdaki buton ile yeni bir mail gönderip
                      onaylayabilirsiniz .
                    </p>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <RaisedButton
                      primary
                      style={{ margin: 'auto' }}
                      label="Aktivasyon Maili Gönder"
                      disabled={this.state.sent}
                      onClick={this.resendActivationMail}
                    />
                  </div>
                </div>
              </Paper>
            )}
          </div>
        </div>
      </Layout>
    );
  }
}
const validate = (values) => {
  const errors = {};
  errors.dateOfBirth = validateDate(values.dateOfBirth, false);
  return errors;
};
ProfileDetail = reduxForm({ form: 'profileDetail', validate })(ProfileDetail);

const selector = formValueSelector('profileDetail');
export default connect((state) => ({
  emailconfirmationdate: selector(state, 'emailconfirmationdate'),
  email: selector(state, 'email'),
  initialValues: state.appData[INIT_ME],
  user: state.appData[USER],
}))(ProfileDetail);
