import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import RaisedButton from 'material-ui/RaisedButton';
import { renderTextField } from '../../utils/redux-metarial-ui-util';
import AccountApi from '../../data/api/AccountApi';
import { hidePB, showPB } from '../../actions/progressbarAction';
import { setAppData } from '../../actions/appdata';
import { DIALOG_OPEN } from '../../constants/index';
import { openSnackbar } from '../../actions/snackbarActions';

class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  onSubmit = (values, dispatch) => {
    showPB(dispatch);
    const queryParams = this.props.query;
    return AccountApi.register(values, queryParams).then(
      () => {
        dispatch(setAppData({ name: DIALOG_OPEN, value: true }));
        hidePB(dispatch);
      },
      (e) => {
        dispatch(openSnackbar(e.errors.error));
        hidePB(dispatch);
        return Promise.reject(e);
      },
    );
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div>
          <Field
            name="firstName"
            style={{ margin: 0 }}
            component={renderTextField}
            floatingLabelText="İsim"
            fullWidth
          />
        </div>
        <div>
          <Field
            name="lastName"
            style={{ margin: 0 }}
            component={renderTextField}
            floatingLabelText="Soy İsim"
            fullWidth
          />
        </div>
        <div>
          <Field
            name="email"
            style={{ margin: 0 }}
            component={renderTextField}
            floatingLabelText="Email"
            fullWidth
          />
        </div>
        <div>
          <Field
            name="password"
            style={{ margin: 0 }}
            component={renderTextField}
            type="password"
            floatingLabelText="Şifre"
            fullWidth
          />
        </div>
        {this.state.loading && (
          <div style={{ marginTop: 16 }}>
            <img src="loading.svg" style={{ width: 64 }} />
          </div>
        )}
        <div style={{ marginTop: 32 }}>
          <RaisedButton
            type="submit"
            style={{ float: 'right', width: '50%' }}
            label="Kayıt Ol"
            primary
            disabled={this.state.loading}
            fullWidth
          />
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = 'Zorunlu alan';
  } else if (values.password.length < 6) {
    errors.password = 'Şifreniz 6 karakter veya daha fazla olmalı.';
  }
  if (!values.email) {
    errors.email = 'Zorunlu alan';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Geçersiz Email Adresi';
  }
  return errors;
};

export default reduxForm({ form: 'registerForm', validate })(RegisterForm);
