import PersonUpdateRequest from './PersonUpdateRequest';
import { convertPercentageToNum, convertToDate } from '../../../../utils/javascript-object-util';
import { CLEANER_DATETIME } from '../../../../utils/CleanerValues';

export default class ChildUpdateRequest {
  constructor(config, { dateOfIssue = null }) {
    if (typeof config !== 'object') return;
    this.basic = new PersonUpdateRequest(config.basic, {
      dateOfBirth: dateOfIssue,
    });
    this.disability = convertPercentageToNum(config.disability);
    this.dateOfGraduation = config.dateOfGraduation
      ? convertToDate(config.dateOfGraduation)
      : CLEANER_DATETIME;
    this.dateOfMarriage = config.dateOfMarriage
      ? convertToDate(config.dateOfMarriage)
      : CLEANER_DATETIME;
    this.dateOfEmployment = config.dateOfEmployment
      ? convertToDate(config.dateOfEmployment)
      : CLEANER_DATETIME;
    this.tempParentId = config.tempParentId;
  }
}
