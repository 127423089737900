import React from 'react';
import { connect } from 'react-redux';
import Chip from 'material-ui/Chip';
import uniq from 'lodash/uniq';
import { green100, red100 } from 'material-ui/styles/colors';
import { List, ListItem } from 'material-ui/List';
import { Divider, IconButton, Paper, RaisedButton, Subheader, TextField } from 'material-ui';
import moment from 'moment';
import Close from 'material-ui/svg-icons/navigation/close';
import Help from 'material-ui/svg-icons/action/help';
import { hidePB, showPB } from '../../actions/progressbarAction';
import Clear from '../../components/Functional/Clear';
import { INVITATIONS_LIST, USER } from '../../constants';
import { openSnackbar } from '../../actions/snackbarActions';
import EmptyView from '../../components/Functional/EmptyView';
import CompanyInvitationApi from '../../data/api/CompanyInvitationApi';
import Layout from '../../components/Layout/Layout';
import Authorization from '../../components/Authorization/Authorization';
import { WriteCompany } from '../../data/model/Privilege';

function InvitedUserView({ data, onClick }) {
  return (
    <div>
      <ListItem
        primaryText={`${data.user.fullName}`}
        secondaryText={
          <p>
            <span style={{ color: 'black' }}>{data.user.email || data.email}</span>
            <br />
            Davet edildiği tarih :{moment(data.timestamp).format('DD.MM.YYYY HH:mm:ss')}
          </p>
        }
        secondaryTextLines={2}
        rightIconButton={
          <div style={{ paddingTop: 20, paddingRight: 16 }}>
            <IconButton onClick={() => onClick(data.hash)}>
              <Close />
            </IconButton>
          </div>
        }
      />
      <Divider />
    </div>
  );
}

class UserInvite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      crudeData: '',
    };
    this.styles = {
      chip: {
        margin: 4,
      },
      wrapper: {
        marginTop: 16,
        marginBottom: 16,
        display: 'flex',
        flexWrap: 'wrap',
      },
    };
  }

  deleteOnClick = (hash) => {
    const { dispatch } = this.props;
    const { user } = this.props;
    showPB(dispatch, 'Davet Kaldırılıyor.');
    CompanyInvitationApi.delete(user.getCompanyId, hash).then(
      (r) => {
        this.fetchData();
      },
      (r) => {
        hidePB(dispatch);
        dispatch(openSnackbar('Daveti kaldırma sırasında bir hata meydana geldi'));
      },
    );
  };

  handleAddOnClick = () => {
    const { crudeData } = this.state;
    const { dispatch, user } = this.props;
    showPB(dispatch);

    const crudeArray = uniq(crudeData.split('\n').map((c) => c.trim()));
    const { list } = this.state;
    const buildList = crudeArray
      .filter((c) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(c))
      .map((c) => ({
        key: list.length + crudeArray.indexOf(c),
        label: c,
        color: green100,
      }));
    const newList = list.concat(buildList);

    CompanyInvitationApi.create(user.getCompanyId, {
      identifiers: buildList.map((x) => x.label),
    }).then(
      () => {
        this.setState({ list: newList });
        hidePB(dispatch);
        this.fetchData();
      },
      (r) => {
        const response = r.errors;
        if (response) {
          const invalidEmails = [];
          for (let i = 0; i < Object.keys(response).length; i++) {
            if (response.hasOwnProperty(i)) {
              invalidEmails.push(response[i]);
            }
          }
          invalidEmails.forEach((m) =>
            newList.filter((n) => n.label === m).forEach((n) => (n.color = red100)),
          );
          this.setState({ list: newList });
        }
        hidePB(dispatch);
        throw r;
      },
    );
    this.setState({ crudeData: '' });
  };

  handleRequestDelete = (key) => {
    const chipData = this.state.list;
    const chipToDelete = chipData.map((chip) => chip.key).indexOf(key);
    chipData.splice(chipToDelete, 1);
    this.setState({ list: chipData });
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.fetchData(this.props);
    }
  }

  async fetchData(nextProps) {
    const props = nextProps || this.props;
    const { user, dispatch } = props;
    if (user) {
      showPB(dispatch, 'Davet edilen Kullanıcılar Listeleniyor');
      CompanyInvitationApi.list(dispatch, user.getCompanyId).then(
        () => hidePB(dispatch),
        () => hidePB(dispatch),
      );
    }
  }

  render() {
    const { invitations } = this.props;
    const { list } = this.state;

    const chipList = list.map((i) => (
      <Chip
        key={i.key}
        backgroundColor={i.color}
        onRequestDelete={() => this.handleRequestDelete(i.key)}
        style={this.styles.chip}
      >
        {i.label}
      </Chip>
    ));

    return (
      <Layout>
        <Authorization allowedRoles={[WriteCompany]} force>
          <div className={'root'}>
            <div className={'container'}>
              <div className={'max1024'}>
                <h1>Kullanıcı Davet Et</h1>
                <Paper style={{ padding: 16 }}>
                  <div style={{ minWidth: 400, width: '100%', position: 'relative' }}>
                    <div
                      style={{
                        position: 'absolute',
                        right: 144,
                        top: 25,
                        zIndex: 99,
                      }}
                    >
                      <div className={'tooltip'}>
                        <Help />
                        <span className={'tooltipRightText'} style={{ fontSize: '11pt' }}>
                          Bu alanda çoklu davet yapılabilir.
                          <br />
                          Bunun için mail adreslerini alt alta gelecek şekilde girmelisiniz.
                        </span>
                      </div>
                    </div>
                    <div style={{ float: 'left', width: 'calc(100% - 144px)' }}>
                      <TextField
                        hintText={
                          <span>
                            ali@ornek.com
                            <br />
                            ayse@ornek.com
                            <br />
                            fatma@ornek.com
                          </span>
                        }
                        fullWidth
                        floatingLabelText="Kullanıcı mail listesi"
                        floatingLabelStyle={{ fontWeight: 400 }}
                        onChange={(event) => {
                          this.setState({ crudeData: event.target.value });
                        }}
                        value={this.state.crudeData}
                        multiLine
                        rows={4}
                        rowsMax={10}
                      />
                    </div>
                    <RaisedButton
                      style={{
                        marginTop: 98,
                        marginLeft: 16,
                        width: 128,
                      }}
                      label="Davet Et"
                      onClick={this.handleAddOnClick}
                    />
                    <Clear />
                  </div>

                  {chipList.length > 0 && (
                    <div>
                      <div style={this.styles.wrapper}>{chipList}</div>
                      <div
                        style={{
                          backgroundColor: green100,
                          width: 24,
                          height: 24,
                          borderRadius: 24,
                          float: 'left',
                        }}
                      />
                      <span
                        style={{
                          fontSize: 14,
                          lineHeight: '24px',
                          verticalAlign: 'middle',
                          marginLeft: 8,
                        }}
                      >
                        Başarı ile davet edilen kullanıcılar
                      </span>
                      <Clear />
                      <div
                        style={{
                          backgroundColor: red100,
                          width: 24,
                          height: 24,
                          borderRadius: 24,
                          float: 'left',
                        }}
                      />
                      <span
                        style={{
                          fontSize: 14,
                          lineHeight: '24px',
                          verticalAlign: 'middle',
                          marginLeft: 8,
                        }}
                      >
                        Kayıt işlemini tamamlamamış veya zaten davet edilmiş kullanıcılar.
                      </span>
                    </div>
                  )}
                </Paper>
                {invitations && invitations.length > 0 ? (
                  <Paper style={{ marginTop: 32 }}>
                    <List style={{ padding: 0 }}>
                      <Subheader>Davet Edilen Kullanıcılar</Subheader>
                      {invitations.map((i) => (
                        <InvitedUserView data={i} onClick={this.deleteOnClick} />
                      ))}
                    </List>
                  </Paper>
                ) : (
                  <div style={{ marginTop: 64 }}>
                    <EmptyView icon="user-plus" label="Davet sırasında kullanıcı bulunmuyor" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Authorization>
      </Layout>
    );
  }
}

export default connect((state) => ({
  user: state.appData[USER],
  invitations: state.appData[INVITATIONS_LIST],
}))(UserInvite);
