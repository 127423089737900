import { convertPercentageToNum, toMoment } from '../../../../utils/javascript-object-util';
import { DISABLED } from '../../IncidentType';
import { SUPREME } from '../../ShareRateCategory';
import { CSO, TRH } from '../../MortalityTable';
import { CIRCULAR_2020_MOMENT, CIRCULAR_2021_MOMENT } from '../../../../constants';

export function getInterest(mortalityTable, dateOfIssue) {
  const isBeforeCircular2021 = dateOfIssue
    ? toMoment(dateOfIssue).isBefore(CIRCULAR_2021_MOMENT)
    : null;

  if (mortalityTable === TRH) {
    if (isBeforeCircular2021) return 0.018;
    return 0.0165;
  }
  if (mortalityTable === CSO) return 0.02;
  return 0.1;
}

export default class ConfigCreateRequest {
  constructor(config) {
    if (typeof config !== 'object') return;
    const { incidentType, dateOfIssue, formType } = config;

    const isBeforeCircular2020 = dateOfIssue
      ? toMoment(dateOfIssue).isBefore(CIRCULAR_2020_MOMENT)
      : null;
    const isBeforeCircular2021 = dateOfIssue
      ? toMoment(dateOfIssue).isBefore(CIRCULAR_2021_MOMENT)
      : null;

    this.mortalityTable = config.mortalityTable;
    this.lifeAnnuity = config.lifeAnnuity;
    this.shareRate = config.shareRate;
    this.faultRatio = convertPercentageToNum(config.faultRatio);
    this.disabilityRatio =
      incidentType === DISABLED ? convertPercentageToNum(config.disabilityRatio) : 1;
    this.interest = convertPercentageToNum(config.interest);
    this.useTerminalAge = config.useTerminalAge;
    this.useMinimumWage = config.useMinimumWage;
    this.withoutAllowance = config.withoutAllowance;
    this.dontLimitCompensation = config.dontLimitCompensation;
    this.usePassiveEarningUntilAgeOfConsent = config.usePassiveEarningUntilAgeOfConsent;
    this.calculateNurse = config.calculateNurse;
    this.isNurseDocumented = config.isNurseDocumented;
    this.nurseCategory = config.nurseCategory;
    this.skipTemporaryIncapacity = config.skipTemporaryIncapacity;
    this.useHouseholdPriorityShare = config.useHouseholdPriorityShare;

    if (incidentType === DISABLED) {
      this.lifeAnnuity = 'single';
      this.shareRate = SUPREME;
    }
    if (this.mortalityTable !== TRH) {
      this.lifeAnnuity = 'single';
    }
    if (!isBeforeCircular2020) {
      this.useTerminalAge = this.useTerminalAge || false;
      this.shareRate = this.shareRate || SUPREME;
      this.lifeAnnuity = this.lifeAnnuity || 'single';
      this.mortalityTable = this.mortalityTable || TRH;
      this.interest = this.interest || getInterest(this.mortalityTable, dateOfIssue);
    }
    if (!isBeforeCircular2021) {
      this.interest = this.interest || getInterest(this.mortalityTable, dateOfIssue);
    }
    if (formType === 'basic') {
      this.interest = getInterest(this.mortalityTable, dateOfIssue);
      this.shareRate = SUPREME;
      if (!isBeforeCircular2021)
        this.useTerminalAge = incidentType === DISABLED && this.mortalityTable === TRH;
    }
  }
}
