import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from '../Link/Link';
import {
  ACTUARY,
  BASIC,
  ReadCompany,
  ReadCompanyPricingTable,
  ReadIncident,
  ReadPricing,
  ReadRole,
  WriteCompany,
  WriteCompanyPricingTable,
  WriteIncident,
  WritePricing,
} from '../../data/model/Privilege';
import Authorization from '../Authorization/Authorization';

const me = {
  text: 'Profilim',
  link: '/me/detail',
  leftIcon: <FontAwesomeIcon fixedWidth icon="user" />,
  allowedRoles: [BASIC],
};
const account = {
  text: 'Hesabım',
  link: '/me/account',
  leftIcon: <FontAwesomeIcon fixedWidth icon="key" />,
  allowedRoles: [BASIC],
};
const invitations = {
  text: 'Davetlerim',
  link: '/me/invitations',
  leftIcon: <FontAwesomeIcon fixedWidth icon="address-card" />,
  allowedRoles: [BASIC],
};
const createBasicReport = {
  text: 'Basit Rapor',
  link: '/reports/create/basic',
  leftIcon: <FontAwesomeIcon fixedWidth icon="file" />,
  allowedRoles: [WriteIncident],
};
const createDocumentReport = {
  text: 'Belge ile Rapor',
  link: '/reports/create/document',
  leftIcon: <FontAwesomeIcon fixedWidth icon="folder" />,
  allowedRoles: [WriteIncident],
};
const companyDetail = {
  text: 'Şirket Bilgileri',
  link: '/company/detail',
  leftIcon: <FontAwesomeIcon fixedWidth icon="building" />,
  allowedRoles: [ReadCompany],
};
const companyCreate = {
  text: 'Şirket Oluştur',
  link: '/company/create',
  leftIcon: (
    <span>
      <FontAwesomeIcon icon="building" />
      <FontAwesomeIcon style={{ fontSize: '12px' }} icon="plus" />
    </span>
  ),
  allowedRoles: [ReadCompany, ACTUARY],
};
const companies = {
  text: 'Şirket Listesi',
  link: '/company/list',
  leftIcon: (
    <span>
      <FontAwesomeIcon icon="building" />
      <FontAwesomeIcon style={{ fontSize: '12px' }} icon="list" />
    </span>
  ),
  allowedRoles: [ReadCompany, ACTUARY],
};
const companyUsers = {
  text: 'Personeller',
  link: '/company/user/list',
  leftIcon: <FontAwesomeIcon fixedWidth icon="users" />,
  allowedRoles: [ReadCompany],
};
const companyUserRoles = {
  text: 'Roller',
  link: '/company/user/role',
  leftIcon: <FontAwesomeIcon fixedWidth icon="chess" />,
  allowedRoles: [ReadRole, ReadCompany],
  and: true,
};
const companyUserInvite = {
  text: 'Personel Ekle',
  link: '/company/user/invite',
  leftIcon: <FontAwesomeIcon fixedWidth icon="user-plus" />,
  allowedRoles: [WriteCompany, ReadCompany],
};
const reportList = {
  text: 'Raporlar',
  link: '/reports',
  leftIcon: <FontAwesomeIcon fixedWidth icon="archive" />,
  allowedRoles: [ReadIncident],
};
const transactionSummary = {
  text: 'Dönem Özeti',
  link: '/transactions',
  leftIcon: <FontAwesomeIcon fixedWidth icon="receipt" />,
  allowedRoles: [ReadPricing],
};
const invoiceDraft = {
  text: 'Mutabakat',
  link: '/invoices/drafts',
  leftIcon: <FontAwesomeIcon fixedWidth icon="handshake" />,
  allowedRoles: [ReadPricing],
};
const invoice = {
  text: 'Faturalar',
  link: '/invoices',
  leftIcon: <FontAwesomeIcon fixedWidth icon="file-invoice" />,
  allowedRoles: [ReadPricing],
};
const pricing = {
  text: 'Ücretlendirme',
  link: '/pricing',
  leftIcon: <FontAwesomeIcon fixedWidth icon="money-bill-wave" />,
  allowedRoles: [ReadPricing],
};
const customerPricing = {
  text: 'Şirket Fiyatları',
  link: '/customers/pricing',
  leftIcon: <FontAwesomeIcon fixedWidth icon="money-bill-wave" />,
  allowedRoles: [ReadCompanyPricingTable, WriteCompanyPricingTable],
  and: true,
};
const webhook = {
  text: 'Entegrasyon',
  link: '/company/integration',
  leftIcon: <FontAwesomeIcon fixedWidth icon="link" />,
  allowedRoles: [WriteCompany, ReadCompany],
  and: true,
};
export const getMenu = () => [
  {
    text: 'Rapor Oluştur',
    allowedRoles: [WriteIncident],
    child: [createBasicReport, createDocumentReport],
  },
  {
    text: 'Raporlar',
    allowedRoles: [ReadIncident],
    child: [reportList],
  },
  {
    text: 'Muhasebe',
    allowedRoles: [ReadPricing, WritePricing],
    child: [pricing, invoiceDraft, invoice],
  },
  {
    text: 'Şirket',
    allowedRoles: [ReadCompany, WriteCompany, ReadRole],
    child: [companyDetail, companyUserRoles, companyUsers, companyUserInvite],
  },
  {
    text: '',
    leftIcon: <FontAwesomeIcon fixedWidth icon="cog" />,
    style: { marginLeft: -105 },
    allowedRoles: [BASIC],
    child: [me, account, invitations, webhook],
  },
];
export const getAdminMenu = () => [
  {
    text: 'Şirket',
    allowedRoles: [ACTUARY, ReadCompany, WriteCompany, ReadRole],
    child: [companies, companyCreate, customerPricing],
    and: true,
  },
];

export const buildMenuItem = (m) => (
  <span>
    {m.leftIcon && <div className={'menuItemIcon'}>{m.leftIcon}</div>}
    {m.text}
    {m.rightIcon && <div className={'menuItemIcon'}>{m.rightIcon}</div>}
  </span>
);
export const buildNavigationMenu = (menu) =>
  menu.map((m) => {
    if (m.child) {
      return (
        <Authorization key={m.text.hashCode()} allowedRoles={m.allowedRoles} and={m.and}>
          <div className={'dropdown'}>
            <div className={`link bigLink`}>
              {m.leftIcon && <div className={'menuItemIcon'}>{m.leftIcon}</div>}
              {`${m.text} `}
              {m.rightIcon ? (
                <div className={'menuItemIcon'}>{m.rightIcon}</div>
              ) : (
                <FontAwesomeIcon icon="caret-down" />
              )}
            </div>
            <div style={m.style} className={'dropdownContent'}>
              {m.child.map((mi) => (
                <Authorization key={mi.text.hashCode()} allowedRoles={mi.allowedRoles} and={mi.and}>
                  <Link className={'link'} to={mi.link}>
                    {buildMenuItem(mi)}
                  </Link>
                </Authorization>
              ))}
            </div>
          </div>
        </Authorization>
      );
    }
    return (
      <Authorization key={menu.indexOf(m)} allowedRoles={m.allowedRoles} and={m.and}>
        <Link className={`link bigLink`} to={m.link}>
          {buildMenuItem(m)}
        </Link>
      </Authorization>
    );
  });

export const buildMainMenuItem = (m) => (
  <Link
    className={`link menuItemLink`}
    to={m.link}
    style={{
      width: '100%',
      height: '100%',
      padding: 8,
      boxSizing: 'border-box',
    }}
  >
    <div style={{ textAlign: 'center' }}>
      <div style={{ fontSize: '24px' }}>{m.leftIcon}</div>
      <div>{m.text}</div>
    </div>
  </Link>
);

export function MainMenu({ menu }) {
  return (
    <div className={'mainMenuContainer'}>
      {menu.map((m) => {
        if (m.child) {
          return (
            <Authorization key={m.text.hashCode()} allowedRoles={m.allowedRoles} and={m.and}>
              <div className={'menuContainer'}>
                <div className={'menuHeader'}>
                  <div className={'center'}>
                    {m.leftIcon && <div className={'menuItemIcon'}>{m.leftIcon}</div>}
                    {`${m.text}`}
                    {m.rightIcon && <div className={'menuItemIcon'}>{m.rightIcon}</div>}
                  </div>
                </div>
                <div className={'menuItemContainer'}>
                  {m.child.map((mi) => (
                    <Authorization
                      key={mi.text.hashCode()}
                      allowedRoles={mi.allowedRoles}
                      and={mi.and}
                    >
                      <div className={'menuItem'}>{buildMainMenuItem(mi)}</div>
                    </Authorization>
                  ))}
                </div>
              </div>
            </Authorization>
          );
        }
        return (
          <Authorization key={menu.indexOf(m)} allowedRoles={m.allowedRoles} and={m.and}>
            <Link className={`link bigLink`} to={m.link}>
              {buildMenuItem(m)}
            </Link>
          </Authorization>
        );
      })}
    </div>
  );
}
