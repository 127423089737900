import React from 'react';
import { connect } from 'react-redux';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import { Paper, RaisedButton } from 'material-ui';
import {
  INVOICE_CONTENT_RANGE,
  INVOICE_ITEM_COUNT,
  INVOICE_LIST,
  INVOICE_LIST_PAGE,
  USER,
} from '../../constants';
import { hidePB, showPB } from '../../actions/progressbarAction';
import CustomerAutoComplete from '../../components/AutoComplete/CustomerAutoComplete';
import Clear from '../../components/Functional/Clear';
import Authorization from '../../components/Authorization/Authorization';
import { ACTUARY, CUSTOMER, ReadPricing } from '../../data/model/Privilege';
import { openSnackbar } from '../../actions/snackbarActions';
import { baseFloatingLabelStyle } from '../../utils/redux-metarial-ui-util';
import { clearAppData, setAppData } from '../../actions/appdata';
import InvoiceApi from '../../data/api/InvoiceApi';
import InvoiceViewHolder from './InvoiceViewHolder';
import EmptyView from '../../components/Functional/EmptyView';
import Layout from '../../components/Layout/Layout';
import Pagination from '../../components/Functional/Pagination';

class InvoiceListPage extends React.Component {
  fetchData = (companyId, page) => {
    const { dispatch } = this.props;
    if (!companyId) {
      dispatch(openSnackbar('Şirket Seçilmedi'));
      return;
    }

    showPB(dispatch);
    dispatch(clearAppData(INVOICE_LIST));
    InvoiceApi.list(dispatch, companyId, page - 1).then(
      (r) => {
        this.setState({ lastQueriedCompanyId: companyId });
        hidePB(dispatch);
      },
      (r) => {
        hidePB(dispatch);
      },
    );
  };

  handlePageChange = (page) => {
    this.setPage(page);
    this.fetchData(this.state.companyId, page);
  };

  setPage = (num) => {
    const { dispatch } = this.props;
    dispatch(setAppData({ name: INVOICE_LIST_PAGE, value: num }));
  };

  clearData = () => {
    this.setState({ companyId: null });
    this.setState({ lastQueriedCompanyId: null });
  };

  constructor(props) {
    super(props);
    this.state = {
      companyId: null,
      lastQueriedCompanyId: null,
    };
  }

  componentDidMount() {
    const { user } = this.props;

    if (includes(user?.getRoles, CUSTOMER)) {
      this.setState({ companyId: user.getCompanyId });
      this.fetchData(user.getCompanyId, 1);
    }
  }

  componentWillUnmount() {
    this.props.dispatch(clearAppData(INVOICE_LIST));
  }

  render() {
    const { invoices } = this.props;
    return (
      <Layout>
        <Authorization allowedRoles={[ReadPricing]} force>
          <div className={'root'}>
            <div className={`container max1024`}>
              <h1>Faturalar</h1>
              <Authorization allowedRoles={[ACTUARY]}>
                <Paper style={{ position: 'relative', paddingBottom: 10 }}>
                  <div className={`${'basicReportFieldContainer'}`}>
                    <CustomerAutoComplete
                      title="Sigorta Şirketi"
                      fullWidth
                      floatingLabelStyle={{ ...baseFloatingLabelStyle }}
                      onChange={(v) => this.setState({ companyId: v })}
                    />
                  </div>
                  <div style={{ position: 'absolute', bottom: 16, right: 16 }}>
                    <RaisedButton
                      style={{ float: 'right', marginLeft: 16 }}
                      primary
                      label="Faturaları Getir"
                      onClick={() => this.handlePageChange(1)}
                    />
                  </div>
                  <Clear />
                </Paper>
              </Authorization>
              {isEmpty(invoices) ? (
                <EmptyView
                  style={{ marginTop: 16 }}
                  label="Fatura Oluşturulmamış"
                  icon="file-invoice"
                />
              ) : (
                <div style={{ marginTop: 6 }}>
                  {invoices.map((i) => (
                    <InvoiceViewHolder detailedInvoice={i} />
                  ))}
                </div>
              )}

              {!isEmpty(invoices) && (
                <Pagination
                  name={'Faturadan'}
                  style={{ float: 'right' }}
                  total={this.props.itemCount}
                  onChange={this.handlePageChange}
                  showTitle={false}
                  current={this.props.page}
                />
              )}
            </div>
          </div>
        </Authorization>
      </Layout>
    );
  }
}

export default connect((state) => ({
  invoices: state.appData[INVOICE_LIST] || [],
  page: state.appData[INVOICE_LIST_PAGE],
  contentRange: state.appData[INVOICE_CONTENT_RANGE],
  itemCount: state.appData[INVOICE_ITEM_COUNT],
  user: state.appData[USER],
}))(InvoiceListPage);
