import React from 'react';
import { connect } from 'react-redux';
import { FieldArray, reduxForm } from 'redux-form';
import { RaisedButton } from 'material-ui';
import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';
import Clear from '../../Functional/Clear';
import { validatePayments } from '../../../utils/validation';
import { objDiff } from '../../../utils/javascript-object-util';
import { hidePB } from '../../../actions/progressbarAction';
import { openSnackbar } from '../../../actions/snackbarActions';
import { clearAppData } from '../../../actions/appdata';
import PaymentCreateRequest from '../../../data/model/request/create/PaymentCreateRequest';
import { DETAILED_INCIDENT_CC, INIT_PAYMENTS } from '../../../constants';
import PaymentListForm from '../PaymentListForm';
import { getErrorMessage } from '../../../utils/error-messages';
import RouteStory, { RouteLocationStory } from '../../../utils/RouteStory';
import ReportFlexInfo from '../ReportFlexInfo';
import PaymentApi from '../../../data/api/PaymentApi';

class PaymentCreateUpdateForm extends React.Component {
  onSubmit = (values) => {
    const { dispatch, incidentId, initialValues } = this.props;
    const requests = [];

    const { payments } = values;
    const initialPayments = initialValues.payments;
    if (payments) {
      payments
        .filter((payment) => !payment.id)
        .forEach((payment) => {
          requests.push(PaymentApi.create(new PaymentCreateRequest(payment), incidentId));
        });
    }
    if (initialPayments) {
      const oldList = initialPayments.map((s) => s.id);
      const newList = payments.filter((s) => s.id).map((s) => s.id);
      difference(oldList, newList).forEach((id) => requests.push(PaymentApi.delete(id)));
    }
    if (initialPayments && payments) {
      payments
        .filter((payment) => payment.id)
        .forEach((payment) => {
          const oldPayment = initialPayments.find(
            (initialPayment) => initialPayment.id === payment.id,
          );
          const newItem = objDiff(payment, oldPayment);
          if (!isEmpty(newItem))
            requests.push(PaymentApi.update(payment.id, new PaymentCreateRequest(newItem)));
        });
    }

    Promise.all(requests).then(
      () => {
        hidePB(dispatch);
        dispatch(openSnackbar('Yapılan Ödemeler Güncellendi'));
        dispatch(clearAppData(INIT_PAYMENTS));
        this.props.history.goBack();
        this.props.history.push(`/reports/${incidentId}`);
      },
      (err) => {
        hidePB(dispatch);
        dispatch(openSnackbar(`Güncelleme sırasında hata oluştu. ${getErrorMessage(err.message)}`));
      },
    );
  };

  componentDidMount() {
    if (!this.props.detailedIncident) this.props.history.push('/reports');
  }

  render() {
    const { pristine, submitting, detailedIncident } = this.props;
    if (!detailedIncident) return <div />;
    return (
      <div className={'root'}>
        <div className={`${'container'}`} style={{ maxWidth: 1080, margin: 'auto' }}>
          <h1>Ödemeleri Güncelle</h1>
          <RouteLocationStory
            locations={[
              RouteStory.reports(),
              RouteStory.report({
                text: detailedIncident.injuredParty.casualty.basic.fullName,
              }),
              RouteStory.paymentCreateUpdate(),
            ]}
          />
          <form style={{ position: 'relative' }} onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <FieldArray
              name="payments"
              component={PaymentListForm}
              incidentType={detailedIncident.incident.incidentType}
            />
            <RaisedButton
              label="Ödemeleri Güncelle"
              primary
              type="submit"
              disabled={pristine || submitting}
              style={{ float: 'right', marginRight: 12 }}
            />
            <Clear />
          </form>
          <ReportFlexInfo />
        </div>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};

  errors.payments = validatePayments(values.payments);

  return errors;
};
PaymentCreateUpdateForm = reduxForm({
  form: 'detailedReport',
  validate,
  enableReinitialize: true,
})(PaymentCreateUpdateForm);

export default connect((state) => ({
  initialValues: state.appData[INIT_PAYMENTS],
  detailedIncident: state.appData[DETAILED_INCIDENT_CC],
}))(PaymentCreateUpdateForm);
