import React from 'react';
import scriptLoader from 'react-async-script-loader';
import { connect } from 'react-redux';
import { IconButton } from 'material-ui';
import isEmpty from 'lodash/isEmpty';
import { INVOICE_DRAFT } from '../../constants';

class DraftReportGenerator extends React.Component {
  handleOnDownload = async () => {
    const workBook = { SheetNames: [], Sheets: {} };
    const workSheetName = 'Sheet1';
    workBook.SheetNames.push(workSheetName);
    workBook.Sheets[workSheetName] = this.props.draft.buildReportData();
    const workBookOptions = {
      bookType: 'xlsx',
      bookSST: false,
      type: 'binary',
    };
    // const workBookOut = XLSX.write(workBook, workBookOptions);
    //
    // function s2ab(s) {
    //   const buf = new ArrayBuffer(s.length);
    //   const view = new Uint8Array(buf);
    //   for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    //   return buf;
    // }
    //
    // const date = moment().format('YYYYMMDD_HHmmss');
    // fs.saveAs(
    //   new Blob([s2ab(workBookOut)], { type: 'application/octet-stream' }),
    //   `Inova_Mutabakat_${date}.xlsx`,
    // );
  };

  componentDidUpdate(prevProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    if (isScriptLoaded && !prevProps.isScriptLoaded) {
      if (isScriptLoadSucceed) {
        prevProps.onSuccess();
      } else prevProps.onError();
    }
  }

  render() {
    const disable = !this.props.isScriptLoadSucceed || isEmpty(this.props.draft);
    return (
      <IconButton
        onClick={this.handleOnDownload}
        style={{ width: 60, height: 60 }}
        disabled={disable}
        tooltip="Excel Raporu İndir"
      >
        {disable ? (
          <img width={36} height={36} draggable={false} src={'/ic_file_excel_download_gray.png'} />
        ) : (
          <img width={36} height={36} draggable={false} src={'/ic_file_excel_download.png'} />
        )}
      </IconButton>
    );
  }
}

DraftReportGenerator = scriptLoader('../xlsx.full.min.js')(DraftReportGenerator);
export default connect((state) => ({
  draft: state.appData[INVOICE_DRAFT],
}))(DraftReportGenerator);
