import { dateFormat, toMoment } from '../../../utils/javascript-object-util';
import IncidentType, { DEATH, DISABLED } from '../IncidentType';
import {
  CIRCULAR_2015_MOMENT,
  CIRCULAR_2020_MOMENT,
  CIRCULAR_2021_MOMENT,
} from '../../../constants';

export default class IncidentResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.id = config.id;
    this.damageFileIdentifier = config.damageFileIdentifier;
    this.courtFileIdentifier = config.courtFileIdentifier;
    this.arbitrationFileIdentifier = config.arbitrationFileIdentifier;
    this.dateOfIncident = dateFormat(config.dateOfIncident);
    this.dateOfReport = dateFormat(config.dateOfReport);
    this.dateOfDeath = dateFormat(config.dateOfDeath);
    this.dateOfRetirement = dateFormat(config.dateOfRetirement);
    this.dateOfIssue = dateFormat(config.dateOfIssue);
    this.casualtyId = config.casualtyId;
    this.timestamp = config.timestamp;
    this.isWorkAccident = config.isWorkAccident;
    this.incidentType = this.dateOfDeath ? DEATH : DISABLED;
  }

  get getIncidentType() {
    return IncidentType[this.incidentType];
  }

  get isBeforeCircular2015() {
    return toMoment(this.dateOfIssue).isBefore(CIRCULAR_2015_MOMENT);
  }

  get isBeforeCircular2020() {
    return toMoment(this.dateOfIssue).isBefore(CIRCULAR_2020_MOMENT);
  }

  get isBeforeCircular2021() {
    return toMoment(this.dateOfIssue).isBefore(CIRCULAR_2021_MOMENT);
  }

  get fileIdentifier() {
    return (
      this.damageFileIdentifier || this.courtFileIdentifier || this.arbitrationFileIdentifier || '-'
    );
  }
}
