import CasualtyResponse from './CasualtyResponse';
import IncidentResponse from './IncidentResponse';

export default class EventResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.id = config.id;
    this.responseStatus = config.responseStatus;
    this.timestamp = config.timestamp;
    this.incident = new IncidentResponse(config.incident);
    this.casualty = new CasualtyResponse(config.casualty);
  }
}
