import { setAppData } from '../../actions/appdata';
import { CASUALTIES, CASUALTY } from '../../constants';
import Connection from './Connection';
import CasualtyResponse from '../model/response/CasualtyResponse';

export default class CasualtyApi extends Connection {
  // CASUALTY ||||||||||||||||||||||||||||||
  static find(dispatch, casualtyId) {
    return this._getSecurely(`/injured-parties/casualties/${casualtyId}`)
      .then(...this.justData)
      .then((r) => {
        dispatch(setAppData({ name: CASUALTY, value: CasualtyResponse(r) }));
      })
      .catch(this.catchError);
  }

  static list(dispatch) {
    return this._getSecurely(`/injured-parties/casualties/`)
      .then(...this.justData)
      .then((r) => {
        dispatch(setAppData({ name: CASUALTIES, value: r.bindList(CasualtyResponse) }));
      })
      .catch(this.catchError);
  }

  static update(incidentId, casualtyId, data) {
    return this._patch(`/injured-parties/casualties/${casualtyId}`, data);
  }

  static create(incidentId, data) {
    return this._post(`/injured-parties/casualties/`, data);
  }

  static delete(incidentId, casualtyId) {
    return this._delete(`/injured-parties/casualties/${casualtyId}`);
  }
}
