import { setAppData } from '../../actions/appdata';
import { SPECIAL_PERIOD, SPECIAL_PERIOD_LIST } from '../../constants';
import Connection from './Connection';

export default class SpecialPeriodApi extends Connection {
  static list(dispatch, incidentId) {
    return this._getSecurely(`/incidents/${incidentId}/special-periods `)
      .then(...this.justData)
      .then((r) => dispatch(setAppData({ name: SPECIAL_PERIOD_LIST, value: r })))
      .catch(this.catchError);
  }

  static find(dispatch, incidentId, id) {
    return this._getSecurely(`/special-periods/${id}`)
      .then(...this.justData)
      .then((r) => {
        dispatch(setAppData({ name: SPECIAL_PERIOD, value: r }));
      })
      .catch(this.catchError);
  }

  static update(incidentId, id, data) {
    return this._patch(`/special-periods/${id}`, data);
  }

  static create(data, incidentId) {
    return this._post(`/incidents/${incidentId}/special-periods`, data);
  }

  static delete(incidentId, id) {
    return this._delete(`/special-periods/${id}`);
  }
}
