import React from 'react';
import { connect } from 'react-redux';
import { FormSection } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Subheader } from 'material-ui';
import {
  alimonyAmount,
  dateOfDivorce,
  dateOfMarriage,
  dateOfRetirement,
  employmentStatus,
  endOfSupportToOverride,
  hideShare,
} from '../../data/model/InjuredPerson';
import Clear from '../Functional/Clear';
import PersonForm from './PersonForm';
import { getFieldArrayItemStyle } from '../../utils/material-ui-style-util';
import FieldArrayRightButton from '../Functional/FieldArrayRightButton';

class SpouseForm extends React.Component {
  handleRemove = () => {
    const { index, fields } = this.props;
    fields.remove(index);
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    const { index, fields } = this.props;
    const { open } = this.state;

    return (
      <FormSection name={this.props.name} style={getFieldArrayItemStyle(index, fields.length)}>
        <Subheader className={'injuredPartySubHeader'}>Eş {index + 1}</Subheader>
        <PersonForm />
        {employmentStatus.component()}
        <div
          className={'optionalFieldContainer'}
          style={{
            visibility: open ? 'visible' : 'hidden',
            opacity: open ? 1 : 0,
            maxHeight: open ? 300 : 0,
            marginTop: open ? 24 : 0,
            paddingTop: open ? 16 : 0,
          }}
        >
          <span className={'optionalFieldHeader'} style={{ left: 0 }}>
            Detaylı Bilgiler
          </span>
          {dateOfMarriage.component()}
          {dateOfRetirement.component()}
          {endOfSupportToOverride.component()}
          <FormSection style={{ float: 'left' }} name="divorce">
            {dateOfDivorce.component()}
            {alimonyAmount.component()}
          </FormSection>
          {hideShare.component()}
          <Clear />
        </div>
        <div className={'bottomRightLinkButton'} onClick={() => this.setState({ open: !open })}>
          <FontAwesomeIcon icon={open ? 'caret-up' : 'caret-down'} fixedWidth />
          Detayları&nbsp;
          {open ? 'Gizle' : 'Göster'}
        </div>
        <Clear />
        <FieldArrayRightButton onClick={() => this.handleRemove()} />
      </FormSection>
    );
  }
}

export default connect()(SpouseForm);
