import Connection from './Connection';
import { setAppData } from '../../actions/appdata';
import { WEBHOOKS } from '../../constants';
import WebhookResponse from '../model/response/WebhookResponse';

export default class WebhookApi extends Connection {
  static find(dispatch, companyId) {
    return this._getSecurely(`/companies/${companyId}/webhooks`)
      .then(...this.justData)
      .then((r) =>
        dispatch(
          setAppData({
            name: WEBHOOKS,
            value: r ? r.bindClass(WebhookResponse) : {},
          }),
        ),
      )
      .catch(this.catchError);
  }

  static create(dispatch, companyId, data) {
    return this._post(`/companies/${companyId}/webhooks`, data)
      .then(...this.justData)
      .then((r) =>
        dispatch(
          setAppData({
            name: WEBHOOKS,
            value: r ? r.bindClass(WebhookResponse) : {},
          }),
        ),
      )
      .catch(this.catchError);
  }

  static delete(companyId) {
    return this._delete(`/companies/${companyId}/webhooks`);
  }
}
