import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import { connect } from 'react-redux';
import { FieldArray, formValueSelector, reduxForm } from 'redux-form';
import { Subheader } from 'material-ui';
import FileUploadList from '../../components/FileUpload/FileUploadList';
import Clear from '../../components/Functional/Clear';
import { EPOCH_DATE, UNKNOWN } from '../../constants';
import { openSnackbar } from '../../actions/snackbarActions';
import { hidePB, showPB } from '../../actions/progressbarAction';
import { validateFiles } from '../../utils/validation';
import { fileCountValidation, fileUpload } from '../../utils/file-upload-util';
import { getUUIDFromUrl } from '../../utils/javascript-object-util';
import { WriteCalculation, WriteIncident } from '../../data/model/Privilege';
import Authorization from '../../components/Authorization/Authorization';
import IncidentApi from '../../data/api/IncidentApi';
import Layout from '../../components/Layout/Layout';

class DocumentReport extends React.Component {
  state = {
    files: {},
  };

  handleValueChange = (files, index) => {
    const newFiles = {
      ...this.state.files,
      [index]: files,
    };
    this.setState({ files: newFiles });
  };

  onSubmit = (values) => {
    const obj = JSON.parse(JSON.stringify(values));
    const { dispatch } = this.props;
    const { files } = this.state;

    if (!fileCountValidation(dispatch, files, obj.files)) return;

    const initValues = {
      dateOfIncident: EPOCH_DATE,
      damageFileIdentifier: UNKNOWN,
      faultRatio: 0,
      mortalityTable: 'trh',
      lifeAnnuity: 'single',
      isApprovalNeeded: true,
      dateOfIssue: EPOCH_DATE,
      dateOfReport: EPOCH_DATE,
      disabilityRatio: 1,
      incidentType: 'death',
      files: [],
      interest: 0.02,
      shareRate: 'supreme',
      incomeCategory: 'minimumWage',
      injuredParty: {
        casualty: {
          basic: {
            personalIdentificationNumber: UNKNOWN,
            name: UNKNOWN,
            surname: UNKNOWN,
            gender: 'male',
            dateOfBirth: EPOCH_DATE,
          },
        },
      },
    };

    showPB(dispatch, 'Rapor Kaydı Oluşturuluyor');
    IncidentApi.create(initValues).then(
      (r) => {
        console.log(r.location);
        if (!r.location) {
          hidePB(dispatch);
          dispatch(
            openSnackbar('Dosyalar yüklenemedi. Lütfen oluşturduğunuz rapora belge ekleyiniz.'),
          );
          return;
        }
        fileUpload(dispatch, files, obj.files, getUUIDFromUrl(r.location));
      },
      (r) => {
        hidePB(dispatch);
        dispatch(openSnackbar('Rapor Kaydı oluşturma sırasında hata oluştu'));
      },
    );
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <Layout>
        <Authorization allowedRoles={[WriteIncident]} force>
          <div className={'root'}>
            <div className={'container'}>
              <div className={'max1024'}>
                <h1>Belge İle Oluştur</h1>
                <form style={{ padding: '0 16px 16px' }} onSubmit={handleSubmit(this.onSubmit)}>
                  <Subheader>Belge Ekle</Subheader>
                  <div style={{ marginBottom: 90 }}>
                    <FieldArray
                      name="files"
                      component={FileUploadList}
                      handleValueChange={this.handleValueChange}
                      style={{ margin: 0 }}
                    />
                    <Clear />
                  </div>
                  <RaisedButton
                    style={{ float: 'right' }}
                    type="submit"
                    disabled={!this.props.files || !this.props.files.length}
                    label="Rapor Oluştur"
                    primary
                  />
                </form>
                <Clear />
                <Authorization allowedRoles={[WriteCalculation]}>
                  <div
                    className={'smallInfo'}
                    style={{ width: '-webkit-fill-available', margin: 16 }}
                  >
                    &#9679; Yüklenen belgeler ile aktüer onaylı olacak şekilde rapor oluşturulur.{' '}
                    <br />
                    &#9679; Rapor oluşturma işlemi tamamlandıktan sonra yeni belgeleri rapor detayı
                    sayfasından yükleyebilirsiniz.
                  </div>
                </Authorization>
              </div>
            </div>
          </div>
        </Authorization>
      </Layout>
    );
  }
}

const validate = (values) => {
  const errors = {};
  errors.files = validateFiles(values);

  return errors;
};

DocumentReport = reduxForm({ form: 'report', validate })(DocumentReport);

const selector = formValueSelector('report');
export default connect((state) => ({
  files: selector(state, 'files'),
}))(DocumentReport);
