import React from 'react';
import { Divider, Paper, Subheader } from 'material-ui';
import { paperStyle } from '../../utils/material-ui-style-util';
import ListAddFAB from '../Functional/ListAddFAB';
import SpecialPeriodForm from './SpecialPeriodForm';
import { FieldArrayEmptyView } from '../Functional/FieldArrayEmptyView';

function SpecialPeriodListForm({ fields }) {
  return (
    <Paper style={paperStyle}>
      <Subheader className={`basicReportSubheader`}>Özel Durumlar</Subheader>
      {fields.map((name, index) => (
        <div key={index}>
          <SpecialPeriodForm name={name} index={index} fields={fields} />
          <Divider style={{ marginTop: 0 }} />
        </div>
      ))}
      <FieldArrayEmptyView fields={fields} />
      <ListAddFAB onClick={() => fields.push({})} />
    </Paper>
  );
}

export default SpecialPeriodListForm;
