export function AuthorizationError(message) {
  this.message = message;
  // Use V8's native method if available, otherwise fallback
  if ('captureStackTrace' in Error) Error.captureStackTrace(this, AuthorizationError);
  else this.stack = new Error().stack;
}

AuthorizationError.prototype = Object.create(Error.prototype);
AuthorizationError.prototype.name = 'AuthorizationError';
AuthorizationError.prototype.constructor = AuthorizationError;
