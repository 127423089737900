/* eslint-disable no-continue,prefer-destructuring,react/prop-types,react/prop-types,react/sort-comp */
import React from 'react';
import { Avatar, Chip, Divider, FlatButton, IconButton } from 'material-ui';
import { connect } from 'react-redux';
import Add from 'material-ui/svg-icons/content/add';
import Close from 'material-ui/svg-icons/navigation/close';
import includes from 'lodash/includes';
import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';
import { Field, initialize, reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { setAppData } from '../../actions/appdata';
import Clear from '../Functional/Clear';
import { ACTIVE_FILTER_OPTIONS, INIT_REPORT_FILTER, REPORT_FILTERS } from '../../constants';
import ColorPalette from '../../ColorPalette';
import { reportFilterPatterns } from './ReportFilterPattern';
import Authorization from '../Authorization/Authorization';
import { ACTUARY, CUSTOMER } from '../../data/model/Privilege';

const labelStyle = {
  display: 'inline-block',
  fontSize: '1.25em',
  fontWeight: 400,
  margin: '0.75em 0',
  color: ColorPalette.textColor,
};

const styles = {
  chip: {
    margin: 4,
    display: 'inline-flex',
  },
  chipLabel: {
    fontWeight: 500,
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  labelStyle: { ...labelStyle },
  labelStyleTitle: {
    ...labelStyle,
    minWidth: 190,
    textAlign: 'right',
  },
  percentageInputStyle: {
    color: ColorPalette.primary1Color,
    textAlign: 'center',
  },
};

class ReportFilterForm extends React.Component {
  handleScroll = (dispatch, change) => (name) => (event) => {
    const step = event.deltaY < 0 ? 1 : -1;
    let value = parseFloat(this.props[name]);
    value = isNaN(value) ? 0 : parseFloat(value.toFixed(2));
    let newValue = value + step;
    if (newValue > 100) newValue = 100;
    if (newValue < 0) newValue = 0;
    dispatch(change(name, `${newValue}`));
  };

  constructor(props) {
    super(props);

    const emptyFilter = {};
    for (const k in reportFilterPatterns) {
      if (reportFilterPatterns.hasOwnProperty(k)) {
        emptyFilter[reportFilterPatterns[k].name] = null;
        emptyFilter[`${reportFilterPatterns[k].name}From`] = null;
        emptyFilter[`${reportFilterPatterns[k].name}Till`] = null;
      }
    }
    this.state = {
      selectedFilters: [],
      emptyFilter,
    };
    this.props.dispatch(setAppData({ name: INIT_REPORT_FILTER, value: emptyFilter }));
  }

  componentDidMount() {
    const { activeFilterOptions } = this.props;
    const activeFilterKeys = [];
    const filters = [];
    for (const k in activeFilterOptions) {
      if (activeFilterOptions.hasOwnProperty(k) && activeFilterOptions[k]) {
        activeFilterKeys.push(k);
      }
    }

    uniq(activeFilterKeys.map((k) => k.replace('From', '').replace('Till', ''))).forEach((k) => {
      if (includes(activeFilterKeys, `${k}From`) && includes(activeFilterKeys, `${k}Till`))
        filters.push({ name: k, type: 4 });
      else if (includes(activeFilterKeys, `${k}Till`)) filters.push({ name: k, type: 3 });
      else if (includes(activeFilterKeys, `${k}From`)) filters.push({ name: k, type: 2 });
      else filters.push({ name: k, type: 1 });
    });

    this.setState({ selectedFilters: filters });
  }

  setDate = (name, unit) =>
    this.props.change(`${name}From`, moment().subtract(1, unit).format('DD.MM.YYYY'));

  addFilter = (filter) => {
    const abilities = reportFilterPatterns[filter].abilities;
    this.setState({
      selectedFilters: [
        ...this.state.selectedFilters,
        { name: filter, type: abilities.length === 1 ? abilities[0].id : null },
      ],
    });
  };

  removeFilter = (filter) => {
    this.setState({
      selectedFilters: [...this.state.selectedFilters.filter((f) => f.name !== filter.name)],
    });

    const { dispatch, change } = this.props;
    dispatch(change(filter.name, null));
    dispatch(change(`${filter.name}From`, null));
    dispatch(change(`${filter.name}Till`, null));
  };

  updateFilter = (filter, ability) => {
    const filters = this.state.selectedFilters;
    const index = filters.findIndex((f) => f.name === filter.name);
    filters[index].type = ability.id;
    this.setState({ selectedFilters: filters });
  };

  buildFilterSelections = () => {
    const chipList = [];
    for (const filter in reportFilterPatterns) {
      if (!reportFilterPatterns.hasOwnProperty(filter)) continue;
      if (
        includes(
          this.state.selectedFilters.map((f) => f.name),
          filter,
        )
      )
        continue;
      const filterPattern = reportFilterPatterns[filter];
      chipList.push(
        <Authorization allowedRoles={filterPattern.allowedRoles || [CUSTOMER, ACTUARY]}>
          <Chip
            backgroundColor="#aed1d766"
            key={filterPattern.name}
            style={styles.chip}
            onClick={() => this.addFilter(filter)}
            labelStyle={styles.chipLabel}
          >
            <Avatar backgroundColor="#aed1d7" icon={<Add />} />
            {filterPattern.text}
          </Chip>
        </Authorization>,
      );
    }
    return chipList;
  };

  buildFilters = () => {
    const list = [];

    this.state.selectedFilters.forEach((filter) => {
      const pattern = reportFilterPatterns[filter.name];
      const name = pattern.name;
      const change = this.props.change;
      const dispatch = this.props.dispatch;
      const onWheel = this.handleScroll(dispatch, change);
      const getField = (name) => (
        <Field
          name={name}
          autoComplete="off"
          {...pattern.props}
          onWheel={
            pattern.additionalProp && pattern.additionalProp.includes('onWheel')
              ? onWheel(name)
              : () => {}
          }
        />
      );
      list.push(
        <div>
          <IconButton
            size={24}
            style={{ margin: 1, float: 'left' }}
            backgroundColor="#dea1a7"
            onClick={() => this.removeFilter(filter)}
          >
            <Close color="#8e8e8e" />
          </IconButton>
          <p style={styles.labelStyleTitle}>{pattern.text}</p>
          {filter.type === null && (
            <div style={{ marginLeft: 8, display: 'inline-block' }}>
              {pattern.abilities.map((ability) => (
                <Chip
                  backgroundColor="#aed1d733"
                  key={ability.id}
                  style={styles.chip}
                  onClick={() => {
                    this.updateFilter(filter, ability);
                  }}
                  labelStyle={styles.chipLabel}
                >
                  {ability.text}
                </Chip>
              ))}
            </div>
          )}
          {filter.type === 1 && (
            <div style={{ display: 'inline-block' }}>
              {getField(name)}
              {name !== 'issuerId' && (
                <p
                  style={
                    styles.labelStyle // Todo Bad Fix
                  }
                >
                  {' '}
                  değerine eşit
                </p>
              )}
            </div>
          )}
          {filter.type === 2 && (
            <div style={{ display: 'inline-block' }}>
              {getField(`${name}From`)}
              <p style={styles.labelStyle}>'dan Başlayan</p>
              {(name.includes('date') || name.includes('timestamp')) && (
                <div
                  style={{
                    display: 'inline-block',
                    padding: '0 8px',
                  }}
                >
                  <span
                    className={'smallInfoButton'}
                    style={{ marginLeft: 4 }}
                    onClick={() => this.setDate(name, 'days')}
                  >
                    1 gün
                  </span>
                  <span
                    className={'smallInfoButton'}
                    style={{ marginLeft: 4 }}
                    onClick={() => this.setDate(name, 'weeks')}
                  >
                    1 hafta
                  </span>
                  <span
                    className={'smallInfoButton'}
                    style={{ marginLeft: 4 }}
                    onClick={() => this.setDate(name, 'months')}
                  >
                    1 ay
                  </span>
                </div>
              )}
            </div>
          )}
          {filter.type === 3 && (
            <div style={{ display: 'inline-block' }}>
              {getField(`${name}Till`)}
              <p style={styles.labelStyle}>'a Kadar</p>
            </div>
          )}
          {filter.type === 4 && (
            <div style={{ display: 'inline-block' }}>
              {getField(`${name}From`)}
              <p style={styles.labelStyle}>ile</p>
              {getField(`${name}Till`)}
              <p style={styles.labelStyle}>arasında</p>
            </div>
          )}
        </div>,
      );
    });
    return list;
  };

  handleFilterResetClick = () => {
    this.props.reset();
    this.props.dispatch(
      setAppData({
        name: ACTIVE_FILTER_OPTIONS,
        values: this.state.emptyFilter,
      }),
    );
    this.props.dispatch(
      setAppData({
        name: INIT_REPORT_FILTER,
        values: this.state.emptyFilter,
      }),
    );
    this.setState({ selectedFilters: [] });
    this.props.dispatch(initialize('reportFilter', this.state.emptyFilter));
    this.props.onReset();
  };

  render() {
    return (
      <div
        style={{
          position: 'relative',
          minWidth: 295,
        }}
      >
        <div style={{ padding: '0 16px 8px 16px' }}>{this.buildFilterSelections()}</div>
        <Divider />
        <div style={{ position: 'relative' }}>
          {this.buildFilters()}
          {!isEqual(this.props.activeFilterOptions, this.props.initialValues) && (
            <FlatButton
              style={{ position: 'absolute', minWidth: 36, top: 8, right: 16 }}
              tooltip="Temizle"
              icon={<FontAwesomeIcon fixedWidth icon="eraser" />}
              onClick={this.handleFilterResetClick}
            />
          )}
          <Clear />
        </div>
      </div>
    );
  }
}

ReportFilterForm = reduxForm({
  form: 'reportFilter',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ReportFilterForm);

export default connect((state) => ({
  filterOptions: state.appData[REPORT_FILTERS],
  activeFilterOptions: state.appData[ACTIVE_FILTER_OPTIONS],
}))(ReportFilterForm);
