const innerMargin = [20, 10, 10, 5];
const paragraphStyle = { fontSize: 10 };
const trhPeriodMarginHeader = [20, 5, 10, 0];
const trhPeriodMargin = [20, 5, 10, 5];

export const pmfRegulationsIntro = {
  margin: innerMargin,
  style: paragraphStyle,
  text:
    'Yargıtay 17.Hukuk Dairesi, "İş Gücü Kaybına ve Destek Kaybına İlişkin Tazminat Hesabı" Y17.HD.E.2011/11066K.2012/10762.T. 09.10.2012 tarihli karara esasen, hesaplamalarda, PMF 1931 Ölüm Tablosuna göre bulunan yaşam ümidi ve %10 iskonto faizi dikkate alınır.',
};
export const trhRegulationsIntro = {
  margin: innerMargin,
  style: paragraphStyle,
  text:
    'Hesaplamalarda Ölüm Tablosu olarak poliçe tanzim tarihi 01.06.2015 öncesi poliçeler için CSO 1980 ya da TRH 2010 Ölüm Tabloları ve sonrası poliçeler için ise TRH 2010 Ölüm Tablosu dikkate alınır.',
};
export const regulationsBodyList = [
  { ul: ['Borçlar Kanunu'], margin: [20, 10, 10, 5], style: { bold: true } },
  {
    text:
      'BK Madde 53 e göre; Ölen kişinin sağlığı zamanındaki kazancıyla, ölmesi neticesinde, desteğinin yitirilmesinden doğan kayıpların temin edilmesi amaçlanmaktadır.',
    margin: [20, 0, 10, 5],
    style: { fontSize: 10 },
  },
  {
    ul: ['Sigortacılık Mevzuatı'],
    margin: [20, 5, 10, 5],
    style: { bold: true },
  },
  {
    text:
      'Sigorta türüne göre, hesaplamalara esas mevzuata ilişkin açıklamalar, aşağıda ve detayları ile EK 1’ de sunulmuştur.',
    margin: [20, 0, 10, 5],
    style: { fontSize: 10 },
  },
  {
    ul: ['Karayolları Trafik Kanunu ve Karayolları Trafik Sigortası'],
    margin: innerMargin,
    style: { bold: true },
  },
  {
    ul: ['Zorunlu Karayolu Taşımacılık Mali Sorumluluk Sigortası'],
    margin: innerMargin,
    style: { bold: true },
  },
  {
    ul: ['Karayolu Yolcu Taşımacılığı Zorunlu Koltuk Ferdi Kaza Sigortası'],
    margin: innerMargin,
    style: { bold: true },
  },
  {
    ul: ['Tüpgaz Zorunlu Mali Sorumluluk Sigortası Yönetmeliği'],
    margin: innerMargin,
    style: { bold: true },
  },
  {
    ul: ['Tehlikeli Maddeler ve Tehlikeli Atık Zorunlu Mali Sorumluluk Sigortası'],
    margin: innerMargin,
    style: { bold: true },
  },
  {
    ul: ['Maden Çalışanları Zorunlu Ferdi Kaza Sigortası'],
    margin: innerMargin,
    style: { bold: true },
  },
  {
    ul: ['Tıbbi Kötü Uygulamaya İlişkin Zorunlu Mali Sorumluluk'],
    margin: innerMargin,
    style: { bold: true },
  },
  {
    text:
      'Sigortacılık Mevzuatına göre Mağdur bir işte çalışmasa dahi sakat kalması neticesi uğradığı çalışma gücü ve yaşamsal faaliyetlerini sürdürmesi için gereken ekonomik kaybın karşılanması amacı ile yapılan tazminat hesabı, Malul kalan kişinin muhtemel yaşam süresi boyunca Yargıtay 4. Hukuk Dairesi tarafından düzenlenen ve müteakiben açıklanan yöntem üzerinden hesaplanır. ',
    margin: innerMargin,
    style: paragraphStyle,
  },
];
export const workability = [
  {
    text: 'ÇALIŞMA YAŞI:',
    style: { fontSize: 11, bold: true },
    margin: trhPeriodMarginHeader,
  },
  {
    text: 'Hesaplamalarda çalışma yaşı 18 olarak kabul edilmiştir.',
    style: { fontSize: 10 },
    margin: trhPeriodMargin,
  },
];

export const remarrying = (percentage) => {
  const section = [
    {
      text: 'YENİDEN EVLENME OLASILIĞI:',
      style: { fontSize: 11, bold: true },
      margin: trhPeriodMarginHeader,
    },
  ];

  const body = [
    `Müteveffanın eşinin yeniden evlenme olasılığı Askeri Yüksek İdare Mahkemesi (AYİM) Tablosu'na göre aşağıdaki formül ile hesaplanmaktadır.\n`,
    `Yeniden evlenme olasılığı =  AYİM % Oran – (%5 * Rapor tarihinde Müteveffanın reşit olmayan çocuk sayısı)\n`,
    `Sonucun negatif çıkması halinde yeniden evlenme olasılığı sıfır olarak kabul edilmektedir.\n`,
  ];

  if (percentage) {
    body.push(
      'Bu Rapor için yeniden evlenme oranı ',
      {
        text: `%${percentage}`,
        style: { bold: true },
        margin: trhPeriodMarginHeader,
      },
      ' olarak hesaplanmıştır.',
    );
  }

  section.push({
    text: body,
    margin: trhPeriodMargin,
    style:{ fontSize: 10 },
  });

  return section;
};

export const calculationRules = {
  text: 'HESAPLAMA USÜLLERİ',
  style: 'h1',
  alignment: 'center',
  margin: [0, 20, 0, 10],
};

export const pmfRegulationsEnd = [
  {
    text:
      'Denklik kuralı yani gelir artışı için kabul edilen artış oranı, peşin ödeme değeri (iskonto) oranına da uygulanma prensibi esas alındığında faiz ve iskonto birbirinin tersi olup, olumsuz faktörleri ortadan kaldıracağından;',
    margin: innerMargin,
    style: paragraphStyle,
  },
  {
    text:
      '“Bilinmeyen döneme ait gerçek zararın ve hüküm altına alınacak tazminat miktarının belirlenmesi için, raporun düzenlendiği tarihte bilinen son gelir miktarı esas alınmalı ve her yılın toplam geliri %10 oranında artırılmalı ve yine %10 oranında indirilmek suretiyle hesaplama yapılmalıdır.”',
    margin: innerMargin,
    style: paragraphStyle,
  },
];

export const trhRegulationsEndHeader = [
  {
    text:
      'Hesaplanacak olan tazminat tutarı, rapor tarihi itibariyle peşin olarak ödeneceğinden, destek tutarının aktüeryal formüllerle peşin değerinin bulunması gerekmektedir. Bunun için;',
    margin: innerMargin,
    style: paragraphStyle,
  },
];
export const trhRegulationsEndKnown = [
  {
    headlineLevel: 1,
    stack: [
      {
        text: 'BİLİNEN DÖNEM:',
        style: { bold: true, fontSize: 11 },
        margin: trhPeriodMarginHeader,
      },
      {
        text:
          '\tKaza tarihi ile tazminat hesaplama tarihi arasındaki dönem için teknik faiz %0 olmak üzere “Belirli Süreli Dönem Başı Ödemeli Kesin Anüite” formülü uygulanır.',
        margin: trhPeriodMargin,
        style: paragraphStyle,
      },
    ],
  },
];
export const trhRegulationEndActive = [
  {
    headlineLevel: 1,
    stack: [
      {
        text: 'AKTİF DÖNEM:',
        style: { bold: true, fontSize: 11 },
        margin: trhPeriodMarginHeader,
      },
      {
        text:
          '\tTazminat hesaplama tarihi ile Müteveffanın emeklilik tarihi arasındaki dönem için, hesaplamada kullanılacak teknik faiz esas alınarak, “ä x:r-x = (N x -N r )/D x Devre Başı Ödemeli Belirli Süreli Rant” formülü uygulanır.\n r: Emeklilik Yaşı',
        margin: trhPeriodMargin,
        style: paragraphStyle,
      },
    ],
  },
];
export const trhRegulationEndPassive = [
  {
    headlineLevel: 1,
    stack: [
      {
        text: 'PASİF DÖNEM:',
        style: { bold: true, fontSize: 11 },
        margin: trhPeriodMarginHeader,
      },
      {
        text:
          '\tMüteveffanın emeklilik tarihi ile destek vereceği süre sonu arasındaki dönem için, hesaplamada kullanılacak teknik faiz esas alınarak, “r ä x:n = (N r -N r+n )/D x Devre Başı Ödemeli Ertelenmiş Rant” formülü uygulanır.\n r: Emeklilik Yaşı',
        margin: trhPeriodMargin,
        style: paragraphStyle,
      },
    ],
  },
];

export const militaryInfo = [
  { ul: ['Askerlik Kanunu'], margin: innerMargin, style: { bold: true } },
  {
    text:
      "26.Haziran.2019 tarihli Resmi Gazetede yayınlanan 7179 Sayılı Kanun Numaralı “Askeralma Kanunu” ile Tazminat Hesabında; askerlik yaşı 20 yaş olarak, askerlik süresi 6 ay olarak kabul edilmiştir. Ayrıca yürürlükteki kanun dışında kalan kişiler için tabii oldukları Askerlik Kanunu'na uygun şekilde işlem yapılmıştır.",
    style: paragraphStyle,
    margin: innerMargin,
  },
];

export const temporaryDisabilityInfo = [
  { ul: ['GİG'], margin: innerMargin, style: { bold: true } },
  {
    text:
      'Kişinin kaza tarihinde vefat etmemesi durumunda, kaza tarihi ile ölüm tarihi arasındaki süreç kişi için geçici iş göremez olarak kabul edilir.',
    style: { bold: true, fontSize: 10, italics: true },
    margin: [20, 5, 10, 5],
  },
];

export const previousPaymentsInfo = [
  { ul: ['Önceden Ödeme'], margin: innerMargin, style: { bold: true } },
  {
    text:
      'Sigorta Şirketi tarafından ödenen meblağ, ödeme tarihi ile rapor tarihi arasında yasal faiz işletilerek, aşağıda formülü verilen şekilde güncellenir.',
    style: { bold: true, fontSize: 10, italics: true },
    margin: [20, 5, 10, 5],
  },
  {
    text: '[Daha önce yapılan ödeme * (1+(i* (hesap tarihi – ödeme tarihi)/365))]',
    style: { bold: true, italics: true, fontSize: 12 },
    margin: [20, 5, 10, 5],
  },
];

export const headerDivider = [
  {
    type: 'line',
    x1: 40,
    y1: 0,
    x2: 555,
    y2: 0,
    lineColor: '#333333',
    lineWidth: 2,
  },
  {
    type: 'line',
    x1: 40,
    y1: 3,
    x2: 555,
    y2: 3,
    lineColor: '#333333',
    lineWidth: 1,
  },
];
export const footerDivider = [
  {
    type: 'line',
    x1: 40,
    y1: 0,
    x2: 555,
    y2: 0,
    lineColor: '#333333',
    lineWidth: 1,
  },
  {
    type: 'line',
    x1: 40,
    y1: 3,
    x2: 555,
    y2: 3,
    lineColor: '#333333',
    lineWidth: 2,
  },
];
