/* eslint-disable radix */
import { SALARY_TYPE_GROSS, SALARY_TYPE_NET } from '../../SalaryType';
import { CLEANER_INTEGER } from '../../../../utils/CleanerValues';

export class SalaryUpdateRequest {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.net = config.salaryType === SALARY_TYPE_NET ? config.net : CLEANER_INTEGER;
    this.gross = config.salaryType === SALARY_TYPE_GROSS ? config.gross : CLEANER_INTEGER;
    this.year = parseInt(config.year);
    this.month = parseInt(config.month);
    this.workingDays = parseInt(config.workingDays) || 30;
  }
}

export class SalaryCreateRequest {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.net = config.salaryType === SALARY_TYPE_NET ? config.net : undefined;
    this.gross = config.salaryType === SALARY_TYPE_GROSS ? config.gross : undefined;
    this.year = parseInt(config.year);
    this.month = parseInt(config.month);
    this.workingDays = parseInt(config.workingDays) || 30;
  }
}
