import React from 'react';
import { connect } from 'react-redux';
import { Divider, FlatButton, Paper, RaisedButton } from 'material-ui';
import Edit from 'material-ui/svg-icons/editor/mode-edit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReportInfos from './ReportInfos';
import { ACTUARY, WriteIncident } from '../../../data/model/Privilege';
import Authorization from '../../Authorization/Authorization';
import FileList from '../../File/FileList';
import { openSnackbar } from '../../../actions/snackbarActions';
import { hidePB, showPB } from '../../../actions/progressbarAction';
import { INIT_BASIC_REPORT } from '../../../constants';
import { setAppData } from '../../../actions/appdata';
import IncidentApi from '../../../data/api/IncidentApi';
import FileUploadForm from '../../FileUpload/FileUploadForm';
import Dialog from '../../Functional/Dialog';

class ReportInfosPage extends React.Component {
  pushUpdatePage = () => {
    const { detailedIncident } = this.props;
    const { incident } = detailedIncident;
    this.props.history.push(`/reports/${incident.id}/update`);
  };

  deleteIncident = () => {
    const { detailedIncident, dispatch } = this.props;
    const { incident } = detailedIncident;
    showPB(dispatch, 'Rapor Siliniyor');
    IncidentApi.delete(incident.id).then(
      () => {
        hidePB(dispatch);
        dispatch(openSnackbar('Rapor Silindi.'));
        this.props.history.push('/reports');
        this.handleDeleteDialogClose();
      },
      (r) => {
        hidePB(dispatch);
        dispatch(openSnackbar(`Rapor Silinemedi ${r.message}`));
        this.handleDeleteDialogClose();
      },
    );
  };

  handleDeleteDialogClose = () => {
    this.setState({ openDelete: false });
  };

  deleteActions = [
    <FlatButton label="İptal" keyboardFocused onClick={() => this.handleDeleteDialogClose()} />,
    <FlatButton label="Sil" secondary onClick={this.deleteIncident} />,
  ];

  handleUploadOpen = () => {
    this.setState({ openUpload: true });
  };

  handleUploadClose = () => {
    this.setState({ openUpload: false });
  };

  constructor(props) {
    super(props);
    this.state = {
      openDelete: false,
      openUpload: false,
    };
  }

  componentDidMount() {
    const { detailedIncident, dispatch } = this.props;
    const { incident } = detailedIncident;
    dispatch(
      setAppData({
        name: INIT_BASIC_REPORT,
        value: {
          incident: {
            ...incident,
            tagId: detailedIncident.tag.id,
          },
        },
      }),
    );
  }

  render() {
    const { detailedIncident } = this.props;

    return (
      <Paper style={{ position: 'relative' }}>
        <Authorization allowedRoles={[WriteIncident]}>
          <FlatButton
            className={'hide'}
            style={{
              position: 'absolute',
              top: 8,
              right: 8,
              minWidth: 38,
              maxWidth: 38,
            }}
            tooltip="Güncelle"
            tooltipposition="top-right"
            onClick={this.pushUpdatePage}
            icon={<Edit />}
          />
        </Authorization>
        <Authorization allowedRoles={[ACTUARY, WriteIncident]} and>
          <FlatButton
            className={'hide'}
            style={{
              position: 'absolute',
              top: 48,
              right: 8,
              minWidth: 38,
              maxWidth: 38,
            }}
            secondary
            hoverColor="#FFDDDD"
            rippleColor="#FFAAAA"
            tooltip="Sil"
            tooltipposition="top-right"
            onClick={() => this.setState({ openDelete: true })}
          >
            <FontAwesomeIcon icon="archive" />
          </FlatButton>
        </Authorization>
        <div
          style={{
            padding: '32px 16px 0px 16px',
          }}
        >
          <ReportInfos detailedIncident={detailedIncident} />
          <Divider
            style={{
              marginBottom: 8,
              marginTop: 24,
              marginRight: -16,
              marginLeft: -16,
            }}
          />
          <div className={'hide'} style={{ paddingBottom: '16px' }}>
            <FileList incidentId={detailedIncident.incident.id} />
            <Authorization allowedRoles={[WriteIncident]}>
              <RaisedButton
                primary
                style={{ margin: 'auto', display: 'table' }}
                label="Ek Yükle"
                onClick={this.handleUploadOpen}
              />
            </Authorization>
          </div>
        </div>
        <Dialog
          title={<span className={'errorInfo'}>Silme Uyarısı</span>}
          actions={this.deleteActions}
          onRequestClose={() => this.handleDeleteDialogClose()}
          open={this.state.openDelete}
        >
          <b>
            <p>Bu raporu silmek istediğinize emin misiniz?</p>

            <p>
              <FontAwesomeIcon icon="exclamation" fixedWidth />
              İşlem sonucunda rapor ve ilişkili <strong>hesaplama sonuçları</strong> (varsa)
              silinir.
            </p>
          </b>
        </Dialog>
        <Dialog
          title="Belge Yükle"
          modal={false}
          contentStyle={{ width: 304 }}
          open={this.state.openUpload}
        >
          <FileUploadForm
            onRequestClose={this.handleUploadClose}
            incidentId={detailedIncident.incident.id}
          />
        </Dialog>
      </Paper>
    );
  }
}

export default connect()(ReportInfosPage);
