/* eslint-disable no-continue,no-prototype-builtins,no-restricted-syntax,no-await-in-loop,prefer-promise-reject-errors */
import { hidePB, showPB } from '../actions/progressbarAction';
import { openSnackbar } from '../actions/snackbarActions';
import FileApi from '../data/api/FileApi';

export async function fileUpload(dispatch, files, fileCategories, incidentId) {
  if (!files) return;
  let allFiles = 0;
  let uploadedFileCount = 0;
  for (const index in files) {
    if (!files.hasOwnProperty(index)) continue;
    allFiles += files[index].length;
  }
  showPB(dispatch, `Dosyalar Yükleniyor ${uploadedFileCount}/${allFiles}`);
  for (const index in files) {
    if (!files.hasOwnProperty(index)) continue;

    await FileApi.create(files[index], incidentId, fileCategories[index].category).then(
      (r) => {
        uploadedFileCount += files[index].length;
        showPB(dispatch, `Dosyalar Yükleniyor ${uploadedFileCount}/${allFiles}`);
      },
      (r) => {
        dispatch(openSnackbar('Dosya yükleme sırasında bir hata oluştu.'));
        hidePB(dispatch);
      },
    );
  }
}

export function fileCountValidation(dispatch, files, categories, requiredFiles = 0) {
  const filesCount = files ? Object.keys(files).length : 0;
  const categoryCount = categories ? categories.length : 0;
  if (categoryCount !== filesCount && filesCount >= requiredFiles) {
    dispatch(openSnackbar('Bazı belge tipleri için dosya girmediniz.'));
    return false;
  }
  return true;
}

export function fileToFileList(files) {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('file', files[i]);
  }
  return formData;
}
