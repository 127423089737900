import TransactionResponse from './TransactionResponse';
import PricingIncident from './PricingIncident';
import DetailedInvoiceDraftResponse from './DetailedInvoiceDraftResponse';

export default class PricingResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.incident = new PricingIncident(config.incident);
    this.draft = new DetailedInvoiceDraftResponse(config.draft);
    this.transactions = config.transactions
      ? config.transactions.bindList(TransactionResponse)
      : [];
  }
}
