import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import RaisedButton from 'material-ui/RaisedButton';
import { connect } from 'react-redux';
import { renderTextField } from '../../utils/redux-metarial-ui-util';
import { openSnackbar } from '../../actions/snackbarActions';
import { INIT_RESET_PASSWORD } from '../../constants';
import { hidePB } from '../../actions/progressbarAction';
import { getErrorMessage } from '../../utils/error-messages';
import AccountApi from '../../data/api/AccountApi';

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(values, dispatch) {
    values.token = this.props.tokenId;

    return AccountApi.resetPassword(values).then(
      (r) => {
        hidePB(dispatch);
        dispatch(openSnackbar('Şifre Değiştirildi.'));
        this.props.history.push('/login');
      },
      (r) => {
        hidePB(dispatch);
        dispatch(openSnackbar(getErrorMessage(r.error)));
      },
    );
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <p>
          Giriş yaparken kullanacağınız şifrenizi belirleyiniz. <br />
          Şifre 6 karakterden fazla olmalıdır.
        </p>
        <div>
          <Field
            name="password"
            style={{ margin: 0 }}
            component={renderTextField}
            floatingLabelText="Şifre"
            type="password"
            fullWidth
          />
          <Field
            name="passwordAgain"
            style={{ margin: 0 }}
            component={renderTextField}
            floatingLabelText="Şifre (Tekrar)"
            type="password"
            fullWidth
          />
        </div>
        <div style={{ marginTop: 32 }}>
          <RaisedButton
            type="submit"
            style={{ float: 'right', width: '50%' }}
            label="Şifremi Güncelle"
            primary
            fullWidth
          />
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const REQUIRED = 'Zorunlu Alan';
  const LESS_THEN_6_CHAR = 'Şifre 6 karakterden fazla olmalıdır.';
  const fields = ['password', 'passwordAgain'];
  if (values.password !== values.passwordAgain) {
    errors.passwordAgain = 'Şifreler Aynı Değil';
  }
  fields.forEach((k) => {
    if (!values[k]) {
      errors[k] = REQUIRED;
      return;
    }
    if (values[k].length < 6) {
      errors[k] = LESS_THEN_6_CHAR;
    }
  });

  return errors;
};
ResetPasswordForm = reduxForm({
  form: 'forgotPasswordForm',
  validate,
})(ResetPasswordForm);

export default connect((state) => ({
  initialValues: state.appData[INIT_RESET_PASSWORD],
}))(ResetPasswordForm);
