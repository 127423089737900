/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { FloatingActionButton, Paper } from 'material-ui';
import ContentAdd from 'material-ui/svg-icons/content/add';
import { useHistory } from 'react-router';
import CalculationConfigViewHolder from './CalculationConfigViewHolder';
import { WriteIncident } from '../../../data/model/Privilege';
import Authorization from '../../Authorization/Authorization';
import { setAppData } from '../../../actions/appdata';
import { INIT_CALCULATION_CONFIG } from '../../../constants';
import EmptyView from '../../Functional/EmptyView';
import p from '../../print.css';

function CalculationConfigListPage({ detailedIncident, dispatch }) {
  const history = useHistory();

  const handleNewConfig = () => {
    const { configs } = detailedIncident;
    const initData = {};
    if (configs && configs.length > 0) {
      initData.incidentType = configs[0].incidentType;
    }
    dispatch(setAppData({ name: INIT_CALCULATION_CONFIG, value: initData }));
    history.push(`/reports/${detailedIncident.incident.id}/calculation-configs/create`);
  };

  const { configs } = detailedIncident;

  return (
    <div>
      {configs && configs.length > 0 ? (
        configs.map((c) => (
          <CalculationConfigViewHolder
            key={c.id}
            configs={configs}
            detailedIncident={detailedIncident}
            config={c}
            history={history}
          />
        ))
      ) : (
        <Paper style={{ padding: 16 }}>
          <EmptyView icon="calculator" label="Hesaplama Ayarı Yok" />
        </Paper>
      )}
      <Authorization allowedRoles={[WriteIncident]}>
        <FloatingActionButton
          className={p.hide}
          mini
          onClick={handleNewConfig}
          style={{ margin: 'auto', display: 'table', marginTop: 12 }}
          label="Yeni Ayar Ekle"
        >
          <ContentAdd />
        </FloatingActionButton>
      </Authorization>
    </div>
  );
}

export default connect()(CalculationConfigListPage);
