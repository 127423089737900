import { setAppData } from '../../actions/appdata';
import { ROLE_LIST } from '../../constants';
import Connection from './Connection';
import RoleResponse from '../model/response/RoleResponse';

export default class RoleApi extends Connection {
  static find(companyId, roleId) {
    return this._getSecurely(`/companies/${companyId}/roles/${roleId}`)
      .then(...this.justData)
      .catch(this.catchError);
  }

  static list(dispatch, companyId) {
    return this._getSecurely(`/companies/${companyId}/roles`)
      .then(...this.justData)
      .then((r) =>
        dispatch(
          setAppData({
            name: ROLE_LIST,
            value: r ? r.bindList(RoleResponse) : [],
          }),
        ),
      )
      .catch(this.catchError);
  }

  static update(companyId, roleId, data) {
    return this._patch(`/companies/${companyId}/roles/${roleId}`, data);
  }

  static create(companyId, data) {
    return this._post(`/companies/${companyId}/roles`, data);
  }

  static delete(companyId, roleId) {
    return this._delete(`/companies/${companyId}/roles/${roleId}`);
  }
}
