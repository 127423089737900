/* eslint-disable react/prop-types,no-prototype-builtins,no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AutoComplete } from 'material-ui';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { CUSTOMERS, DEBOUNCE_TEXT_INPUT } from '../../constants';
import CustomerApi from '../../data/api/CustomerApi';

class TagComboBox extends Component {
  getData = debounce((text) => CustomerApi.list(this.props.dispatch, text), DEBOUNCE_TEXT_INPUT);

  handleUpdateInput = (searchText, dataSource, params) => {
    this.props.onChange(null);
    this.getData(searchText);
    this.setState({ searchText });
  };

  handleNewRequest = (chosenRequest, index) => {
    console.log('NewRequest');
    this.props.onChange(chosenRequest.id);
    this.setState({ searchText: chosenRequest.officialName });
  };

  constructor(props) {
    super(props);
    this.state = {
      searchText: null,
    };
  }

  render() {
    const { title, label, customers } = this.props;
    return (
      <AutoComplete
        {...this.props}
        fullWidth
        onUpdateInput={this.handleUpdateInput}
        onNewRequest={this.handleNewRequest}
        dataSource={customers || []}
        dataSourceConfig={{ text: 'officialName', value: 'id' }}
        floatingLabelText={title || label}
        searchText={this.state.searchText}
        maxSearchResults={10}
        menuCloseDelay={0}
        filter={() => true}
        animated={false}
        onBlur={() => {
          if (isEmpty(customers)) {
            this.props.onChange(null);
            this.setState({ searchText: '' });
          } else {
            this.props.onChange(customers[0].id);
            this.setState({ searchText: customers[0].officialName });
          }
        }}
      />
    );
  }
}

export default connect((state) => ({
  customers: state.appData[CUSTOMERS],
}))(TagComboBox);
