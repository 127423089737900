import React, { Component } from 'react';
import JSONTree from 'react-json-tree';

class AppJsonTree extends Component {
  render() {
    return (
      <div style={{ fontFamily: 'monospace' }}>
        <JSONTree {...this.props} theme={'google'} />
      </div>
    );
  }
}

export default AppJsonTree;
