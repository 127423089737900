import React from 'react';
import { connect } from 'react-redux';
import { FieldArray, reduxForm } from 'redux-form';
import { RaisedButton } from 'material-ui';
import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Clear from '../../Functional/Clear';
import { validateSalaries } from '../../../utils/validation';
import { objDiff } from '../../../utils/javascript-object-util';
import { hidePB } from '../../../actions/progressbarAction';
import { openSnackbar } from '../../../actions/snackbarActions';
import { clearAppData } from '../../../actions/appdata';
import { CIRCULAR_2015, DETAILED_INCIDENT_CC, INIT_SPECIAL_PERIOD } from '../../../constants';
import SpecialPeriodCreateRequest from '../../../data/model/request/create/SpecialPeriodCreateRequest';
import SpecialPeriodListForm from '../SpecialPeriodListForm';
import RouteStory, { RouteLocationStory } from '../../../utils/RouteStory';
import ReportFlexInfo from '../ReportFlexInfo';
import SpecialPeriodApi from '../../../data/api/SpecialPeriodApi';

class SpecialPeriodCreateUpdateForm extends React.Component {
  onSubmit = (values) => {
    const { dispatch, incidentId, initialValues } = this.props;
    const requests = [];

    const { specialPeriods } = values;
    const initialSpecialPeriods = initialValues.specialPeriods;
    if (specialPeriods) {
      specialPeriods
        .filter((specialPeriod) => !specialPeriod.id)
        .forEach((specialPeriod) => {
          requests.push(
            SpecialPeriodApi.create(new SpecialPeriodCreateRequest(specialPeriod), incidentId),
          );
        });
    }
    if (initialSpecialPeriods) {
      const oldList = initialSpecialPeriods.map((s) => s.id);
      const newList = specialPeriods.filter((s) => s.id).map((s) => s.id);
      difference(oldList, newList).forEach((id) =>
        requests.push(SpecialPeriodApi.delete(incidentId, id)),
      );
    }
    if (initialSpecialPeriods && specialPeriods) {
      specialPeriods
        .filter((specialPeriod) => specialPeriod.id)
        .forEach((specialPeriod) => {
          const oldSpecialPeriod = initialSpecialPeriods.find(
            (initialSpecialPeriod) => initialSpecialPeriod.id === specialPeriod.id,
          );
          const newItem = objDiff(specialPeriod, oldSpecialPeriod);
          if (!isEmpty(newItem))
            requests.push(
              SpecialPeriodApi.update(
                incidentId,
                specialPeriod.id,
                new SpecialPeriodCreateRequest(newItem),
              ),
            );
        });
    }

    Promise.all(requests).then(
      () => {
        hidePB(dispatch);
        dispatch(openSnackbar('Özel Durumlar Güncellendi'));
        dispatch(clearAppData(INIT_SPECIAL_PERIOD));
        this.props.history.goBack();
        this.props.history.push(`/reports/${incidentId}`);
      },
      (err) => {
        hidePB(dispatch);
        dispatch(openSnackbar(`Güncelleme sırasında hata oluştu. ${err.message}`));
      },
    );
  };

  componentDidMount() {
    if (!this.props.detailedIncident) this.props.history.push('/reports');
  }

  render() {
    const { pristine, submitting, detailedIncident } = this.props;
    if (!detailedIncident) return <div />;
    const { incident } = detailedIncident;
    return (
      <div className={'root'}>
        <div className={`${'container'}`} style={{ maxWidth: 1080, margin: 'auto' }}>
          <h1>Özel Durumları Güncelle</h1>
          <RouteLocationStory
            locations={[
              RouteStory.reports(),
              RouteStory.report({
                text: detailedIncident.injuredParty.casualty.basic.fullName,
              }),
              RouteStory.specialPeriodsPartyCreateUpdate(),
            ]}
          />
          <form style={{ position: 'relative' }} onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <FieldArray name="specialPeriods" component={SpecialPeriodListForm} />
            <RaisedButton
              label="Özel Durumları Güncelle"
              primary
              type="submit"
              disabled={pristine || submitting}
              style={{ float: 'right', marginRight: 12 }}
            />
            <Clear />
          </form>
          <ReportFlexInfo />

          {incident.isBeforeCircular2015 && (
            <div className={'smallInfo'} style={{ width: '-webkit-fill-available', marginTop: 64 }}>
              <FontAwesomeIcon fixedWidth icon="info-circle" />
              <span style={{ marginTop: 0 }}>
                &nbsp;Poliçe tanzim tarihi {CIRCULAR_2015} tarihli genelgeden sonra olduğu için.
                Geçici iş görmezlik bu rapor için seçeneklerden çıkarılmıştır.
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  errors.salaries = validateSalaries(values.salaries);
  return errors;
};
SpecialPeriodCreateUpdateForm = reduxForm({
  form: 'detailedReport',
  validate,
  enableReinitialize: true,
})(SpecialPeriodCreateUpdateForm);

export default connect((state) => ({
  initialValues: state.appData[INIT_SPECIAL_PERIOD],
  detailedIncident: state.appData[DETAILED_INCIDENT_CC],
}))(SpecialPeriodCreateUpdateForm);
