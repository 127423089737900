export default class IssuerResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.email = config.email;
    this.name = config.name;
    this.surname = config.surname;
  }

  get fullName() {
    return `${this.name.capitalize()} ${this.surname.capitalize()}`;
  }
}
