/* eslint-disable react/prop-types,no-prototype-builtins */
import React, { Component } from 'react';
import isArray from 'lodash/isArray';
import ComboBox from './ComboBox';

const states = (obj) => {
  if (isArray(obj)) {
    return JSON.parse(JSON.stringify(obj));
  }

  const items = [];
  for (const k in obj) {
    if (obj.hasOwnProperty(k)) {
      items.push({ value: k, text: obj[k] });
    }
  }
  items.unshift({ value: null, text: 'Seçiniz' });
  return items;
};

class StaticSelectField extends Component {
  render() {
    return <ComboBox {...this.props} floatingLabelFixed data={states(this.props.data)} />;
  }
}

export default StaticSelectField;
