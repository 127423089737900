import React from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs } from 'material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionCreate from '../../components/Report/ActionCreate';
import Clear from '../../components/Functional/Clear';
import {
  CALCULATION,
  CALCULATION_FILES,
  DETAILED_INCIDENT,
  LAST_VISITED_TAB_REPORT_DETAIL,
  SALARY_LIST,
} from '../../constants';
import { clearAppData, setAppData } from '../../actions/appdata';
import { hidePB, showPB } from '../../actions/progressbarAction';
import { openSnackbar } from '../../actions/snackbarActions';
import ActionList from '../../components/Report/ActionList';
import ColorPalette from '../../ColorPalette';
import PersonViewList from '../../components/Report/InjuredParty/PersonViewList';
import PaymentViewList from '../../components/Report/Payments/PaymentViewList';
import Authorization from '../../components/Authorization/Authorization';
import { WriteAction } from '../../data/model/Privilege';
import ReportInfosPage from '../../components/Report/ReportDetail/ReportInfosPage';
import CalculationConfigListPage from '../../components/Report/CalculationConfig/CalculationConfigListPage';
import SpecialPeriodViewList from '../../components/Report/SpecialPeriods/SpecialPeriodViewList';
import TabTitleItemCount from '../../components/Functional/TabTitleItemCount';
import RouteStory, { RouteLocationStory } from '../../utils/RouteStory';
import ActionCategory, {
  ActionCategoryColors,
  SELF_PROCESSED,
} from '../../data/model/ActionCategory';
import SalaryViewList from '../../components/Report/Salaries/SalaryViewList';
import ReportFlexInfo from '../../components/Report/ReportFlexInfo';
import IncidentApi from '../../data/api/IncidentApi';
import SalaryApi from '../../data/api/SalaryApi';

const styles = {
  tabButton: {
    color: ColorPalette.primary1Color,
  },
};

class ReportDetail extends React.Component {
  handleTabChange = (value) => {
    this.props.dispatch(setAppData({ name: LAST_VISITED_TAB_REPORT_DETAIL, value }));
  };

  fetchData = () => {
    const { incidentId, dispatch } = this.props;
    showPB(dispatch, 'Rapor Bilgilerine ulaşılıyor');

    Promise.all([IncidentApi.find(dispatch, incidentId), SalaryApi.list(dispatch, incidentId)])
      .then(
        () => {},
        (r) => {
          dispatch(openSnackbar(r.message || 'Bir Hata Oluştu Lütfen Sayfayı yenileyin'));
          this.props.history.push('/not-found');
        },
      )
      .then(
        () => hidePB(dispatch),
        () => hidePB(dispatch),
      );
  };

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearAppData(DETAILED_INCIDENT));
    dispatch(clearAppData(CALCULATION));
    dispatch(clearAppData(CALCULATION_FILES));
    document.title = 'Inova Aktueryal Danışmanlık';
  }

  render() {
    const { detailedIncident, salaries, history } = this.props;
    if (!detailedIncident) return <div />;
    const { injuredParty, incident, latestAction } = detailedIncident;
    const incidentId = incident.id;
    const { casualty } = injuredParty;
    const { fullName } = casualty.basic;
    return (
      <div className={'root'}>
        <div className={'container'}>
          <div className={'max1024'} style={{ position: 'relative', minWidth: 850 }}>
            <h1>
              <div className={`${'tooltip'}`}>
                <FontAwesomeIcon
                  style={{
                    color: latestAction
                      ? latestAction.getCategoryColor
                      : ActionCategoryColors.selfProcessed,
                    fontSize: '28px',
                    textShadow: '2px 2px 2px #bdbdbd8a',
                  }}
                  icon="bookmark"
                  fixedWidth
                />
                <span style={{ fontSize: '14px' }} className={'tooltiptext'}>
                  {latestAction ? latestAction.getCategory : ActionCategory.selfProcessed}
                </span>
              </div>
              {fullName}
            </h1>
            <RouteLocationStory
              locations={[RouteStory.reports(), RouteStory.report({ text: fullName })]}
            />
            <Tabs
              tabItemContainerStyle={{
                backgroundColor: 'white',
                borderBottom: '1px solid #4fc3f7',
                boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
              }}
              inkBarStyle={{
                marginTop: -3,
                height: 3,
              }}
              style={{
                zIndex: 3,
                position: 'relative',
              }}
              value={this.props.tab || 'details'}
              onChange={this.handleTabChange}
            >
              <Tab buttonStyle={styles.tabButton} label="Rapor Bilgisi" value="details">
                <ReportInfosPage history={history} detailedIncident={detailedIncident} />
              </Tab>
              <Tab
                buttonStyle={styles.tabButton}
                label={<TabTitleItemCount text="Hesaplamalar" data={detailedIncident.configs} />}
                value="configs"
              >
                <CalculationConfigListPage history={history} detailedIncident={detailedIncident} />
              </Tab>
              <Tab
                buttonStyle={styles.tabButton}
                label={
                  <TabTitleItemCount
                    text="Pay Sahipleri"
                    data={detailedIncident.injuredParty.familyMembersWithoutSimulated}
                  />
                }
                value="persons"
              >
                <PersonViewList history={history} detailedIncident={detailedIncident} />
              </Tab>
              <Tab
                buttonStyle={styles.tabButton}
                label={
                  <TabTitleItemCount text="Yapılan Ödemeler" data={detailedIncident.payments} />
                }
                value="payments"
              >
                <PaymentViewList history={history} detailedIncident={detailedIncident} />
              </Tab>
              <Tab
                buttonStyle={styles.tabButton}
                label={<TabTitleItemCount text="Maaş Bilgileri" data={salaries || []} />}
                value="salaries"
              >
                <SalaryViewList history={history} />
              </Tab>
              <Tab
                buttonStyle={styles.tabButton}
                label={
                  <TabTitleItemCount text="Özel Durumlar" data={detailedIncident.specialPeriods} />
                }
                value="special-periods"
              >
                <SpecialPeriodViewList history={history} detailedIncident={detailedIncident} />
              </Tab>
            </Tabs>
            <ActionList incidentId={incidentId} />
            <Authorization allowedRoles={[WriteAction]}>
              <div>
                <ActionCreate
                  incidentId={incidentId}
                  actionCategory={latestAction ? latestAction.category : SELF_PROCESSED}
                />
                <Clear />
              </div>
            </Authorization>
            {/* <div className={'printarea'}>
              <h1>{fullName}</h1>
              <ReportInfosPage detailedIncident={detailedIncident} />
              <h3>Hesaplamalar</h3>
              <CalculationConfigListPage detailedIncident={detailedIncident} />
              <h3>Pay Sahipleri</h3>
              <PersonViewList detailedIncident={detailedIncident} />
              <h3>Ödemeler</h3>
              <PaymentViewList detailedIncident={detailedIncident} />
              <h3>Özel Durumlar</h3>
              <SpecialPeriodViewList detailedIncident={detailedIncident} />
            </div> */}
            <ReportFlexInfo />
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  detailedIncident: state.appData[DETAILED_INCIDENT],
  calculation: state.appData[CALCULATION],
  salaries: state.appData[SALARY_LIST],
  calculationFiles: state.appData[CALCULATION_FILES],
  tab: state.appData[LAST_VISITED_TAB_REPORT_DETAIL],
}))(ReportDetail);
