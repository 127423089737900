/* eslint-disable prefer-destructuring */
import { convertToDate } from '../../../../utils/javascript-object-util';

export default class IncidentCreateRequest {
  constructor(config) {
    if (typeof config !== 'object') return;

    this.companyId = config.companyId;
    this.incomeCategory = config.incomeCategory; // TODO apide kaldırılmış
    this.damageFileIdentifier = config.damageFileIdentifier;
    this.courtFileIdentifier = config.courtFileIdentifier;
    this.arbitrationFileIdentifier = config.arbitrationFileIdentifier;
    this.dateOfIncident = convertToDate(config.dateOfIncident);
    this.dateOfReport = convertToDate(config.dateOfReport);
    this.dateOfDeath = convertToDate(config.dateOfDeath);
    this.dateOfRetirement = convertToDate(config.dateOfRetirement);
    this.dateOfIssue = convertToDate(config.dateOfIssue);
    this.isWorkAccident = config.isWorkAccident;
  }
}
