import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import includes from 'lodash/includes';
import { DatePicker, Paper, RaisedButton } from 'material-ui';
import moment from 'moment';
import { INVOICE_DRAFT, TAG_DAMAGE, USER } from '../../constants';
import { hidePB, showPB } from '../../actions/progressbarAction';
import CustomerAutoComplete from '../../components/AutoComplete/CustomerAutoComplete';
import Clear from '../../components/Functional/Clear';
import Authorization from '../../components/Authorization/Authorization';
import { ACTUARY, CUSTOMER, ReadPricing } from '../../data/model/Privilege';
import { openSnackbar } from '../../actions/snackbarActions';
import InvoiceDraftApi from '../../data/api/InvoiceDraftApi';
import TagComboBox from '../../components/ComboBox/TagComboBox';
import InvoiceDraftDetail from './InvoiceDraftDetail';
import { baseFloatingLabelStyle } from '../../utils/redux-metarial-ui-util';
import { clearAppData } from '../../actions/appdata';
import OptionalFieldWrapper from '../../components/Functional/OptionalFieldWrapper';
import DraftReportGenerator from './DraftReportGenerator';
import Layout from '../../components/Layout/Layout';

class InvoiceDraftPage extends React.Component {
  fetchData = (companyId) => {
    const { dispatch } = this.props;
    const { tagId, till } = this.state;
    if (!companyId) {
      dispatch(openSnackbar('Şirket Seçilmedi'));
      return;
    }
    if (!tagId) {
      dispatch(openSnackbar('Departman Seçilmedi'));
      return;
    }
    if (!till) {
      dispatch(openSnackbar('Bitiş Zamanı Seçilmedi'));
      return;
    }
    showPB(dispatch);
    dispatch(clearAppData(INVOICE_DRAFT));
    const tillDate = moment(till).add(3, 'hours').toISOString();

    InvoiceDraftApi.find(dispatch, companyId, tagId, tillDate).then(
      (r) => {
        this.setState({ lastQueriedCompanyId: companyId });
        this.setState({ lastQueriedTagId: tagId });
        this.setState({ lastQueriedTillDate: tillDate });
        hidePB(dispatch);
      },
      (r) => {
        hidePB(dispatch);
      },
    );
  };

  onError = () => {
    this.setState({ isFailure: true });
    this.setState({ inProgress: false });
  };

  onSuccess = () => {
    this.setState({ isFailure: false });
    this.setState({ inProgress: false });
  };

  refresh = () => {
    this.setState({ isFailure: false });
    this.setState({ inProgress: true });
  };

  constructor(props) {
    super(props);
    this.state = {
      companyId: null,
      lastQueriedCompanyId: null,
      lastQueriedTagId: TAG_DAMAGE,
      lastQueriedTillDate: null,
      tagId: TAG_DAMAGE,
      till: moment().toDate(),

      inProgress: true,
      isFailure: false,
    };
  }

  componentDidMount() {
    const { user } = this.props;

    if (includes(user.getRoles, CUSTOMER)) {
      const companyId = user.getCompanyId;
      this.setState({ companyId });
      this.fetchData(companyId);
    }
  }

  componentWillUnmount() {
    this.props.dispatch(clearAppData(INVOICE_DRAFT));
  }

  render() {
    const { draft } = this.props;
    let refreshButton = null;
    if (this.state.isFailure) {
      refreshButton = (
        <Layout>
          <Authorization allowedRoles={[ReadPricing]} force>
            <RaisedButton label="Yeniden Yükle" onClick={this.refresh()} />
          </Authorization>
        </Layout>
      );
    }
    return (
      <Layout>
        <Authorization allowedRoles={[ReadPricing]} force>
          <div className={'root'}>
            <div className={`${'container'} ${'max1024'}`}>
              <h1>Mutabakat</h1>

              <Paper style={{ position: 'relative', paddingBottom: 10 }}>
                <Authorization allowedRoles={[ACTUARY]}>
                  <div className={`${'basicReportFieldContainer'}`}>
                    <CustomerAutoComplete
                      title="Sigorta Şirketi"
                      fullWidth
                      floatingLabelStyle={baseFloatingLabelStyle}
                      onChange={(v) => {
                        this.setState({ companyId: v });
                      }}
                    />
                  </div>
                </Authorization>
                <div className={`${'basicReportFieldContainer'}`}>
                  <TagComboBox
                    fullWidth
                    floatingLabelFixed
                    floatingLabelStyle={baseFloatingLabelStyle}
                    title="Departman"
                    onChange={(v) => this.setState({ tagId: v })}
                    value={this.state.tagId}
                  />
                </div>
                <Authorization allowedRoles={[ACTUARY]}>
                  <OptionalFieldWrapper text={<FontAwesomeIcon icon="clock" />}>
                    <div className={`${'basicReportFieldContainer'}`}>
                      <DatePicker
                        fullWidth
                        style={{ display: 'inline-block' }}
                        floatingLabelStyle={baseFloatingLabelStyle}
                        floatingLabelText="Bitiş Tarihi"
                        onChange={(e, v) => this.setState({ till: v })}
                        value={this.state.till}
                      />
                    </div>
                  </OptionalFieldWrapper>
                </Authorization>
                <div style={{ position: 'absolute', bottom: 6, right: 16 }}>
                  {refreshButton || (
                    <DraftReportGenerator onError={this.onError} onSuccess={this.onSuccess} />
                  )}
                  <RaisedButton
                    style={{ float: 'right', marginLeft: 16, marginTop: 12 }}
                    primary
                    label="Mutabakatı Getir"
                    onClick={() => this.fetchData(this.state.companyId)}
                  />
                </div>
                <Clear />
              </Paper>

              <InvoiceDraftDetail
                draft={draft}
                companyId={this.state.lastQueriedCompanyId}
                tagId={this.state.lastQueriedTagId}
                till={this.state.lastQueriedTillDate}
              />
            </div>
          </div>
        </Authorization>
      </Layout>
    );
  }
}

export default connect((state) => ({
  draft: state.appData[INVOICE_DRAFT],
  user: state.appData[USER],
}))(InvoiceDraftPage);
