import ConfigResponse from './ConfigResponse';
import BasicCalculation from './BasicCalculation';

export default class DetailedConfigResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.config = config.config ? new ConfigResponse(config.config) : undefined;
    this.calculation = config.calculation ? new BasicCalculation(config.calculation) : undefined;
  }
}
