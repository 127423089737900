import React from 'react';
import { connect } from 'react-redux';
import { Paper } from 'material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router';
import ColorPalette from '../../ColorPalette';
import Clear from '../../components/Functional/Clear';
import Gender from '../../data/model/Gender';
import { dateFormatLongNonSecond } from '../../utils/javascript-object-util';
import Authorization from '../../components/Authorization/Authorization';
import { ACTUARY } from '../../data/model/Privilege';

export function InfoTextReportViewHolder({
  style = {},
  labelStyle = {},
  title,
  nonLabel,
  content,
}) {
  return (
    <div
      style={{
        fontSize: '16px',
        display: 'inline-block',
        ...style,
      }}
    >
      {!nonLabel && (
        <span
          style={{
            fontWeight: 500,
            fontSize: '12px',
            color: ColorPalette.primary1Color,
            ...labelStyle,
          }}
        >
          {title ? `${title}:` : ''}
        </span>
      )}
      <br />
      <span style={{}}>{content}</span>
      <Clear />
    </div>
  );
}

function ReportViewHolder({ data }) {
  const history = useHistory();

  const incidentBasic = [];
  if (data.incident.arbitrationFileIdentifier)
    incidentBasic.unshift({
      title: 'Tahkim Dosyası',
      content: data.incident.arbitrationFileIdentifier,
      width: 170,
    });
  else if (data.incident.courtFileIdentifier)
    incidentBasic.unshift({
      title: 'Hukuk Dosyası',
      content: data.incident.courtFileIdentifier,
      width: 170,
    });
  else if (data.incident.damageFileIdentifier)
    incidentBasic.unshift({
      title: 'Hasar Dosyası',
      content: data.incident.damageFileIdentifier,
      width: 170,
    });

  const casualty = [];
  casualty.push({
    title: 'İsim',
    content: data.casualty.basic.fullName,
    width: 280,
  });
  if (data.casualty.basic.dateOfBirth)
    casualty.push({
      title: 'Doğum Tarihi',
      content: data.casualty.basic.dateOfBirth,
      width: 120,
    });
  casualty.push({
    title: 'Cinsiyeti',
    content: Gender[data.casualty.basic.gender],
    width: 80,
  });
  const identificationNumber = data.casualty.basic.personalIdentificationNumber;
  casualty.push({
    title: 'Kimlik No',
    content: identificationNumber || '-',
    width: 120,
  });
  const { dateOfDeath } = data.incident;
  const workAccident = data.incident.isWorkAccident;
  return (
    <Paper
      className={'reportViewHolder'}
      onClick={() => history.push(`/reports/${data.incident.id}`)}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: 12,
          borderLeft: `6px solid ${data.actionResponse.getCategoryColor}`,
        }}
        className={'tooltip'}
      >
        <span style={{ fontSize: '14px', marginLeft: -89 }} className={'tooltiptext'}>
          {data.actionResponse.getCategory}
        </span>
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: '3px',
          lineHeight: '14px',
          width: 32,
          height: 32,
        }}
        className={'tooltip'}
      >
        <span
          style={{
            fontWeight: '700',
            fontSize: '12px',
            color: '#b5b5b5',
            float: 'right',
            textAlign: 'right',
          }}
        >
          {dateOfDeath ? 'V' : 'SS'}
          {workAccident && <br />}
          {workAccident && (
            <span>
              <FontAwesomeIcon icon="wrench" />
            </span>
          )}
        </span>
        <span style={{ fontSize: '14px', marginBottom: 6 }} className={'tooltiptext'}>
          {dateOfDeath ? 'Vefat' : 'Sürekli Sakatlık'}
          {workAccident && <br />}
          {workAccident && 'İş Kazası'}
        </span>
      </div>
      <div
        style={{
          width: '100%',
          paddingRight: 16,
          paddingTop: 4,
          paddingBottom: 28,
          paddingLeft: 16,
          display: 'flex',
          justifyContent: 'space-between',
          boxSizing: 'border-box',
        }}
      >
        {incidentBasic.map((d) => (
          <InfoTextReportViewHolder
            style={{ width: d.width, float: 'left' }}
            key={incidentBasic.indexOf(d)}
            title={d.title}
            content={d.content}
          />
        ))}
        {casualty.map((d) => (
          <InfoTextReportViewHolder
            style={{ width: d.width, float: 'left' }}
            key={casualty.indexOf(d)}
            title={d.title}
            content={d.content}
          />
        ))}
        <Clear />
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '4px',
          left: '20px',
          fontSize: '12px',
          color: '#808080',
          fontWeight: '400',
        }}
      >
        <span>{dateFormatLongNonSecond(data.incident.timestamp)}</span> &#x2022;{' '}
        <span>{data.issuer.fullName}</span>
        <Authorization allowedRoles={[ACTUARY]}>
          <span> &#x2022; {data.company.name}</span>
        </Authorization>
      </div>
      <Clear />
    </Paper>
  );
}

export default connect()(ReportViewHolder);
