/* eslint-disable prefer-destructuring */
import React from 'react';
import { connect } from 'react-redux';
import Clear from '../Functional/Clear';
import { ACTION_LIST } from '../../constants';
import { dateFormatLongNonSecond, intToRGB } from '../../utils/javascript-object-util';
import { clearAppData } from '../../actions/appdata';
import EmptyView from '../Functional/EmptyView';
import ActionCategory, {
  ActionCategoryIcons,
  MARK_AS_MISSING_INFO,
  MARK_AS_OVERVIEWED,
  MARK_AS_RESOLVED,
  REQUEST_REVIEW,
  SELF_PROCESSED,
} from '../../data/model/ActionCategory';
import ActionApi from '../../data/api/ActionApi';

class ActionList extends React.Component {
  fetchData = () => {
    const incidentId = this.props.incidentId;
    const dispatch = this.props.dispatch;

    if (!incidentId) return;

    ActionApi.list(dispatch, incidentId, 0, 20).then(this.setState({ inProgress: false }));
  };

  constructor(props) {
    super(props);
    this.state = {
      inProgress: true,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearAppData(ACTION_LIST));
  }

  categoryMessage = (category) => {
    switch (category) {
      case SELF_PROCESSED:
        return (
          <div>
            <span
              style={{
                fontWeight: '500',
              }}
            >
              &nbsp;{ActionCategory[category]}
            </span>
            &nbsp;oluşturdu.
          </div>
        );
      case REQUEST_REVIEW:
      case MARK_AS_MISSING_INFO:
      case MARK_AS_RESOLVED:
      case MARK_AS_OVERVIEWED:
        return (
          <div>
            &nbsp;dosyayı&nbsp;
            <span
              style={{
                fontWeight: '500',
              }}
            >
              {ActionCategory[category]}
            </span>
            &nbsp;olarak işaretledi.
          </div>
        );
      default:
        return <span>&nbsp;yorum ekledi.</span>;
    }
  };

  render() {
    const actions = this.props.actions;
    return (
      <div style={{ position: 'relative', marginTop: 4 }}>
        {actions && actions.length
          ? actions.map((a) => {
              const index = actions.indexOf(a);
              const hasMessage = a.message;
              return (
                <div
                  key={index}
                  className={'action'}
                  style={{
                    position: 'relative',
                    padding: '4px 0px',
                    borderRadius: 4,
                  }}
                >
                  <div
                    style={{
                      float: 'left',
                      backgroundColor: intToRGB(a.issuer.email.hashCode(), 0.75),
                      width: hasMessage ? 42 : 30,
                      height: hasMessage ? 42 : 30,
                      marginLeft: hasMessage ? 0 : 12,
                      boxShadow: '1px 1px 3px -1px #0000007a',
                      borderRadius: hasMessage ? 4 : 2,
                      position: 'relative',
                      zIndex: 1,
                    }}
                  >
                    <div
                      className={'center'}
                      style={{
                        color: 'white',
                        fontWeight: 400,
                        fontSize: hasMessage ? '20px' : '16px',
                      }}
                    >
                      {a.issuer.name.charAt(0).toUpperCase()}
                    </div>
                  </div>
                  <div
                    style={{
                      float: 'left',
                      width: 'calc(100% - 50px)',
                      display: 'relative',
                      marginLeft: 8,
                      boxShadow: hasMessage ? 'rgba(0, 0, 0, 0.12) 0px 1px 6px' : 'none',
                      background: hasMessage ? 'white' : 'none',
                      zIndex: 1,
                      position: 'relative',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        padding: '2px',
                        paddingRight: 8,
                        boxSizing: 'border-box',
                        borderBottom: hasMessage ? '1px solid rgba(227, 231, 229, 1)' : 'none',
                        backgroundColor: hasMessage ? '#fcfcfc' : 'none',
                      }}
                    >
                      <div
                        style={{
                          display: 'inline-block',
                          padding: 2,
                          background: '#fcfcfc',
                          color: 'gray',
                          borderRadius: '2px',
                          boxShadow: hasMessage ? 'none' : '1px 1px 3px -1px #0000007a',
                          lineHeight: '22px',
                        }}
                      >
                        {a.category ? ActionCategoryIcons[a.category] : ActionCategoryIcons.comment}
                      </div>

                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: '500',
                          marginLeft: 8,
                        }}
                      >
                        {a.issuer.fullName}
                      </span>
                      <div
                        style={{
                          fontSize: 14,
                          display: 'inline-block',
                        }}
                      >
                        {this.categoryMessage(a.category)}
                      </div>
                      <span
                        style={{
                          float: 'right',
                          fontSize: '14px',
                          lineHeight: '24px',
                          color: 'gray',
                        }}
                      >
                        {dateFormatLongNonSecond(a.createdAt)}
                      </span>
                    </div>
                    {hasMessage && (
                      <div
                        style={{ padding: 16 }}
                        dangerouslySetInnerHTML={{
                          __html: a.message || '<p>&nbsp;</p>',
                        }}
                      />
                    )}
                  </div>
                  <Clear />
                  <div
                    style={{
                      position: 'absolute',
                      left: 40,
                      top: hasMessage ? 28 : 23,
                      width: 15,
                      backgroundColor: '#bdbdbd',
                      height: 1,
                      zIndex: 0,
                    }}
                  />
                </div>
              );
            })
          : !this.state.inProgress && (
              <EmptyView
                icon="bookmark"
                label="Durum değişikliği yok"
                style={{ marginBottom: 16, marginTop: 16 }}
              />
            )}
        <Clear />
        {actions && actions.length > 0 && (
          <div
            style={{
              position: 'absolute',
              left: 63,
              top: -60,
              width: 1,
              backgroundColor: '#bdbdbd',
              height: 'calc(100% + 90px)',
              zIndex: 0,
            }}
          />
        )}
      </div>
    );
  }
}

export default connect((state) => ({
  actions: state.appData[ACTION_LIST],
}))(ActionList);
