export function AuthenticationError(message) {
  this.message = message;
  // Use V8's native method if available, otherwise fallback
  if ('captureStackTrace' in Error) Error.captureStackTrace(this, AuthenticationError);
  else this.stack = new Error().stack;
}

AuthenticationError.prototype = Object.create(Error.prototype);
AuthenticationError.prototype.name = 'AuthenticationError';
AuthenticationError.prototype.constructor = AuthenticationError;
