import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function FieldArrayEmptyView({ fields }) {
  if (fields.length !== 0) return <span />;
  return (
    <p
      style={{
        textAlign: 'center',
        fontSize: '18px',
        color: 'rgba(40, 35, 30, 0.54)',
      }}
    >
      <FontAwesomeIcon fixedWidth icon="list" /> Kayıt Yok
    </p>
  );
}
