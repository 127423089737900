import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dialog, FlatButton, Paper, RaisedButton } from 'material-ui';
import { useHistory } from 'react-router';
import InvoiceDraftApi from '../../data/api/InvoiceDraftApi';
import { hidePB, showPB } from '../../actions/progressbarAction';
import { openSnackbar } from '../../actions/snackbarActions';
import InvoiceItemViewHolder from './InvoiceItemViewHolder';
import IncidentSummary from '../../data/model/response/IncidentSummary';
import EmptyView from '../../components/Functional/EmptyView';
import Authorization from '../../components/Authorization/Authorization';
import { ACTUARY } from '../../data/model/Privilege';
import IssuerResponse from '../../data/model/response/IssuerResponse';
import { getUUIDFromUrl } from '../../utils/javascript-object-util';

function InvoiceDraftDetail({ dispatch, companyId, tagId, draft, till }) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const removeItemToDraft = (id) => {
    showPB(dispatch);
    InvoiceDraftApi.deleteItem(companyId, id).then(
      () => {
        dispatch(openSnackbar('Dosya Mutabakattan çıkarıldı'));
        InvoiceDraftApi.find(dispatch, companyId, tagId).then(
          () => hidePB(dispatch),
          () => hidePB(dispatch),
        );
        hidePB(dispatch);
      },
      (r) => {
        dispatch(openSnackbar('Bir hata oluştu'));
        hidePB(dispatch);
      },
    );
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    showPB(dispatch, 'Faturalar Hazırlanıyor');
    InvoiceDraftApi.promote(companyId, till, tagId).then(
      (r) => {
        dispatch(openSnackbar('Faturalar oluşturuldu'));
        history.push(`/invoices/${getUUIDFromUrl(r.location)}`);
        hidePB(dispatch);
      },
      (r) => {
        dispatch(openSnackbar('Faturalar oluşturulamadı. Hata!'));
        hidePB(dispatch);
      },
    );

    handleClose();
  };

  if (!draft)
    return (
      <EmptyView style={{ marginTop: 16 }} label="Mutabakat oluşturulmamış" icon="handshake" />
    );
  const actions = [
    <FlatButton label="İptal" primary keyboardFocused onClick={handleClose} />,
    <FlatButton label="Tamam" primary onClick={handleSubmit} />,
  ];
  return (
    <div>
      <Paper style={{ marginTop: 16 }}>
        <div
          style={{
            padding: '16px 30px',
            borderBottom: '1px solid gainsboro',
            position: 'relative',
            fontSize: '20px',
            fontWeight: '400',
          }}
        >
          {draft.tag.name}
          <div
            style={{
              position: 'absolute',
              top: '50%',
              right: '30px',
              transform: 'translate(0,-50%)',
            }}
          >
            {draft.dueAmount}₺
          </div>
        </div>

        <div
          className={'invoiceItemsContainer'}
          style={{
            position: 'relative',
            padding: '0px 24px 16px',
          }}
        >
          <InvoiceItemViewHolder
            item={{
              price: 'Tutar',
              incident: {
                summary: new IncidentSummary({
                  fileIdentifier: 'Dosya Numarası',
                  casualtyName: 'Mağdur/Müteveffa',
                  casualtySurname: '',
                }),
                issuer: new IssuerResponse({
                  name: 'Oluşturan',
                  surname: '',
                }),
              },
            }}
            title
          />
          {draft.items.map((t) => (
            <InvoiceItemViewHolder item={t} onRemove={removeItemToDraft} />
          ))}
        </div>
      </Paper>
      <Authorization allowedRoles={[ACTUARY]}>
        <div
          style={{
            display: 'inline-block',
            float: 'right',
            margin: 16,
          }}
        >
          <RaisedButton label="Faturalandır" primary onClick={handleOpen} />
        </div>
      </Authorization>

      <Dialog
        title="Faturalandırmayı Tamamla"
        actions={actions}
        modal={false}
        open={open}
        onRequestClose={handleClose}
      >
        <strong>
          Bu işlem mutabık olunduğunu ifade eder. Değerler faturalandırılır ve ilgili dosyaların
          hesapları kapatılır.
        </strong>
      </Dialog>
    </div>
  );
}

export default connect()(InvoiceDraftDetail);
