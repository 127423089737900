import PersonCreateRequest from './PersonCreateRequest';
import { convertToDate } from '../../../../utils/javascript-object-util';
import DivorceCreateRequest from './DivorceCreateRequest';

export default class SpouseCreateRequest {
  constructor(config, { casualtyDateOfBirth }) {
    if (typeof config !== 'object') return;
    this.basic = new PersonCreateRequest(config.basic, {
      dateOfBirth: casualtyDateOfBirth,
    });
    this.dateOfMarriage = convertToDate(config.dateOfMarriage);
    this.dateOfRetirement = convertToDate(config.dateOfRetirement);
    this.employmentStatus = config.employmentStatus;
    this.status = config.status;
    this.divorce = new DivorceCreateRequest(config.divorce);
  }
}
