import { dateFormat } from '../../../utils/javascript-object-util';
import PaymentCategory from '../PaymentCategory';

export default class PaymentResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.id = config.id;
    this.amount = config.amount;
    this.date = dateFormat(config.date);
    this.recipients = config.recipients;
    this.tempRecipients = config.tempRecipients;
    this.description = config.description;
    this.category = config.category;
    this.people = [];
    if (this.recipients && this.recipients.length > 0) this.people.push(...this.recipients);
    if (this.tempRecipients && this.tempRecipients.length > 0)
      this.people.push(...this.tempRecipients);
  }

  get getCategory() {
    return PaymentCategory[this.category];
  }
}
