import { convertNumToPercentage, toMoney } from '../../../utils/javascript-object-util';

export default class Correlation {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.correlationCoefficient = convertNumToPercentage(config.correlationCoefficient);
    this.amount = config.amount;
  }

  get getAmount() {
    return toMoney(this.amount);
  }
}
