/* eslint-disable react/prop-types,no-prototype-builtins */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ComboBox from './ComboBox';
import { DOC_TYPE_REPORT, DOCUMENT_TYPES } from '../../constants';
import DocumentTypeApi from '../../data/api/DocumentTypeApi';

const states = (obj) => {
  if (!obj) return [];

  return obj.filter((k) => k.id !== DOC_TYPE_REPORT).map((k) => ({ value: k.id, text: k.name }));
};

class DocumentTypeComboBox extends Component {
  componentDidMount() {
    if (!this.props.documentTypes) DocumentTypeApi.list(this.props.dispatch);
  }

  render() {
    return <ComboBox {...this.props} data={states(this.props.documentTypes)} />;
  }
}

export default connect((state) => ({
  documentTypes: state.appData[DOCUMENT_TYPES],
}))(DocumentTypeComboBox);
