import IssuerResponse from './IssuerResponse';
import ActionCategory, { ActionCategoryColors } from '../ActionCategory';

export default class ActionResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.id = config.id;
    this.createdAt = config.createdAt;
    this.issuer = new IssuerResponse(config.issuer);
    this.editAt = config.editAt;
    this.category = config.category;
    this.message = config.message;
  }

  get getCategory() {
    return ActionCategory[this.category];
  }

  get getCategoryColor() {
    return ActionCategoryColors[this.category];
  }
}
