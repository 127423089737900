/* eslint-disable no-extend-native */
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import * as DataObjectParser from 'dataobject-parser';

if (typeof Object.assign !== 'function') {
  Object.assign = function (target) {
    if (target == null) {
      throw new TypeError('Cannot convert undefined or null to object');
    }

    target = Object(target);
    for (let index = 1; index < arguments.length; index++) {
      const source = arguments[index];
      if (source != null) {
        for (const key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }
    }
    return target;
  };
}

require('moment-precise-range-plugin');
require('moment/locale/tr.js');

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function deepen(o) {
  return DataObjectParser.transpose(o).data();
}

export const removeEmpty = (obj) =>
  Object.keys(obj)
    .filter((f) => obj[f] != null)
    .reduce(
      (r, i) =>
        typeof obj[i] === 'object' ? { ...r, [i]: removeEmpty(obj[i]) } : { ...r, [i]: obj[i] },
      {},
    );
export function clean(obj) {
  if (isEmpty(obj)) return undefined;
  const result = obj;
  const propNames = Object.getOwnPropertyNames(result);
  for (let i = 0; i < propNames.length; i++) {
    if (isEmpty(result[propNames[i]])) delete result[propNames[i]];
  }
  return result;
}
Object.defineProperty(Object.prototype, 'renameProperty', {
  writable: false, // Cannot alter this property
  enumerable: false, // Will not show up in a for-in loop.
  configurable: false, // Cannot be deleted via the delete operator
  value(oldName, newName) {
    // Do nothing if the names are the same
    if (oldName === newName) {
      return this;
    }
    // Check for the old property name to
    // avoid a ReferenceError in strict mode.
    if (this.hasOwnProperty(oldName)) {
      this[newName] = this[oldName];
      delete this[oldName];
    }
    return this;
  },
});

const { hasOwnProperty } = Object.prototype;

Object.defineProperty(Object.prototype, 'clog', {
  writable: false,
  enumerable: false,
  configurable: false,
  value(title) {
    console.log((title || '') + JSON.stringify(this, null, 2));
    return this;
  },
});

Object.defineProperty(Object.prototype, 'bindClass', {
  value(T, props) {
    if (!this) return null;
    return new T(this, props);
  },
});

Object.defineProperty(Array.prototype, 'bindList', {
  value(T, props) {
    if (!this) return null;
    return this.map((l) => l.bindClass(T, props));
  },
});

String.prototype.hashCode = function () {
  let hash = 0;
  let i;
  let chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
String.prototype.capitalizeAllWord = function () {
  return this.toLocaleLowerCase('tr')
    .split(' ')
    .map((s) => s.capitalize())
    .join(' ');
};

String.prototype.replaceAll = function (search, replacement) {
  const target = this;
  return target.replace(new RegExp(search, 'g'), replacement);
};

export function roundPrecision(num, precision = 2) {
  return num ? parseFloat(num.toFixed(precision)) : 0;
}
export function toMoneyFormat(x) {
  if (x !== 0 && !x) return;
  const parts = x.toFixed(2).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join(',');
}

export function toMoney(x, currency = '₺') {
  return toMoneyFormat(x) + currency;
}

export function intToRGB(i, brightness) {
  const rgb = Math.abs(i) % (256 * 256 * 256);
  const red = parseInt(((rgb / 65536) % 256) * brightness, 10) * 256 * 256;
  const green = parseInt(((rgb / 256) % 256) * brightness, 10) * 256;
  const blue = parseInt((rgb % 256) * brightness, 10);
  const color = (red + green + blue).toString(16);
  return '#000000'.substring(0, 7 - color.length) + color;
}

export function toUTC(d) {
  const date = moment({
    y: d.getFullYear(),
    M: d.getMonth(),
    d: d.getDate(),
  });
  const offset = moment(date).parseZone().utcOffset();
  return date.utc().add(offset, 'm');
}

export function toQuery(obj) {
  if (isEmpty(obj)) return '';

  let query = '';
  for (const k in obj) {
    if (hasOwnProperty.call(obj, k)) {
      query += obj[k] ? `${k}=${obj[k]}&` : '';
    }
  }
  return `?${query}`;
}

export function dateFormat(date) {
  if (!date) return undefined;
  return moment(date).locale('tr').format('DD.MM.YYYY');
}

export function dateFormatWeekday(date) {
  if (!date) return undefined;
  return moment(date).locale('tr').format('DD.MM.YYYY dddd');
}

export function dateFormatLong(date) {
  if (!date) return undefined;
  return moment(date).locale('tr').format('DD.MM.YYYY HH:mm:ss');
}

export function dateFormatLongNonSecond(date) {
  if (!date) return undefined;
  return moment(date).locale('tr').format('DD.MM.YYYY HH:mm');
}

export function compareString(p, q) {
  const P = p.text.toUpperCase();
  const Q = q.text.toUpperCase();
  if (P < Q) return -1;
  if (P > Q) return 1;
  return 0;
}

export const convertPercentageToNum = (x) => {
  if (x === undefined || x === null) return null;
  return parseFloat(x / 100).toFixed(4);
};
export const convertNumToPercentage = (x) => {
  if (x === undefined || x === null) return null;
  return (x * 100).toFixed(2).replace(/\.0+$/, '');
};
export const getNameFragments = (x) => {
  if (!x) return [undefined, undefined];
  const trimmed = x.trim();
  const a = trimmed.lastIndexOf(' ');
  const b = trimmed.substring(0, a).trim();
  const c = trimmed.substring(a + 1).trim();
  return a > -1 ? [b, c] : [c, undefined];
};

export const convertToDate = (date) => {
  if (!date) return undefined;
  return toUTC(moment(date, 'DD.MM.YYYY').toDate());
};
export const toMoment = (date) => {
  if (!date) return undefined;
  return moment(date, 'DD.MM.YYYY');
};
export const setMidnight = (date) => {
  if (!date) return undefined;
  date.set('hour', 0);
  date.set('minute', 0);
  date.set('second', 0);
  date.set('millisecond', 0);
  return date;
};
export function getUUIDFromUrl(url) {
  return url.substr(url.length - 36, 36);
}

export function objDiff(obj1, obj2, exclude = []) {
  const r = {};

  for (const prop in obj1) {
    if (obj1.hasOwnProperty(prop) && prop !== '__proto__') {
      if (exclude.indexOf(prop) === -1) {
        // check if obj2 has prop
        if (!obj2.hasOwnProperty(prop)) r[prop] = obj1[prop];
        else if (obj1[prop] === Object(obj1[prop])) {
          // check if prop is object and
          // NOT a JavaScript engine object (i.e. __proto__), if so, recursive diff
          const difference = objDiff(obj1[prop], obj2[prop]);
          if (Object.keys(difference).length > 0) r[prop] = difference;
        } else if (obj1[prop] !== obj2[prop]) {
          // check if obj1 and obj2 are equal
          if (obj1[prop] === undefined) r[prop] = 'undefined';
          if (obj1[prop] === null) r[prop] = null;
          else if (typeof obj1[prop] === 'function') r[prop] = 'function';
          else if (typeof obj1[prop] === 'object') r[prop] = 'object';
          else r[prop] = obj1[prop];
        }
      }
    }
  }

  return r;
}

String.prototype.toEnglish = function () {
  let str = this;
  const charMap = {
    Ç: 'C',
    Ö: 'O',
    Ş: 'S',
    İ: 'I',
    I: 'i',
    Ü: 'U',
    Ğ: 'G',
    ç: 'c',
    ö: 'o',
    ş: 's',
    ı: 'i',
    ü: 'u',
    ğ: 'g',
  };
  const str_array = str.split('');
  for (let i = 0, len = str_array.length; i < len; i++) {
    str_array[i] = charMap[str_array[i]] || str_array[i];
  }
  str = str_array.join('');
  return str.replace(/[çöşüğı]/gi, '');
};

export function dateDiff(d1, d2, unit, decimal = false) {
  return moment(convertToDate(d2)).diff(moment(convertToDate(d1)), unit, decimal);
}

export function datePreciseDiff(d1, d2) {
  const values = moment.preciseDiff(moment(convertToDate(d2)), moment(convertToDate(d1)), true);

  const result = [];
  if (values.years) result.push(`${values.years} yıl`);
  if (values.months) result.push(`${values.months} ay`);
  if (values.days) result.push(`${values.days} gün`);

  return result.join(' ');
}

String.prototype.replaceAt = function (index, char) {
  const a = this.split('');
  a[index] = char;
  return a.join('');
};

export function isValidJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const copyClipboard = (text) => {
  const e = document.createElement('textarea');
  e.value = text;
  document.body.appendChild(e);
  e.select();
  document.execCommand('copy');
  document.body.removeChild(e);
};

export function paramsToObject(entries) {
  const result = {};
  for (const entry of entries) {
    const [key, value] = entry;
    result[key] = value;
  }
  return result;
}
