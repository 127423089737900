import { dateFormat } from '../../../utils/javascript-object-util';

export default class SpecialPeriodResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.id = config.id;
    this.from = dateFormat(config.from);
    this.till = dateFormat(config.till);
    this.category = config.category;
  }
}
