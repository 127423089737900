import { setAppData } from '../../actions/appdata';
import { INVITATIONS_LIST } from '../../constants';
import Connection from './Connection';
import InvitationResponse from '../model/response/InvitationResponse';

export default class UserInvitationApi extends Connection {
  static list(dispatch) {
    return this._getSecurely(`/me/invitations`)
      .then(...this.justData)
      .then((r) =>
        dispatch(
          setAppData({
            name: INVITATIONS_LIST,
            value: r ? r.bindList(InvitationResponse) : [],
          }),
        ),
      )
      .catch(this.catchError);
  }

  static accept(customerId) {
    return this._put(`/me/invitations/customers/${customerId}`, {});
  }

  static reject(customerId) {
    return this._delete(`/me/invitations/customers/${customerId}`, {});
  }
}
