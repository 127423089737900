import Correlation from './Correlation';
import CalculationDetail from './CalculationDetail';

export default class NurseCalculation extends CalculationDetail {
  constructor(config) {
    super(config);
    if (typeof config !== 'object') return;
    this.correlation = new Correlation(config.correlation);
  }
}
