import IncidentResponse from './IncidentResponse';
import CasualtyResponse from './CasualtyResponse';
import SimpleUserResponse from './SimpleUserResponse';
import ConfigResponse from './ConfigResponse';
import SimpleCompanyResponse from './SimpleCompanyResponse';
import ActionResponse from './ActionResponse';

export default class ReportResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.actionResponse = config.actionResponse
      ? new ActionResponse(config.actionResponse)
      : new ActionResponse({ category: 'selfProcessed' });

    this.incident = new IncidentResponse(config.incident);
    this.casualty = new CasualtyResponse(config.casualty);
    this.issuer = new SimpleUserResponse(config.issuer);
    this.company = new SimpleCompanyResponse(config.company);
    this.configs = config.configs ? config.configs.bindList(ConfigResponse) : [];
  }
}
