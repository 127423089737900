import find from 'lodash/find';
import { convertToDate, dateDiff } from '../../../utils/javascript-object-util';
import { injuredPartyDegree } from '../../../data/model/response/InjuredPartyResponse';
import { EPOCH_DATE } from '../../../constants';

function th(text) {
  return {
    text,
    style: { bold: true, fontSize: 8, fillColor: '#d7d7d7', color: 'black' },
    alignment: 'center',
  };
}

function td(text, color = 'black') {
  return {
    text,
    style: { bold: true, fontSize: 9, color },
    alignment: 'center',
  };
}

function getPersonData(entity, incident, injuredParty, calculation) {
  const endOfSupports = calculation.details.buildEndOfSupportPeriods(injuredParty);

  const { basic } = entity;
  const { dateOfDeath } = incident;
  const { dateOfReport } = incident;
  const { dateOfBirth } = basic;
  const birthYear = convertToDate(dateOfBirth).years();
  const ageAtDeath = dateDiff(dateOfBirth, dateOfDeath, 'years');
  const ageAtReport = dateDiff(dateOfBirth, dateOfReport, 'years');
  const period = find(endOfSupports, (p) => p.personId === basic.id);
  const endOfSupport = period && period.period ? period.period.end : EPOCH_DATE;
  const supportDuration = dateDiff(dateOfReport, endOfSupport, 'years');
  const endOfSupportYear = convertToDate(endOfSupport).years();
  const personDegree = injuredPartyDegree(entity, injuredParty, incident.incidentType);
  return {
    dateOfBirth,
    birthYear,
    ageAtDeath,
    ageAtReport,
    supportDuration,
    endOfSupportYear,
    personDegree,
  };
}

function personMapper(entities, entity, incident, injuredParty, calculation) {
  const {
    dateOfBirth,
    ageAtDeath,
    ageAtReport,
    supportDuration,
    endOfSupportYear,
    personDegree,
  } = getPersonData(entity, incident, injuredParty, calculation);
  const { basic } = entity;
  return [
    td(personDegree),
    td(basic.fullName),
    td(basic.getGender),
    td(dateOfBirth),
    td(ageAtDeath),
    td(ageAtReport),
    td(supportDuration),
    td(endOfSupportYear),
  ];
}

export default function InjuredPartyInfo() {
  const { config } = this;
  const { incident } = this;
  const { calculation } = this;
  const { injuredParty } = this;
  const { casualty } = injuredParty;
  const casualtyBasic = casualty.basic;
  const {
    dateOfBirth,
    ageAtDeath,
    ageAtReport,
    supportDuration,
    endOfSupportYear,
    personDegree,
  } = getPersonData(casualty, incident, injuredParty, calculation);
  const body = [
    [
      th('\n\nDURUM'),
      th('\n\nAdı Soyadı'),
      th('\n\nCinsiyeti'),
      th('\n\nDoğum Tarihi'),
      th('\nÖlüm\nTarihindeki\nYaş'),
      th('\nRapor\nTarihindeki\nYaş'),
      th('Rapor\nTarihi\nİtibariyle\nDestek\nSüresi'),
      th('\nDestek\nSonu\nYılı'),
    ],
    [
      td(personDegree, 'red'),
      td(casualtyBasic.fullName, 'red'),
      td(casualtyBasic.getGender, 'red'),
      td(dateOfBirth, 'red'),
      td(ageAtDeath, 'red'),
      td(ageAtReport, 'red'),
      td(supportDuration, 'red'),
      td(endOfSupportYear, 'red'),
    ],
  ];
  const spouses = injuredParty.spouses.filter((p) => !p.basic.simulatedAt);
  const parents = injuredParty.parents.filter((p) => !p.basic.simulatedAt);
  const children = injuredParty.children.filter((p) => !p.basic.simulatedAt);
  spouses.forEach((s) => body.push(personMapper(spouses, s, incident, injuredParty, calculation)));
  parents.forEach((s) => body.push(personMapper(parents, s, incident, injuredParty, calculation)));
  children.forEach((s) =>
    body.push(personMapper(children, s, incident, injuredParty, calculation)),
  );
  return [
    {
      headlineLevel: 1,
      stack: [
        {
          text: 'Hak Sahiplerine Dair Bilgiler',
          style: 'h2',
          margin: [0, 20, 0, 5],
        },
        {
          margin: [0, 10, 0, 20],
          style: { fontSize: 8 },
          table: {
            heights: 10,
            widths: [43, '*', 32, 48, 40, 40, 43, 30],
            body,
          },
        },
      ],
    },
  ];
}
