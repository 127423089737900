import React, { Children } from 'react';
import { connect } from 'react-redux';
import { USER } from '../../constants/index';
import { getViewIfUnauthorized } from '../../actions/authActions';

class Authorization extends React.Component {
  render() {
    const { user, allowedRoles, children, and, force } = this.props;
    if (!user) {
      return null;
    }
    return getViewIfUnauthorized(user, Children.only(children), allowedRoles, and, force);
  }
}

export default connect((state) => ({ user: state.appData[USER] }))(Authorization);
