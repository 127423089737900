import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { RaisedButton, Subheader } from 'material-ui';
import { renderTag, renderTextField } from '../../utils/redux-metarial-ui-util';
import Clear from '../../components/Functional/Clear';
import { USER } from '../../constants';
import { hidePB, showPB } from '../../actions/progressbarAction';
import { openSnackbar } from '../../actions/snackbarActions';
import RoleApi from '../../data/api/RoleApi';

class RoleCreateForm extends React.Component {
  onSubmit = (values) => {
    const { user, dispatch } = this.props;
    const companyId = user.getCompanyId;

    if (companyId) {
      showPB(dispatch);
      RoleApi.create(companyId, values).then(
        () => {
          RoleApi.list(dispatch, companyId).then(
            () => {
              hidePB(dispatch);
              dispatch(openSnackbar('Rol Oluşturuldu'));
            },
            () => {
              hidePB(dispatch);
              dispatch(openSnackbar('Yeni rol listeleme sırasında bir hata oluştu'));
            },
          );
        },
        () => {
          hidePB(dispatch);
          dispatch(openSnackbar('Rol oluşturma sırasında bir hata oluştu'));
        },
      );
    }
  };

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Subheader style={{ position: 'absolute' }}>Rol Oluşturma Formu</Subheader>
          <div
            style={{
              marginTop: 24,
              float: 'left',
              width: '100%',
            }}
          >
            <div className={'basicReportFieldContainer'}>
              <Field
                name="name"
                label="Rol Adı"
                autoComplete="off"
                component={renderTextField}
                fullWidth
              />
            </div>
            <div className={'basicReportFieldContainer'}>
              <Field
                fullWidth
                floatingLabelFixed
                name="tagId"
                label="Departman"
                autoComplete="off"
                component={renderTag}
              />
            </div>
            <RaisedButton
              type="submit"
              primary
              label="Rol Oluştur"
              style={{ float: 'right', marginTop: 28 }}
            />
          </div>
        </form>
        <Clear />
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.name) errors.name = 'Zorunlu Alan';
  if (!values.tagId) errors.tagId = 'Zorunlu Alan';
  return errors;
};
RoleCreateForm = reduxForm({ form: 'roleCreate', validate })(RoleCreateForm);
export default connect((state) => ({
  user: state.appData[USER],
}))(RoleCreateForm);
