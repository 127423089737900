import React from 'react';
import { Divider } from 'material-ui';
import { connect } from 'react-redux';
import Link from '../Link/Link';
import { isLocalStorageAccessible, isLogin, logout } from '../../actions/authActions';
import Clear from '../Functional/Clear';
import { USER } from '../../constants';
import { buildNavigationMenu, getMenu } from './Menu';

class Navigation extends React.Component {
  render() {
    const login = isLocalStorageAccessible() && isLogin();
    const menu = buildNavigationMenu(getMenu(this.props.user));
    return (
      <div className={'navigation-root'} role="navigation">
        <div style={{ float: 'right' }}>
          <Link className={'link'} to="/">
            Anasayfa
          </Link>
          <Link className={'link'} to="/about">
            Hakkımızda
          </Link>
          <Link className={'link'} to="/contact">
            İletişim
          </Link>
          <Link className={'link'} to="/change-log">
            Güncellemeler
          </Link>
          <span className={'spacer'}> | </span>
          {login ? (
            <Link className={'link'} to="/login" onClick={() => logout(this.props.dispatch)}>
              Çıkış Yap
            </Link>
          ) : (
            <div style={{ display: 'inline-block' }}>
              <Link className={'link'} to="/login">
                Giriş Yap
              </Link>
              <span className={'spacer'}>&nbsp;ya da</span>
              <Link className={'link highlight'} to="/register">
                Kayıt Ol
              </Link>
            </div>
          )}
        </div>
        {login && (
          <div>
            <Divider
              style={{
                float: 'right',
                width: '100%',
                marginTop: 4,
                marginBottom: 4,
              }}
            />
            <Clear />
            <div style={{ float: 'right' }}>{menu}</div>
          </div>
        )}
      </div>
    );
  }
}

export default connect((state) => ({
  user: state.appData[USER],
}))(Navigation);
