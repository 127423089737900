import find from 'lodash/find';
import { injuredPartyDegree } from '../../../data/model/response/InjuredPartyResponse';
import { DEATH, DISABLED } from '../../../data/model/IncidentType';
import { toMoneyFormat } from '../../../utils/javascript-object-util';

export default function InjuredPartyConclusionInfo() {
  const { incidentType } = this.incident;
  const { calculation, injuredParty } = this;
  const { details } = calculation;
  const { compensationByPerson, hasTemporaryIncapability } = details;
  const overPolicyLimit = calculation.isOverPolicyLimit;
  const { policyLimit } = calculation.details.policy;
  const familyMembers = injuredParty.familyMembersWithoutSimulated;
  const hasDeductedPayment = calculation.hasDeductedAmount(incidentType, familyMembers);

  function personMapper(p) {
    const degree = injuredPartyDegree(p, injuredParty, incidentType);
    const compensation = find(compensationByPerson, (c) => c.personId === p.basic.id);
    const { termAmounts } = compensation;
    const { cappedAmount } = compensation;
    const temporaryIncapacity = details.getTemporaryIncapabilityCompensation(p.basic.id);
    const knownConclusion = (termAmounts.knownActive || 0) + (termAmounts.knownPassive || 0);
    const activeConclusion = termAmounts.unknownActive;
    const passiveConclusion = termAmounts.unknownPassive;

    return {
      degree,
      fullName: p.basic.fullName,
      temporaryIncapacity,
      knownConclusion,
      activeConclusion,
      passiveConclusion,
      cappedAmount,
      compensationAmount: compensation.calculatedAmount,
      deductedAmount: compensation.deductedPayment,
    };
  }

  function td(text, fontSize = 10) {
    return {
      text,
      alignment: 'right',
      style: { fontSize },
    };
  }

  function th(text, colSpan = 1) {
    return {
      text,
      style: { bold: true },
      alignment: 'center',
      colSpan,
      fillColor: '#DDD',
    };
  }

  function tdLabel(text) {
    return {
      text,
      style: { bold: true },
      alignment: 'center',
      fillColor: '#DDD',
    };
  }

  function buildFirstTableHeaders() {
    const tableHeaders = [th('DESTEK ALACAKLAR', 2), ''];
    if (incidentType === DISABLED && hasTemporaryIncapability) {
      tableHeaders.push(th('GİG*'));
    }
    tableHeaders.push(th('BİLİNEN\nDÖNEM'));
    tableHeaders.push(th('AKTİF\nDÖNEM'));
    tableHeaders.push(th('PASİF\nDÖNEM'));
    tableHeaders.push(th('TOPLAM'));
    tableHeaders.push(th(''));

    return tableHeaders;
  }

  const body = [];
  const tableFirst = [buildFirstTableHeaders()];

  const concludedRows = [];

  if (incidentType === DEATH) {
    const spouses = injuredParty.spouses
      .filter((p) => !p.basic.simulatedAt)
      .filter((p) => !p.basic.hideShare);
    const parents = injuredParty.parents
      .filter((p) => !p.basic.simulatedAt)
      .filter((p) => !p.basic.hideShare);
    const children = injuredParty.children
      .filter((p) => !p.basic.simulatedAt)
      .filter((p) => !p.basic.hideShare);
    concludedRows.push(...spouses.map(personMapper));
    concludedRows.push(...parents.map(personMapper));
    concludedRows.push(...children.map(personMapper));

    let knownConclusion = 0;
    let activeConclusion = 0;
    let passiveConclusion = 0;
    let compensationAmount = 0;

    concludedRows.forEach((c) => {
      const items = [
        td(c.degree, 8),
        tdLabel(c.fullName),
        td(c.knownConclusion ? toMoneyFormat(c.knownConclusion) : '-'),
        td(c.activeConclusion ? toMoneyFormat(c.activeConclusion) : '-'),
        td(c.passiveConclusion ? toMoneyFormat(c.passiveConclusion) : '-'),
        td(c.compensationAmount ? toMoneyFormat(c.compensationAmount) : '-'),
        td('₺'),
      ];
      knownConclusion += c.knownConclusion || 0;
      activeConclusion += c.activeConclusion || 0;
      passiveConclusion += c.passiveConclusion || 0;
      compensationAmount += c.compensationAmount || 0;
      tableFirst.push(items);
    });

    tableFirst.push([
      '',
      tdLabel('TOPLAM:'),
      td(knownConclusion ? toMoneyFormat(knownConclusion) : '-'),
      td(activeConclusion ? toMoneyFormat(activeConclusion) : '-'),
      td(passiveConclusion ? toMoneyFormat(passiveConclusion) : '-'),
      td(compensationAmount ? toMoneyFormat(compensationAmount) : '-'),
      td('₺'),
    ]);
  } else {
    const c = personMapper(injuredParty.casualty);
    const items = [td(c.degree), tdLabel(c.fullName)];
    if (hasTemporaryIncapability) {
      items.push(td(toMoneyFormat(c.temporaryIncapacity)));
    }
    const knownConc = c.knownConclusion ? c.knownConclusion - c.temporaryIncapacity : 0;
    items.push(
      td(knownConc > 0.01 ? toMoneyFormat(knownConc) : '-'),
      td(c.activeConclusion ? toMoneyFormat(c.activeConclusion) : '-'),
      td(c.passiveConclusion ? toMoneyFormat(c.passiveConclusion) : '-'),
      td(c.compensationAmount ? toMoneyFormat(c.compensationAmount) : '-'),
      td('₺'),
    );
    tableFirst.push(items);
  }
  const widths = ['auto', 150, 50.65, 50.65, 50.65, 50.65];
  if (incidentType === DISABLED && hasTemporaryIncapability) {
    widths.push(50.65);
  }
  widths.push(3);
  const explanation1 = [];
  if (hasTemporaryIncapability) {
    explanation1.push({
      text: '* Geçici İş Görmezlik',
      style: { fontSize: 9 },
      margin: [0, 0, 0, 0],
    });
  }
  body.push(
    {
      margin: [0, 15, 0, 0],
      style: { fontSize: 9 },
      table: {
        dontBreakRows: true,
        keepWithHeaderRows: 1,
        headerRows: 1,
        heights: 10,
        widths,
        body: tableFirst,
      },
    },
    ...explanation1,
  );
  //-------------------------------------------------
  if (hasDeductedPayment || overPolicyLimit) {
    let explanation1Revocation = false;
    const tableHeaders = [th('DESTEK ALACAKLAR', 2), ''];

    tableHeaders.push(th('TOPLAM'));
    if (overPolicyLimit) {
      tableHeaders.push(th('POLİÇE\nLİMİTLİ*\nTAZMİNAT'));
    }
    if (hasDeductedPayment) {
      tableHeaders.push(th('ÖDEME\nTENZİLİ'));
      tableHeaders.push(th('KALAN\nTAZMİNAT'));
    }
    tableHeaders.push(th(''));
    const tableSecond = [tableHeaders];
    if (incidentType === DEATH) {
      let totalConclusion = 0;
      let deductedAmount = 0;
      let totalPolicyLimit = 0;
      let total = 0;
      concludedRows.forEach((c) => {
        const items = [
          td(c.degree, 8),
          tdLabel(c.fullName),
          td(c.compensationAmount ? toMoneyFormat(c.compensationAmount) : '-'),
        ];
        if (overPolicyLimit) {
          items.push(td(c.cappedAmount ? `${toMoneyFormat(c.cappedAmount)}` : '-'));
        }
        let subTotal = 0;
        if (hasDeductedPayment) {
          items.push(td(c.deductedAmount ? `-${toMoneyFormat(c.deductedAmount)}` : '-'));
          const subTotalRow = (a) => {
            subTotal = a - c.deductedAmount;
            const minus = subTotal < 0;
            items.push(td(a ? `${minus ? '**' : ''}${toMoneyFormat(subTotal)}` : '-'));
            if (minus) explanation1Revocation = true;
          };
          if (overPolicyLimit) {
            subTotalRow(c.cappedAmount);
          } else {
            subTotalRow(c.compensationAmount);
          }
        }
        deductedAmount += c.deductedAmount;
        totalConclusion += c.compensationAmount;
        totalPolicyLimit += c.cappedAmount;
        total += subTotal < 0 ? 0 : subTotal;
        items.push(td('₺'));
        tableSecond.push(items);
      });
      const totalRow = [
        '',
        tdLabel('TOPLAM:'),
        td(totalConclusion ? toMoneyFormat(totalConclusion) : '-'),
      ];
      if (overPolicyLimit) {
        totalRow.push(td(policyLimit ? `${toMoneyFormat(totalPolicyLimit)}` : '-'));
      }
      if (hasDeductedPayment) {
        totalRow.push(td(deductedAmount ? `-${toMoneyFormat(deductedAmount)}` : '-'));
        totalRow.push(td(toMoneyFormat(total)));
      }
      totalRow.push(td('₺'));
      tableSecond.push(totalRow);
    } else {
      const { casualty } = injuredParty;
      const cr = personMapper(casualty);
      const items = [td(cr.degree), tdLabel(cr.fullName)];
      items.push(td(cr.compensationAmount ? toMoneyFormat(cr.compensationAmount) : '-'));
      if (overPolicyLimit) {
        items.push(td(cr.cappedAmount ? `${toMoneyFormat(cr.cappedAmount)}` : '-'));
      }
      if (hasDeductedPayment) {
        items.push(td(cr.deductedAmount ? `-${toMoneyFormat(cr.deductedAmount)}` : '-'));
        const totalCell = (a) => {
          const subTotal = a - cr.deductedAmount;
          const minus = subTotal < 0;
          items.push(td(a ? `${minus ? '**' : ''}${toMoneyFormat(minus ? 0 : subTotal)}` : '-'));
          if (minus) explanation1Revocation = true;
        };
        if (overPolicyLimit) totalCell(cr.cappedAmount);
        else totalCell(cr.compensationAmount);
      }
      items.push(td('₺'));
      tableSecond.push(items);
    }

    const defaultWidth = 56.65;
    const widthsSecond = ['auto', 150, defaultWidth];
    if (overPolicyLimit) widthsSecond.push(defaultWidth);
    if (hasDeductedPayment) widthsSecond.push(defaultWidth, defaultWidth);
    widthsSecond.push(3);

    const explanation2 = [];
    if (overPolicyLimit) {
      explanation2.push({
        margin: [0, 1, 0, 0],
        text: '* Poliçe Teminat Limitleriyle Sınırlandırılmış Tazminat',
        style: { fontSize: 9 },
      });
    }
    if (explanation1Revocation) {
      explanation2.push({
        margin: [0, 1, 0, 0],
        text: '** Negatif değerler ödeme fazlası olduğundan tazminata dahil edilmemiştir.',
        style: { fontSize: 9 },
      });
    }
    body.push(
      {
        margin: [0, 15, 0, 0],
        style: { fontSize: 9 },
        table: {
          dontBreakRows: true,
          keepWithHeaderRows: 1,
          headerRows: 1,
          heights: 10,
          widths: widthsSecond,
          body: tableSecond,
        },
      },
      ...explanation2,
    );
  }

  return body;
}
