import { setAppData } from '../../actions/appdata';
import { ACTION_LIST, PAGE_SIZE } from '../../constants';
import Connection from './Connection';
import { toQuery } from '../../utils/javascript-object-util';
import ActionResponse from '../model/response/ActionResponse';

export default class ActionApi extends Connection {
  static find(dispatch, incidentId, id) {
    return this._getSecurely(`/actions/${id}`)
      .then(...this.justData)
      .catch(this.catchError);
  }

  static list(dispatch, incidentId, page = 0, size = PAGE_SIZE) {
    const query = {
      page,
      size,
    };
    return this._getSecurely(`/incidents/${incidentId}/actions${toQuery(query)}`)
      .then(...this.justData)
      .then((r) =>
        dispatch(
          setAppData({
            name: ACTION_LIST,
            value: r.bindList(ActionResponse),
          }),
        ),
      )
      .catch(this.catchError);
  }

  static create(incidentId, data) {
    return this._post(`/incidents/${incidentId}/actions`, data).then(...this.justData);
  }

  static delete(incidentId, actionId) {
    return this._delete(`/actions/${actionId}`);
  }
}
