import { setAppData } from '../../actions/appdata';
import { COMPANY_USER_LIST, PAGE_SIZE } from '../../constants';
import Connection from './Connection';
import CompanyUserResponse from '../model/response/CompanyUserResponse';
import { toQuery } from '../../utils/javascript-object-util';

export default class CompanyUserApi extends Connection {
  static list(dispatch, companyId, page = 0, size = PAGE_SIZE) {
    const query = toQuery({
      page,
      size,
    });
    return this._getSecurely(`/companies/${companyId}/users${query}`)
      .then(...this.justData)
      .then((r) =>
        dispatch(
          setAppData({
            name: COMPANY_USER_LIST,
            value: r ? r.bindList(CompanyUserResponse) : [],
          }),
        ),
      )
      .catch(this.catchError);
  }

  static update(companyId, userId, data) {
    return this._patch(`/companies/${companyId}/users/${userId}`, data);
  }

  static delete(companyId, userId) {
    return this._delete(`/companies/${companyId}/users/${userId}`);
  }
}
