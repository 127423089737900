import React from 'react';
import { connect } from 'react-redux';
import { Paper } from 'material-ui';
import RoleCreateForm from './RoleCreateForm';
import RoleList from './RoleList';
import { ReadRole, WriteRole } from '../../data/model/Privilege';
import Authorization from '../../components/Authorization/Authorization';
import Layout from '../../components/Layout/Layout';

class RoleManagement extends React.Component {
  render() {
    return (
      <Layout>
        <Authorization allowedRoles={[ReadRole]} force>
          <div className={'root'}>
            <div className={'container'}>
              <div className={'max1024'} style={{ padding: '0 0 80px' }}>
                <h1>Rol Yönetimi</h1>
                <RoleList />
                <Authorization allowedRoles={[WriteRole]}>
                  <Paper style={{ marginBottom: 32, marginTop: 8 }}>
                    <div style={{ padding: '0 16px 16px 16px' }}>
                      <RoleCreateForm />
                    </div>
                  </Paper>
                </Authorization>
              </div>
            </div>
          </div>
        </Authorization>
      </Layout>
    );
  }
}

export default connect()(RoleManagement);
