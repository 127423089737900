import { setAppData } from '../../actions/appdata';
import { PAYMENT, PAYMENT_LIST } from '../../constants';
import Connection from './Connection';
import IncidentResponse from '../model/response/IncidentResponse';

export default class PaymentApi extends Connection {
  static list(dispatch, incidentId) {
    return this._getSecurely(`/incidents/${incidentId}/payments `)
      .then((r) => dispatch(setAppData({ name: PAYMENT_LIST, value: r })))
      .catch(this.catchError);
  }

  static find(dispatch, id) {
    return this._getSecurely(`/payments/${id}`)
      .then((r) => {
        const data = new IncidentResponse(r);
        dispatch(setAppData({ name: PAYMENT, value: data }));
        return data;
      })
      .catch(this.catchError);
  }

  static update(id, data) {
    return this._patch(`/payments/${id}`, data);
  }

  static create(data, incidentId) {
    return this._post(`/incidents/${incidentId}/payments`, data);
  }

  static delete(id) {
    return this._delete(`/payments/${id}`);
  }
}
