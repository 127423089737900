import React from 'react';
import InfoText from './InfoText';

class MultipleInfoText extends React.Component {
  render() {
    const { style = {}, labelStyle = {}, data, className } = this.props;
    return (
      <div className={className} style={style}>
        {data &&
          data.map((d) => (
            <InfoText
              key={data.indexOf(d)}
              title={d.title}
              content={d.content}
              nonLabel={d.nonLabel}
              labelStyle={labelStyle}
            />
          ))}
      </div>
    );
  }
}

export default MultipleInfoText;
