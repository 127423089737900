import Calculation from './Calculation';

export default class ClaimsByPerson {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.personId = config.personId;
    this.amount = config.amount;
    this.share = config.share;
    this.mortality = config.mortality;
    this.calculations = config.calculations ? config.calculations.bindList(Calculation) : [];
  }
}
