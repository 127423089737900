export const normalizeDate = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 2) {
    if (onlyNums[0] <= 3) return onlyNums;
    return null;
  }
  if (onlyNums.length <= 4) {
    if (onlyNums[2] <= 1) return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 4)}`;
    return `${onlyNums.slice(0, 2)}`;
  }
  if (onlyNums[4] > 0 && onlyNums[4] <= 2)
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 4)}.${onlyNums.slice(4, 8)}`;
  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 4)}`;
};
