/* eslint-disable react/prop-types,no-prototype-builtins */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ComboBox from './ComboBox';
import { ROLE_LIST } from '../../constants';

const states = (obj) => {
  if (!obj) return [];
  return obj.map((k) => ({ value: k.id, text: k.name }));
};

class RoleComboBox extends Component {
  render() {
    return <ComboBox {...this.props} data={states(this.props.roles)} />;
  }
}

export default connect((state) => ({
  roles: state.appData[ROLE_LIST],
}))(RoleComboBox);
