import CasualtyCreateRequest from './CasualtyCreateRequest';
import ChildCreateRequest from './ChildCreateRequest';
import SpouseCreateRequest from './SpouseCreateRequest';
import ParentCreateRequest from './ParentCreateRequest';

export default class InjuredPartyCreateRequest {
  constructor(config, { dateOfIssue }) {
    if (typeof config !== 'object') return;
    this.casualty = new CasualtyCreateRequest(config.casualty);
    this.spouses = config.spouses
      ? config.spouses.bindList(SpouseCreateRequest, { dateOfIssue })
      : [];
    this.children = config.children
      ? config.children.bindList(ChildCreateRequest, { dateOfIssue })
      : [];
    this.parents = config.parents
      ? config.parents.bindList(ParentCreateRequest, { dateOfIssue })
      : [];
  }
}
