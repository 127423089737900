import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { RaisedButton } from 'material-ui';
import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';
import InjuredPartyForm from '../InjuredPartyForm';
import Clear from '../../Functional/Clear';
import { validateInjuredParty } from '../../../utils/validation';
import { convertToDate, objDiff, sleep } from '../../../utils/javascript-object-util';
import CasualtyCreateRequest from '../../../data/model/request/create/CasualtyCreateRequest';
import ChildCreateRequest from '../../../data/model/request/create/ChildCreateRequest';
import ParentCreateRequest from '../../../data/model/request/create/ParentCreateRequest';
import SpouseCreateRequest from '../../../data/model/request/create/SpouseCreateRequest';
import { hidePB } from '../../../actions/progressbarAction';
import { openSnackbar } from '../../../actions/snackbarActions';
import { DETAILED_INCIDENT_CC, INIT_INJURED_PARTY } from '../../../constants';
import { clearAppData } from '../../../actions/appdata';
import RouteStory, { RouteLocationStory } from '../../../utils/RouteStory';
import ChildUpdateRequest from '../../../data/model/request/update/ChildUpdateRequest';
import SpouseUpdateRequest from '../../../data/model/request/update/SpouseUpdateRequest';
import ParentUpdateRequest from '../../../data/model/request/update/ParentUpdateRequest';
import ReportFlexInfo from '../ReportFlexInfo';
import ChildApi from '../../../data/api/ChildApi';
import ParentApi from '../../../data/api/ParentApi';
import SpouseApi from '../../../data/api/SpouseApi';
import CasualtyApi from '../../../data/api/CasualtyApi';
import IncidentApi from '../../../data/api/IncidentApi';
import Layout from '../../Layout/Layout';
import Authorization from '../../Authorization/Authorization';
import { WriteIncident } from '../../../data/model/Privilege';

class InjuredPartyCreateUpdateForm extends React.Component {
  isSameId = (other) => (entity) => entity.basic && entity.basic.id === other.basic.id;

  hasId = (entity) => entity.basic && entity.basic.id;

  hasNotId = (entity) => entity.basic && !entity.basic.id;

  onSubmit = (values) => {
    const { dispatch, incidentId, initialValues } = this.props;
    const casualtyId = initialValues.injuredParty.casualty.basic.id;
    const requests = [];

    const initialInjuredParty = initialValues.injuredParty;
    const { injuredParty } = values;

    const casualty = objDiff(injuredParty.casualty, initialInjuredParty.casualty);
    if (!isEmpty(casualty)) {
      const casualtyUpdate = new CasualtyCreateRequest(casualty);
      requests.push(CasualtyApi.update(incidentId, casualtyId, casualtyUpdate));
    }

    const initialSpouses = initialInjuredParty.spouses;
    const spouses = injuredParty.spouses || [];
    const initialParents = initialInjuredParty.parents;
    const parents = injuredParty.parents || [];
    const initialChildren = initialInjuredParty.children;
    const children = injuredParty.children || [];

    requests.push(
      this.injuredPartyRequester(
        values,
        children,
        initialChildren,
        ChildApi.create,
        ChildApi.update,
        ChildApi.delete,
        ChildCreateRequest,
        ChildUpdateRequest,
      ),
    );
    requests.push(
      this.injuredPartyRequester(
        values,
        parents,
        initialParents,
        ParentApi.create,
        ParentApi.update,
        ParentApi.delete,
        ParentCreateRequest,
        ParentUpdateRequest,
      ),
    );
    requests.push(
      this.injuredPartyRequester(
        values,
        spouses,
        initialSpouses,
        SpouseApi.create,
        SpouseApi.update,
        SpouseApi.delete,
        SpouseCreateRequest,
        SpouseUpdateRequest,
      ),
    );

    Promise.all(requests).then(
      async () => {
        hidePB(dispatch);
        dispatch(openSnackbar('Pay Sahipleri Güncellendi'));
        dispatch(clearAppData(INIT_INJURED_PARTY));
        this.props.history.goBack();
        this.props.history.push(`/reports/${incidentId}`);
        await sleep(25);
        IncidentApi.find(dispatch, incidentId);
      },
      (err) => {
        hidePB(dispatch);
        dispatch(openSnackbar(`Güncelleme sırasında hata oluştu. ${err.message}`));
      },
    );
  };

  injuredPartyRequester(values, list, initialList, create, update, remove, Creator, Updater) {
    const requests = [];
    const { initialValues, detailedIncident } = this.props;
    const casualtyId = initialValues.injuredParty.casualty.basic.id;
    const casualtyDateOfBirth = values.injuredParty.casualty.basic.dateOfBirth;
    const { incident } = detailedIncident;
    list.filter(this.hasId).forEach((item) => {
      requests.push(update(item.basic.id, new Updater(item, { dateOfIssue: null })));
    });
    list.filter(this.hasNotId).forEach((item) => {
      requests.push(
        create(
          casualtyId,
          new Creator(item, {
            dateOfIssue: convertToDate(incident.dateOfIssue),
            casualtyDateOfBirth: convertToDate(casualtyDateOfBirth),
          }),
        ),
      );
    });
    const oldList = initialList.map((s) => s.basic.id);
    const newList = list.filter(this.hasId).map((s) => s.basic.id);
    difference(oldList, newList).forEach((id) => requests.push(remove(id)));
    return requests;
  }

  componentDidMount() {
    if (!this.props.detailedIncident) this.props.history.push('/reports');
  }

  render() {
    const { pristine, submitting, update, detailedIncident } = this.props;
    if (!detailedIncident) return <div />;
    return (
      <Layout>
        <Authorization allowedRoles={[WriteIncident]} force>
          <div className={'root'}>
            <div
              className={`container`}
              style={{ maxWidth: 850, minWidth: 850, margin: 'auto' }}
            >
              <h1>Pay Sahiplerini Güncelle</h1>
              <RouteLocationStory
                locations={[
                  RouteStory.reports(),
                  RouteStory.report({
                    text: detailedIncident.injuredParty.casualty.basic.fullName,
                  }),
                  RouteStory.injuredPartyCreateUpdate(),
                ]}
              />
              <form
                style={{ position: 'relative' }}
                onSubmit={this.props.handleSubmit(this.onSubmit)}
              >
                <InjuredPartyForm update={update} detailedIncident={detailedIncident} />

                <RaisedButton
                  label="Pay Sahiplerini Güncelle"
                  primary
                  type="submit"
                  disabled={pristine || submitting}
                  style={{ float: 'right', marginRight: 12 }}
                />
                <Clear />
              </form>
              <ReportFlexInfo />
            </div>
          </div>
        </Authorization>
      </Layout>
    );
  }
}

const validate = (values) => {
  const errors = { injuredParty: { casualty: {} } };

  errors.injuredParty = validateInjuredParty(values.injuredParty);

  return errors;
};
InjuredPartyCreateUpdateForm = reduxForm({
  form: 'detailedReport',
  validate,
  enableReinitialize: true,
})(InjuredPartyCreateUpdateForm);

export default connect((state) => ({
  initialValues: state.appData[INIT_INJURED_PARTY],
  detailedIncident: state.appData[DETAILED_INCIDENT_CC],
}))(InjuredPartyCreateUpdateForm);
