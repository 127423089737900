import React from 'react';
import { connect } from 'react-redux';
import { FormSection } from 'redux-form';
import {
  dateOfBirth,
  fullName,
  gender,
  personalIdentificationNumber,
} from '../../data/model/InjuredPerson';
import Clear from '../Functional/Clear';

class PersonForm extends React.Component {
  render() {
    return (
      <FormSection name="basic" style={{ display: 'inline-block', float: 'left' }}>
        {personalIdentificationNumber.component()}
        {fullName.component()}
        {dateOfBirth.component()}
        {gender.component()}
        <Clear />
      </FormSection>
    );
  }
}

export default connect()(PersonForm);
