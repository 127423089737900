import { setAppData } from '../../actions/appdata';
import { DETAILED_INCIDENT, DETAILED_INCIDENT_CC, FILE_LIST, INCIDENT_LIST } from '../../constants';
import Connection from './Connection';
import DetailedIncidentResponse from '../model/response/DetailedIncidentResponse';
import { toQuery } from '../../utils/javascript-object-util';

export default class IncidentApi extends Connection {
  static create(data) {
    return this._post(`/incidents`, data);
  }

  static list(dispatch) {
    return this._getSecurely(`/incidents`)
      .then(...this.justData)
      .then((r) => dispatch(setAppData({ name: INCIDENT_LIST, value: r })))
      .catch(this.catchError);
  }

  static find(dispatch, id) {
    return this._getSecurely(`/incidents/${id}`)
      .then(...this.justData)
      .then((r) => {
        const data = new DetailedIncidentResponse(r);
        document.title =
          data?.injuredParty?.casualty?.basic?.fullName || 'Inova Aktueryal Danışmanlık';
        dispatch(setAppData({ name: DETAILED_INCIDENT, value: data }));
        dispatch(setAppData({ name: DETAILED_INCIDENT_CC, value: data }));
        dispatch(setAppData({ name: FILE_LIST, value: data.files }));
        return data;
      })
      .catch(this.catchError);
  }

  static update(id, data) {
    return this._patch(`/incidents/${id}`, data).then(...this.justData);
  }

  static delete(id) {
    return this._delete(`/incidents/${id}`);
  }

  static lookup(parameters) {
    const query = toQuery(parameters);

    return this._getSecurely(`/incidents/id-lookup${query}`)
      .then(...this.justData)
      .catch(this.catchError);
  }
}
