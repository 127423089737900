import { setAppData } from '../../actions/appdata';
import { PARENT, PARENTS } from '../../constants';
import Connection from './Connection';
import ChildResponse from '../model/response/ChildResponse';
import { toQuery } from '../../utils/javascript-object-util';

export default class ChildApi {
  static find(dispatch, childId) {
    return Connection._getSecurely(`/injured-parties/children/${childId}`)
      .then(...Connection.justData)
      .then((r) => {
        dispatch(setAppData({ name: PARENT, value: ChildResponse(r) }));
      })
      .catch(Connection.catchError);
  }

  static list(dispatch, casualtyId) {
    return Connection._getSecurely(`/injured-parties/children/${toQuery({ casualtyId })}`)
      .then(...Connection.justData)
      .then((r) => {
        dispatch(setAppData({ name: PARENTS, value: r.bindList(ChildResponse) }));
      })
      .catch(Connection.catchError);
  }

  static update(childId, data) {
    return Connection._patch(`/injured-parties/children/${childId}`, data);
  }

  static create(casualtyId, data) {
    return Connection._post(`/injured-parties/casualties/${casualtyId}/children`, data);
  }

  static delete(childId) {
    return Connection._delete(`/injured-parties/children/${childId}`);
  }
}
