import Connection from './Connection';
import { CUSTOMERS, PAGE_SIZE } from '../../constants';
import { toQuery } from '../../utils/javascript-object-util';
import { setAppData } from '../../actions/appdata';

export default class CustomerApi extends Connection {
  static list(dispatch, name, page = 0, size = PAGE_SIZE) {
    const query = toQuery({
      name,
      page,
      size,
    });
    return this._getSecurely(`/customers${query}`)
      .then(...this.justData)
      .then((r) => dispatch(setAppData({ name: CUSTOMERS, value: r })))
      .catch(this.catchError);
  }

  static create(data) {
    return this._post(`/customers`, data);
  }
}
