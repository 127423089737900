import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Paper, RaisedButton, Subheader } from 'material-ui';
import Clear from '../../components/Functional/Clear';
import { renderTextField } from '../../utils/redux-metarial-ui-util';
import { hidePB, showPB } from '../../actions/progressbarAction';
import { openSnackbar } from '../../actions/snackbarActions';
import { getErrorMessage } from '../../utils/error-messages';
import AccountApi from '../../data/api/AccountApi';
import Layout from '../../components/Layout/Layout';

class Account extends React.Component {
  onSubmit = (values, dispatch) => {
    showPB(dispatch);
    AccountApi.changePassword(values).then(
      (r) => {
        hidePB(dispatch);
        dispatch(openSnackbar('Şifre Değiştirildi.'));
        this.props.reset();
      },
      (r) => {
        hidePB(dispatch);
        dispatch(openSnackbar(getErrorMessage(r.error)));
      },
    );
  };

  render() {
    return (
      <Layout>
        <div className={'root'}>
          <div className={`${'container'} ${'max380'}`}>
            <h1>Hesap Bilgilerim</h1>
            <Paper>
              <Subheader>Şifremi Güncelle</Subheader>
              <form style={{ padding: '0 32px' }} onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <Field
                  label="Eski Şifre"
                  name="currentPassword"
                  component={renderTextField}
                  type="password"
                  fullWidth
                />
                <Field
                  label="Yeni Şifre"
                  name="newPassword"
                  component={renderTextField}
                  autoComplete="off"
                  type="password"
                  fullWidth
                />
                <Field
                  label="Yeni Şifre (Tekrar)"
                  name="newPasswordAgain"
                  component={renderTextField}
                  autoComplete="off"
                  type="password"
                  fullWidth
                />
                <RaisedButton
                  className={'right'}
                  style={{ margin: '32px 0' }}
                  primary
                  label="Şifremi Güncelle"
                  type="submit"
                />
                <Clear />
              </form>
            </Paper>
          </div>
        </div>
      </Layout>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const REQUIRED = 'Zorunlu Alan';
  const LESS_THEN_6_CHAR = 'Şifre 6 karakterden fazla olmalıdır.';
  const fields = ['currentPassword', 'newPassword', 'newPasswordAgain'];
  if (values.newPassword !== values.newPasswordAgain) {
    errors.newPasswordAgain = 'Şifreler Aynı Değil';
  }
  fields.forEach((k) => {
    if (!values[k]) {
      errors[k] = REQUIRED;
      return;
    }
    if (values[k].length < 6) {
      errors[k] = LESS_THEN_6_CHAR;
    }
  });

  return errors;
};
Account = reduxForm({ form: 'changePassword', validate })(Account);
export default connect()(Account);
