/* eslint-disable react/prop-types,no-prototype-builtins */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField } from 'material-ui';
import { USER } from '../../constants';

class DocumentTypeComboBox extends Component {
  componentDidMount() {
    if (this.props.user) this.props.input.onChange(this.props.user.id);
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (prevProps.user !== user) {
      if (user) this.props.input.onChange(user.id);
    }
  }

  render() {
    return (
      <TextField
        errorText={this.props.touched && this.props.error}
        errorStyle={{
          float: 'left',
        }}
        inputStyle={{ textAlign: 'center' }}
        value={this.props.user ? this.props.user.fullName : ''}
        style={{ display: 'none' }}
      />
    );
  }
}

export default connect((state) => ({
  user: state.appData[USER],
}))(DocumentTypeComboBox);
