import React from 'react';
import { connect } from 'react-redux';
import { FieldArray, FormSection, formValueSelector, reduxForm } from 'redux-form';
import { Paper, Subheader } from 'material-ui';
import { paperStyle } from '../../utils/material-ui-style-util';
import { DEATH } from '../../data/model/IncidentType';
import ParentListForm from './ParentListForm';
import SpouseListForm from './SpouseListForm';
import ChildListForm from './ChildListForm';
import PersonForm from './PersonForm';
import Clear from '../Functional/Clear';

class InjuredPartyForm extends React.Component {
  render() {
    const { casualty, spouses, parents, children, detailedIncident, update } = this.props;
    const {
      incident: { incidentType },
    } = detailedIncident;
    return (
      <FormSection name="injuredParty">
        <Paper style={paperStyle}>
          <Subheader className={'basicReportSubheader'}>
            {`${incidentType === DEATH ? 'Müteveffanın' : 'Mağdurun'} Bilgileri`}
          </Subheader>
          <FormSection style={{ padding: '0 0 16px 16px' }} name="casualty">
            <PersonForm />
            <Clear />
          </FormSection>
        </Paper>
        <div>
          <Paper style={paperStyle}>
            <Subheader className={'basicReportSubheader'}>Ebeveyn Bilgileri</Subheader>
            <FieldArray
              name="parents"
              component={ParentListForm}
              values={parents}
              update={update}
            />
          </Paper>

          <Paper style={paperStyle}>
            <Subheader className={'basicReportSubheader'}>Eş Bilgileri</Subheader>
            <FieldArray
              name="spouses"
              component={SpouseListForm}
              casualty={casualty}
              values={spouses}
              update={update}
            />
          </Paper>
          <Paper style={paperStyle}>
            <Subheader className={'basicReportSubheader'}>Çocuk Bilgileri</Subheader>
            <FieldArray
              name="children"
              component={ChildListForm}
              spouses={spouses}
              casualty={casualty}
              values={children}
              update={update}
            />
          </Paper>
        </div>
      </FormSection>
    );
  }
}

InjuredPartyForm = reduxForm({
  form: 'detailedReport',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(InjuredPartyForm);
const selector = formValueSelector('detailedReport');
export default connect((state) => ({
  spouses: selector(state, 'injuredParty.spouses') || [],
  parents: selector(state, 'injuredParty.parents') || [],
  children: selector(state, 'injuredParty.children') || [],
  casualty: selector(state, 'injuredParty.casualty'),
  marriage: selector(state, 'injuredParty.marriage'),
}))(InjuredPartyForm);
