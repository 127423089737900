import { convertToDate, getNameFragments } from '../../../../utils/javascript-object-util';
import { CLEANER_DATETIME } from '../../../../utils/CleanerValues';

export default class PersonUpdateRequest {
  constructor(config, defaultValues = {}) {
    if (typeof config !== 'object') return;
    const { dateOfBirth } = defaultValues;
    this.personalIdentificationNumber = config.personalIdentificationNumber;
    [this.name, this.surname] = getNameFragments(config.fullName);
    this.dateOfBirth = config.dateOfBirth ? convertToDate(config.dateOfBirth) : dateOfBirth;
    this.gender = config.gender;
    this.hideShare = config.hideShare;
    this.tempId = config.tempId;
    this.endOfSupportToOverride = config.endOfSupportToOverride
      ? convertToDate(config.endOfSupportToOverride)
      : CLEANER_DATETIME;
  }
}
