import { setAppData } from '../../actions/appdata';
import { PARENT, PARENTS } from '../../constants';
import Connection from './Connection';
import ParentResponse from '../model/response/ParentResponse';
import { toQuery } from '../../utils/javascript-object-util';

export default class ParentApi {
  static find(dispatch, parentId) {
    return Connection._getSecurely(`/injured-parties/parents/${parentId}`)
      .then(...Connection.justData)
      .then((r) => {
        dispatch(setAppData({ name: PARENT, value: ParentResponse(r) }));
      })
      .catch(Connection.catchError);
  }

  static list(dispatch, casualtyId) {
    return Connection._getSecurely(`/injured-parties/parents${toQuery({ casualtyId })}`)
      .then(...Connection.justData)
      .then((r) => {
        dispatch(setAppData({ name: PARENTS, value: r.bindList(ParentResponse) }));
      })
      .catch(this.catchError);
  }

  static update(parentId, data) {
    return Connection._patch(`/injured-parties/parents/${parentId}`, data);
  }

  static create(casualtyId, data) {
    return Connection._post(`/injured-parties/casualties/${casualtyId}/parents`, data);
  }

  static delete(parentId) {
    return Connection._delete(`/injured-parties/parents/${parentId}`);
  }
}
