import InvoiceCancellationResponse from './InvoiceCancellationResponse';
import SimpleUserResponse from './SimpleUserResponse';

export default class DetailedCancellationResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.issuer = new SimpleUserResponse(config.issuer);
    this.details = new InvoiceCancellationResponse(config.details);
  }
}
