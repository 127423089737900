export default class TransactionResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.id = config.id;
    this.amount = config.amount;
    this.createdAt = config.createdAt;
    this.category = config.category;
    this.message = config.message;
  }
}
