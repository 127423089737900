import findIndex from 'lodash/findIndex';
import { dateDiff, toMoney } from '../../../utils/javascript-object-util';
import { MILITARY_SERVICE } from '../../../data/model/SpecialPeriodType';
import { DEATH } from '../../../data/model/IncidentType';

function th(text) {
  return { text, style: ['tableHeader'] };
}

function td(text, alignment = 'center') {
  return { text, alignment };
}

const headers = (known = false) => [
  th('Dönem\nBaşlangıcı'),
  th('Dönem\nBitişi'),
  th('Dönem\nSüresi\n(Gün)'),
  th(`${known ? 'Kazadan' : 'Rapordan'}\nİtibaren Geçen\nSüre(Gün)`),
  th('Yıllık\nMaaş'),
  th('Günlük\nMaaş'),
  th('Dönemsel\nGelir'),
  th('Dönemler\nToplamı'),
];

const headersGIG = () => [
  th('GİG\nBaşlangıcı'),
  th('GİG\nBitişi'),
  th('GİG\nSüresi\n(Gün)'),
  th(`GİG\nSüre(Gün)`),
  th('Yıllık\nMaaş'),
  th('Günlük\nMaaş'),
  th('Dönemsel\nGelir'),
  th('Dönemler\nToplamı'),
];

function buildBody(incident, periods, known = false) {
  let totalCompensation = 0;
  return periods.map((p) => {
    const days = dateDiff(p.start, p.end, 'days').toFixed(0);
    const totalDays = known
      ? dateDiff(periods[0].start || incident.dateOfIncident, p.end, 'days').toFixed(0)
      : dateDiff(incident.dateOfReport, p.end, 'days').toFixed(0);
    const yearlySalary = p.salary * 12;
    const dailySalary = yearlySalary / 365;
    totalCompensation += p.amount;

    const amountPrefix =
      findIndex(p.calculations, { name: MILITARY_SERVICE }) >= 0 ? '(Asker) ' : '';
    return [
      td(p.start),
      td(p.end),
      td(days),
      td(totalDays),
      td(toMoney(yearlySalary), 'right'),
      td(toMoney(dailySalary), 'right'),
      td(amountPrefix + toMoney(p.amount), 'right'),
      td(toMoney(totalCompensation), 'right'),
    ];
  });
}

const tableParams = {
  dontBreakRows: true,
  keepWithHeaderRows: 2,
  headerRows: 2,
  widths: ['auto', 'auto', 'auto', 'auto', '*', '*', '*', '*'],
  heights: 10,
};

const buildTable = (incident, periods, header, isKnown) => {
  if (periods.length === 0) return [];
  return [
    {
      margin: [0, 10, 0, 10],
      style: { fontSize: 10 },
      table: {
        ...tableParams,
        body: [header, headers(isKnown), ...buildBody(incident, periods, isKnown)],
      },
    },
  ];
};

export default function PeriodInfo(nurse = false) {
  const { incident, config, calculation } = this;
  const details = nurse ? calculation.nurseDetails : calculation.details;
  const { hasTemporaryIncapability } = details;

  const { incidentType } = incident;
  const incidentText = incidentType === DEATH ? 'Ölüm' : 'Kaza';
  const gigHeader = [
    {
      text: `GİG: ${
        incidentType === DEATH ? 'Müteveffanın' : 'Mağdurun'
      } Geçici iş görmez kaldığı süre için`,
      style: { fontSize: 11, bold: true },
      alignment: 'center',
      margin: [20, 10, 10, 5],
      colSpan: 8,
    },
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ];
  const knownHeader = [
    {
      text: `BİLİNEN DÖNEM: ${
        hasTemporaryIncapability
          ? 'GİG bitiş'
          : incidentText
      } tarihinden rapor tarihine kadar geçen süre için;`,
      style: { fontSize: 11, bold: true },
      alignment: 'center',
      margin: [20, 10, 10, 5],
      colSpan: 8,
    },
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ];
  const activeHeader = [
    {
      text: nurse
        ? 'AKTİF DÖNEM: Rapor tarihi ile muhtemel ölüm tarihi arasında geçen süre için;'
        : 'AKTİF DÖNEM: Rapor tarihi ile emeklilik tarihi arasında geçen süre için;',
      style: { fontSize: 11, bold: true },
      alignment: 'center',
      margin: [20, 10, 10, 5],
      colSpan: 8,
    },
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ];
  const passiveHeader = [
    {
      text: `PASİF DÖNEM: ${
        details.hasActive ? 'Emeklilik' : 'Rapor'
      } tarihi ile destek süresi sonu arasında geçen süre için;`,
      style: { fontSize: 11, bold: true },
      alignment: 'center',
      margin: [20, 10, 10, 5],
      colSpan: 8,
    },
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ];

  const { passivePeriods } = details;
  const passivePeriod = buildTable(incident, passivePeriods, passiveHeader);
  const { activePeriods } = details;
  const activePeriod = buildTable(incident, activePeriods, activeHeader);
  const knownPeriods = details.getKnownPeriods;
  const knownPeriod = buildTable(incident, knownPeriods, knownHeader, true);
  const gigPeriods = details.temporaryIncapabilityPeriods;
  const gigPeriod =
    gigPeriods.length > 0
      ? [
          {
            margin: [0, 10, 0, 0],
            style: { fontSize: 10 },
            table: {
              ...tableParams,
              body: [gigHeader, headersGIG(), ...buildBody(incident, gigPeriods, true)],
            },
          },
          {
            margin: [0, 3, 0, 10],
            style: { fontSize: 10, italics: true },
            text: 'Mağdur geçici iş göremez kaldığı dönem için %100 malul kabul edilmiştir. ',
          },
        ]
      : [];
  return [...gigPeriod, ...knownPeriod, ...activePeriod, ...passivePeriod];
}
