export default {
  student: 'Öğrenci',
  notStudying: 'Değil',
  // unschooled: 'unschooled',
  // primarySchool: 'primarySchool',
  // middleSchool: 'middleSchool',
  // highSchool: 'highSchool',
  // university: 'university',
  // master: 'master',
  // doctorate: 'doctorate',
};
