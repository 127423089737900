import { setAppData } from '../../actions/appdata';
import { PAGE_SIZE, REPORT_CONTENT_RANGE, REPORT_ITEM_COUNT, REPORT_LIST } from '../../constants';
import Connection from './Connection';
import ReportResponse from '../model/response/ReportResponse';
import { toQuery } from '../../utils/javascript-object-util';

export default class ReportApi extends Connection {
  static list(dispatch, filterOptions, page = 0, size = PAGE_SIZE) {
    const query = toQuery({
      ...filterOptions,
      page,
      size,
    });
    return this._getSecurely(`/reports${query}`)
      .then(...this.setPageInfo(dispatch, REPORT_CONTENT_RANGE, REPORT_ITEM_COUNT))
      .then(...this.justData)
      .then((r) => {
        dispatch(setAppData({ name: REPORT_LIST, value: r.bindList(ReportResponse) }));
      })
      .catch(this.catchError);
  }
}
