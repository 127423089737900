/* eslint-disable prefer-destructuring,radix */
import sumBy from 'lodash/sumBy';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { dateFormat, roundPrecision, toMoney } from '../../../utils/javascript-object-util';
import CalculationDetail from './CalculationDetail';
import { DISABLED } from '../IncidentType';
import NurseCalculation from './NurseCalculation';

export default class CalculationResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.id = config.id;
    this.dateOfRetirement = dateFormat(config.dateOfRetirement);
    this.timestamp = config.timestamp;
    this.knownActiveAmount = roundPrecision(config.knownActiveAmount);
    this.knownPassiveAmount = roundPrecision(config.knownPassiveAmount);
    this.unknownActiveAmount = roundPrecision(config.unknownActiveAmount);
    this.unknownPassiveAmount = roundPrecision(config.unknownPassiveAmount);
    this.compensationAmount = roundPrecision(config.compensationAmount);
    this.deductedAmount = roundPrecision(config.deductedAmount);
    this.details = new CalculationDetail(config.details);
    this.nurseDetails = config.nurseDetails ? new NurseCalculation(config.nurseDetails) : undefined;
  }

  get isOverPolicyLimit() {
    return !!this.details.policy.policyLimit;
  }

  get getActiveTermCompensationAmount() {
    return toMoney(this.unknownActiveAmount);
  }

  get getPassiveTermCompensationAmount() {
    return toMoney(this.unknownPassiveAmount);
  }

  get getKnownTermCompensationAmount() {
    return toMoney(this.knownActiveAmount + this.knownPassiveAmount);
  }

  get getTotalCompensationAmount() {
    return toMoney(this.compensationAmount);
  }

  hasDeductedAmount(incidentType, injuredParty) {
    if (incidentType === DISABLED) {
      return this.deductedAmount > 0;
    }
    const deductedAmount = sumBy(
      injuredParty.filter((p) => !p.basic.hideShare),
      (p) => {
        const compensation = find(
          this.details.compensationByPerson,
          (c) => c.personId === p.basic.id,
        );
        return compensation.deductedPayment;
      },
    );
    return deductedAmount > 0;
  }

  hasNurseDetail() {
    return !isEmpty(this.nurseDetails);
  }
}
