export const paperStyle = {
  marginBottom: 32,
  marginTop: 24,
  marginLeft: 2,
  marginRight: 2,
  position: 'relative',
  minHeight: 105,
};

export const fieldStyle = { float: 'left', width: 150, marginRight: 16 };
export const getFieldArrayItemStyle = (index, length, single) => ({
  padding: '16px 0 32px 16px',
  position: 'relative',
  backgroundColor: index % 2 === 0 ? 'transparent' : '#32969617',
  paddingBottom: single || index !== length - 1 ? 16 : 32,
});
export const inputStyle = {
  padding: '0 8px',
};
export const comboBoxStyle = {
  marginTop: 0,
};

export const languageComboBoxStyle = {
  marginTop: 0,
  width: 150,
};

export const checkBoxStyle = {
  marginTop: 40,
  width: 256,
  marginBottom: 10,
};
export const submitButtonStyle = {
  margin: 20,
};
export const toggleStyle = {
  marginLeft: 20,
  marginTop: 8,
  width: 256,
  marginBottom: 8,
};
export const progressbarContainerStyle = {
  position: 'absolute',
  backgroundColor: '#FFF',
  width: '100%',
  height: '100%',
  zIndex: 999,
  transform: 'translate3d(0px, 0px, 0px)',
};
export const progressbarStyle = {
  position: 'fixed',
  top: 200,
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export const fabStyle = {
  position: 'absolute',
  right: 56,
  bottom: 0,
  marginBottom: 56,
};
export const languageTabStyle = {
  width: 256,
  marginLeft: 20,
  marginRight: 20,
};

export const iconButtonStyles = {
  smallestIcon: {
    width: 24,
    height: 24,
  },
  smallestButton: {
    padding: 4,
    width: 32,
    height: 32,
  },
  smallIcon: {
    width: 36,
    height: 36,
  },
  mediumIcon: {
    width: 48,
    height: 48,
  },
  largeIcon: {
    width: 60,
    height: 60,
  },
  small: {
    width: 72,
    height: 72,
    padding: 16,
  },
  medium: {
    width: 96,
    height: 96,
    padding: 24,
  },
  large: {
    width: 120,
    height: 120,
    padding: 30,
  },
};
