import React from 'react';
import { connect } from 'react-redux';
import { Field, FormSection } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlatButton } from 'material-ui';
import {
  renderMultiSelectableInjuredPartyComponent,
  renderPaymentCategory,
  renderTextField,
} from '../../utils/redux-metarial-ui-util';
import Clear from '../Functional/Clear';
import { fieldStyle, getFieldArrayItemStyle } from '../../utils/material-ui-style-util';
import { normalizeDate } from '../../utils/redux-normalize';

class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: false,
    };
  }

  render() {
    const { index } = this.props;
    const { fields } = this.props;
    const { payment } = this.props;
    const { injuredParty } = this.props;
    const { incidentType } = this.props;
    return (
      <FormSection style={getFieldArrayItemStyle(index, fields.length)} name={this.props.name}>
        <Field
          style={fieldStyle}
          component={renderTextField}
          normalize={normalizeDate}
          name="date"
          label="Ödeme Tarihi"
        />
        <Field style={fieldStyle} component={renderTextField} name="amount" label="Ödenen Tutar" />

        <Field
          style={{ ...fieldStyle, width: 180 }}
          component={renderPaymentCategory}
          name="category"
          label="Faiz Uygulama Tipi"
        />
        <div className={'basicReportFieldContainer'}>
          <div className={`tooltipv2 questionMark`}>
            <FontAwesomeIcon fixedWidth icon="info-circle" />
            <div className={'tooltipTextv2'}>
              Ödeme yapılan kişileri seçiniz. <br />
              Toplu ödeme varsa tüm pay sahipleri işaretlenmelidir.
            </div>
          </div>
          <Field
            style={fieldStyle}
            component={renderMultiSelectableInjuredPartyComponent}
            data={injuredParty}
            incidentType={incidentType}
            name="people"
            title="Ödeme Yapılan Kişiler"
          />
        </div>
        {payment && !payment.description && !this.state.description && (
          <span
            className={'smallInfoButton'}
            style={{ marginTop: 37 }}
            onClick={() => this.setState({ description: true })}
          >
            Açıklama Ekle
          </span>
        )}
        {payment && (payment.description || this.state.description) && (
          <Field
            style={fieldStyle}
            component={renderTextField}
            name="description"
            label="Açıklama"
          />
        )}
        <Clear />

        <div>
          <FlatButton
            style={{ position: 'absolute', minWidth: 36, top: 0, right: 0 }}
            className="material-icons"
            tooltip="Kaldır"
            icon={<FontAwesomeIcon fixedWidth icon="trash" />}
            onClick={() => fields.remove(index)}
          />
        </div>
      </FormSection>
    );
  }
}

export default connect()(PaymentForm);
