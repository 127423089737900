/* eslint-disable react/forbid-prop-types,prefer-destructuring,react/prop-types */
import React, { Component } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { connect } from 'react-redux';
import { DISABLED } from '../../data/model/IncidentType';

class MultiSelectableInjuredPartyComponent extends Component {
  selectionRenderer = (values) => {
    switch (values.length) {
      case 0:
        return '';
      default:
        return `${values.length} Kişi seçildi.`;
    }
  };

  componentDidMount() {}

  handleChange = (event, index, values) => {
    this.props.onChange(values);
  };

  personMapper = (person) => ({
    text: (person.basic && person.basic.fullName) || 'İsim Girilmemiş',
    id: person.basic && person.basic.id,
    tempId: person.basic && person.basic.tempId,
  });

  buildMenuItems = (injuredParty, values) => {
    if (!injuredParty) return;
    const incidentType = this.props.incidentType;
    const casualty = injuredParty.casualty;
    const familyMembers = injuredParty.familyMembersWithoutSimulated;
    const people = [];
    if (incidentType === DISABLED) {
      people.push(this.personMapper(casualty));
    } else {
      familyMembers.forEach((p) => people.push(this.personMapper(p)));
    }

    return people.map((p) => (
      <MenuItem
        key={p.id || p.tempId}
        insetChildren
        checked={values && values.some((v) => v === p.id || v === p.tempId)}
        value={p.id || p.tempId}
        primaryText={p.text}
      />
    ));
  };

  render() {
    const menuItems = this.buildMenuItems(this.props.data, this.props.value);
    return (
      <SelectField
        floatingLabelText={this.props.title}
        style={this.props.style}
        labelStyle={{ height: '56px' }}
        errorStyle={{ float: 'left' }}
        autoWidth
        value={this.props.value}
        onChange={this.handleChange}
        multiple
        selectionRenderer={this.selectionRenderer}
        errorText={this.props.errorText}
      >
        {menuItems}
      </SelectField>
    );
  }
}

export default connect()(MultiSelectableInjuredPartyComponent);
