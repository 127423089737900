import React from 'react';
import './index.scss';
import { getAdminMenu, getMenu, MainMenu } from '../../components/Navigation/Menu';
import Authorization from '../../components/Authorization/Authorization';
import { ACTUARY, BASIC } from '../../data/model/Privilege';
import ChangeLog from '../changeLog/ChangeLog';
import changelogLite from '../changeLog/changelogLite.md';
import Link from '../../components/Link/Link';
import '../changeLog/changeLog.css';
import Layout from '../../components/Layout/Layout';

export default function Home() {
  return (
    <Layout>
      <div className={'home-root'}>
        <div className={'container'}>
          <div style={{ textAlign: 'center', marginTop: 32 }}>
            <Link
              to={'/01-04-2020'}
              style={{
                textDecoration: 'none',
                fontSize: '12px',
                fontWeight: 'bold',
                padding: '4px 44px',
                background: 'white',
                boxShadow: '1px 1px 6px -3px black',
                color: '#00738b',
              }}
            >
              01-04-2020 TANZİM ve ÖNCESİ POLİÇELER için SİSTEM DEĞİŞİKLİKLERİ İLE İLGİLİ KONU
              BAŞLIKLARI
            </Link>
          </div>

          <Authorization allowedRoles={[BASIC]}>
            <MainMenu menu={getMenu()} />
          </Authorization>
          <Authorization allowedRoles={[ACTUARY]}>
            <MainMenu menu={getAdminMenu()} />
          </Authorization>

          <div className={'changeLog'}>
            <ChangeLog
              html={changelogLite}
              style={{
                display: 'table',
                margin: 'auto',
                minWidth: 550,
                marginTop: 64,
              }}
            />
            <Link
              style={{
                display: 'table',
                margin: 'auto',
                fontWeight: 'bold',
              }}
              to="/change-log"
            >
              Tüm güncelleme notları
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}
