export const TRH = 'trh';
export const CSO = 'cso';
export const PMF = 'pmf';
export const TRH_PROGRESSIVE = 'trh_progressive';

export default {
  trh: 'TRH 2010',
  cso: 'CSO 1980',
  pmf: 'PMF 1931',
  trh_progressive: 'TRH Progresif',
};

export const After2020MortalityTable = {
  trh: 'TRH 2010',
  pmf: 'PMF 1931',
  trh_progressive: 'TRH Progresif',
};
