import Connection from './Connection';
import { setAppData } from '../../actions/appdata';
import { EVENT, EVENTS, PAGE_SIZE } from '../../constants';
import EventResponse from '../model/response/EventResponse';
import DetailedEventResponse from '../model/response/DetailedEventResponse';
import { toQuery } from '../../utils/javascript-object-util';

export default class EventsApi extends Connection {
  static find(dispatch, companyId, eventId) {
    return this._getSecurely(`/companies/${companyId}/events/${eventId}`)
      .then(...this.justData)
      .then((r) =>
        dispatch(
          setAppData({
            name: EVENT,
            value: r ? r.bindClass(DetailedEventResponse) : {},
          }),
        ),
      )
      .catch(this.catchError);
  }

  static list(dispatch, companyId, page = 0, size = PAGE_SIZE) {
    const query = toQuery({
      page,
      size,
    });
    return this._getSecurely(`/companies/${companyId}/events${query}`)
      .then(...this.justData)
      .then((r) =>
        dispatch(
          setAppData({
            name: EVENTS,
            value: r ? r.bindList(EventResponse) : {},
          }),
        ),
      )
      .catch(this.catchError);
  }

  static redeliver(dispatch, companyId, eventId) {
    return this._post(`/companies/${companyId}/events/${eventId}/redeliver`, {})
      .then(...this.justData)
      .catch(this.catchError);
  }
}
