import moment from 'moment';
import ClaimsByPerson from './ClaimsByPerson';
import Calculator from './Calculator';
import AllowanceScenario from './AllowanceScenario';
import { dateFormat } from '../../../utils/javascript-object-util';
import Calculation from './Calculation';

export default class Period {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.id = config.id;
    this.start = dateFormat(moment(config.start, 'YYYY-MM-DD'));
    this.end = dateFormat(moment(config.end, 'YYYY-MM-DD'));
    this.allowanceScenario = new AllowanceScenario(config.allowanceScenario);
    this.salary = config.salary;
    this.term = config.term;
    this.amount = config.amount;
    this.claimsByPerson = config.claimsByPerson
      ? config.claimsByPerson.bindList(ClaimsByPerson)
      : [];
    this.calculations = config.calculations ? config.calculations.bindList(Calculation) : [];
  }
}
