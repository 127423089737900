import React from 'react';
import { connect } from 'react-redux';
import { Divider, FlatButton, IconButton, IconMenu, ListItem, MenuItem } from 'material-ui';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import Download from 'material-ui/svg-icons/file/file-download';
import Delete from 'material-ui/svg-icons/action/delete';
import Edit from 'material-ui/svg-icons/image/edit';
import * as downloadJs from 'downloadjs';
import { hidePB, showPB } from '../../actions/progressbarAction';
import { openSnackbar } from '../../actions/snackbarActions';
import Clear from '../Functional/Clear';
import { CALCULATION_FILES, FILE_LIST, INIT_FILE_UPDATE } from '../../constants';
import { setAppData } from '../../actions/appdata';
import { dateFormatLongNonSecond } from '../../utils/javascript-object-util';
import CalculationFileApi from '../../data/api/CalculationFileApi';
import FileApi from '../../data/api/FileApi';
import FileUpdateForm from './FileUpdateForm';
import Dialog from '../Functional/Dialog';
import { getHeadersWithToken } from '../../data/api/ApiUtil';

const { grey400 } = require('material-ui/styles/colors');
const { grey700 } = require('material-ui/styles/colors');

class FileViewHolder extends React.Component {
  iconButtonElement = (
    <IconButton touch tooltip="İşlemler" tooltipposition="bottom-left">
      <MoreVertIcon color={grey400} />
    </IconButton>
  );

  constructor(props) {
    super(props);
    this.state = {
      openDelete: false,
      openUpdate: false,
      hover: false,
    };
  }

  deleteFile = (id) => {
    const {
      incidentId,
      calculationId,
      dispatch,
      unready,
      calculationFiles,
      files,
      onDelete,
    } = this.props;
    this.handleDeleteClose();
    showPB(dispatch, 'Dosya Siliniyor');
    if (unready) {
      CalculationFileApi.delete(calculationId, id).then(
        () => {
          dispatch(
            setAppData({
              name: CALCULATION_FILES,
              value: calculationFiles ? calculationFiles.filter((f) => f.id !== id) : [],
            }),
          );
          if (onDelete) onDelete(id);
          hidePB(dispatch);
          dispatch(openSnackbar('Dosya Kaldırıldı'));
        },
        (r) => {
          hidePB(dispatch);
          dispatch(openSnackbar(r.error));
        },
      );
    } else {
      FileApi.delete(incidentId, id).then(
        () => {
          this.handleDeleteClose();
          dispatch(
            setAppData({
              name: FILE_LIST,
              value: files.filter((f) => f.id !== id),
            }),
          );
          hidePB(dispatch);
          dispatch(openSnackbar('Dosya Kaldırıldı'));
        },
        (r) => {
          this.handleDeleteClose();
          hidePB(dispatch);
          dispatch(openSnackbar(r.error));
        },
      );
    }
  };

  download = async (fileId, filename, force = false) => {
    const { dispatch, incidentId, unready, calculationId } = this.props;

    const file = await (unready
      ? CalculationFileApi.getSignedUrl(calculationId, fileId)
      : FileApi.getSignedUrl(incidentId, fileId));

    showPB(dispatch, `${file.name} indiriliyor...`);
    fetch(file.url, {
      method: 'GET',
      headers: getHeadersWithToken(),
    })
      .then((response) => response.blob())
      .then(
        (blob) => {
          if (force || file.extension !== 'pdf') {
            downloadJs(blob, filename);
          } else {
            const file = new File([blob], filename, { type: 'application/pdf' });
            const url = URL.createObjectURL(file);
            window.open(url, '_blank');
          }
          hidePB(dispatch);
        },
        () => {
          dispatch(openSnackbar('Dosya İndirme Sırasında Hata'));
          hidePB(dispatch);
        },
      );
  };

  handleOpenFile = async () => {
    const { dispatch, file } = this.props;

    if (file.extension !== 'pdf') {
      dispatch(
        openSnackbar('Dosya Pdf olmadığından otomatik açılamıyor', {
          onClick: () => this.download(file.id, file.name),
          text: 'İNDİR',
        }),
      );
      return;
    }
    await this.download(file.id, file.name);
  };

  handleDeleteOpen = () => this.setState({ openDelete: true });

  handleDeleteClose = () => this.setState({ openDelete: false });

  handleUpdateOpen = () => this.setState({ openUpdate: true });

  handleUpdateClose = () => this.setState({ openUpdate: false });

  mouseOver = () => this.setState({ hover: true });

  mouseOut = () => this.setState({ hover: false });

  rightIconMenu = (readOnly) => {
    const { file } = this.props;
    return (
      <IconMenu iconButtonElement={this.iconButtonElement}>
        <MenuItem leftIcon={<Download />} onClick={() => this.download(file.id, file.name, true)}>
          İndir
        </MenuItem>
        <MenuItem
          leftIcon={<Edit color={readOnly ? grey400 : grey700} />}
          onClick={() => {
            this.handleUpdateOpen();
            this.props.dispatch(
              setAppData({
                name: INIT_FILE_UPDATE,
                value: {
                  name: file.name,
                  categoryId: file.category.id,
                },
              }),
            );
          }}
          disabled={readOnly}
        >
          Güncelle
        </MenuItem>
        <Divider />
        <MenuItem
          leftIcon={<Delete color={readOnly ? grey400 : grey700} />}
          disabled={readOnly}
          onClick={this.handleDeleteOpen}
        >
          Sil
        </MenuItem>
      </IconMenu>
    );
  };

  deleteActions = (file) => [
    <FlatButton keyboardFocused label="İptal" primary onClick={this.handleDeleteClose} />,
    <FlatButton label="Sil" primary onClick={() => this.deleteFile(file.id)} />,
  ];

  render() {
    const { unready, file } = this.props;
    const MAX_FILE_NAME_LENGTH = 57;
    return (
      <ListItem
        onMouseEnter={this.mouseOver}
        onMouseLeave={this.mouseOut}
        primaryText={
          <div style={{ fontWeight: 400 }}>
            <span
              style={{
                display: 'inline-block',
                width: 140,
                fontWeight: 500,
                color: '#00738b',
              }}
            >
              {file.category.name}
            </span>
            {file.name.length >= MAX_FILE_NAME_LENGTH
              ? `${file.name.substr(0, MAX_FILE_NAME_LENGTH)}...`
              : `${file.name}`}
            <span style={{ color: '#bdbdbd' }}>.{file.extension}</span>
            <span style={{ float: 'right' }}>{dateFormatLongNonSecond(file.createdAt)}</span>
          </div>
        }
        rightIconButton={this.rightIconMenu(file.category.name === 'Rapor' && !unready)}
        hoverColor="#f5f5f5"
        onClick={this.handleOpenFile}
      >
        <Clear />
        <Dialog
          title="Dosya silinsin mi?"
          actions={this.deleteActions(file)}
          modal={false}
          open={this.state.openDelete}
          onRequestClose={this.handleDeleteClose}
        >
          <strong>
            <strong>{file.name}</strong>
            <br />
            <br />
            Bu dosyayı kalıcı olarak silmek istediğinize emin misiniz?
          </strong>
        </Dialog>

        <Dialog
          title="Belgeyi Güncelle"
          modal={false}
          contentStyle={{ width: 304 }}
          open={this.state.openUpdate}
        >
          <FileUpdateForm
            onSubmitSuccess={() => {
              this.handleUpdateClose();
              this.props.onSuccessUpdate();
            }}
            onRequestClose={this.handleUpdateClose}
            file={file}
            incidentId={this.props.incidentId}
          />
        </Dialog>
      </ListItem>
    );
  }
}

export default connect((state) => ({
  calculationFiles: state.appData[CALCULATION_FILES],
  isProgressbarOpen: state.runtime.isProgressbarOpen,
  files: state.appData[FILE_LIST],
}))(FileViewHolder);
