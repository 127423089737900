import React from 'react';
import { Provider, ReactReduxContext } from 'react-redux';
import { Dialog as MaterialDialog } from 'material-ui';

export default function Dialog({ children, ...props }) {
  return (
    <ReactReduxContext.Consumer>
      {(ctx) => (
        <MaterialDialog {...props}>
          <Provider store={ctx.store}>{children}</Provider>
        </MaterialDialog>
      )}
    </ReactReduxContext.Consumer>
  );
}
