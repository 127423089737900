/* eslint-disable prefer-destructuring */
import InjuredPartyCreateRequest from './InjuredPartyCreateRequest';
import PaymentCreateRequest from './PaymentCreateRequest';
import SpecialPeriodCreateRequest from './SpecialPeriodCreateRequest';
import ConfigCreateRequest from './ConfigCreateRequest';
import { APPROVED } from '../../ApprovalType';
import IncidentCreateRequest from './IncidentCreateRequest';

export default class DetailedIncidentCreateRequest {
  constructor(config) {
    if (typeof config !== 'object') return;

    const incidentType = config.incident.incidentType;
    this.incident = new IncidentCreateRequest(config.incident);
    this.isApprovalNeeded = config.isApprovalNeeded && config.isApprovalNeeded === APPROVED;
    this.injuredParty =
      config.injuredParty &&
      config.injuredParty.bindClass(InjuredPartyCreateRequest, {
        dateOfIssue: this.incident.dateOfIssue,
      });
    this.payments = config.payments ? config.payments.bindList(PaymentCreateRequest) : [];
    this.specialPeriods = config.specialPeriods
      ? config.specialPeriods.bindList(SpecialPeriodCreateRequest)
      : [];
    if (config.calculationConfigs) {
      config.calculationConfigs = config.calculationConfigs.map((p) => ({
        ...p,
        formType: config.formType,
        dateOfIssue: this.incident.dateOfIssue,
        incidentType,
      }));
    }
    this.calculationConfigs = config.calculationConfigs
      ? config.calculationConfigs.bindList(ConfigCreateRequest)
      : [];
  }
}
