/* eslint-disable react/prop-types,prefer-destructuring */
import React from 'react';
import { connect } from 'react-redux';
import { FlatButton, List, Paper, RaisedButton } from 'material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Delete from 'material-ui/svg-icons/action/delete';
import Edit from 'material-ui/svg-icons/editor/mode-edit';
import Copy from 'material-ui/svg-icons/content/content-copy';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import { DEATH, DISABLED } from '../../../data/model/IncidentType';
import p from '../../print.css';
import { PMF, TRH_PROGRESSIVE } from '../../../data/model/MortalityTable';
import FileList from '../../File/FileList';
import {
  CALCULATION_FILES,
  DOC_TYPE_REPORT,
  INIT_CALCULATION_CONFIG,
  LAST_VISITED_TAB_REPORT_DETAIL,
  SALARY_LIST,
  USER,
} from '../../../constants';
import { ACTUARY, CUSTOMER, WriteCalculation, WriteIncident } from '../../../data/model/Privilege';
import Authorization from '../../Authorization/Authorization';
import { toMoney } from '../../../utils/javascript-object-util';
import ColorPalette from '../../../ColorPalette';
import Clear from '../../Functional/Clear';
import { hidePB, showPB } from '../../../actions/progressbarAction';
import { clearAppData, setAppData } from '../../../actions/appdata';
import { openSnackbar } from '../../../actions/snackbarActions';
import FileUploadCompact from '../../FileUpload/FileUploadCompact';
import ReportTemplate from '../../../routes/reportGenerator/ReportTemplate';
import CalculationConfigApi from '../../../data/api/CalculationConfigApi';
import IncidentApi from '../../../data/api/IncidentApi';
import CalculationApi from '../../../data/api/CalculationApi';
import CalculationFileApi from '../../../data/api/CalculationFileApi';
import FileApi from '../../../data/api/FileApi';
import { MARK_AS_RESOLVED, SELF_PROCESSED } from '../../../data/model/ActionCategory';
import ActionApi from '../../../data/api/ActionApi';
import Dialog from '../../Functional/Dialog';

export function CalculationConfigInfo({ config, configs, detailedIncident, salaries }) {
  if (!config) return <div />;
  if (!detailedIncident) return <div />;

  const { incident } = detailedIncident;
  const {
    incidentType,
    isBeforeCircular2020: isBefore2020,
    isBeforeCircular2021: isBefore2021,
  } = incident;

  const hasDifferent = (key, value) => {
    if (!key) return false;
    if (configs) return configs.filter((c) => c[key] !== value).length > 0;
    return false;
  };

  const getValue = ({ key, value, type }) => {
    switch (type) {
      case Boolean: {
        return config[key] ? 'Evet' : 'Hayır';
      }
      default: {
        const prefix = key && (key.includes('Ratio') || key === 'interest') ? '%' : '';
        const text = key ? config[key] : value;
        return prefix + text;
      }
    }
  };

  const infoText = (text, valueObj, valueStyle = {}) => (
    <div className={'configInfoContainer'}>
      <span className={'configInfoLabel'}>{text}</span>
      <span>:</span>
      <span
        className={'configInfoText'}
        style={{
          fontWeight: hasDifferent(valueObj.key, config[valueObj.key]) ? '500' : '300',
          ...valueStyle,
        }}
      >
        {getValue(valueObj)}
      </span>
    </div>
  );

  return (
    <div
      style={{
        fontWeight: 400,
      }}
    >
      {infoText('Ölüm Tablosu', { key: 'getMortalityTable' }, { fontSize: 14 })}
      {infoText(config.isTechnicalInterest ? 'Teknik Faiz' : 'İskonto Faizi', {
        key: 'interest',
      })}
      {isBefore2020 &&
        incidentType === DEATH &&
        infoText('Pay Dağıtım Tipi', { key: 'getShareRate' })}
      {config.mortalityTable !== PMF &&
        config.mortalityTable !== TRH_PROGRESSIVE &&
        infoText('Hesaplama Tipi', { key: 'getLifeAnnuity' })}
      {infoText('Kusur Oranı', { key: 'faultRatio' })}
      {incidentType === DISABLED &&
        infoText(<span style={{ fontSize: '15px' }}>S.Sakatlık Oranı</span>, {
          key: 'disabilityRatio',
        })}
      {infoText('Tam Hayat', {
          key: 'useTerminalAge',
          type: Boolean,
        })}
      {infoText(
        <div style={{ position: 'relative', width: '100%' }}>
          <span style={{ fontSize: '14px' }}>Çalışma Yaşını Kaldır</span>
          <div
            className={`tooltipv2 questionMark`}
            style={{
              top: 1,
              right: -5,
            }}
          >
            <FontAwesomeIcon fixedWidth icon="question-circle" />
            <div className={'tooltipTextv2'}>
              <b>Evet Durumunda:</b> Çocuk Mağdur/Müteveffa çalışmaya başlama yaşı yerine kaza
              tarihinden itibaren Asgari eksi Agi üzerinden destek verir olarak kabul edilir. <br />
              <b>Hayır Durumunda:</b> Çalışmaya başlama yaşına kadar (Bordro/Sgk hizmet dökümü veya
              GİG yoksa) destek veremez olarak kabul edilir.
            </div>
          </div>
        </div>,
        {
          key: 'usePassiveEarningUntilAgeOfConsent',
          type: Boolean,
        },
      )}

      {!isBefore2021 &&
        infoText(
          <div style={{ position: 'relative', width: '100%' }}>
            <span style={{ fontSize: '14px' }}>Hane Halkı Kullan</span>
            <div
              className={`tooltipv2 questionMark`}
              style={{
                top: 1,
                right: -5,
              }}
            >
              <FontAwesomeIcon fixedWidth icon="question-circle" />
              <div className={'tooltipTextv2'}>
                <b>İşaretli Durumda:</b> Pay dağılımında Hane Halkı kavramını kullanır.
                <br />
                <b>İşaretsiz Durumda:</b> Pay dağılımında Hane Halkı kavramını
                <b> kaldırır.</b>
              </div>
            </div>
          </div>,
          {
            key: 'useHouseholdPriorityShare',
            type: Boolean,
          },
        )}

      {incidentType === DISABLED &&
        infoText(<span style={{ fontSize: '16px' }}>Bakıcı Hesaplaması</span>, {
          key: 'getNurseCategory',
        })}
      {incidentType === DISABLED &&
        infoText('Bakıcı Belgeli', { key: 'isNurseDocumented', type: Boolean })}
      {incidentType === DISABLED &&
        infoText(<span style={{ fontSize: '15px' }}>GİG Hesaplanmasın</span>, {
          key: 'skipTemporaryIncapacity',
          type: Boolean,
        })}
      {!isEmpty(salaries) &&
        infoText(
          <div style={{ position: 'relative', width: '100%' }}>
            Asgari Ücret
            <div
              className={`tooltipv2 questionMark`}
              style={{
                top: 1,
                right: -5,
              }}
            >
              <FontAwesomeIcon fixedWidth icon="question-circle" />
              <div className={'tooltipTextv2'}>
                Maaş bilgisi olmasına rağmen asgari ücretle hesaplama yapılır.
              </div>
            </div>
          </div>,
          {
            key: 'useMinimumWage',
            type: Boolean,
          },
        )}
      {infoText('Poliçe Limitsiz', { key: 'dontLimitCompensation', type: Boolean })}
      {infoText('Agi Uygulamasız', { key: 'withoutAllowance', type: Boolean })}
    </div>
  );
}

class CalculationConfigViewHolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detailedConfig: null,
      files: null,
      openUpdate: false,
      openDelete: false,
      hover: false,
    };
  }

  calculate = async () => {
    const { detailedIncident, user, dispatch, salaries } = this.props;
    const { detailedConfig } = this.state;
    const config = detailedConfig.config;
    const id = config.id;
    const incidentId = detailedIncident.incident.id;
    showPB(dispatch, 'Rapor Hesaplanıyor');
    CalculationConfigApi.calculate(id).then(
      () => {
        Promise.all([
          IncidentApi.find(dispatch, incidentId),
          CalculationConfigApi.find(dispatch, id),
          CalculationApi.find(dispatch, id),
        ]).then(
          async ([newDetailedIncident, newDetailedConfig, calculation]) => {
            this.setState({ detailedConfig: newDetailedConfig });
            const template = new ReportTemplate(
              newDetailedIncident,
              newDetailedConfig,
              calculation,
              salaries,
              user,
            );
            const blob = await template.buildReport();
            const fileName = template.fileNameBuilder();

            blob.getBlob((data) => {
              const file = new File([data], fileName, {
                type: 'file/pdf',
              });
              if (includes(user.getRoles, CUSTOMER)) {
                this.handleReportFileUpload(file);
                ActionApi.list(dispatch, incidentId);
              } else
                CalculationFileApi.create([file], calculation.id).then(
                  () => {
                    dispatch(clearAppData(CALCULATION_FILES));
                    CalculationFileApi.list(dispatch, calculation.id).then((r) => {
                      if (r) this.setState({ files: r });
                    });
                    hidePB(dispatch);
                    dispatch(openSnackbar('Hesaplama Tamamlandı'));
                  },
                  () => {
                    hidePB(dispatch);
                    dispatch(openSnackbar('Rapor Çıktısı oluşturulamadı'));
                  },
                );
            });
          },
          () => {
            hidePB(dispatch);
            dispatch(openSnackbar('Hesaplama Bilgilerine erişilemiyor.'));
          },
        );
      },
      () => {
        hidePB(dispatch);
        dispatch(openSnackbar('Rapor Hesaplanamadı, Rapor bilgilerini kontrol edin.'));
      },
    );
  };

  deleteCalculation = () => {
    const { dispatch } = this.props;
    const { detailedConfig } = this.state;
    const { calculation } = detailedConfig;
    showPB(dispatch, 'Hesaplama temizleniyor');
    CalculationApi.delete(calculation.id).then(
      () => {
        this.calculate();
      },
      () => {
        hidePB(dispatch);
        dispatch(openSnackbar('Hesaplama temizlenemedi'));
      },
    );
  };

  handleDeleteFile = (id) => {
    const { files } = this.state;
    this.setState({
      files: files ? files.filter((f) => f.id !== id) : [],
    });
  };

  handleReportFileUpload = async (obj) => {
    const { detailedIncident, dispatch } = this.props;
    const incident = detailedIncident.incident;
    const incidentId = incident.id;
    showPB(dispatch, `${obj.name} Yükleniyor...`);
    FileApi.create([obj], incidentId, DOC_TYPE_REPORT).then(
      () => {
        FileApi.list(dispatch, incidentId);
        hidePB(dispatch);
        dispatch(openSnackbar('Rapor Oluşturuldu'));
        dispatch(
          setAppData({
            name: LAST_VISITED_TAB_REPORT_DETAIL,
            value: 'details',
          }),
        );
      },
      () => {
        hidePB(dispatch);
        dispatch(openSnackbar('Rapor Oluşturma Sırasında Hata'));
      },
    );
  };

  handleAttachReady = async (obj) => {
    // Todo test et
    const { detailedIncident, dispatch, user } = this.props;
    const { detailedConfig } = this.state;
    const { calculation } = detailedConfig;
    const incidentId = detailedIncident.incident.id;
    const template = new ReportTemplate(detailedIncident, detailedConfig, calculation, user);
    const blob = await template.buildReport([obj]);
    const fileName = template.fileNameBuilder('Ek3');
    showPB(dispatch, `${fileName} Yükleniyor...`);
    blob.getBlob((data) => {
      const file = new File([data], fileName, {
        type: 'file/pdf',
      });
      CalculationFileApi.create([file], incidentId, calculation.id).then(
        () => {
          CalculationFileApi.list(dispatch, incidentId, calculation.id).then((r) => {
            if (r) this.setState({ files: r });
          });
          hidePB(dispatch);
          dispatch(openSnackbar('Dosya Oluşturuldu'));
        },
        () => {
          hidePB(dispatch);
          dispatch(openSnackbar('Dosya Oluşturma Sırasında Hata'));
        },
      );
    });
  };

  handleFileUpload = (file) => {
    const { dispatch } = this.props;
    const { detailedConfig } = this.state;
    const { calculation } = detailedConfig;
    showPB(dispatch, `${file.name} Yükleniyor...`);
    CalculationFileApi.create([file], calculation.id).then(
      () => {
        CalculationFileApi.list(dispatch, calculation.id).then((r) => {
          if (r) this.setState({ files: r });
        });
        hidePB(dispatch);
        dispatch(openSnackbar('Dosya Yükleme Tamamlandı'));
      },
      () => {
        hidePB(dispatch);
        dispatch(openSnackbar('Dosya Yüklenirken Bir Hata Oluştu'));
      },
    );
  };

  deleteConfig = () => {
    const { dispatch, detailedIncident } = this.props;
    const { detailedConfig } = this.state;
    const { calculation, config } = detailedConfig;

    const id = config.id;
    showPB(dispatch, 'Hsaplama ayarı siliniyor...');
    const success = [
      () => {
        IncidentApi.find(dispatch, detailedIncident.incident.id);
        this.handleDeleteCancel();
        dispatch(openSnackbar('Hesaplama ayarı silindi.'));
        hidePB(dispatch);
      },
      () => {
        dispatch(openSnackbar('Hesaplama ayarı silme sırasında hata oluştu.'));
        hidePB(dispatch);
      },
    ];
    if (calculation) {
      CalculationApi.delete(calculation.id)
        .then(() => CalculationConfigApi.delete(id))
        .then(...success);
    } else {
      CalculationConfigApi.delete(id).then(...success);
    }
  };

  pushUpdatePage = () => {
    this.setState({ openUpdate: false });
    const { dispatch } = this.props;
    const { detailedConfig } = this.state;
    const { config } = detailedConfig;
    dispatch(setAppData({ name: INIT_CALCULATION_CONFIG, value: config }));
    this.props.history.push(`/calculation-configs/${config.id}/update`);
  };

  handleUpdateCancel = () => this.setState({ openUpdate: false });

  handleDeleteCancel = () => this.setState({ openDelete: false });

  deleteActions = [
    <FlatButton label="İptal" onClick={this.handleDeleteCancel} />,
    <FlatButton label="Sil" secondary onClick={this.deleteConfig} />,
  ];

  handleUpdateApprove = () => {
    const { dispatch } = this.props;
    const { detailedConfig } = this.state;
    CalculationApi.delete(detailedConfig.calculation.id).then(
      () => this.pushUpdatePage(),
      () => dispatch(openSnackbar('Hesaplama silinemedi')),
    );
  };

  updateActions = [
    <FlatButton label="İptal" onClick={this.handleUpdateCancel} />,
    <FlatButton label="Onayla" secondary onClick={this.handleUpdateApprove} />,
  ];

  getCalculationDetail = () => {
    const { config } = this.props;
    CalculationConfigApi.find(null, config.id)
      .then((r) => {
        this.setState({ detailedConfig: r });
        if (r.calculation && this.props.user.isAuthorized([ACTUARY]))
          return CalculationFileApi.list(null, r.calculation.id);
      })
      .then((r) => {
        if (r) this.setState({ files: r });
      });
  };

  componentDidMount() {
    this.getCalculationDetail();
  }

  handleCopyConfig() {
    const { dispatch, detailedIncident } = this.props;
    const { detailedConfig } = this.state;
    const { config } = detailedConfig;
    dispatch(setAppData({ name: INIT_CALCULATION_CONFIG, value: config }));
    this.props.history.push(`/reports/${detailedIncident.incident.id}/calculation-configs/create`);
  }

  mouseOver = () => {
    this.setState({ hover: true });
  };

  mouseOut = () => {
    this.setState({ hover: false });
  };

  render() {
    const { config, configs, detailedIncident } = this.props;
    const { hover, files, detailedConfig } = this.state;
    const hasCalculation = detailedConfig && detailedConfig.calculation;
    const latestAction = detailedIncident.latestAction;
    const operationButtonStyle = {
      float: 'right',
      minWidth: 38,
      maxWidth: 38,
    };
    const calculateButton = (
      <RaisedButton
        className={`${p.hide} ${'upsertButton'}`}
        primary
        onClick={() => {
          detailedConfig.calculation ? this.deleteCalculation() : this.calculate();
        }}
        label="Hesapla"
      />
    );
    return (
      <Paper
        className={`${'configViewHolder'}`}
        style={{ position: 'relative' }}
        onMouseEnter={this.mouseOver}
        onMouseLeave={this.mouseOut}
      >
        <div
          style={{
            padding: 16,
            position: 'relative',
            display: 'inline-block',
            width: '100%',
            boxSizing: 'border-box',
          }}
          className={p.configInfosViewHolder}
        >
          <CalculationConfigInfo
            config={config}
            configs={configs}
            salaries={this.props.salaries}
            detailedIncident={detailedIncident}
          />
          <div
            style={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              width: '38px',
              borderBottomLeftRadius: '2px',
              zIndex: '4',
              background: 'rgb(250, 250, 250)',
              boxShadow: 'rgb(193, 193, 193) 1px 1px 8px -1px',
              visibility: hover ? 'visible' : 'hidden',
              transition: 'opacity 300ms, visibility 300ms',
              opacity: hover ? 1 : 0,
            }}
          >
            <Authorization allowedRoles={[WriteIncident]} and>
              <FlatButton
                style={{
                  float: 'right',
                  minWidth: 38,
                  maxWidth: 38,
                }}
                onClick={() => {
                  this.handleCopyConfig();
                }}
              >
                <Copy />
              </FlatButton>
            </Authorization>
            {hasCalculation ? (
              <Authorization allowedRoles={[WriteCalculation]}>
                <FlatButton
                  style={operationButtonStyle}
                  tooltip="Güncelle"
                  tooltipposition="top-right"
                  onClick={() => this.setState({ openUpdate: true })}
                >
                  <Edit />
                </FlatButton>
              </Authorization>
            ) : (
              <Authorization allowedRoles={[WriteIncident]}>
                <FlatButton
                  style={operationButtonStyle}
                  tooltip="Güncelle"
                  tooltipposition="top-right"
                  onClick={this.pushUpdatePage}
                >
                  <Edit />
                </FlatButton>
              </Authorization>
            )}
            <Authorization allowedRoles={[ACTUARY, WriteCalculation]} and>
              <FlatButton
                style={operationButtonStyle}
                secondary
                hoverColor="#FFDDDD"
                rippleColor="#FFAAAA"
                onClick={() => this.setState({ openDelete: true })}
                icon={<Delete />}
              />
            </Authorization>
          </div>
          {hasCalculation && (
            <div
              style={{
                bottom: 0,
                right: 0,
                position: 'absolute',
                padding: '4px 8px',
                display: 'inline-block',
                borderRadius: '8px 0px 0px',
              }}
            >
              <span
                style={{
                  color: ColorPalette.primary1Color,
                  fontWeight: 400,
                }}
              >
                Tazminat Tutarı :
              </span>
              <span
                style={{
                  minWidth: '100px',
                  display: 'inline-block',
                  textAlign: 'right',
                  fontWeight: 500,
                }}
              >
                {toMoney(detailedConfig.calculation.compensationAmount)}
              </span>
            </div>
          )}
          <div>
            <Authorization allowedRoles={[ACTUARY]} and>
              {calculateButton}
            </Authorization>
            <Authorization allowedRoles={[CUSTOMER, WriteCalculation]} and>
              {latestAction.category === SELF_PROCESSED ||
              latestAction.category === MARK_AS_RESOLVED ? (
                calculateButton
              ) : (
                <div className={'warnInfo'} style={{ marginTop: 24, marginBottom: 16 }}>
                  Rapor <b>{latestAction.getCategory}</b> durumundadır. Çalışma aktüer kontrolünde
                  sürdürülüyor.
                </div>
              )}
            </Authorization>
          </div>
        </div>
        <Clear />
        {files && files.length > 0 && (
          <Authorization allowedRoles={[ACTUARY]}>
            <List
              style={{
                padding: '8px 16px',
                borderTop: '1px solid gainsboro',
                background: '#FAFAFA',
                paddingBottom: 42,
              }}
            >
              <FileList
                data={files}
                unready
                readOnly={false}
                hideWarn
                onDelete={this.handleDeleteFile}
                calculationId={
                  detailedConfig && detailedConfig.calculation && detailedConfig.calculation.id
                }
              />
              <FileUploadCompact
                style={{
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'inline-block',
                  borderTop: '1px solid #e0e0e0',
                  borderLeft: '1px solid #e0e0e0',
                  borderRadius: '4px 0 0px',
                  background: 'rgba(244, 248, 244, 1)',
                }}
                submitUpload={this.handleFileUpload}
              />
            </List>
          </Authorization>
        )}

        <Dialog
          title={<span className={'warnInfo'}>Güncelleme Uyarısı</span>}
          actions={this.updateActions}
          modal
          open={this.state.openUpdate}
        >
          <b>
            <p>Güncelleme yapabilmek için hesaplamanın silinmesi gerekiyor. Onaylıyor musun ?</p>
            <p>
              <strong>Bu işlem yeniden hesaplama yapmanızı engellemez.</strong>
            </p>
          </b>
        </Dialog>
        <Dialog
          title={<span className={'errorInfo'}>Silme Uyarısı</span>}
          actions={this.deleteActions}
          modal
          open={this.state.openDelete}
        >
          <b>
            <p>Bu ayarlamayı silmek istediğinize emin misiniz?</p>
            <Authorization allowedRoles={[ACTUARY]}>
              <p>
                <FontAwesomeIcon icon="exclamation" fixedWidth />
                İşlem sonucunda bu hesaplama ile üretilen <strong>onaylanmamış rapor</strong>{' '}
                (varsa) silinir.
              </p>
            </Authorization>
          </b>
        </Dialog>
      </Paper>
    );
  }
}

export default connect((state) => ({
  isProgressbarOpen: state.runtime.isProgressbarOpen,
  salaries: state.appData[SALARY_LIST],
  user: state.appData[USER],
}))(CalculationConfigViewHolder);
