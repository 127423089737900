/* eslint-disable no-plusplus,react/react-in-jsx-scope */
import moment from 'moment/moment';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { DEATH } from '../data/model/IncidentType';
import { toMoment } from './javascript-object-util';

export const REQUIRED = 'Zorunlu Alan';

export function validateHttpsUrl(value, required = true) {
  if (!value) return required ? 'Zorunlu Alan' : null;

  if (!/^(https:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:?#[\]@!\$&'\*\+,;=.`\/\\]+$/.test(value)) {
    return 'Geçersiz url ( https olmalı )';
  }
  return null;
}

export function validateAmount(value, required = true) {
  if (!value) return required ? 'Zorunlu Alan' : null;

  if (isNaN(Number(value))) return 'Sayılardan Oluşmalı';
  if (!/^(?=[^.])\d{0,13}(?:\.\d{0,2})?$/g.test(value)) return 'Geçersiz değer';
  if (value < 0) return 'Sayı Negatif Olamaz';
  return null;
}

export function validatePercentage(value, required = true) {
  if (!value) return required ? 'Zorunlu Alan' : null;

  if (isNaN(Number(value))) return 'Sayılardan Oluşmalı';
  if (!/^(?=[^.])\d{0,3}(?:\.\d{0,2})?$/g.test(value)) return 'Geçersiz değer';

  const num = Number(value);
  if (num > 100 || num < 0) return '0-100 aralığında olmalıdır.';
  return null;
}

export function validateTCNo(value, required = true) {
  if (!value) return required ? 'Zorunlu Alan' : null;

  if (isNaN(Number(value))) return 'Sayılardan Oluşmalı';
  if (value.length !== 11) return '11 Haneli olmalı';
  return null;
}

export function validateDate(value, required = true) {
  if (!value) return required ? 'Zorunlu Alan' : null;

  if (!moment(value, 'DD.MM.YYYY').isValid()) return 'Geçersiz Tarih';
  return null;
}

export function validateFiles(values) {
  let errors = {};
  if (values.files && values.files.length !== 0) {
    const filesArrayErrors = [];
    values.files.forEach((file, fileIndex) => {
      const fileErrors = {};
      if (!file || !file.category) {
        fileErrors.category = 'Belge Tipi Seçilmedi';
        filesArrayErrors[fileIndex] = fileErrors;
      }
    });
    if (filesArrayErrors.length) {
      errors = filesArrayErrors;
    }
  }
  return errors;
}

function validatePerson(value = {}, requirement) {
  const errors = {};
  const { reqDateOfBirth, reqTc, reqFullName, reqGender } = requirement;
  errors.dateOfBirth = validateDate(value.dateOfBirth, reqDateOfBirth);
  errors.personalIdentificationNumber = validateTCNo(value.personalIdentificationNumber, reqTc);
  if (!value.fullName && reqFullName) errors.fullName = REQUIRED;
  if (!value.gender && reqGender) errors.gender = REQUIRED;
  return errors;
}

export function validateCasualty(value = {}) {
  const errors = { basic: {} };
  errors.dateOfDeath = validateDate(value.dateOfDeath);
  errors.basic = validatePerson(value.basic, {
    reqTc: false,
    reqFullName: true,
    reqDateOfBirth: true,
    reqGender: true,
  });
  return errors;
}

export function validateInjuredParty(values = {}, incidentType) {
  const errors = {};

  errors.casualty = validateCasualty(values.casualty);

  const basicRequirement =
    incidentType === DEATH
      ? {
          reqTc: false,
          reqFullName: true,
          reqDateOfBirth: true,
          reqGender: true,
        }
      : {
          reqTc: false,
          reqFullName: false,
          reqDateOfBirth: false,
          reqGender: true,
        };
  if (values && values.spouses && values.spouses.length !== 0) {
    const arrayErrors = [];
    values.spouses.forEach((person, index) => {
      const err = {};
      err.basic = validatePerson(person.basic, basicRequirement);
      err.dateOfMarriage = validateDate(person.dateOfMarriage, incidentType === DEATH);
      err.dateOfDivorce = validateDate(person.dateOfDivorce, false);
      err.alimonyAmount = validateAmount(person.alimonyAmount, false);
      arrayErrors[index] = err;
    });
    if (arrayErrors.length) {
      errors.spouses = arrayErrors;
    }
  }

  if (values && values.children && values.children.length !== 0) {
    const arrayErrors = [];
    values.children.forEach((person, index) => {
      const err = {};
      err.basic = validatePerson(person.basic, basicRequirement);
      err.dateOfMarriage = validateDate(person.dateOfMarriage, false);
      err.dateOfGraduation = validateDate(person.dateOfGraduation, false);
      err.dateOfEmployment = validateDate(person.dateOfEmployment, false);
      err.disability = validatePercentage(person.disability, false);
      arrayErrors[index] = err;
    });
    if (arrayErrors.length) {
      errors.children = arrayErrors;
    }
  }

  if (values && values.parents && values.parents.length !== 0) {
    const arrayErrors = [];
    values.parents.forEach((person, index) => {
      const err = {};
      err.basic = validatePerson(person.basic, {
        ...basicRequirement,
        reqDateOfBirth: true,
      });
      arrayErrors[index] = err;
    });
    if (arrayErrors.length) {
      errors.parents = arrayErrors;
    }
  }

  return errors;
}

export function validateSalaries(values = []) {
  const errors = [];
  for (let i = 0; i < values.length; i++) {
    if (!values[i]) break;
    const err = {};
    if (!values[i].salaryType) err.salaryType = REQUIRED;
    if (!values[i].year) err.year = REQUIRED;
    if (!values[i].month) err.month = REQUIRED;
    if (!values[i].workingDays) err.workingDays = REQUIRED;
    err.net = validateAmount(values[i].net);
    err.gross = validateAmount(values[i].gross);
    errors[i] = err;
  }
  return errors;
}

export function validateSpecialPeriods(values = []) {
  const errors = [];
  for (let i = 0; i < values.length; i++) {
    if (!values[i]) break;
    const err = {};
    err.from = validateDate(values[i].from);
    err.till = validateDate(values[i].till);
    if (!values[i].category) err.category = REQUIRED;
    errors[i] = err;
  }
  return errors;
}

export function validatePayments(values = []) {
  const errors = [];
  for (let i = 0; i < values.length; i++) {
    if (!values[i]) break;
    const err = {};
    err.date = validateDate(values[i].date);
    err.amount = validateAmount(values[i].amount);
    if (isEmpty(values[i].people)) err.people = REQUIRED;
    if (isEmpty(values[i].category)) err.category = REQUIRED;
    errors[i] = err;
  }
  return errors;
}

export function validateCalculationConfig(values = []) {
  const errors = [];
  for (let i = 0; i < values.length; i++) {
    if (!values[i]) break;
    const err = {};
    if (!values[i].mortalityTable) err.mortalityTable = REQUIRED;
    err.faultRatio = validatePercentage(values[i].faultRatio);
    err.disabilityRatio = validatePercentage(values[i].disabilityRatio);
    errors[i] = err;
  }
  return errors;
}

export function validateIncidentLogical(values, errors) {
  const _error = {
    logical: [],
  };
  const { logical } = _error;
  function FieldText({ text }) {
    return (
      <strong>
        <i>{text}</i>
      </strong>
    );
  }

  if (!errors.dateOfIssue && !errors.dateOfIncident) {
    if (toMoment(values.dateOfIncident).diff(toMoment(values.dateOfIssue), 'years', true) > 1)
      logical.push(
        <span>
          <FieldText text="Poliçe Tanzim Tarihi" /> ve <FieldText text="Kaza Tarihi" /> arasında{' '}
          <strong>1 yıldan</strong> fazla olamaz.
        </span>,
      );
    if (toMoment(values.dateOfIncident).isBefore(toMoment(values.dateOfIssue))) {
      logical.push(
        <span>
          <FieldText text="Kaza Tarihi" />, <FieldText text="Poliçe Tanzim Tarihi" />
          nden önce olamaz.
        </span>,
      );
    }
  }

  if (!errors.dateOfIssue && !errors.dateOfReport) {
    if (toMoment(values.dateOfReport).isBefore(toMoment(values.dateOfIssue))) {
      logical.push(
        <span>
          <FieldText text="Rapor Tarihi" />, <FieldText text="Poliçe Tanzim Tarihi" />
          nden önce olamaz.
        </span>,
      );
    }
  }
  if (!errors.dateOfIncident && !errors.dateOfReport) {
    if (toMoment(values.dateOfReport).isBefore(toMoment(values.dateOfIncident))) {
      logical.push(
        <span>
          <FieldText text="Rapor Tarihi" />, <FieldText text="Kaza Tarihi" />
          nden önce olamaz.
        </span>,
      );
    }
  }
  if (!errors.dateOfIncident && !errors.dateOfDeath) {
    if (toMoment(values.dateOfDeath).isBefore(toMoment(values.dateOfIncident))) {
      logical.push(
        <span>
          <FieldText text="Kaza Tarihi" />, <FieldText text="Ölüm Tarihi" />
          nden önce olamaz.
        </span>,
      );
    }
  }
  if (!errors.dateOfReport && !errors.dateOfDeath) {
    if (toMoment(values.dateOfReport).isBefore(toMoment(values.dateOfDeath))) {
      logical.push(
        <span>
          <FieldText text="Ölüm Tarihi" />, <FieldText text="Rapor Tarihi" />
          nden önce olamaz.
        </span>,
      );
    }
  }

  return isEmpty(_error) ? undefined : _error;
}
