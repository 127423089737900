import React from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  RaisedButton,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui';
import { useHistory } from 'react-router';
import SalaryType, {
  SALARY_TYPE_GROSS,
  SALARY_TYPE_MINIMUM_WAGE,
  SALARY_TYPE_NET,
} from '../../../data/model/SalaryType';
import { toMoney } from '../../../utils/javascript-object-util';
import { DETAILED_INCIDENT, SALARY_LIST } from '../../../constants';
import { WriteIncident } from '../../../data/model/Privilege';
import Authorization from '../../Authorization/Authorization';
import EmptyView from '../../Functional/EmptyView';

const SalaryViewHolder = ({ salary }) => {
  const { month } = salary;
  const { workingDays, year, net, gross } = salary;
  return (
    <TableRow>
      <TableRowColumn>
        {month.length === 1 ? `0${month}` : month}/{year}
      </TableRowColumn>
      <TableRowColumn>{workingDays}</TableRowColumn>
      <TableRowColumn>
        {net > 0 ? SalaryType[SALARY_TYPE_NET] : gross > 0 ? SalaryType[SALARY_TYPE_GROSS] : '-'}
      </TableRowColumn>
      <TableRowColumn>
        {net > 0 ? toMoney(net) : gross > 0 ? toMoney(gross) : SalaryType[SALARY_TYPE_MINIMUM_WAGE]}
      </TableRowColumn>
    </TableRow>
  );
};

function SalaryViewList({ salaries, detailedIncident }) {
  const history = useHistory();
  const handleOpenUpdatePage = () => {
    history.push(`/reports/${detailedIncident.incident.id}/salaries/update`);
  };

  return (
    <Paper>
      <div style={{ paddingBottom: 16, paddingTop: 1 }}>
        {salaries && salaries.length > 0 ? (
          <Table
            className={'incidentTables'}
            fixedHeader={false}
            fixedFooter={false}
            selectable={false}
          >
            <TableHeader adjustForCheckbox={false} displaySelectAll={false} enableSelectAll={false}>
              <TableRow>
                <TableHeaderColumn>Ay/Yıl</TableHeaderColumn>
                <TableHeaderColumn>Çalışılan Gün</TableHeaderColumn>
                <TableHeaderColumn>Maaş Tipi</TableHeaderColumn>
                <TableHeaderColumn>Maaş Miktarı</TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
              {salaries.map((p) => (
                <SalaryViewHolder key={p.id} salary={p} />
              ))}
            </TableBody>
          </Table>
        ) : (
          <EmptyView style={{ marginTop: 16 }} icon="money-bill-alt" label="Maaş Bilgisi Yok" />
        )}
        <Authorization allowedRoles={[WriteIncident]}>
          <RaisedButton
            className={`hide upsertButton`}
            onClick={handleOpenUpdatePage}
            label="Ekle / Güncelle"
            primary
          />
        </Authorization>
      </div>
    </Paper>
  );
}

export default connect((state) => ({
  salaries: state.appData[SALARY_LIST],
  detailedIncident: state.appData[DETAILED_INCIDENT],
}))(SalaryViewList);
