/* eslint-disable react/prop-types,prefer-destructuring,no-class-assign */
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { FlatButton, RaisedButton } from 'material-ui';
import { Field, reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Clear from '../Functional/Clear';
import { roundPrecision } from '../../utils/javascript-object-util';
import { renderDocumentType } from '../../utils/redux-metarial-ui-util';
import FileInput from '../Functional/FileInput';
import { hidePB, showPB } from '../../actions/progressbarAction';
import { openSnackbar } from '../../actions/snackbarActions';
import FileApi from '../../data/api/FileApi';
import { REQUIRED } from '../../utils/validation';
import { DOC_TYPE_ATTACH } from '../../constants';

class FileUploadForm extends React.Component {
  handleValueChange = (files) => {
    if (files && files.length >= 1) this.setState({ files });
  };

  onSubmit = (values, dispatch) => {
    const { incidentId } = this.props;
    const files = this.state.files;
    if (!files) {
      dispatch(openSnackbar('! Belge Seçilmedi'));
      return;
    }
    showPB(dispatch, `${files.length} Belge Yükleniyor...`);
    this.props.onRequestClose();

    FileApi.create(files, incidentId, values.category).then(
      () => {
        FileApi.list(dispatch, incidentId).then(() => {
          hidePB(dispatch);
          dispatch(openSnackbar('Dosyalar Yüklendi'));
        });
      },
      () => {
        hidePB(dispatch);
        dispatch(openSnackbar('Dosya Yükleme Sırasında Hata Oluştu'));
      },
    );
  };

  constructor(props) {
    super(props);
    this.state = {
      files: null,
    };
  }

  render() {
    const files = this.state.files;
    const fileInfos = [];
    if (files)
      for (let i = 0; i < files.length; i++) {
        fileInfos.push({ name: files[i].name, size: files[i].size });
      }
    const fileInputStyle = {
      cursor: 'pointer',
      position: 'absolute',
      top: '0',
      bottom: '0',
      right: '0',
      left: '0',
      width: '100%',
      opacity: '0',
      zIndex: 99,
    };

    return (
      <div>
        <div style={{ display: 'inline-block' }}>
          <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <Field name="category" label="Belge Kategorisi" component={renderDocumentType} />

            <RaisedButton style={{ margin: 'auto', display: 'table' }} primary label="Belge Seç">
              <FileInput
                style={fileInputStyle}
                multiple
                handleValueChange={this.handleValueChange}
              />
            </RaisedButton>
            <div className="preUploadInfoContainer">
              {files &&
                fileInfos.map((file) => (
                  <div className={`wrap preUploadInfo`}>
                    <FontAwesomeIcon
                      style={{
                        float: 'left',
                        marginTop: 4,
                      }}
                      icon="file"
                      fixedWidth
                    />
                    {`${file.name} (${roundPrecision(file.size / (1024 * 1024), 3)}mb)`}
                  </div>
                ))}
            </div>
            <div style={{ margin: 'auto', display: 'table', paddingTop: '16px' }}>
              <FlatButton label="İptal" onClick={this.props.onRequestClose} />
              <FlatButton label="Yükle" type="submit" primary />
            </div>
            <Clear />
          </form>
          <Clear />
        </div>
      </div>
    );
  }
}
const validate = (values) => {
  const errors = {};
  if (!values.category) errors.category = REQUIRED;
  return errors;
};
FileUploadForm = reduxForm({ form: 'fileUploadForm', validate })(FileUploadForm);
export default connect(() => {
  return {
    initialValues: { category: DOC_TYPE_ATTACH },
  };
})(FileUploadForm);
