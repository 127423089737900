import React from 'react';
import { Field, FormSection } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlatButton } from 'material-ui';
import {
  renderDayComboBox,
  renderMonthComboBox,
  renderSalaryType,
  renderTextField,
  renderYearComboBox,
} from '../../utils/redux-metarial-ui-util';
import Clear from '../Functional/Clear';
import { SALARY_TYPE_GROSS, SALARY_TYPE_NET } from '../../data/model/SalaryType';

class SalaryForm extends React.Component {
  render() {
    const { salary } = this.props;
    return (
      <FormSection name={this.props.name}>
        <div className={'basicReportFieldContainer'} style={{ width: 102 }}>
          <Field fullWidth component={renderMonthComboBox} name="month" label="Ay" />
        </div>
        <div className={'basicReportFieldContainer'} style={{ width: 102 }}>
          <Field fullWidth component={renderYearComboBox} name="year" label="Yıl" />
        </div>
        <div className={'basicReportFieldContainer'} style={{ width: 102 }}>
          <Field fullWidth component={renderDayComboBox} name="workingDays" label="Çalışılan Gün" />
        </div>

        <div className={'basicReportFieldContainer'}>
          <Field fullWidth component={renderSalaryType} name="salaryType" label="Gelir Tipi" />
        </div>
        {salary && salary.salaryType === SALARY_TYPE_NET && (
          <div className={'basicReportFieldContainer'}>
            <Field
              fullWidth
              component={renderTextField}
              name={SALARY_TYPE_NET}
              label="Net Maaş Tutarı"
            />
          </div>
        )}
        {salary && salary.salaryType === SALARY_TYPE_GROSS && (
          <div className={'basicReportFieldContainer'}>
            <Field
              fullWidth
              component={renderTextField}
              floatingLabelStyle={{ fontSize: '14px' }}
              name={SALARY_TYPE_GROSS}
              label="Brüt Maaş Tutarı"
            />
          </div>
        )}
        {salary && !salary.salaryType && (
          <span className={'smallInfo'} style={{ marginTop: 37 }}>
            Gelir Tipi Seçiniz.
          </span>
        )}
        <Clear />
        <div>
          <FlatButton
            style={{ position: 'absolute', minWidth: 36, top: 0, right: 0 }}
            className="material-icons"
            tooltip="Kaldır"
            icon={<FontAwesomeIcon fixedWidth icon="trash" />}
            onClick={this.props.remove}
          />
        </div>
      </FormSection>
    );
  }
}

export default SalaryForm;
