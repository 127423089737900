const bodyListMargin = [20, 5, 10, 5];
const bodyListStyle = { fontSize: 10 };

export const nurseHeader = {
  text: 'BAKIM GİDERİ DESTEK RAPORU',
  style: 'h0',
  alignment: 'center',
  margin: [0, 5, 0, 5],
};

export const nurseRegulationsHeaders = [
  {
    text: 'İLGİLİ MEVZUATLAR',
    margin: [0, 10, 0, 5],
    style: 'h2',
  },
  {
    ul: [
      'Borçlar Kanunu',
      'Sigortacılık Mevzuatı',
      'Karayolları Trafik Kanunu ve Karayolları Trafik Sigortası',
    ],
    style: { bold: true },
    margin: bodyListMargin,
  },
];

export const nurseRegulationsBodyList = [
  {
    text: 'SİGORTACILIK MEVZUATINA DAYALI FORMÜLLER VE HESAPLAMA USULLERİ',
    margin: [0, 10, 0, 5],
    style: 'h2',
  },
  {
    ol: [
      {
        text: `Esas ve gerçek anlamda hukuki yönden sakat kalan kişinin sağlığı zamanındaki kazancıyla sakat kalması neticesi uğradığı maddi zararın tazminini temin etmek BK 53. ve 54. Maddeleriyle sağlanmıştır.\nBK 53. madde “ölüm halindeki” zararlardan, BK 54. madde “sakatlanma halindeki” zararlardan söz eder. BK ilgili maddelerine istinaden, çalışma gücünün azalması ya da yitirilmesi nispetinde ödenecek tazminat tutarı hesaplanıp, müteakiben tarafınıza sunulmuştur.`,
        margin: [0, 0, 0, 5],
      },
      {
        text: `Motorlu aracın işletilmesi sırasında bir kimsenin ölümüne veya yaralanmasına veya bir şeyin zarara uğramasına sebebiyet vermiş olmasından dolayı, 2918 sayılı Karayolları Trafik Kanunu’na göre işletene düşen hukuki sorumluluk için tanzim edilen Karayolları Motorlu Araçlar Zorunlu Mali Sorumluluk (Trafik) sigorta poliçesi, bu zararı sigorta limitlerine kadar temin eder. Limit üzeri zarar mevcut ise işletenden tahsil edilir.`,
        margin: [0, 0, 0, 5],
      },
      {
        text: `Yürürlükteki emeklilik mevzuatına paralel olarak kişinin emeklilik yaşı üstü yaşama dönemi pasif dönem (emekli) olarak değerlendirilmiştir.`,
        margin: [0, 0, 0, 5],
      },
      {
        text: `Yargıtay 21 Hukuk Dairesi’nin E.2010/11117,K.2011/2009 ve 08.03.2011 tarihli kararında “Açıklanan zarar ve tazminatın hesaplanması yönteminde, işçinin yaşlılık aylığı alması veya işçinin yaşı ve işçide oluşan meslekte kazanma gücü kayıp oranına göre ileride yaşlılık aylığına hak kazanması üstün olasılık içinde bulunsa dahi pasif dönemin zarar hesabına dahil edilmesi gerekir. Sigortalıya bağlanan yaşlılık aylığında meslek hastalığı ve iş kazası kolundan alınan primlerin hiçbir etkisi bulunmamakta tamamen uzun vadedeki sigorta kollarından ödenen primler sonucu aylık bağlanmaktadır.\nPasif dönemde herhangi bir işte çalışılmasa bile, ekonomik bir değer taşıyan salt yaşamsal faaliyetlerinin sürdürülmesi sebebiyle emsallerine göre fazla efor sarf edeceği ve bu durumun sigortalı bakımından zarar oluşturacağı açıktır. Bu sebeple pasif devre zararının da hesaplanması ve bu hesaplamanın fiili bir çalışmanın olmadığı gözetilerek asgari geçim indirimi nazara alınmaksızın belirlenecek asgari ücret esas alınarak yapılması gerekir.`,
        margin: [0, 0, 0, 5],
      },
    ],
    margin: bodyListMargin,
    style: bodyListStyle,
  },
];
const toText = (t, a = 'center') => ({
  text: t,
  alignment: a,
});
export const correlationTable = [
  {
    headlineLevel: 1,
    stack: [
      {
        text:
          '14.05.2015 tarihli ve 29355 Sayılı Resmi Gazete’de yayımlanan Motorlu Araçları Zorunlu Mali Sorumluluk Sigortası Genel Şartları Ek 3 de belirtilen “Destekten Yoksun Kalma Sürekli sakatlık Tazminatı Hesaplanması” 6.  Maddesi uyarınca maluliyet oranının denk geldiği ve aşağıda tarafınıza sunulan aralıktaki katsayı nispetinde bakıcı gideri hesaplanmıştır. ',
        style: { bold: true, italics: true, fontSize: 12 },
        margin: [20, 10, 0, 0],
      },
      {
        margin: [170, 5, 10, 10],
        style: { fontSize: 10 },
        table: {
          body: [
            [
              toText('Maluliyet Oranı\nAralığı\n(%)'),
              toText('Asgari Ücrete\nUygulanacak Nispet\n(%)'),
            ],
            [toText('0-69'), toText('0')],
            [toText('70-79'), toText('50')],
            [toText('80-89'), toText('75')],
            [toText('90-100'), toText('100')],
          ],
        },
      },
    ],
  },
];
