import React from 'react';
import { connect } from 'react-redux';
import { Field, FormSection, formValueSelector, reduxForm } from 'redux-form';
import { Paper, Subheader } from 'material-ui';
import moment from 'moment/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEmpty from 'lodash/isEmpty';
import { DEATH } from '../../data/model/IncidentType';
import {
  renderCheckbox,
  renderCustomerAutoComplete,
  renderIncidentTypes,
  renderTag,
  renderTextField,
} from '../../utils/redux-metarial-ui-util';
import { normalizeDate } from '../../utils/redux-normalize';
import { paperStyle } from '../../utils/material-ui-style-util';
import Clear from '../../components/Functional/Clear';
import { clean, dateFormat } from '../../utils/javascript-object-util';
import OptionalButton from '../../components/Functional/OptionalButton';
import Authorization from '../../components/Authorization/Authorization';
import { ACTUARY } from '../../data/model/Privilege';
import IncidentApi from '../../data/api/IncidentApi';
import Link from '../../components/Link/Link';

class IncidentForm extends React.Component {
  handleQuickEditDateOfDeath = () => {
    this.props.dispatch(this.props.change(`incident.dateOfDeath`, this.props.dateOfIncident));
  };

  handleQuickEditDateOfReport = () => {
    this.props.dispatch(this.props.change(`incident.dateOfReport`, dateFormat(moment())));
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    const { error, incidentType, update, dateOfIssue } = this.props;
    const { open } = this.state;
    return (
      <Paper style={{ ...paperStyle, paddingBottom: 16 }}>
        <Subheader className={`left basicReportSubheader`}>Dosya Bilgileri</Subheader>
        <FormSection name="incident">
          <Clear />
          {!update && (
            <Authorization allowedRoles={[ACTUARY]}>
              <div className={`basicReportFieldContainer`}>
                <Field
                  fullWidth
                  component={renderCustomerAutoComplete}
                  name="companyId"
                  title="Sigorta Şirketi"
                />
              </div>
            </Authorization>
          )}
          <div className={`basicReportFieldContainer`}>
            <Field
              fullWidth
              component={renderIncidentTypes}
              name="incidentType"
              title="Kaza Türü"
            />
          </div>
          {update && (
            <div className={`basicReportFieldContainer`}>
              <Field
                fullWidth
                floatingLabelFixed
                component={renderTag}
                name="tagId"
                title="Departman"
              />
            </div>
          )}
          <div className={'basicReportFieldContainer'}>
            <Field
              fullWidth
              component={renderTextField}
              floatingLabelStyle={{ fontSize: '14px' }}
              normalize={normalizeDate}
              name="dateOfIssue"
              label="Poliçe Tanzim Tarihi"
            />
          </div>
          <div className={'basicReportFieldContainer'}>
            <Field
              fullWidth
              component={renderTextField}
              normalize={normalizeDate}
              name="dateOfIncident"
              label="Kaza Tarihi"
            />
          </div>
          <div className={'basicReportFieldContainer'} style={{ position: 'relative' }}>
            <div className={`tooltipv2 questionMark`}>
              <FontAwesomeIcon fixedWidth icon="question-circle" />
              <div className={'tooltipTextv2'}>
                Hesaplamanın yapıldığı günü ifade eder. <br /> Genellikle <i>Bugün</i> değeri
                kullanılır.
              </div>
            </div>
            <Field
              fullWidth
              component={renderTextField}
              normalize={normalizeDate}
              name="dateOfReport"
              label="Rapor Tarihi"
            />
            <OptionalButton onClick={() => this.handleQuickEditDateOfReport()} text="Bugün" />
          </div>
          {incidentType === DEATH && (
            <div className={'basicReportFieldContainer'} style={{ position: 'relative' }}>
              <Field
                fullWidth
                component={renderTextField}
                normalize={normalizeDate}
                name="dateOfDeath"
                label="Vefat Tarihi"
              />
              {this.props.dateOfIncident && (
                <OptionalButton
                  onClick={() => this.handleQuickEditDateOfDeath()}
                  text="Kaza Tarihi"
                />
              )}
            </div>
          )}
          <div className={'basicReportFieldContainer'}>
            <Field
              fullWidth
              component={renderTextField}
              floatingLabelStyle={{ fontSize: '14px' }}
              name="damageFileIdentifier"
              label="Hasar Dosya Numarası"
            />
          </div>
          <div
            className={'optionalFieldContainer'}
            style={{
              visibility: open ? 'visible' : 'hidden',
              opacity: open ? 1 : 0,
              maxHeight: open ? 300 : 0,
              marginTop: open ? 24 : 0,
              paddingTop: open ? 16 : 0,
            }}
          >
            <span className={'optionalFieldHeader'}>Detaylı Bilgiler</span>
            <div className={'basicReportFieldContainer'}>
              <Field
                fullWidth
                component={renderTextField}
                floatingLabelStyle={{ fontSize: '14px' }}
                name="courtFileIdentifier"
                label="Hukuk Dosya Numarası"
                hintText="Opsiyonel"
              />
            </div>
            <div className={'basicReportFieldContainer'}>
              <Field
                fullWidth
                component={renderTextField}
                floatingLabelStyle={{ fontSize: '14px' }}
                name="arbitrationFileIdentifier"
                label="Tahkim Dosya Numarası"
                hintText="Opsiyonel"
              />
            </div>
            <div className={'basicReportFieldContainer'}>
              <Field
                fullWidth
                component={renderTextField}
                normalize={normalizeDate}
                name="dateOfRetirement"
                label="Emeklilik Tarihi"
                hintText="Opsiyonel"
              />
            </div>
            <div className={'basicReportFieldContainer'} style={{ position: 'relative' }}>
              <div className={`tooltipv2 questionMark`}>
                <FontAwesomeIcon fixedWidth icon="question-circle" />
                <div className={'tooltipTextv2'}>
                  Dosyanın İş kazası olduğunu belirtir. Aktüer Onaylı olarak oluşturulursa ek olarak
                  PSD hesabı yapılır.
                </div>
              </div>
              <Field
                style={{ marginTop: 38 }}
                fullWidth
                labelStyle={{
                  lineHeight: '16px',
                  marginTop: '1px',
                  width: '100%',
                  color: '#7f7f7f',
                  fontWeight: '500',
                }}
                component={renderCheckbox}
                name="isWorkAccident"
                label="İş Kazası"
              />
            </div>
            <Clear />
          </div>
          <div className={'bottomRightLinkButton'} onClick={() => this.setState({ open: !open })}>
            <FontAwesomeIcon icon={open ? 'caret-up' : 'caret-down'} fixedWidth />
            Detayları&nbsp;
            {open ? 'Gizle' : 'Göster'}
          </div>
          <Clear />
          {error && (
            <div className={'formErrors'}>
              <ul>
                {[...(error.fileIdentifier || []), ...(error.logical || [])].map((e, i) => (
                  <li key={i}>
                    <FontAwesomeIcon fixedWidth icon="exclamation" />
                    {e}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </FormSection>
      </Paper>
    );
  }
}

const asyncValidate = (values, dispatch, events) => {
  const e = events;
  const { arbitrationFileIdentifier, courtFileIdentifier, damageFileIdentifier } = values.incident;

  return IncidentApi.lookup({
    arbitrationFileIdentifier,
    courtFileIdentifier,
    damageFileIdentifier,
  }).then((r) => {
    if (values.id !== r.incidentId && !isEmpty(r)) {
      throw {
        _error: {
          logical: e.error ? e.error.logical : [],
          fileIdentifier: [
            <span>
              Bu dosya daha önce oluşturulmuş&nbsp;
              <strong>
                <Link to={`/reports/${r.incidentId}`}>Dosyayı görüntüle</Link>
              </strong>
            </span>,
          ],
        },
      };
    } else {
      const _error = clean({
        logical: e.error ? e.error.logical : [],
      });
      if (!isEmpty(_error)) throw { _error };
    }
  });
};

IncidentForm = reduxForm({
  form: 'basicReport',
  asyncValidate,
  asyncBlurFields: [
    'incident.damageFileIdentifier',
    'incident.courtFileIdentifier',
    'incident.arbitrationFileIdentifier',
  ],
})(IncidentForm);

const selector = formValueSelector('basicReport');
export default connect((state) => ({
  incidentType: selector(state, 'incident.incidentType'),
  dateOfIncident: selector(state, 'incident.dateOfIncident'),
  dateOfReport: selector(state, 'incident.dateOfReport'),
  dateOfIssue: selector(state, 'incident.dateOfIssue'),
}))(IncidentForm);
