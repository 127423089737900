import React from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Paper, Subheader } from 'material-ui';
import { EVENTS, USER } from '../../constants';
import { hidePB, showPB } from '../../actions/progressbarAction';
import EventsApi from '../../data/api/EventsApi';
import EventViewHolder from './EventViewHolder';
import EmptyView from '../../components/Functional/EmptyView';
import Clear from '../../components/Functional/Clear';
import Pager from '../../components/Functional/Pager';

class EventList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  handlePageChange = (page) => {
    this.setState({ page });
    this.fetchData(this.props, page);
  };

  fetchData = (props, page) => {
    const { dispatch, user } = props;
    const companyId = user.getCompanyId;
    if (!companyId) return;
    showPB(dispatch);

    EventsApi.list(dispatch, companyId, page).then(
      () => {
        hidePB(dispatch);
      },
      () => {
        hidePB(dispatch);
      },
    );
  };

  componentDidMount() {
    this.fetchData(this.props);
  }

  render() {
    const { events } = this.props;
    return (
      <Paper style={{ marginTop: 32, display: 'grid', position: 'relative' }}>
        <Subheader>Event Listesi</Subheader>
        {isEmpty(events) ? (
          <EmptyView
            icon="mouse-pointer"
            style={{ marginTop: 64, marginBottom: 64 }}
            label="Henüz bir event gerçekleşmedi"
          />
        ) : (
          <div
            style={{
              padding: 16,
              paddingBottom: 64,
            }}
          >
            <EventViewHolder event={{}} title />
            {events.map((p, i) => (
              <EventViewHolder key={i} event={p} />
            ))}
          </div>
        )}
        <Clear />

        <Pager
          style={{ bottom: 0 }}
          currentPage={this.state.page}
          currentPageItemCount={events ? events.length : 0}
          onChange={this.handlePageChange}
        />
      </Paper>
    );
  }
}

export default connect((state) => ({
  user: state.appData[USER],
  events: state.appData[EVENTS],
}))(EventList);
