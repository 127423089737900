/* eslint-disable no-prototype-builtins,no-restricted-syntax,no-plusplus,prefer-destructuring,no-underscore-dangle */
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import sumBy from 'lodash/sumBy';
import find from 'lodash/find';
import CompensationByPerson from './CompensationByPerson';
import Period from './Period';
import CalculatorResult from './CalculatorResult';
import { convertToDate, toMoney } from '../../../utils/javascript-object-util';
import PolicyResponse from './PolicyResponse';
import { MILITARY_SERVICE, TEMPORARY_INCAPABILITY } from '../SpecialPeriodType';

export default class CalculationDetail {
  trimPeriods = (periods) => {
    const tempPeriods = JSON.parse(JSON.stringify(periods)).reverse();
    let index = 0;
    const length = tempPeriods.length;
    for (let i = 0; i < length; i++) {
      if (tempPeriods[i].amount === 0) {
        ++index;
      } else break;
    }
    return tempPeriods.slice(index, length).reverse();
  };

  constructor(config) {
    if (typeof config !== 'object') return;
    this.compensationByPerson = config.compensationByPerson
      ? config.compensationByPerson.bindList(CompensationByPerson)
      : [];
    this.periods = config.periods ? config.periods.bindList(Period) : [];
    this.trimmedPeriods = this.trimPeriods(this.periods);
    this.calculatorResults = config.calculatorResults
      ? config.calculatorResults.bindList(CalculatorResult)
      : [];
    this.policy = new PolicyResponse(config.policy);
  }

  get hasMilitaryService() {
    return (
      this.trimmedPeriods.flatMap((p) => p.calculations.filter((c) => c.name === MILITARY_SERVICE))
        .length > 0
    );
  }

  get getKnownCalculationPeriods() {
    return this.knownPeriods.activePeriods.concat(this.knownPeriods.passivePeriods);
  }

  get _getActivePeriodSalary() {
    const activePeriods = this.unknownPeriods.activePeriods;
    if (activePeriods.length > 0) {
      const firstNonZeroSalaryPeriod = activePeriods.filter((p) => p.salary !== 0)[0];
      return firstNonZeroSalaryPeriod ? firstNonZeroSalaryPeriod.salary : 0;
    }
    return 0;
  }

  get _getPassivePeriodSalary() {
    const passivePeriods = this.unknownPeriods.passivePeriods;
    return passivePeriods[0] ? passivePeriods[0].salary : 0;
  }

  getLastKnownSalary(dateOfReport) {
    const periods = this.periods.filter((p) =>
      moment(p.end, 'DD.MM.YYYY').isSame(moment(dateOfReport, 'DD.MM.YYYY')),
    );

    if (periods && periods.length > 0) {
      return periods[periods.length - 1].salary;
    }
    return 0;
  }

  get getActivePeriodSalary() {
    return toMoney(this._getActivePeriodSalary);
  }

  get getPassivePeriodSalary() {
    return toMoney(this._getPassivePeriodSalary);
  }

  get getActivePeriodAnnualSalary() {
    return toMoney(this._getActivePeriodSalary * 12);
  }

  get getPassivePeriodAnnualSalary() {
    return toMoney(this._getPassivePeriodSalary * 12);
  }

  get hasActive() {
    return this.unknownPeriods.activePeriods.length > 0;
  }

  get activePeriods() {
    return this.unknownPeriods.activePeriods;
  }

  get hasPassive() {
    return this.unknownPeriods.passivePeriods.length > 0;
  }

  get passivePeriods() {
    return this.unknownPeriods.passivePeriods;
  }

  get hasKnown() {
    return (
      this.knownPeriods.passivePeriods.length > 0 || this.knownPeriods.activePeriods.length > 0
    );
  }

  get getKnownPeriods() {
    return [...this.knownPeriods.activePeriods, ...this.knownPeriods.passivePeriods];
  }

  get hasTemporaryIncapability() {
    return this.temporaryIncapabilityPeriods.length > 0;
  }

  get defaultCalculatorResults() {
    return find(this.calculatorResults, (i) => i.name === 'default');
  }

  get deductedAmount() {
    // TODO ************
  }

  buildEndOfSupportPeriods(injuredParty) {
    const persons = injuredParty.hashSet;
    this.endOfSupportPeriods = [];
    for (const k in persons) {
      if (persons.hasOwnProperty(k)) {
        const personPeriods = this.periods
          .filter((p) => p.claimsByPerson.filter((c) => c.personId === k).length === 1)
          .pop();
        if (personPeriods)
          this.endOfSupportPeriods.push({
            personId: k,
            period: personPeriods,
          });
      }
    }
    this.endOfSupportPeriods = sortBy(this.endOfSupportPeriods, (o) => convertToDate(o.period.end));
    return this.endOfSupportPeriods;
  }

  separatePeriods(dateOfReport, config) {
    this.knownPeriods = {
      activePeriods: [],
      passivePeriods: [],
    };
    this.unknownPeriods = {
      activePeriods: [],
      passivePeriods: [],
    };
    this.temporaryIncapabilityPeriods = [];

    this.trimmedPeriods.forEach((p) => {
      const term = p.term;
      if (p.calculations.filter((c) => c.name === TEMPORARY_INCAPABILITY).length > 0) {
        if (!config.skipTemporaryIncapacity) this.temporaryIncapabilityPeriods.push(p);
        return;
      }
      const periodEnd = moment(p.end, 'DD.MM.YYYY');
      const isKnown = periodEnd.isSameOrBefore(moment(dateOfReport, 'DD.MM.YYYY'));
      if (isKnown) {
        if (term === 'active') this.knownPeriods.activePeriods.push(p);
        if (term === 'passive') this.knownPeriods.passivePeriods.push(p);
      } else {
        if (term === 'active') this.unknownPeriods.activePeriods.push(p);
        if (term === 'passive') this.unknownPeriods.passivePeriods.push(p);
      }
    });
  }

  getTemporaryIncapabilityCompensation(id) {
    return sumBy(
      this.temporaryIncapabilityPeriods,
      (p) => p.claimsByPerson.filter((c) => c.personId === id)[0].amount,
    );
  }
}
