import React from 'react';
import { connect } from 'react-redux';
import { Avatar } from 'material-ui';
import ColorPalette from '../../ColorPalette';

class TabTitleItemCount extends React.Component {
  render() {
    const { text, data } = this.props;
    return (
      <span style={{ display: 'flex' }}>
        {text}
        {data.length > 0 && (
          <Avatar
            color="#FFFFFF"
            backgroundColor={ColorPalette.primary2Color}
            size={18}
            style={{ marginTop: -8, marginLeft: 4 }}
          >
            {data.length}
          </Avatar>
        )}
      </span>
    );
  }
}

export default connect()(TabTitleItemCount);
