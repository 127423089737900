import React from 'react';
import { Divider, Paper, Subheader } from 'material-ui';
import FileUpload from './FileUpload';
import { paperStyle } from '../../utils/material-ui-style-util';
import ListAddFAB from '../Functional/ListAddFAB';
import { FieldArrayEmptyView } from '../Functional/FieldArrayEmptyView';
import { DOC_TYPE_ATTACH } from '../../constants';

function FileUploadList({ fields, meta: { error }, handleValueChange, style }) {
  return (
    <Paper style={{ ...paperStyle, ...style }}>
      <Subheader className={'basicReportSubheader'}>Belgeler</Subheader>
      {fields.map((name, index) => (
        <div key={index}>
          <FileUpload
            name={name}
            index={index}
            fields={fields}
            handleValueChange={handleValueChange}
          />
          <Divider style={{ marginTop: 0 }} />
        </div>
      ))}

      {error && <div style={{ textAlign: 'center', fontWeight: 400 }}>{error}</div>}
      <FieldArrayEmptyView fields={fields} />
      <ListAddFAB fields={fields} onClick={() => fields.push({ category: DOC_TYPE_ATTACH })} />
    </Paper>
  );
}

export default FileUploadList;
