import { setAppData } from '../../actions/appdata';
import { CUSTOMER_PRICING } from '../../constants';
import Connection from './Connection';
import CustomerPricingResponse from '../model/response/CustomerPricingResponse';

const CUSTOMERS = '/customers';
export default class CustomerPricingApi extends Connection {
  static upsert(customerId, data) {
    return this._put(`${CUSTOMERS}/${customerId}/pricings`, data);
  }

  static find(dispatch, customerId) {
    return this._getSecurely(`${CUSTOMERS}/${customerId}/pricings`)
      .then(...this.justData)
      .then((r) => {
        const data = new CustomerPricingResponse(r);
        dispatch(setAppData({ name: CUSTOMER_PRICING, value: data }));
        return data;
      })
      .catch(this.catchError);
  }
}
