/* eslint-disable prefer-destructuring,react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Dialog, FlatButton, IconButton, List, ListItem, Paper, Subheader } from 'material-ui';
import Done from 'material-ui/svg-icons/action/done';
import Close from 'material-ui/svg-icons/navigation/close';
import moment from 'moment';
import AccountApi from '../../data/api/AccountApi';
import { INVITATIONS_LIST, USER } from '../../constants/index';
import EmptyView from '../Functional/EmptyView';
import UserInvitationApi from '../../data/api/UserInvitationApi';
import Layout from '../Layout/Layout';

class Invitations extends React.Component {
  fetchData = () => {
    UserInvitationApi.list(this.props.dispatch);
  };

  confirmInvitation = (props) => {
    this.setState({ selectedInvitation: props });
    this.handleOpenConfirmDialog();
  };

  rejectInvitation = (props) => {
    this.setState({ selectedInvitation: props });
    this.handleOpenRejectDialog();
  };

  renderListItem = (props) => (
    <ListItem
      key={props.hash}
      rightIconButton={
        <div>
          <IconButton onClick={() => this.confirmInvitation(props)}>
            <Done color="green" />
          </IconButton>
          <IconButton onClick={() => this.rejectInvitation(props)}>
            <Close color="red" />
          </IconButton>
        </div>
      }
      primaryText={props.company.name}
      secondaryText={<p>Davet tarihi : {moment(props.timestamp).format('DD.MM.YYYY HH:mm:ss')}</p>}
    />
  );

  handleOpenConfirmDialog = () => {
    this.setState({ openConfirmDialog: true });
  };

  handleCloseConfirmDialog = () => {
    this.setState({ openConfirmDialog: false });
  };

  handleSubmitConfirmDialog = () => {
    const dispatch = this.props.dispatch;
    const selectedInvitation = this.state.selectedInvitation;
    UserInvitationApi.accept(selectedInvitation.company.id).then((r) => {
      AccountApi.getMe(dispatch);
      this.handleOpenConfirmDialog();
    });
    this.setState({ openConfirmDialog: false });
  };

  handleOpenRejectDialog = () => {
    this.setState({ openRejectDialog: true });
  };

  handleCloseRejectDialog = () => {
    this.setState({ openRejectDialog: false });
  };

  handleSubmitRejectDialog = () => {
    const user = this.props.user;
    const invitationList = this.state.invitationList;
    const selectedInvitation = this.state.selectedInvitation;
    UserInvitationApi.reject(user.getCompanyId).then(() => {
      const newInvitationList = invitationList.filter((i) => i !== selectedInvitation);
      this.setState({ invitationList: newInvitationList });
    });
    this.setState({ openRejectDialog: false });
  };

  confirmActions = [
    <FlatButton label="İptal" onClick={this.handleCloseConfirmDialog} />,
    <FlatButton
      label="Onaylıyorum"
      secondary
      keyboardFocused
      onClick={this.handleSubmitConfirmDialog}
    />,
  ];

  rejectActions = [
    <FlatButton label="İptal" keyboardFocused onClick={this.handleCloseRejectDialog} />,
    <FlatButton label="Reddet" secondary onClick={this.handleSubmitRejectDialog} />,
  ];

  constructor(props) {
    super(props);
    this.state = {
      invitationList: [],
      openConfirmDialog: false,
      openRejectDialog: false,
      selectedInvitation: null,
    };
  }

  componentDidMount() {
    const user = this.props.user;
    const dispatch = this.props.dispatch;
    if (!user) AccountApi.getMe(dispatch);
    else this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.fetchData();
    }
  }

  render() {
    const invitations = this.props.invitations;
    if (!invitations || invitations.length === 0)
      return (
        <Layout>
          <div style={{ marginTop: 64 }}>
            <EmptyView icon="user-plus" label="Henüz Davet Yok" />
          </div>
        </Layout>
      );

    return (
      <Layout>
        <div className={'root'}>
          <div className={'container'}>
            <div className={'max1024'}>
              <h1>Davetler</h1>
              <Dialog
                title="Davet kabul etmek istiyor musunuz?"
                actions={this.confirmActions}
                modal={false}
                open={this.state.openConfirmDialog}
                onRequestClose={this.handleCloseConfirmDialog}
              >
                Daveti kabul ettiğinizde; şirketinizin size sağlayacağı imkanlardan faydalanabilir
                aktüeryal sistemi yeni ortamında kullanabilirsiniz.
              </Dialog>

              <Dialog
                title="Daveti reddetmek istediğinize emin misiniz?"
                actions={this.rejectActions}
                modal={false}
                open={this.state.openRejectDialog}
                onRequestClose={this.handleCloseRejectDialog}
              >
                Daveti reddettiğinizde şirketin sizinle bir bağı olmadığını belirtmiş olursunuz ve
                aktüeryal raporlama portalından faydalanamazsınız.
                <br />
                Bu durum tekrar davet edilmenizi engellemez.
              </Dialog>
              <Paper style={this.props.style}>
                <List>
                  <Subheader>Davetlerim</Subheader>
                  {invitations.map(this.renderListItem)}
                </List>
              </Paper>
              <div className={'invitationInfo'}>
                <p>Davet edilmek ne anlama gelir?</p>
                <span>
                  İnova Yönetim ve Aktüeryal Danışmanlık şirketiniz ve sizin için bir çok hizmet
                  sağlar.
                </span>
                <br />
                <span>
                  Şirketinize sağlanan imkanlardan faydalanabilmek için daveti kabul etmeniz
                  gerekir.
                </span>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default connect((state) => ({
  user: state.appData[USER],
  invitations: state.appData[INVITATIONS_LIST],
}))(Invitations);
