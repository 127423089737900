import { toMoney } from '../../../utils/javascript-object-util';

export default function SalaryInfo() {
  const { calculation } = this;
  const { details } = calculation;

  function tr(text, amount) {
    return [
      text,
      {
        text: amount,
        style: { bold: true },
        alignment: 'right',
      },
    ];
  }

  const body = [];
  if (details.hasActive) {
    body.push(tr('Aktif Dönem Aylık Net Gelir', details.getActivePeriodSalary));
    body.push(tr('Aktif Dönem Yıllık Net Gelir', details.getActivePeriodAnnualSalary));
  }
  if (details.hasPassive) {
    body.push(tr('Pasif Dönem Aylık Net Gelir', details.getPassivePeriodSalary));
    body.push(tr('Pasif Dönem Yıllık Net Gelir', details.getPassivePeriodAnnualSalary));
  }
  if (this.onlyKnown) {
    const salary = details.getLastKnownSalary(this.incident.dateOfReport);
    body.push(tr('Bilinen Son Aylık Net Gelir', toMoney(salary)));
    body.push(tr('Bilinen Son Yıllık Net Gelir', toMoney(salary * 12)));
  }

  return [
    {
      headlineLevel: 1,
      stack: [
        {
          text: 'Rapor Tarihi İtibariyle Hesaplamaya Esas Gelir Bilgisi',
          margin: [0, 10, 0, 5],
          style: 'h2',
        },
        {
          margin: [150, 5, 10, 10],
          style: { fontSize: 10 },
          table: {
            heights: 10,
            widths: [130, 70],
            body,
          },
          layout: {
            hLineColor: '#333333',
            vLineColor: '#333333',
          },
        },
      ],
    },
  ];
}
