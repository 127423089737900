import PersonCreateRequest from './PersonCreateRequest';
import { convertPercentageToNum, convertToDate } from '../../../../utils/javascript-object-util';

export default class ChildCreateRequest {
  constructor(config, { dateOfIssue = null }) {
    if (typeof config !== 'object') return;
    this.basic = new PersonCreateRequest(config.basic, {
      dateOfBirth: dateOfIssue,
    });
    this.disability = convertPercentageToNum(config.disability);
    this.dateOfGraduation = convertToDate(config.dateOfGraduation);
    this.dateOfMarriage = convertToDate(config.dateOfMarriage);
    this.dateOfEmployment = convertToDate(config.dateOfEmployment);
    this.tempParentId = config.tempParentId;
  }
}
