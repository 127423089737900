import React from 'react';
import BaseLayout from './BaseLayout';

export default function StandardLayout({ children }) {
  return (
    <BaseLayout>
      <div className={'root'}>
        <div className={'container'}>
          <div className={'max1024'}>{children}</div>
        </div>
      </div>
    </BaseLayout>
  );
}
