/* eslint-disable no-prototype-builtins,react/sort-comp */
import React, { Component } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import isEqual from 'lodash/isEqual';

function buildOptions(data) {
  if (!data) {
    return [];
  }
  const items = [];
  data.forEach((l) =>
    items.push(
      <MenuItem
        value={l.value}
        label={l.label || l.text}
        key={data.indexOf(l)}
        primaryText={l.text}
      />,
    ),
  );
  return items;
}

class ComboBox extends Component {
  defaultFilter = (x, value) => isEqual(x.value, value);

  handleChange = (value) => {
    this.setState({ value });
    this.props.onChange(value);
  };

  updateValue = (data, value) => {
    if (!data) {
      this.state.isInitialized && this.handleChange(null);
    } else if (value && value !== '') {
      const { filter } = this.props;
      const found = data.findIndex((x) =>
        filter ? filter(x, value) : this.defaultFilter(x, value),
      );
      found >= 0 && this.handleChange(data[found].value);
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      isInitialized: false,
      value: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateValue = this.updateValue.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { data, value } = this.props;

    const oldData = prevProps.data;
    const oldValue = prevProps.value;
    if (!isEqual(oldData, data) || !isEqual(oldValue, value)) {
      this.setState({ isInitialized: true });
      this.updateValue(data, value);
    }
  }

  componentDidMount() {
    if (this.props.data && this.props.value) {
      this.setState({ isInitialized: true });
      this.updateValue(this.props.data, this.props.value);
    }
  }

  render() {
    const { title, label, data } = this.props;
    return (
      <SelectField
        {...this.props}
        value={this.state.value}
        maxHeight={300}
        errorStyle={{ float: 'left' }}
        onChange={(event, index, value) => this.handleChange(value)}
        floatingLabelText={title || label}
      >
        {buildOptions(data)}
      </SelectField>
    );
  }
}

export default ComboBox;
