import find from 'lodash/find';
import { datePreciseDiff } from '../../../utils/javascript-object-util';
import { DEATH } from '../../../data/model/IncidentType';

export default function PeriodSummary() {
  const { incident } = this;
  const { calculation } = this;
  const { details } = calculation;
  const { casualty } = this.injuredParty;
  const casualtyBasic = casualty.basic;
  const { incidentType } = incident;
  const endOfSupports = details.buildEndOfSupportPeriods(this.injuredParty);
  const endOfSupport = find(endOfSupports, (p) => p.personId === casualtyBasic.id).period.end;
  const { dateOfBirth } = casualtyBasic;
  const { dateOfDeath } = incident;
  const { dateOfReport } = incident;
  const { dateOfIncident } = incident;
  const { dateOfRetirement } = calculation;
  const { hasActive } = details;
  const { hasPassive } = details;
  const birthToIncident = datePreciseDiff(dateOfBirth, dateOfIncident);
  const birthToDeath = datePreciseDiff(dateOfBirth, dateOfDeath);
  const birthToReport = datePreciseDiff(dateOfBirth, dateOfReport);
  const birthToEndOfSupport = datePreciseDiff(dateOfBirth, endOfSupport);
  const birthToRetirement = datePreciseDiff(dateOfBirth, dateOfRetirement);
  const incidentToReport = datePreciseDiff(
    incidentType === DEATH ? dateOfDeath : dateOfIncident,
    dateOfReport,
  );
  const reportToRetirement = datePreciseDiff(dateOfReport, dateOfRetirement);
  const retirementToEndOfSupport = datePreciseDiff(dateOfRetirement, endOfSupport);
  function th(text, fontSize = 10, options) {
    return {
      text,
      style: { bold: true, fontSize, color: 'black' },
      ...options,
    };
  }

  function tdDate(text) {
    return {
      text,
      style: { bold: true },
      fillColor: '#DDD',
    };
  }

  const tableHeader = [];
  const tableDateRow = [''];
  const tableYearRow = [
    {
      text: 'Yaş',
      style: { bold: true, fontSize: 10, color: 'black' },
    },
  ];
  const tableDiffRow = [
    {
      text: 'Dönem Süresi ',
      style: { bold: true, fontSize: 10, color: 'black' },
    },
  ];
  tableHeader.push(
    th(`${hasActive ? 'AKTİF' : ''} ${hasPassive ? 'PASİF' : ''} DÖNEM ÖZETİ`, 12, {
      rowSpan: 2,
    }),
  );
  tableHeader.push(th(incidentType === DEATH ? 'Ölüm Tarihi' : 'Kaza Tarihi'));
  tableHeader.push(th('Ak. Rapor Tarihi'));
  if (hasActive) tableHeader.push(th('Emeklilik Tarihi'));
  tableHeader.push(th('Destek Sonu Tarihi'));

  tableDateRow.push(tdDate(incidentType === DEATH ? dateOfDeath : dateOfIncident));
  tableDateRow.push(tdDate(dateOfReport));
  if (hasActive) tableDateRow.push(tdDate(dateOfRetirement));
  tableDateRow.push(tdDate(endOfSupport));

  tableYearRow.push(incidentType === DEATH ? birthToDeath : birthToIncident);
  tableYearRow.push(birthToReport);
  if (hasActive) tableYearRow.push(birthToRetirement);
  tableYearRow.push(birthToEndOfSupport);

  tableDiffRow.push('');
  tableDiffRow.push(incidentToReport);
  if (hasActive) tableDiffRow.push(reportToRetirement);
  tableDiffRow.push(retirementToEndOfSupport);

  const body = [tableHeader, tableDateRow, tableYearRow, tableDiffRow];
  const widths = [80, 90, 90, 90, 90];
  if (hasActive) widths.push(90);

  return {
    margin: [hasActive ? 10 : 55, 10, 0, 10],
    style: { fontSize: 9 },
    alignment: 'center',
    table: {
      widths,
      body,
    },
  };
}
