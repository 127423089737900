import React, { Component } from 'react';
import ChildForm from './ChildForm';
import { FieldArrayEmptyView } from '../Functional/FieldArrayEmptyView';
import ListAddFAB from '../Functional/ListAddFAB';
import { getOppositeGender } from '../../data/model/Gender';

class ChildListForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastIndex: 0,
    };
  }

  handleOnClick = () => {
    const { fields, spouses, casualty } = this.props;
    const initData = { basic: {} };
    if (casualty && casualty.basic) {
      initData.basic.gender = getOppositeGender(casualty.basic.gender);
    }
    if (spouses && spouses.length > 0) {
      initData.tempParentId = 'tempPersonSpouse0';
    }
    initData.basic.tempId = `tempPersonChild${this.state.lastIndex}`;
    fields.push(initData);
    this.setState({ lastIndex: this.state.lastIndex + 1 });
  };

  render() {
    const {
      fields,
      meta: { error, submitFailed },
      casualty,
      spouses,
      values,
      update,
    } = this.props;
    return (
      <div>
        {fields.map((name, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            <ChildForm
              spouses={spouses}
              name={name}
              index={index}
              fields={fields}
              casualtyGender={casualty.basic && casualty.basic.gender}
              update={update}
              id={values[index] && values[index].basic && values[index].basic.id}
            />
          </div>
        ))}
        <FieldArrayEmptyView fields={fields} />
        <ListAddFAB disabled={!casualty || !casualty.basic} onClick={() => this.handleOnClick()} />
      </div>
    );
  }
}

export default ChildListForm;
