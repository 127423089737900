import * as React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class EmptyView extends React.Component {
  render() {
    if (this.props.isProgressbarOpen) return <div />;
    return (
      <div className={`emptyViewContainer ${this.props.className}`} style={this.props.style}>
        <FontAwesomeIcon
          style={{
            boxSizing: 'border-box',
            fontSize: '36px',
            padding: '4px',
            margin: 'auto',
            width: '100%',
            display: 'inline-block',
          }}
          fixedWidth
          icon={this.props.icon || 'list-ul'}
        />
        <span
          style={{
            width: '100%',
            textAlign: 'center',
            display: 'inline-block',
          }}
        >
          {this.props.label || 'Burada henüz kayıt yok'}
        </span>
      </div>
    );
  }
}

export default connect((state) => ({
  isProgressbarOpen: state.runtime.isProgressbarOpen,
}))(EmptyView);
