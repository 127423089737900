import React from 'react';
import changeLog from './changelog.md';
import ChangeLog from './ChangeLog';

class ChangeLogPage extends React.Component {
  render() {
    return (
      <div className={'max1024'}>
        <div className={'changeLog'}>
          <ChangeLog html={changeLog} style={{ display: 'table', margin: 'auto' }} />
        </div>
      </div>
    );
  }
}

export default ChangeLogPage;
