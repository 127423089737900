import { setAppData } from '../../actions/appdata';
import { CALCULATION_CONFIG, CALCULATION_CONFIGS } from '../../constants';
import Connection from './Connection';
import DetailedConfigResponse from '../model/response/DetailedConfigResponse';
import ConfigResponse from '../model/response/ConfigResponse';

export default class CalculationConfigApi extends Connection {
  static list(dispatch, incidentId) {
    return this._getSecurely(`/incidents/${incidentId}/calculation-configs`)
      .then(...this.justData)
      .then((r) =>
        dispatch(
          setAppData({
            name: CALCULATION_CONFIGS,
            value: r ? r.bindList(ConfigResponse) : [],
          }),
        ),
      )
      .catch(this.catchError);
  }

  static find(dispatch, id) {
    return this._getSecurely(`/calculation-configs/${id}`)
      .then(...this.justData)
      .then((r) => {
        const data = new DetailedConfigResponse(r);
        if (dispatch)
          dispatch(
            setAppData({
              name: CALCULATION_CONFIG,
              value: data,
            }),
          );
        return data;
      })
      .catch(this.catchError);
  }

  static create(incidentId, data) {
    return this._post(`/incidents/${incidentId}/calculation-configs`, data);
  }

  static update(id, data) {
    return this._patch(`/calculation-configs/${id}`, data);
  }

  static delete(id) {
    return this._delete(`/calculation-configs/${id}`);
  }

  static calculate(id) {
    return this._post(`/calculation-configs/${id}/calculation`);
  }
}
