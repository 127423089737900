import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Paper, RaisedButton, Subheader } from 'material-ui';
import isEmpty from 'lodash/isEmpty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { USER, WEBHOOKS } from '../../constants';
import { hidePB, showPB } from '../../actions/progressbarAction';
import { openSnackbar } from '../../actions/snackbarActions';
import { validateHttpsUrl } from '../../utils/validation';
import { renderWebhookUrl } from '../../utils/redux-metarial-ui-util';
import WebhookApi from '../../data/api/WebhookApi';
import WebhookCreateRequest from '../../data/model/request/create/WebhookCreateRequest';
import { copyClipboard } from '../../utils/javascript-object-util';
import EventList from './EventList';
import WebhookDocumentation from './WebhookDocumentation';
import Clear from '../../components/Functional/Clear';
import Layout from '../../components/Layout/Layout';
import Authorization from '../../components/Authorization/Authorization';
import { ReadCompany, WriteCompany } from '../../data/model/Privilege';

class CompanyWebhook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  fetchData = (props) => {
    const { dispatch, user } = props;
    const companyId = user.getCompanyId;
    if (!companyId) return;
    showPB(dispatch);

    WebhookApi.find(dispatch, companyId).then(
      (r) => {
        hidePB(dispatch);
      },
      (r) => {
        hidePB(dispatch);
      },
    );
  };

  onSubmit = (values) => {
    const { dispatch, user } = this.props;
    const companyId = user.getCompanyId;
    if (!companyId) return;
    showPB(dispatch);
    WebhookApi.create(dispatch, companyId, new WebhookCreateRequest(values)).then(
      (r) => {
        dispatch(openSnackbar('WebhookApi Oluşturuldu'));
        hidePB(dispatch);
      },
      (r) => {
        dispatch(openSnackbar('Bir Hata Oluştu'));
        hidePB(dispatch);
      },
    );
  };

  removeWebhook = () => {
    const { dispatch, user } = this.props;
    const companyId = user.getCompanyId;
    if (!companyId) return;
    showPB(dispatch);

    WebhookApi.delete(companyId).then(
      (r) => {
        dispatch(openSnackbar('WebhookApi Kaldırıldı'));
        hidePB(dispatch);
      },
      (r) => {
        dispatch(openSnackbar('Bir Hata Oluştu'));
        hidePB(dispatch);
      },
    );
  };

  componentDidMount() {
    this.fetchData(this.props);
  }

  copySecretKey = () => {
    const { dispatch, webhook } = this.props;
    copyClipboard(webhook.secret);
    dispatch(openSnackbar('Kopyalandı'));
  };

  render() {
    const { handleSubmit, title, webhook } = this.props;
    const { open } = this.state;

    return (
      <Layout>
        <Authorization allowedRoles={[ReadCompany, WriteCompany]} force>
          <div className={`root max1024`} style={{ marginBottom: 64 }}>
            <h1>{title}</h1>
            <Paper className={'webhook'}>
              <Subheader>Webhook</Subheader>
              <form onSubmit={handleSubmit(this.onSubmit)} style={{ padding: '0 32px' }}>
                <Field
                  className={'webhook-input'}
                  name="url"
                  label="POST"
                  component={renderWebhookUrl}
                  fullWidth
                  placeholder="WebHook Url"
                />
                {isEmpty(webhook) ? (
                  <RaisedButton style={{ maxHeight: 36 }} primary label="Kaydet" type="submit" />
                ) : (
                  <RaisedButton
                    style={{ maxHeight: 36 }}
                    secondary
                    label="Kaldır"
                    onClick={this.removeWebhook}
                  />
                )}
              </form>

              {webhook && webhook.secret && (
                <div className={'secret-key-wrapper'}>
                  <div className={'title'}>Secret-Key:</div>
                  <div className={'key'}>
                    {webhook.secret}
                    <FontAwesomeIcon
                      style={{ cursor: 'pointer' }}
                      fixedWidth
                      icon="copy"
                      onClick={() => this.copySecretKey()}
                    />
                  </div>
                </div>
              )}

              <div
                className={'optionalFieldContainer'}
                style={{
                  display: open ? 'block' : 'none',
                  visibility: open ? 'visible' : 'hidden',
                  opacity: open ? 1 : 0,
                  maxHeight: open ? 8000 : 0,
                  marginTop: open ? 24 : 0,
                  paddingTop: open ? 16 : 0,
                }}
              >
                <span className={'optionalFieldHeader'} style={{ left: 0 }}>
                  Dökümantasyon
                </span>
                <WebhookDocumentation />
                <Clear />
              </div>
              <Clear />
              <div
                className={'bottomCenterLinkButton'}
                onClick={() => this.setState({ open: !open })}
              >
                <FontAwesomeIcon icon={open ? 'caret-up' : 'caret-down'} fixedWidth />
                Dökümantasyonu&nbsp;{open ? 'Gizle' : 'Göster'}
              </div>
            </Paper>
            <EventList />
          </div>
        </Authorization>
      </Layout>
    );
  }
}

const validate = (values) => {
  const errors = {};
  errors.url = validateHttpsUrl(values.url);
  return errors;
};
CompanyWebhook = reduxForm({ form: 'companyWebhook', validate })(CompanyWebhook);

export default connect((state) => ({
  user: state.appData[USER],
  initialValues: state.appData[WEBHOOKS],
  webhook: state.appData[WEBHOOKS],
}))(CompanyWebhook);
