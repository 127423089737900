/* eslint-disable react/prop-types,react/sort-comp */
import React from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import { connect } from 'react-redux';

const interval = 1000;
const o = '77';
const colors = ['#13839e', '#2e3e8a', '#820333', '#8c2683', '#8f4360'];
class Progressbar extends React.Component {
  tick = () => {
    this.setState({
      date: this.state.date + 1,
    });
  };

  constructor(props) {
    super(props);
    this.state = { date: 0 };
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), interval);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    const { date } = this.state;
    return (
      <div className={'progressBarContainer'}>
        <div
          className={`progressBar center`}
          style={{
            background: colors[date % 4] + o,
          }}
        >
          <CircularProgress color="white" size={64} className={'center'} />
          {this.props.progressLabel && (
            <div className={`center progressLabel`}>{this.props.progressLabel}</div>
          )}
        </div>
      </div>
    );
  }
}

export default connect((state) => ({
  isProgressbarOpen: state.runtime.isProgressbarOpen,
  progressLabel: state.runtime.progressLabel,
}))(Progressbar);
