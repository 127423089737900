import { convertNumToPercentage } from '../../../utils/javascript-object-util';
import ShareRateCategory from '../ShareRateCategory';
import LifeAnnuity from '../LifeAnnuity';
import MortalityTable, { CSO, TRH, TRH_PROGRESSIVE } from '../MortalityTable';
import NurseCategory from '../NurseCategory';

export default class ConfigResponse {
  constructor(config) {
    if (typeof config !== 'object') return;

    this.id = config.id;
    this.mortalityTable = config.mortalityTable;
    this.lifeAnnuity = config.lifeAnnuity;
    this.shareRate = config.shareRate;
    this.faultRatio = convertNumToPercentage(config.faultRatio);
    this.disabilityRatio = convertNumToPercentage(config.disabilityRatio);
    this.interest = convertNumToPercentage(config.interest);
    this.useAllowanceExcludedWageOnPassive = config.useAllowanceExcludedWageOnPassive;
    this.useTerminalAge = config.useTerminalAge;
    this.useMinimumWage = config.useMinimumWage;
    this.withoutAllowance = config.withoutAllowance;
    this.dontLimitCompensation = config.dontLimitCompensation;
    this.usePassiveEarningUntilAgeOfConsent = config.usePassiveEarningUntilAgeOfConsent;
    this.nurseCategory = config.nurseCategory;
    this.isNurseDocumented = config.isNurseDocumented;
    this.skipTemporaryIncapacity = config.skipTemporaryIncapacity;
    this.useHouseholdPriorityShare = config.useHouseholdPriorityShare;
  }

  get isZeroDisability() {
    return parseInt(this.disabilityRatio, 10) === 0;
  }

  get getShareRate() {
    return ShareRateCategory[this.shareRate];
  }

  get getLifeAnnuity() {
    return LifeAnnuity[this.lifeAnnuity];
  }

  get getMortalityTable() {
    return MortalityTable[this.mortalityTable];
  }

  get getMortalityTableForReport() {
    return this.mortalityTable === TRH_PROGRESSIVE ? MortalityTable.trh : this.getMortalityTable;
  }

  get getNurseCategory() {
    return NurseCategory[this.nurseCategory] || 'Yok';
  }

  get isTechnicalInterest() {
    return this.mortalityTable === TRH || this.mortalityTable === CSO;
  }
}
