import React from 'react';
import { connect } from 'react-redux';
import {
  ACTIVE_FILTER_OPTIONS,
  LAST_VISITED_TAB_REPORT_DETAIL,
  REPORT_CONTENT_RANGE,
  REPORT_ITEM_COUNT,
  REPORT_LIST,
  REPORT_LIST_PAGE,
} from '../../constants';
import ReportFilter from '../../components/Report/ReportFilter';
import ReportViewHolder from './ReportViewHolder';
import { hidePB, showPB } from '../../actions/progressbarAction';
import { openSnackbar } from '../../actions/snackbarActions';
import EmptyView from '../../components/Functional/EmptyView';
import { convertToDate } from '../../utils/javascript-object-util';
import { normalizeDate } from '../../utils/redux-normalize';
import { reportFilterPatterns } from '../../components/Report/ReportFilterPattern';
import Clear from '../../components/Functional/Clear';
import { clearAppData, setAppData } from '../../actions/appdata';
import ReportApi from '../../data/api/ReportApi';
import Layout from '../../components/Layout/Layout';
import Authorization from '../../components/Authorization/Authorization';
import { ReadIncident } from '../../data/model/Privilege';
import Pagination from '../../components/Functional/Pagination';

export function filterOptionsToQueryParams(values) {
  const obj = {};
  for (const k in values) {
    if (values.hasOwnProperty(k) && values[k] !== null) {
      let value = values[k];
      const pureKey = k.replace('From', '').replace('Till', '');
      if (reportFilterPatterns[pureKey].props.normalize) {
        if (reportFilterPatterns[pureKey].props.normalize === normalizeDate) {
          value = `${convertToDate(values[k]).format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`;
        }
      }
      if (pureKey.includes('Ratio')) {
        value = (value / 100).toFixed(4);
      }
      if (reportFilterPatterns[k]) {
        if (reportFilterPatterns[k].abilities.findIndex((f) => f.id === 2) !== -1) {
          obj[`${k}From`] = value;
        }
        if (reportFilterPatterns[k].abilities.findIndex((f) => f.id === 3) !== -1) {
          obj[`${k}Till`] = value;
        }
        if (reportFilterPatterns[k].abilities.findIndex((f) => f.id === 1) !== -1) {
          obj[k] = value;
        }
      }
      obj[k] = value;
    }
  }
  return obj;
}

class ReportList extends React.Component {
  handlePageChange = (page) => {
    this.setPage(page);
    this.fetchData(filterOptionsToQueryParams(this.props.activeFilterOptions), page);
  };

  handleFilter = (filterOptions) => {
    this.setPage(1);
    this.fetchData(filterOptionsToQueryParams(filterOptions), 1);
  };

  handleFilterReset = () => {
    this.setPage(1);
    this.fetchData({}, 1);
  };

  setPage = (num) => {
    const { dispatch } = this.props;
    dispatch(setAppData({ name: REPORT_LIST_PAGE, value: num }));
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData(filterOptionsToQueryParams(this.props.activeFilterOptions), this.props.page);
    this.props.dispatch(clearAppData(LAST_VISITED_TAB_REPORT_DETAIL));
  }

  async fetchData(filterOptions, page) {
    const { dispatch } = this.props;
    showPB(dispatch);
    ReportApi.list(dispatch, filterOptions, page - 1).then(
      () => hidePB(dispatch),
      (error) => {
        dispatch(openSnackbar(error.message));
        hidePB(dispatch);
      },
    );
  }

  render() {
    const { reportList } = this.props;
    return (
      <Layout>
        <Authorization allowedRoles={[ReadIncident]} force>
          <div className={'root'}>
            <div className={'container'}>
              <div className={'max1024'}>
                <ReportFilter onFilter={this.handleFilter} onReset={this.handleFilterReset} />
                {reportList && reportList.length > 0 ? (
                  reportList.map((r) => <ReportViewHolder key={reportList.indexOf(r)} data={r} />)
                ) : (
                  <EmptyView style={{ marginTop: 64 }} icon="copy" label="Rapor Bulunamadı" />
                )}
                {reportList && (
                  <Pagination
                    name={'Rapordan'}
                    style={{ float: 'right' }}
                    total={this.props.itemCount}
                    onChange={this.handlePageChange}
                    showTitle={false}
                    current={this.props.page}
                  />
                )}
                <Clear />
              </div>
            </div>
          </div>
        </Authorization>
      </Layout>
    );
  }
}

export default connect((state) => ({
  contentRange: state.appData[REPORT_CONTENT_RANGE],
  itemCount: state.appData[REPORT_ITEM_COUNT],
  reportList: state.appData[REPORT_LIST],
  page: state.appData[REPORT_LIST_PAGE] || 1,
  activeFilterOptions: state.appData[ACTIVE_FILTER_OPTIONS],
}))(ReportList);
