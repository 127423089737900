import React, { Component } from 'react';
import { FlatButton } from 'material-ui';
import { dateFormatLongNonSecond } from '../../utils/javascript-object-util';
import Clear from '../../components/Functional/Clear';
import Link from '../../components/Link/Link';
import EventDetail from './EventDetail';
import Dialog from '../../components/Functional/Dialog';

class EventViewHolder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      open: false,
    };
  }

  mouseOver = () => {
    this.setState({ hover: true });
  };

  mouseOut = () => {
    this.setState({ hover: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { event, title } = this.props;
    const { hover, open } = this.state;
    const { responseStatus, timestamp, incident, casualty } = event;
    const actions = [
      <FlatButton
        label="Tamam"
        primary
        onClick={() => {
          this.handleClose();
        }}
      />,
    ];
    return (
      <div
        style={{
          position: 'relative',
          background: hover ? '#F1F1F1' : 'transparent',
          fontWeight: title ? '600' : '400',
          padding: title ? '16px 8px' : '4px 8px',
        }}
        onMouseEnter={this.mouseOver}
        onMouseLeave={this.mouseOut}
      >
        <span
          style={{
            display: 'inline-block',
            minWidth: 140,
            fontWeight: 500,
          }}
        >
          {title ? (
            'Dosya Numarası'
          ) : (
            <Link to={`/reports/${incident.id}`} style={{ textDecoration: 'none' }}>
              {incident.fileIdentifier}
            </Link>
          )}
        </span>

        <span
          style={{
            display: 'inline-block',
            width: 216,
          }}
        >
          {title ? 'Mağdur/Müteveffa' : ((casualty || {}).basic || {}).fullName}
        </span>
        <span
          style={{
            display: 'inline-block',
            width: 216,
          }}
        >
          {title ? 'Status' : responseStatus}
        </span>
        <span
          style={{
            display: 'inline-block',
          }}
        >
          {dateFormatLongNonSecond(timestamp) || 'Oluşturulma Tarihi'}
        </span>

        {!title && (
          <Link
            to={`/company/events/${event.id}`}
            style={{ textDecoration: 'none', float: 'right' }}
          >
            Detay
          </Link>
        )}
        <Clear />
        {!title && (
          <Dialog
            title="Event Detayı"
            actions={actions}
            modal={false}
            open={open}
            onRequestClose={this.handleClose}
          >
            <EventDetail eventId={event.id} />
          </Dialog>
        )}
      </div>
    );
  }
}

export default EventViewHolder;
