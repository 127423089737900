import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import RaisedButton from 'material-ui/RaisedButton';
import { login } from '../../actions/authActions';
import { renderTextField } from '../../utils/redux-metarial-ui-util';
import { hidePB, showPB } from '../../actions/progressbarAction';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div>
          <Field
            name="identifier"
            style={{ margin: 0 }}
            component={renderTextField}
            floatingLabelText="Email"
            fullWidth
          />
        </div>
        <div>
          <Field
            name="password"
            style={{ margin: 0 }}
            component={renderTextField}
            type="password"
            floatingLabelText="Şifre"
            fullWidth
          />
        </div>
        {this.state.loading && (
          <div style={{ marginTop: 16 }}>
            <img src="loading.svg" style={{ width: 64 }} />
          </div>
        )}
        <div style={{ marginTop: 32 }}>
          <RaisedButton
            type="submit"
            style={{ float: 'right', width: '50%' }}
            label="Giriş Yap"
            primary
            disabled={this.state.loading}
            fullWidth
          />
        </div>
      </form>
    );
  }
}

function onSubmit(values, dispatch) {
  showPB(dispatch, 'Giriş Yapılıyor');
  return login(values, dispatch).then(
    () => {
      hidePB(dispatch);
      window.location.reload();
    },
    (r) => {
      hidePB(dispatch);
      return Promise.reject(r);
    },
  );
}

// Decorate the form component
LoginForm = reduxForm({ form: 'loginForm', onSubmit })(LoginForm);

export default connect()(LoginForm);
