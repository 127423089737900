import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SELF_PROCESSED = 'selfProcessed';
export const REQUEST_REVIEW = 'requestReview';
export const MARK_AS_MISSING_INFO = 'markAsMissingInfo';
export const MARK_AS_OVERVIEWED = 'markAsOverviewed';
export const MARK_AS_RESOLVED = 'markAsResolved';

export const ActionCategoryColors = {
  selfProcessed: '#4dd0e1',
  requestReview: '#1565c0',
  markAsMissingInfo: '#ff5722',
  markAsOverviewed: '#ffc107',
  markAsResolved: '#388e3c',
  comment: '#F1F1F1',
};
export const ActionCategoryIcons = {
  comment: <FontAwesomeIcon icon="comment" fixedWidth />,
  selfProcessed: <FontAwesomeIcon icon="hand-pointer" fixedWidth />,
  requestReview: <FontAwesomeIcon icon="search" fixedWidth />,
  markAsMissingInfo: <FontAwesomeIcon icon="question" fixedWidth />,
  markAsOverviewed: <FontAwesomeIcon icon="signature" fixedWidth />,
  markAsResolved: <FontAwesomeIcon icon="check" fixedWidth />,
};

export const ActionCategoryActuary = {
  markAsMissingInfo: 'Bilgilendirme Gerekiyor',
  markAsOverviewed: 'Kontrol Edildi',
  markAsResolved: 'Tamamlandı',
};

export const ActionCategoryCustomer = {
  requestReview: 'İnceleme İsteğinde',
};
export default {
  selfProcessed: 'Otomatik Rapor',
  requestReview: 'İnceleme İsteğinde',
  markAsMissingInfo: 'Bilgilendirme Gerekiyor',
  markAsOverviewed: 'Kontrol Edildi',
  markAsResolved: 'Tamamlandı',
};
