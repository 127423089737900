import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTrash,
  faUpload,
  faFile,
  faListUl,
  faComment,
  faHandPointer,
  faSearch,
  faQuestion,
  faSignature,
  faCheck,
  faList,
  faSpinner,
  faTimes,
  faUser,
  faKey,
  faAddressCard,
  faFolder,
  faBuilding,
  faPlus,
  faUsers,
  faChess,
  faUserPlus,
  faArchive,
  faReceipt,
  faHandshake,
  faFileInvoice,
  faMoneyBillWave,
  faLink,
  faCog,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faEllipsisH,
  faExclamation,
  faCaretUp,
  faInfoCircle,
  faEraser,
  faCalendarAlt,
  faQuestionCircle,
  faCalculator,
  faMoneyBillAlt,
  faWheelchair,
  faMousePointer,
  faClock,
  faArrowsAltH,
  faCheckCircle,
  faExclamationTriangle,
  faTimesCircle,
  faFileExport,
  faBookmark,
  faWrench,
  faCopy,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faTrash,
  faUpload,
  faFile,
  faListUl,
  faComment,
  faHandPointer,
  faSearch,
  faQuestion,
  faSignature,
  faCheck,
  faList,
  faSpinner,
  faTimes,
  faUser,
  faKey,
  faAddressCard,
  faFolder,
  faBuilding,
  faPlus,
  faUsers,
  faChess,
  faUserPlus,
  faArchive,
  faReceipt,
  faEllipsisH,
  faHandshake,
  faCaretLeft,
  faCaretRight,
  faFileInvoice,
  faMoneyBillWave,
  faLink,
  faCog,
  faCaretDown,
  faExclamation,
  faCaretUp,
  faInfoCircle,
  faEraser,
  faCalendarAlt,
  faQuestionCircle,
  faCalculator,
  faMoneyBillAlt,
  faWheelchair,
  faMousePointer,
  faClock,
  faArrowsAltH,
  faCheckCircle,
  faExclamationTriangle,
  faTimesCircle,
  faFileExport,
  faBookmark,
  faWrench,
  faCopy,
);
