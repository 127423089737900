import React from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import Layout from '../../components/Layout/Layout';
import { WriteIncident } from '../../data/model/Privilege';
import Authorization from '../../components/Authorization/Authorization';
import CalculationConfigCreateForm from '../../components/Report/CalculationConfig/CalculationConfigCreateForm';

export default function CalculationConfig() {
  const params = useParams();
  const history = useHistory();
  return <CalculationConfigCreateForm configId={params.id} configPage update history={history} />;
}
