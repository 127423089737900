import React, { Children } from 'react';

class OptionalFieldWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
    };
  }

  render() {
    const { clicked } = this.state;
    const { text, children, style } = this.props;
    if (clicked) return Children.only(children);
    return (
      <span
        className={'smallInfoButton'}
        style={{
          marginBottom: 11,
          marginTop: 32,
          marginLeft: 16,
          marginRight: 8,
          ...style,
        }}
        onClick={() => this.setState({ clicked: true })}
      >
        {text}
      </span>
    );
  }
}

export default OptionalFieldWrapper;
