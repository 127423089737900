import React from 'react';
import { dateFormatLongNonSecond } from '../../utils/javascript-object-util';

function TransactionViewHolder({ transaction, title }) {
  const { createdAt, amount, category, message } = transaction;
  return (
    <div style={{ fontSize: '14px', fontFamily: 'monospace' }}>
      <span
        style={{
          display: 'inline-block',
          minWidth: 150,
        }}
      >
        {title ? createdAt : dateFormatLongNonSecond(createdAt)}
      </span>
      <span
        style={{
          display: 'inline-block',
        }}
      >
        {message}
      </span>
      <span
        style={{
          fontWeight: 400,
          display: 'inline-block',
          float: 'right',
          textAlign: 'right',
        }}
      >
        {(category === 'withdrawal' || category === 'correctionWithdrawal') && '-'}
        {amount} {title ? '' : '₺'}
      </span>
    </div>
  );
}

export default TransactionViewHolder;
