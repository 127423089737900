import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import RaisedButton from 'material-ui/RaisedButton';
import { renderTextField } from '../../utils/redux-metarial-ui-util';
import { hidePB } from '../../actions/progressbarAction';
import { openSnackbar } from '../../actions/snackbarActions';
import AccountApi from '../../data/api/AccountApi';

class ConfirmationMailResendForm extends Component {
  onSubmit = (values, dispatch) =>
    AccountApi.resendConfirmationMail(values.email).then(
      (r) => {
        hidePB(dispatch);
        dispatch(openSnackbar('Mail Gönderildi'));
        this.setState({ sent: true });
      },
      (r) => {
        hidePB(dispatch);
        dispatch(openSnackbar(r.error));
      },
    );

  constructor(props) {
    super(props);
    this.state = {
      sent: false,
    };
  }

  render() {
    return (
      <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
        <p>Lütfen aşağıdaki alana kayıt olurken kullandığınız mail adresinizi girin.</p>
        <div>
          <Field
            name="email"
            style={{ margin: 0 }}
            component={renderTextField}
            floatingLabelText="Email"
            fullWidth
          />
        </div>
        <div style={{ marginTop: 32 }}>
          <RaisedButton
            type="submit"
            style={{ float: 'right', width: '50%' }}
            label="Aktivasyon Maili Gönder"
            primary
            disabled={this.state.sent}
            fullWidth
          />
        </div>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Zorunlu alan';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Geçersiz Email Adresi';
  }
  return errors;
};

export default reduxForm({
  form: 'resendConfirmationMail',
  validate,
})(ConfirmationMailResendForm);
