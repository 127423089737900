export const MALE = 'male';
export const FEMALE = 'female';
export const UNSPECIFIED = 'unspecified';
const Gender = {
  // unspecified: 'Belirtilmemiş',
  male: 'Erkek',
  female: 'Kadın',
};

export function getOppositeGender(gender) {
  switch (gender) {
    case MALE:
      return FEMALE;
    case FEMALE:
      return MALE;
    default:
      return UNSPECIFIED;
  }
}

export default Gender;
