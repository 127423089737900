import { APPROVED } from '../../ApprovalType';
import { convertToDate } from '../../../../utils/javascript-object-util';
import { CLEANER_DATETIME, CLEANER_STRING } from '../../../../utils/CleanerValues';

export default class IncidentUpdateRequest {
  constructor(config) {
    if (typeof config !== 'object') return;

    this.companyId = config.companyId;
    this.tagId = config.tagId;
    this.incomeCategory = config.incomeCategory;
    this.isApprovalNeeded = config.isApprovalNeeded && config.isApprovalNeeded === APPROVED;
    this.damageFileIdentifier = config.damageFileIdentifier;
    this.courtFileIdentifier = config.courtFileIdentifier || CLEANER_STRING;
    this.arbitrationFileIdentifier = config.arbitrationFileIdentifier || CLEANER_STRING;
    this.dateOfIncident = convertToDate(config.dateOfIncident);
    this.dateOfReport = convertToDate(config.dateOfReport);
    this.dateOfDeath = convertToDate(config.dateOfDeath) || CLEANER_DATETIME;
    this.dateOfRetirement = convertToDate(config.dateOfRetirement) || CLEANER_DATETIME;
    this.dateOfIssue = convertToDate(config.dateOfIssue);
    this.isWorkAccident = config.isWorkAccident;
  }
}
