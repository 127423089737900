import find from 'lodash/find';
import {
  calculationRules,
  militaryInfo,
  pmfRegulationsEnd,
  pmfRegulationsIntro,
  regulationsBodyList,
  remarrying,
  trhRegulationEndActive,
  trhRegulationEndPassive,
  trhRegulationsEndHeader,
  trhRegulationsEndKnown,
  trhRegulationsIntro,
  workability,
} from './ReportUtil';
import { PMF, TRH_PROGRESSIVE } from '../../../data/model/MortalityTable';
import { DEATH } from '../../../data/model/IncidentType';

export default function StatuteInfo() {
  const { calculation } = this;
  const { details } = calculation;
  const { mortalityTable } = this.config;
  const { hasMilitaryService } = details;
  const { incidentType } = this.incident;
  const { calculatorResults } = details;

  const statute = [];
  if (mortalityTable === PMF || mortalityTable === TRH_PROGRESSIVE) {
    statute.push(mortalityTable === TRH_PROGRESSIVE ? trhRegulationsIntro : pmfRegulationsIntro);
    statute.push(regulationsBodyList);
    if (hasMilitaryService) statute.push(militaryInfo);
    statute.push(calculationRules);
    statute.push(pmfRegulationsEnd);
  } else {
    statute.push(trhRegulationsIntro);
    statute.push(regulationsBodyList);
    if (hasMilitaryService) statute.push(militaryInfo);
    statute.push(calculationRules);
    statute.push(trhRegulationsEndHeader);
    if (details.hasKnown) statute.push(trhRegulationsEndKnown);
    if (details.hasActive) statute.push(trhRegulationEndActive);
    if (details.hasPassive) statute.push(trhRegulationEndPassive);
  }
  statute.push(workability);
  if (incidentType === DEATH) {
    const remarryingCalculator = find(calculatorResults, (c) => c.name === 'remarrying');
    if (remarryingCalculator) {
      // TODO: pass remarrying percentage
      statute.push(remarrying());
    }
  }
  return [
    {
      headlineLevel: 1,
      stack: [
        {
          text: 'İLGİLİ MEVZUATLAR',
          style: 'h1',
          alignment: 'center',
          margin: [0, 20, 0, 10],
        },
        statute.shift(),
      ],
    },
    [...statute],
  ];
}
