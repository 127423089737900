import React from 'react';
import { connect } from 'react-redux';
import { Paper } from 'material-ui';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router';
import { InfoTextReportViewHolder } from '../report/ReportViewHolder';

function InvoiceViewHolder({ detailedInvoice }) {
  const history = useHistory();
  const { invoice, cancellation } = detailedInvoice;
  const isCancelled = !isEmpty(cancellation);
  const data = [];
  data.push({
    title: 'İşlem Tarihleri',
    content: `${invoice.from} - ${invoice.till}`,
    width: 230,
  });
  data.push({
    title: 'Oluşturulma Tarihi',
    content: invoice.createdAt,
    width: 130,
  });
  data.push({
    title: 'Son Ödeme Tarihi',
    content: invoice.dueDate,
    width: 110,
  });
  data.push({
    title: 'Toplam Ücret',
    content: `${invoice.dueAmount} ₺`,
    width: 90,
  });
  return (
    <Paper
      className={'reportViewHolder'}
      style={{
        padding: 16,
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
      }}
      onClick={() => history.push(`/invoices/${invoice.id}`)}
    >
      {isCancelled && <div className={'cancelSign'}>İPTAL</div>}
      {data.map((d) => (
        <InfoTextReportViewHolder style={{ width: d.width }} title={d.title} content={d.content} />
      ))}
    </Paper>
  );
}

export default connect()(InvoiceViewHolder);
