import React from 'react';
import ColorPalette from '../../ColorPalette';
import Clear from './Clear';

function InfoText({ style = {}, labelStyle = {}, title, nonLabel, content }) {
  return (
    <div
      style={{
        fontSize: '16px',
        display: 'inline-block',
        ...style,
      }}
    >
      {!nonLabel && (
        <span
          style={{
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '20px',
            display: 'inline-block',
            float: 'left',
            textAlign: 'left',
            paddingRight: 8,
            width: 100,
            color: ColorPalette.primary1Color,
            ...labelStyle,
          }}
        >
          {title ? `${title}:` : ''}
        </span>
      )}
      <span style={{ display: 'inline-block', float: 'left', width: 'auto' }}>{content}</span>
      <Clear />
    </div>
  );
}

export default InfoText;
