import EndOfSupport from './EndOfSupport';
import PersonResponse from './PersonResponse';

export default class InjuredPersonResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.basic = new PersonResponse(config.basic);
    this.endOfSupport = new EndOfSupport(config.endOfSupport);
  }
}
