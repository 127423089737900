/* eslint-disable react/prop-types,import/no-extraneous-dependencies */
import React, { Component } from 'react';
import ParentForm from './ParentForm';
import { FieldArrayEmptyView } from '../Functional/FieldArrayEmptyView';
import ListAddFAB from '../Functional/ListAddFAB';
import { getOppositeGender } from '../../data/model/Gender';

class ParentListForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastIndex: 0,
    };
  }

  handleOnClick = () => {
    const { fields, casualty } = this.props;
    const initData = { basic: {} };
    if (casualty && casualty.basic) {
      initData.basic.gender = getOppositeGender(casualty.basic.gender);
    }
    initData.basic.tempId = `tempPersonParent${this.state.lastIndex}`;
    fields.push(initData);
    this.setState({ lastIndex: this.state.lastIndex + 1 });
  };

  render() {
    const {
      fields,
      meta: { error, submitFailed },
      values,
      update,
    } = this.props;
    return (
      <div>
        {fields.map((name, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            <ParentForm
              name={name}
              index={index}
              fields={fields}
              update={update}
              id={values[index] && values[index].basic && values[index].basic.id}
            />
          </div>
        ))}
        <FieldArrayEmptyView fields={fields} />
        <ListAddFAB onClick={() => this.handleOnClick()} />
      </div>
    );
  }
}

export default ParentListForm;
