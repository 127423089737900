import * as React from 'react';
import { Field } from 'redux-form';
import {
  renderGender,
  renderActionCategory,
  renderTextField,
  renderTag,
  renderCustomerAutoComplete,
} from '../../utils/redux-metarial-ui-util';
import { normalizeDate } from '../../utils/redux-normalize';
import ColorPalette from '../../ColorPalette';
import MeField from '../ComboBox/MeField';
import { ACTUARY } from '../../data/model/Privilege';

export const filterAbilities = {
  equal: { id: 1, text: 'Eşit' },
  from: { id: 2, text: "'dan Başlayan" },
  till: { id: 3, text: "'a Kadar" },
  between: { id: 4, text: 'Arasında' },
};

const victim = 'Mağdur';
const dead = 'Müteveffa';
const pronoun = ''; // `${victim}/${dead} `;

const fieldStyle = {
  fontWeight: 600,
  margin: '0px 16px',
  width: 128,
};
const styles = {
  fieldStyle,
  dateTimeStyle: {
    ...fieldStyle,
    width: 90,
  },
  percentageStyle: {
    ...fieldStyle,
    width: 90,
  },
  nameStyle: {
    ...fieldStyle,
    width: 128,
  },
  genderStyle: {
    ...fieldStyle,
    width: 'auto',
    float: 'left',
  },
  inputStyle: {
    fontWeight: 600,
    color: ColorPalette.primary1Color,
    textAlign: 'center',
  },
};

export const reportFilterPatterns = {
  issuerId: {
    name: 'issuerId',
    text: 'Benim Raporlarım',
    abilities: [filterAbilities.equal],
    props: {
      inputStyle: styles.inputStyle,
      component: MeField,
      style: styles.fieldStyle,
    },
  },
  category: {
    name: 'category',
    text: `Rapor Durumu`,
    abilities: [filterAbilities.equal],
    props: {
      inputStyle: styles.inputStyle,
      component: renderActionCategory,
      style: styles.genderStyle,
    },
  },
  companyId: {
    name: 'companyId',
    text: `Sigorta Şirketi`,
    abilities: [filterAbilities.equal],
    props: {
      inputStyle: styles.inputStyle,
      component: renderCustomerAutoComplete,
      style: styles.fieldStyle,
    },
    allowedRoles: [ACTUARY],
  },
  tagId: {
    name: 'tagId',
    text: `Departman`,
    abilities: [filterAbilities.equal],
    props: {
      inputStyle: styles.inputStyle,
      component: renderTag,
      style: styles.genderStyle,
    },
  },
  timestamp: {
    name: 'timestamp',
    text: 'Oluşturulma Tarihi',
    abilities: [
      filterAbilities.equal,
      filterAbilities.from,
      filterAbilities.till,
      filterAbilities.between,
    ],
    props: {
      inputStyle: styles.inputStyle,
      component: renderTextField,
      normalize: normalizeDate,
      style: styles.dateTimeStyle,
    },
  },
  dateOfBirth: {
    name: 'dateOfBirth',
    text: `${pronoun}Doğum Tarihi`,
    abilities: [
      filterAbilities.equal,
      filterAbilities.from,
      filterAbilities.till,
      filterAbilities.between,
    ],
    props: {
      inputStyle: styles.inputStyle,
      component: renderTextField,
      normalize: normalizeDate,
      style: styles.dateTimeStyle,
    },
  },
  dateOfDeath: {
    name: 'dateOfDeath',
    text: `${pronoun}Ölüm Tarihi`,
    abilities: [
      filterAbilities.equal,
      filterAbilities.from,
      filterAbilities.till,
      filterAbilities.between,
    ],
    props: {
      inputStyle: styles.inputStyle,
      component: renderTextField,
      normalize: normalizeDate,
      style: styles.dateTimeStyle,
    },
  },
  dateOfRetirement: {
    name: 'dateOfRetirement',
    text: `${pronoun}Emeklilik Tarihi`,
    abilities: [
      filterAbilities.equal,
      filterAbilities.from,
      filterAbilities.till,
      filterAbilities.between,
    ],
    component: ({ ...custom }) => <Field {...custom} />,
    props: {
      inputStyle: styles.inputStyle,
      component: renderTextField,
      normalize: normalizeDate,
      style: styles.dateTimeStyle,
    },
  },
  disabilityRatio: {
    name: 'disabilityRatio',
    text: 'Sürekli Sakatlık Oranı (%)',
    abilities: [
      filterAbilities.equal,
      filterAbilities.from,
      filterAbilities.till,
      filterAbilities.between,
    ],
    props: {
      inputStyle: styles.inputStyle,
      component: renderTextField,
      style: styles.percentageStyle,
    },
    additionalProp: ['onWheel'],
  },
  faultRatio: {
    name: 'faultRatio',
    text: 'Kusur Oranı (%)',
    abilities: [
      filterAbilities.equal,
      filterAbilities.from,
      filterAbilities.till,
      filterAbilities.between,
    ],
    props: {
      inputStyle: styles.inputStyle,
      component: renderTextField,
      style: styles.percentageStyle,
    },
    additionalProp: ['onWheel'],
  },
  compensationAmount: {
    name: 'compensationAmount',
    text: 'Tazminat Tutarı',
    abilities: [
      filterAbilities.equal,
      filterAbilities.from,
      filterAbilities.till,
      filterAbilities.between,
    ],
    props: {
      inputStyle: styles.inputStyle,
      component: renderTextField,
      style: styles.fieldStyle,
    },
  },
  incomeCategory: {
    // Todo Combobox'a çıkar
    name: 'incomeCategory',
    text: 'Gelir Tipi',
    abilities: [filterAbilities.equal],
    props: {
      inputStyle: styles.inputStyle,
      component: renderTextField,
      style: styles.fieldStyle,
    },
  },
  personalIdentificationNumber: {
    name: 'personalIdentificationNumber',
    text: `${pronoun}Kimlik Numarası`,
    abilities: [filterAbilities.equal],
    props: {
      inputStyle: styles.inputStyle,
      component: renderTextField,
      style: styles.fieldStyle,
    },
  },
  fileIdentifier: {
    name: 'fileIdentifier',
    text: `${pronoun}Dosya Numarası`,
    abilities: [filterAbilities.equal],
    props: {
      inputStyle: styles.inputStyle,
      component: renderTextField,
      style: styles.fieldStyle,
    },
  },
  name: {
    name: 'name',
    text: `${pronoun}İsim`,
    abilities: [filterAbilities.equal],
    props: {
      inputStyle: styles.inputStyle,
      component: renderTextField,
      style: styles.nameStyle,
    },
  },
  gender: {
    name: 'gender',
    text: `${pronoun}Cinsiyet`,
    abilities: [filterAbilities.equal],
    props: {
      inputStyle: styles.inputStyle,
      component: renderGender,
      style: styles.genderStyle,
    },
  },
};
