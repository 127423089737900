import React, { Children } from 'react';
import { connect } from 'react-redux';
import Header from '../Header/Header';
import Progressbar from '../Loading/Progressbar';
import Footer from '../Footer/Footer';
import ReduxSnackbar from '../ReduxSnackbar';

function BaseLayout({ children, isProgressbarOpen }) {
  return (
    <div style={{ backgroundColor: '#f5f5f5' }}>
      <Header />
      {isProgressbarOpen && <Progressbar />}
      {Children.only(children)}
      <ReduxSnackbar />
      <Footer />
    </div>
  );
}

export default connect((state) => ({
  isProgressbarOpen: state.runtime.isProgressbarOpen,
}))(BaseLayout);
