import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DETAILED_INCIDENT_CC } from '../../constants';
import { InfoTextReportViewHolder } from '../../routes/report/ReportViewHolder';

class ReportFlexInfo extends React.Component {
  render() {
    const { detailedIncident } = this.props;
    if (!detailedIncident) return <div />;
    const { incident, injuredParty } = detailedIncident;
    const { casualty } = injuredParty;
    return (
      <div className={'reportTimeDetail'}>
        <InfoTextReportViewHolder title="Doğum Tarihi" content={casualty.basic.dateOfBirth} />
        <InfoTextReportViewHolder title="Poliçe Tanzim Tarihi" content={incident.dateOfIssue} />
        <InfoTextReportViewHolder title="Kaza Tarihi" content={incident.dateOfIncident} />
        <InfoTextReportViewHolder title="Rapor Tarihi" content={incident.dateOfReport} />
        {incident.dateOfDeath && (
          <InfoTextReportViewHolder title="Vefat Tarihi" content={incident.dateOfDeath} />
        )}
        {incident.dateOfRetirement && (
          <InfoTextReportViewHolder title="Emeklilik Tarihi" content={incident.dateOfRetirement} />
        )}

        <FontAwesomeIcon
          style={{
            position: 'absolute',
            top: 5,
            right: 0,
            color: '#616161',
          }}
          icon="calendar-alt"
          fixedWidth
        />
      </div>
    );
  }
}

export default connect((state) => ({
  detailedIncident: state.appData[DETAILED_INCIDENT_CC],
}))(ReportFlexInfo);
