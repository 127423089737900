import React from 'react';
import { connect } from 'react-redux';
import { Checkbox,  FlatButton, Subheader, TextField } from 'material-ui';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Privilege from '../../data/model/Privilege';
import Clear from '../../components/Functional/Clear';
import Authorization from '../../components/Authorization/Authorization';
import { USER } from '../../constants';
import DetailedRoleResponse from '../../data/model/response/DetailedRoleResponse';
import EmptyView from '../../components/Functional/EmptyView';
import { objDiff } from '../../utils/javascript-object-util';
import { openSnackbar } from '../../actions/snackbarActions';
import TagComboBox from '../../components/ComboBox/TagComboBox';
import ColorPalette from '../../ColorPalette';
import RoleApi from '../../data/api/RoleApi';
import AccountApi from '../../data/api/AccountApi';
import PrivilegeApi from '../../data/api/PrivilegeApi';
import Dialog from "../../components/Functional/Dialog";

class DetailedRoleView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detailedRole: null,
      open: false,
    };
  }

  componentDidMount() {
    const { role, companyId } = this.props;

    RoleApi.find(companyId, role.id).then(this.handleData);
  }

  handleData = (r) => {
    const detailedRole = new DetailedRoleResponse(r);
    const privileges = {};
    Object.keys(Privilege).forEach((p) => (privileges[p] = detailedRole.privileges.includes(p)));
    const stateRole = {
      name: detailedRole.role.name,
      tagId: detailedRole.tag.id,
      privileges,
    };
    this.setState({ role: stateRole });
    this.setState({ initRole: cloneDeep(stateRole) });
    this.setState({ detailedRole });
  };

  handleUpdateOnClick = () => {
    const { role, initRole } = this.state;
    const { dispatch } = this.props;
    const { companyId } = this.props;
    const roleId = this.props.role.id;
    if (role.name !== initRole.name || role.tagId !== initRole.tagId) {
      if (!role.name || !role.name.length === 0) {
        dispatch(openSnackbar('Rol adı en az 1 karakter içermelidir'));
      } else {
        RoleApi.update(companyId, roleId, {
          name: role.name,
          tagId: role.tagId,
        }).then((r) => {
          this.setState({ initRole: cloneDeep(role) });
          AccountApi.getMe(dispatch);
          this.handleClose();
        });
      }
    }
    const newPrivilegesDiff = objDiff(role.privileges, initRole.privileges);
    if (!isEmpty(newPrivilegesDiff)) {
      const oldPrivilegesDiff = objDiff(initRole.privileges, role.privileges);
      const additions = Object.keys(newPrivilegesDiff).filter((p) => newPrivilegesDiff[p]);
      const removals = Object.keys(oldPrivilegesDiff).filter((p) => oldPrivilegesDiff[p]);

      PrivilegeApi.update(companyId, roleId, {
        additions,
        removals,
      }).then((r) => {
        this.setState({ initRole: cloneDeep(role) });
        AccountApi.getMe(dispatch);
        RoleApi.find(companyId, this.props.role.id).then(this.handleData);
        this.handleClose();
      });
    }
  };

  handleCheckChange = (p) => {
    const { role } = this.state;
    role.privileges[p] = !role.privileges[p];
    this.setState({ role });
  };

  handleNameChange = (e) => {
    const { role } = this.state;
    role.name = e.target.value;
    this.setState({ role });
  };

  handleTagIdChange = (e) => {
    const { role } = this.state;
    role.tagId = e;
    this.setState({ role });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.setState({ initRole: cloneDeep(this.state.role) });
  };

  render() {
    const { detailedRole } = this.state;
    const { role } = this.state;
    const { initRole } = this.state;
    const isChange = role && initRole && !isEmpty(objDiff(role, initRole));
    const actions = [
      <FlatButton label="İptal" keyboardFocused secondary onClick={this.handleClose} />,
      <FlatButton
        label="Güncelle"
        disabled={!isChange}
        primary
        onClick={this.handleUpdateOnClick}
      />,
    ];
    if (isEmpty(detailedRole))
      return (
        <div style={{ position: 'relative', minHeight: 70 }}>
          <img src="/loading.svg" alt="Loading" className={'center'} style={{ width: 64 }} />
        </div>
      );
    return (
      <div
        style={{
          padding: '16px',
          position: 'relative',
          minHeight: 70,
        }}
      >
        {isEmpty(detailedRole.privileges) ? (
          <EmptyView icon="chess" label="Hiç Yetki Yok" />
        ) : (
          <div>
            <Subheader
              style={{
                paddingLeft: 0,
                lineHeight: 'unset !important',
                paddingBottom: 16,
              }}
            >
              Yetkiler
            </Subheader>
            {detailedRole.privileges.map((p) => (
              <Authorization allowedRoles={Privilege[p].allowedRoles}>
                <div style={{ display: 'block', fontWeight: '400' }}>
                  <FontAwesomeIcon
                    fixedWidth
                    icon="check-circle"
                    style={{ color: ColorPalette.primary1Color }}
                  />
                  {Privilege[p].text}
                </div>
              </Authorization>
            ))}
          </div>
        )}

        <div
          style={{
            position: 'absolute',
            fontWeight: '500',
            fontSize: '18px',
            right: 30,
            top: 16,
          }}
        >
          {detailedRole.tag.name}
        </div>

        <FlatButton
          style={{ zIndex: 3, position: 'absolute', right: 16, bottom: 16 }}
          primary
          label="Güncelle"
          onClick={this.handleOpen}
        />

        <Dialog
          title="Role Güncelleme"
          actions={actions}
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
        >
          <TextField
            floatingLabelText="Rol Adı"
            value={role.name}
            floatingLabelFixed
            fullWidth
            onChange={this.handleNameChange}
          />
          <TagComboBox
            title="Departman"
            floatingLabelFixed
            value={role.tagId}
            fullWidth
            onChange={this.handleTagIdChange}
          />
          {Object.keys(role.privileges).map((p) => (
            <Authorization allowedRoles={Privilege[p].allowedRoles}>
              <Checkbox
                checked={role.privileges[p]}
                label={Privilege[p].text}
                onClick={() => this.handleCheckChange(p)}
              />
            </Authorization>
          ))}
        </Dialog>
        <Clear />
      </div>
    );
  }
}

export default connect((state) => ({
  user: state.appData[USER],
}))(DetailedRoleView);
