import { convertToDate, getNameFragments } from '../../../../utils/javascript-object-util';

export default class PersonCreateRequest {
  constructor(config, defaultValues = {}) {
    if (typeof config !== 'object') return;

    const { dateOfBirth } = defaultValues;
    this.personalIdentificationNumber = config.personalIdentificationNumber;
    [this.name, this.surname] = getNameFragments(config.fullName);
    this.dateOfBirth = config.dateOfBirth ? convertToDate(config.dateOfBirth) : dateOfBirth;
    this.gender = config.gender;
    this.hideShare = config.hideShare;
    this.tempId = config.tempId;
    this.endOfSupportToOverride = convertToDate(config.endOfSupportToOverride);
  }
}
