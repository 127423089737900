/* eslint-disable react/prop-types,prefer-destructuring */
import React from 'react';
import { connect } from 'react-redux';
import { FlatButton, RaisedButton } from 'material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, FormSection } from 'redux-form';
import { fieldStyle, getFieldArrayItemStyle } from '../../utils/material-ui-style-util';
import { renderDocumentType } from '../../utils/redux-metarial-ui-util';
import Clear from '../Functional/Clear';
import FileInput from '../Functional/FileInput';
import { roundPrecision } from '../../utils/javascript-object-util';

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: null,
    };
  }

  handleValueChange = (files) => {
    this.setState({
      files,
    });
    this.props.handleValueChange(files, this.props.index);
  };

  render() {
    const index = this.props.index;
    const fields = this.props.fields;
    const fileInputStyle = {
      cursor: 'pointer',
      position: 'absolute',
      top: '0',
      bottom: '0',
      right: '0',
      left: '0',
      width: '100%',
      opacity: '0',
      zIndex: 99,
    };

    const files = this.state.files;
    const fileInfos = [];
    if (files)
      for (let i = 0; i < files.length; i++) {
        fileInfos.push({ name: files[i].name, size: files[i].size });
      }

    return (
      <FormSection style={getFieldArrayItemStyle(index, fields.length)} name={this.props.name}>
        <Field
          style={fieldStyle}
          label="Belge Tipi"
          name="category"
          component={renderDocumentType}
        />
        <RaisedButton style={{ float: 'left', marginTop: 28 }} label="Dosya Seç">
          <Field
            style={fileInputStyle}
            name="document"
            multiple
            component={FileInput}
            handleValueChange={this.handleValueChange}
          />
        </RaisedButton>

        {files && (
          <span style={{ margin: '36px 0 0 16px', float: 'left' }}>
            {fileInfos
              .map((f) => `${f.name} (${roundPrecision(f.size / (1024 * 1024), 3)}mb)`)
              .join(' | ')}
          </span>
        )}

        <div>
          <FlatButton
            style={{ position: 'absolute', minWidth: 36, top: 0, right: 0 }}
            className="material-icons"
            tooltip="Kaldır"
            icon={<FontAwesomeIcon fixedWidth icon="trash" />}
            onClick={() => fields.remove(index)}
          />
        </div>
        <Clear />
      </FormSection>
    );
  }
}

export default connect()(FileUpload);
