import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Paper, RaisedButton, Subheader } from 'material-ui';
import { renderTextField } from '../../utils/redux-metarial-ui-util';
import { clearAppData, setAppData } from '../../actions/appdata';
import { INIT_COMPANY, USER } from '../../constants';
import Clear from '../../components/Functional/Clear';
import { hidePB, showPB } from '../../actions/progressbarAction';
import CompanyApi from '../../data/api/CompanyApi';
import Layout from '../../components/Layout/Layout';
import Authorization from '../../components/Authorization/Authorization';
import { ReadCompany } from '../../data/model/Privilege';

class CompanyDetail extends React.Component {
  fetchData = async (props) => {
    const { dispatch, user } = props;
    if (user) {
      showPB(dispatch);
      const companyId = user.getCompanyId;
      CompanyApi.find(companyId).then((r) => {
        this.props.dispatch(setAppData({ name: INIT_COMPANY, value: r }));
        hidePB(dispatch);
      });
    }
  };

  componentDidMount() {
    this.fetchData(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.fetchData(this.props);
    }
  }

  componentWillUnmount() {
    this.props.dispatch(clearAppData(INIT_COMPANY));
  }

  render() {
    return (
      <Layout>
        <Authorization allowedRoles={[ReadCompany]} force>
          <div className={'root'}>
            <div className={`container max380`}>
              <h1>Şirket Bilgileri</h1>
              <Paper>
                <Subheader>Şirket Bilgileri</Subheader>
                <form style={{ padding: '0 32px' }}>
                  <Field
                    name="officialName"
                    label="Şirket Adı"
                    component={renderTextField}
                    fullWidth
                  />
                  <Field
                    name="taxOffice"
                    label="Vergi Dairesi"
                    component={renderTextField}
                    fullWidth
                  />
                  <Field
                    name="taxNo"
                    label="Vergi Numarası"
                    component={renderTextField}
                    fullWidth
                  />
                  <Field name="address" label="Adres" component={renderTextField} fullWidth />
                  <Field name="postCode" label="Posta Kodu" component={renderTextField} fullWidth />
                  <Field
                    name="phoneNumber"
                    label="Telefon Numarası"
                    component={renderTextField}
                    fullWidth
                  />
                  <Field
                    name="faxNumber"
                    label="Fax Numarası"
                    component={renderTextField}
                    fullWidth
                  />
                  <Field name="web" label="Web Sitesi" component={renderTextField} fullWidth />
                  <RaisedButton
                    className={'right'}
                    style={{ margin: '32px 0' }}
                    primary
                    label="Güncelle"
                    type="submit"
                  />
                  <Clear />
                </form>
              </Paper>
            </div>
          </div>
        </Authorization>
      </Layout>
    );
  }
}

CompanyDetail = reduxForm({ form: 'companyDetail' })(CompanyDetail);

export default connect((state) => ({
  user: state.appData[USER],
  initialValues: state.appData[INIT_COMPANY],
}))(CompanyDetail);
