/* eslint-disable react/prop-types,prefer-destructuring */
import React from 'react';
import { connect } from 'react-redux';
import { FlatButton } from 'material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Clear from '../Functional/Clear';
import FileInput from '../Functional/FileInput';
import { roundPrecision } from '../../utils/javascript-object-util';

class FileUploadCompact extends React.Component {
  handleValueChange = (file) => {
    if (file && file.length === 1) this.setState({ file: file[0] });
  };

  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
  }

  render() {
    const fileInputStyle = {
      cursor: 'pointer',
      position: 'absolute',
      top: '0',
      bottom: '0',
      right: '0',
      left: '0',
      width: '100%',
      opacity: '0',
      zIndex: 99,
    };

    const file = this.state.file;

    return (
      <div style={this.props.style}>
        {!file && (
          <FlatButton style={{ float: 'left' }} label={this.props.label || 'Rapor Yükle'}>
            <FileInput style={fileInputStyle} handleValueChange={this.handleValueChange} />
          </FlatButton>
        )}
        {file && (
          <div>
            <div style={{ float: 'right' }}>
              <FlatButton
                style={{ float: 'right' }}
                label="İptal"
                onClick={() => this.setState({ file: null })}
              />

              <FlatButton
                style={{ float: 'right' }}
                className="material-icons"
                label="Yükle"
                icon={<FontAwesomeIcon fixedWidth icon="upload" />}
                onClick={() => {
                  this.props.submitUpload(this.state.file);
                  this.setState({ file: null });
                }}
              />
            </div>
            <span
              style={{
                float: 'right',
                marginRight: 16,
                marginTop: 8,
                paddingLeft: 8,
              }}
            >
              {`${file.name} (${roundPrecision(file.size / (1024 * 1024), 3)}mb)`}
            </span>
          </div>
        )}
        <Clear />
      </div>
    );
  }
}

export default connect()(FileUploadCompact);
