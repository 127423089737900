import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { FlatButton, RaisedButton } from 'material-ui';
import { renderTextField, renderTransactionTemplate } from '../../utils/redux-metarial-ui-util';
import { openSnackbar } from '../../actions/snackbarActions';
import { hidePB, showPB } from '../../actions/progressbarAction';
import PricingApi from '../../data/api/PricingApi';
import { REQUIRED, validateAmount } from '../../utils/validation';
import Clear from '../../components/Functional/Clear';
import TransactionMessageTemplate from '../../data/model/TransactionMessageTemplate';

const defaultShadow = 'rgba(0, 0, 0, 0.12) 0px 1px 6px';
const depositShadow = '#689f38b3 0px 0px 16px 4px';
const withdrawalShadow = '#b71c1c78 0px 0px 16px 4px';
export const renderTransactionCategory = ({ input, meta: { touched, error }, style }) => (
  <div style={{ position: 'relative', ...style }}>
    <RaisedButton
      style={{
        minWidth: 128,
        boxShadow: input.value === 'deposit' ? depositShadow : defaultShadow,
        zIndex: input.value === 'deposit' ? 4 : 2,
        position: 'relative',
      }}
      label="Ekle"
      backgroundColor="white"
      onClick={() => input.onChange('deposit')}
    />
    <RaisedButton
      style={{
        minWidth: 128,
        boxShadow: input.value === 'withdrawal' ? withdrawalShadow : defaultShadow,
        zIndex: input.value === 'withdrawal' ? 4 : 2,
        position: 'relative',
      }}
      backgroundColor="white"
      label="Çıkar"
      onClick={() => input.onChange('withdrawal')}
    />

    {touched && (
      <span
        style={{
          color: '#f44336',
          display: 'block',
          fontSize: '12px',
          marginTop: '6px',
        }}
      >
        {error}
      </span>
    )}
  </div>
);

class TransactionForm extends React.Component {
  onSubmit = (values, dispatch) => {
    showPB(dispatch);
    const { pricing } = this.props;
    const obj = JSON.parse(JSON.stringify(values));
    obj.message = TransactionMessageTemplate[obj.message];
    PricingApi.addCorrection(dispatch, pricing.incident.summary.id, obj).then(
      (r) => {
        hidePB(dispatch);
        this.props.onSubmitSuccess();
        this.props.onRequestClose();
      },
      (r) => {
        hidePB(dispatch);
        dispatch(openSnackbar('Bir hata oluştu'));
        this.props.onRequestClose();
      },
    );
  };

  render() {
    return (
      <div style={{ display: 'inline-block' }}>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Field
            name="message"
            floatingLabelText="Açıklama"
            floatingLabelFixed
            component={renderTransactionTemplate}
          />
          <Field name="amount" floatingLabelText="Tutar" component={renderTextField} />
          <Field
            name="category"
            style={{ margin: '16px 0', float: 'left' }}
            component={renderTransactionCategory}
          />
          <div style={{ margin: 'auto', display: 'table', paddingTop: '16px' }}>
            <FlatButton label="İptal" onClick={this.props.onRequestClose} />
            <FlatButton label="Tamam" type="submit" secondary />
          </div>
          <Clear />
        </form>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  errors.amount = validateAmount(values.amount, true);
  if (!values.message) errors.message = REQUIRED;
  if (!values.category) errors.category = REQUIRED;
  return errors;
};
TransactionForm = reduxForm({ form: 'transactionForm', validate })(TransactionForm);
export default connect()(TransactionForm);
