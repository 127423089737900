import React from 'react';
import { connect } from 'react-redux';
import { openSnackbar } from '../../actions/snackbarActions';

const MB = 10;
const MAX_FILE_SIZE = MB * 1024 * 1024;

class FileInput extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const { handleValueChange, dispatch } = this.props;
    const file = e.target.files[0];
    if (!file) return;
    for (let i = 0; i < e.target.files.length; i++) {
      if (file.size > MAX_FILE_SIZE) {
        dispatch(openSnackbar(`Dosya Boyutu ${MB}mb'dan küçük olmalıdır`));
        return;
      }
    }
    handleValueChange(e.target.files);
  }

  render() {
    return (
      <div>
        <input
          style={this.props.style}
          type="file"
          onChange={this.onChange}
          multiple={this.props.multiple}
          onBlur={() => {}}
        />
      </div>
    );
  }
}

export default connect()(FileInput);
