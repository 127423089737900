import React from 'react';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import Add from 'material-ui/svg-icons/content/add';
import List from 'material-ui/List';
import { connect } from 'react-redux';
import { hidePB, showPB } from '../../actions/progressbarAction';
import Link from '../../components/Link/Link';
import Pager from '../../components/Functional/Pager';
import { CUSTOMERS } from '../../constants';
import EmptyView from '../../components/Functional/EmptyView';
import CustomerApi from '../../data/api/CustomerApi';
import CustomerCardView from './CustomerCardView';
import Layout from '../../components/Layout/Layout';
import Authorization from '../../components/Authorization/Authorization';
import { ReadCompany } from '../../data/model/Privilege';

class CustomerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      page: 0,
    };
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    const { page } = this.state;
    this.fetchData(page, this.props);
  }

  buildList = () => {
    const data = this.props.customers;
    if (data) {
      return data.map((customer) => <CustomerCardView key={customer.id} customer={customer} />);
    }
    return [];
  };

  handlePageChange = (page) => {
    this.setState({ page });
    this.fetchData(page, this.props);
  };

  async fetchData(page, props) {
    const { dispatch } = props;
    showPB(dispatch);
    CustomerApi.list(dispatch, undefined, page).then(() => hidePB(dispatch));
  }

  render() {
    const list = this.buildList();
    return (
      <Layout>
        <Authorization allowedRoles={[ReadCompany]} force>
          <div className={'root'}>
            <div className={'container'}>
              <div className={'max1024'} style={{ padding: '0 0 80px' }}>
                <h1>Şirket Listesi</h1>
                <List>{list.length > 0 ? list : <EmptyView icon="building" />}</List>
                <Pager
                  currentPage={this.state.page}
                  currentPageItemCount={list ? list.length : 0}
                  onChange={this.handlePageChange}
                />
                <div className={'fabStyle'}>
                  <Link to="/company/create">
                    <FloatingActionButton>
                      <Add />
                    </FloatingActionButton>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Authorization>
      </Layout>
    );
  }
}

export default connect((state) => ({
  customers: state.appData[CUSTOMERS],
}))(CustomerList);
