/* eslint-disable react/prop-types,no-class-assign */
import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { FlatButton } from 'material-ui';
import { renderDocumentType, renderTextField } from '../../utils/redux-metarial-ui-util';
import { openSnackbar } from '../../actions/snackbarActions';
import { hidePB, showPB } from '../../actions/progressbarAction';
import { REQUIRED } from '../../utils/validation';
import Clear from '../Functional/Clear';
import { DOC_TYPE_REPORT, INIT_FILE_UPDATE } from '../../constants';
import FileApi from '../../data/api/FileApi';
import { clearAppData } from '../../actions/appdata';

class FileUpdateForm extends React.Component {
  onSubmit = (values, dispatch) => {
    showPB(dispatch);
    const { file, incidentId } = this.props;
    const obj = JSON.parse(JSON.stringify(values));
    FileApi.update(incidentId, file.id, obj).then(
      (r) => {
        hidePB(dispatch);
        this.props.onSubmitSuccess();
        this.props.onRequestClose();
      },
      (r) => {
        hidePB(dispatch);
        dispatch(openSnackbar('Bir hata oluştu'));
        this.props.onRequestClose();
      },
    );
  };

  componentWillUnmount() {
    this.props.dispatch(clearAppData(INIT_FILE_UPDATE));
  }

  render() {
    return (
      <div style={{ display: 'inline-block' }}>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Field name="name" floatingLabelText="Dosya Adı" component={renderTextField} />
          {this.props.categoryId !== DOC_TYPE_REPORT && (
            <Field name="categoryId" label="Belge Tipi" component={renderDocumentType} />
          )}
          <div style={{ margin: 'auto', display: 'table', paddingTop: '16px' }}>
            <FlatButton label="İptal" onClick={this.props.onRequestClose} />
            <FlatButton label="Güncelle" type="submit" secondary />
          </div>
          <Clear />
        </form>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.name) errors.name = REQUIRED;
  if (!values.categoryId) errors.categoryId = REQUIRED;
  return errors;
};
FileUpdateForm = reduxForm({ form: 'documentUpdateForm', validate })(FileUpdateForm);
const selector = formValueSelector('documentUpdateForm');

export default connect((state) => ({
  categoryId: selector(state, 'categoryId'),
  initialValues: state.appData[INIT_FILE_UPDATE],
}))(FileUpdateForm);
