import RestMessageResponse from './RestMessageResponse';
import EventResponse from './EventResponse';

export default class DetailedEventResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.event = new EventResponse(config.event);
    this.request = new RestMessageResponse(config.request);
    this.response = new RestMessageResponse(config.response);
  }
}
