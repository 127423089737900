import React from 'react';
import './Header.scss';
import Link from '../Link/Link';
import Navigation from '../Navigation/Navigation';
import Clear from '../Functional/Clear';

class Header extends React.Component {
  render() {
    return (
      <div className={'header-root'}>
        <div className={'container'}>
          <Navigation />
          <Link className={'brand'} to="/">
            <img alt="Inova Aktüeryal Danışmanlık" height={64} src={'/logo.png'} />
          </Link>
          <Clear />
        </div>
      </div>
    );
  }
}

export default Header;
