import FileCategoryResponse from './FileCategoryResponse';

export default class FileResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.id = config.id;
    this.name = config.name;
    this.url = config.url;
    this.extension = config.extension;
    this.category = new FileCategoryResponse(config.category);
    this.createdAt = config.createdAt;
  }
}
