import { setAppData } from '../../actions/appdata';
import { INVOICE_DRAFT, INVOICE_DRAFT_LIST, INVOICES } from '../../constants';
import Connection from './Connection';
import InvoiceDraftResponse from '../model/response/InvoiceDraftResponse';
import DetailedInvoiceDraftResponse from '../model/response/DetailedInvoiceDraftResponse';
import { toQuery } from '../../utils/javascript-object-util';

export default class InvoiceDraftApi extends Connection {
  static list(dispatch, companyId) {
    return this._getSecurely(`/${INVOICES}/drafts/companies/${companyId}`)
      .then(...this.justData)
      .then((r) =>
        dispatch(
          setAppData({
            name: INVOICE_DRAFT_LIST,
            value: r ? r.bindList(InvoiceDraftResponse) : [],
          }),
        ),
      )
      .catch(this.catchError);
  }

  static find(dispatch, companyId, tagId, till) {
    return this._getSecurely(
      `/${INVOICES}/drafts/companies/${companyId}/tags/${tagId}${toQuery({ till })}`,
    )
      .then(...this.justData)
      .then((r) =>
        dispatch(
          setAppData({
            name: INVOICE_DRAFT,
            value: new DetailedInvoiceDraftResponse(r),
          }),
        ),
      )
      .catch(this.catchError);
  }

  static promote(companyId, till, tagId) {
    return this._put(`/${INVOICES}/drafts/companies/${companyId}/promote`, {
      till,
      tagId,
    }).catch(this.catchError);
  }

  static fill(companyId, tagId, from, till) {
    return this._put(`/${INVOICES}/drafts/companies/${companyId}/fill`, {
      tagId,
      from,
      till,
    }).catch(this.catchError);
  }

  static addItem(companyId, incidentId) {
    return this._post(`/${INVOICES}/drafts/companies/${companyId}/items`, {
      incidentId,
    }).catch(this.catchError);
  }

  static deleteItem(companyId, incidentId) {
    return this._delete(`/${INVOICES}/drafts/companies/${companyId}/items`, {
      incidentId,
    }).catch(this.catchError);
  }
}
