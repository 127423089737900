/* eslint-disable react/prop-types,no-useless-constructor,prefer-destructuring */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, FormSection, formValueSelector, reduxForm } from 'redux-form';
import { FlatButton } from 'material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  renderAfter2020MortalityTables,
  renderCheckbox,
  renderLifeAnnuity,
  renderMortalityTables,
  renderTextField,
} from '../../../utils/redux-metarial-ui-util';
import { DEATH, DISABLED } from '../../../data/model/IncidentType';
import Clear from '../../Functional/Clear';
import { TRH } from '../../../data/model/MortalityTable';
import { getFieldArrayItemStyle } from '../../../utils/material-ui-style-util';
import { toMoment } from '../../../utils/javascript-object-util';
import { CIRCULAR_2020_MOMENT, CIRCULAR_2021_MOMENT } from '../../../constants';

let CalculationConfigForm = ({
  name,
  configs,
  single,
  index,
  fields,
  incidentType,
  dateOfIssue,
}) => {
  const [open, setOpen] = useState(false);
  const mortalityTable = configs && configs[index] && configs[index].mortalityTable;
  const isBefore2020 = dateOfIssue ? toMoment(dateOfIssue).isBefore(CIRCULAR_2020_MOMENT) : null;
  const isBefore2021 = dateOfIssue ? toMoment(dateOfIssue).isBefore(CIRCULAR_2021_MOMENT) : null;

  return (
    <FormSection
      style={{
        ...getFieldArrayItemStyle(index, fields.length, single),
        paddingLeft: 0,
        paddingRight: 0,
      }}
      name={name}
    >
      <div className={'basicReportFieldContainer'}>
        <Field
          fullWidth
          name="mortalityTable"
          title="Ölüm Tablosu"
          component={
            isBefore2020 !== false ? renderMortalityTables : renderAfter2020MortalityTables
          }
        />
      </div>
      <div className={'basicReportFieldContainer'}>
        <Field fullWidth component={renderTextField} name="faultRatio" label="Kusur Oranı (%)" />
      </div>
      {incidentType === DISABLED && (
        <div className={'basicReportFieldContainer'}>
          <Field
            fullWidth
            component={renderTextField}
            name="disabilityRatio"
            floatingLabelStyle={{ fontSize: '16px' }}
            label="S.Sakatlık Oranı(%)"
          />
        </div>
      )}
      {isBefore2020 !== false && incidentType === DEATH && mortalityTable === TRH && (
        <div className={'basicReportFieldContainer'}>
          <Field
            fullWidth
            component={renderLifeAnnuity}
            name="lifeAnnuity"
            title="Hesaplama Türü"
          />
        </div>
      )}
      <div
        className={'optionalFieldContainer'}
        style={{
          visibility: open ? 'visible' : 'hidden',
          opacity: open ? 1 : 0,
          maxHeight: open ? 300 : 0,
          marginTop: open ? 24 : 0,
          paddingTop: open ? 16 : 0,
        }}
      >
        <span className={'optionalFieldHeader'}>Detaylı Bilgiler</span>
        {isBefore2021 && (
          <div className={'basicReportFieldContainer'} style={{ position: 'relative' }}>
            <div className={`tooltipv2 questionMark`}>
              <FontAwesomeIcon fixedWidth icon="question-circle" />
              <div className={'tooltipTextv2'}>
                İşaretli olduğunda bakiye ömür yerine <b>99 yaş</b> kullanılır.
              </div>
            </div>
            <Field
              style={{ marginTop: 38 }}
              fullWidth
              labelStyle={{
                lineHeight: '16px',
                marginTop: '4px',
                width: '100%',
                color: '#7f7f7f',
                fontWeight: '500',
              }}
              component={renderCheckbox}
              name="useTerminalAge"
              label="Tam Hayat"
            />
          </div>
        )}
        {isBefore2020 && (
          <div className={'basicReportFieldContainer'} style={{ position: 'relative' }}>
            <div className={`tooltipv2 questionMark`}>
              <FontAwesomeIcon fixedWidth icon="question-circle" />
              <div className={'tooltipTextv2'}>
                <b>İşaretli Durumda:</b> Çocuk Mağdur/Müteveffa çalışmaya başlama yaşı yerine kaza
                tarihinden itibaren Asgari eksi Agi üzerinden destek verir olarak kabul edilir.
                <br />
                <b>İşaretsiz Durumda:</b> Çalışmaya başlama yaşına kadar (Bordro/Sgk hizmet dökümü
                veya GİG yoksa) destek veremez olarak kabul edilir.
              </div>
            </div>
            <Field
              style={{ marginTop: 38 }}
              component={renderCheckbox}
              labelStyle={{
                lineHeight: '16px',
                marginTop: '-5px',
                width: '100%',
                color: '#7f7f7f',
                fontWeight: '500',
              }}
              name="usePassiveEarningUntilAgeOfConsent"
              label={
                <span>
                  Çalışma Yaşını
                  <br />
                  Kaldır
                </span>
              }
            />
          </div>
        )}

        {!isBefore2021 && (
          <div className={'basicReportFieldContainer'} style={{ position: 'relative' }}>
            <div className={`tooltipv2 questionMark`}>
              <FontAwesomeIcon fixedWidth icon="question-circle" />
              <div className={'tooltipTextv2'}>
                <b>İşaretli Durumda:</b> Pay dağılımında Hane Halkı kavramını kullanır.
                <br />
                <b>İşaretsiz Durumda:</b> Pay dağılımında Hane Halkı kavramını
                <b> kaldırır.</b>
              </div>
            </div>
            <Field
              style={{ marginTop: 38 }}
              component={renderCheckbox}
              labelStyle={{
                lineHeight: '16px',
                marginTop: '4px',
                width: '100%',
                color: '#7f7f7f',
                fontWeight: '500',
              }}
              name="useHouseholdPriorityShare"
              label={
                <span>
                  Hane Halkı
                  <br />
                  Kavramını Kullan
                </span>
              }
            />
          </div>
        )}
        <Clear />
      </div>
      <div className={'bottomRightLinkButton'} onClick={() => setOpen(!open)}>
        <FontAwesomeIcon icon={open ? 'caret-up' : 'caret-down'} fixedWidth />
        Detayları&nbsp;
        {open ? 'Gizle' : 'Göster'}
      </div>
      {fields.length > 1 && (
        <div>
          <FlatButton
            style={{ position: 'absolute', minWidth: 36, top: 0, right: 0 }}
            className="material-icons"
            tooltip="Kaldır"
            icon={<FontAwesomeIcon fixedWidth icon="trash" />}
            onClick={() => fields.remove(index)}
          />
        </div>
      )}
      <Clear />
    </FormSection>
  );
};

CalculationConfigForm = reduxForm({
  form: 'basicReport',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(CalculationConfigForm);

const selector = formValueSelector('basicReport');
export default connect((state) => ({
  configs: selector(state, 'calculationConfigs'),
  incidentType: selector(state, 'incident.incidentType'),
  dateOfIssue: selector(state, 'incident.dateOfIssue'),
}))(CalculationConfigForm);
