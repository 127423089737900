/* eslint-disable no-return-assign */
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import CasualtyResponse from './CasualtyResponse';
import SpouseResponse from './SpouseResponse';
import ChildResponse from './ChildResponse';
import ParentResponse from './ParentResponse';
import { DEATH, DISABLED } from '../IncidentType';
import MaritalStatus from '../MaritalStatus';
import { MALE } from '../Gender';

export function injuredPartyDegree(person, injuredParty = {}, incidentType) {
  function getPlaceText(people) {
    const index = people && findIndex(people, (p) => p.basic.id === person.basic.id) + 1;
    if (!people) return '';
    return people.length > 1 ? `${index}.` : '';
  }

  if (person instanceof SpouseResponse) {
    const placeText = getPlaceText(injuredParty.spouses);
    return `${placeText}Eş`;
  }
  if (person instanceof ParentResponse) {
    return person.basic.gender === MALE ? 'Baba' : 'Anne';
  }
  if (person instanceof ChildResponse) {
    const placeText = getPlaceText(injuredParty.children);
    return `${placeText}Çocuk`;
  }
  if (person instanceof CasualtyResponse) {
    return incidentType === DEATH ? 'Müteveffa' : 'Mağdur';
  }
  return 'Unknown';
}

export default class InjuredPartyResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.casualty = config.casualty && config.casualty.bindClass(CasualtyResponse);
    this.spouses = config.spouses ? config.spouses.bindList(SpouseResponse) : [];
    this.children = config.children ? config.children.bindList(ChildResponse) : [];
    this.parents = config.parents ? config.parents.bindList(ParentResponse) : [];
  }

  get hasChild() {
    return this.children.length > 0;
  }

  get incidentType() {
    return this.casualty.dateOfDeath ? DEATH : DISABLED;
  }

  get hashSet() {
    const map = {};
    [this.casualty, ...this.familyMembers].forEach((p) => (map[p.basic.id] = p));
    return map;
  }

  get familyMembers() {
    return [...this.spouses, ...this.parents, ...this.children];
  }

  get familyMembersWithoutSimulated() {
    return [...this.spouses, ...this.parents, ...this.children].filter((p) => !p.basic.simulatedAt);
  }

  get getMaritalStatus() {
    return this.spouses.filter((p) => !p.basic.simulatedAt).filter((s) => isEmpty(s.divorce))
      .length > 0
      ? MaritalStatus.married
      : MaritalStatus.single;
  }

  isChild(personId) {
    return this.children.filter((c) => c.basic.getPersonId === personId).length > 0;
  }

  isParent(personId) {
    return this.parents.filter((p) => p.basic.getPersonId === personId).length > 0;
  }

  isSpouse(personId) {
    return this.spouses.filter((s) => s.basic.getPersonId === personId).length > 0;
  }

  hasWife() {
    const spouses = this.spouses.filter((p) => !p.basic.simulatedAt);

    return spouses.length > 0;
  }
}
