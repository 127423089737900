/* eslint-disable no-prototype-builtins */
import { convertToDate } from '../../../../utils/javascript-object-util';

export default class PaymentCreateRequest {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.amount = config.amount;
    this.date = convertToDate(config.date);
    this.description = config.description;
    this.category = config.category;
    this.recipients = [];
    this.tempRecipients = [];

    const { people } = config;
    if (people && people.length > 0) {
      people.forEach((p) => {
        if (p.includes('tempPerson')) {
          this.tempRecipients.push(p);
        } else this.recipients.push(p);
      });
    }
  }
}
