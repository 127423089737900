import React from 'react';
import { connect } from 'react-redux';
import { ROLE_LIST, USER } from '../../constants';
import { hidePB, showPB } from '../../actions/progressbarAction';
import RoleViewHolder from './RoleViewHolder';
import EmptyView from '../../components/Functional/EmptyView';
import RoleApi from '../../data/api/RoleApi';

class RoleList extends React.Component {
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.fetchData(this.props);
    }
  }

  fetchData = async (_props) => {
    const props = _props || this.props;
    const { user } = props;
    if (user) {
      const companyId = user.getCompanyId;
      const { dispatch } = props;
      showPB(dispatch);
      RoleApi.list(dispatch, companyId).then(
        () => hidePB(dispatch),
        () => hidePB(dispatch),
      ); // Todo error handling
    }
  };

  render() {
    const { roles, user } = this.props;

    return (
      <div
        style={{
          display: 'block',
        }}
      >
        {roles ? (
          roles.map((r) => (
            <RoleViewHolder key={r.id} companyId={user && user.getCompanyId} role={r} />
          ))
        ) : (
          <EmptyView />
        )}
      </div>
    );
  }
}

export default connect((state) => ({
  user: state.appData[USER],
  roles: state.appData[ROLE_LIST],
}))(RoleList);
