import React from 'react';
import { connect } from 'react-redux';
import { FlatButton } from 'material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class FieldArrayRightButton extends React.Component {
  render() {
    const { onClick, icon, tooltip, inProgress } = this.props;
    return (
      <div>
        <FlatButton
          style={{ position: 'absolute', minWidth: 36, top: 16, right: 16 }}
          tooltip={tooltip || 'Kaldır'}
          icon={
            <FontAwesomeIcon
              fixedWidth
              icon={inProgress ? 'spinner' : icon || 'times'}
              spin={inProgress}
            />
          }
          onClick={() => onClick()}
        />
      </div>
    );
  }
}
export default connect()(FieldArrayRightButton);
