import React, { Component } from 'react';
import { Divider, Subheader } from 'material-ui';
import AppJsonTree from '../../components/AppJsonTree';
import generateHash from './generateHash.md';

class WebhookDocumentation extends Component {
  render() {
    return (
      <div>
        <Subheader>Gereklilikler</Subheader>
        <ul>
          <li>
            Webhook end-pointi <b>POST</b> metodunu kullanmalıdır.
          </li>
          <li>
            Url <b>https</b> olmalıdır.
          </li>
          <li>
            Secret key webhook oluşturulduğunda <u>bir sefere mahsus</u> olarak görüntülenir. Bu key
            datanın geçerli platformdan geldiğini teyit etmenizi sağlar. ( Bu aşama yapılmadan da
            sistem çalışmayı sürdürebilir. <u>Güvenliğiniz için önerilir</u> )
          </li>
        </ul>
        <Divider />
        <Subheader>Nasıl çalışır?</Subheader>
        Her rapor hesaplaması gerçekleştirildiği anda webhook için belirttiğiniz bir endpoint varsa
        hesaplamaya ilişkin önemli bilgiler endpointe gönderilir. Entegrasyonu yapılmış bir webhook
        ile sistemimizle real-time olarak birleşmiş olursunuz.
        <br />
        <br />
        <Divider />
        <Subheader>Data Yapısı</Subheader>
        Gönderilen data rapora ait bilgileri ve yapılan hesaplamaların bilgisini içerir. Örnek data
        aşağıdaki şekilde gösterilmektedir ve açıklamalarına yer verilmiştir. Data birden fazla
        hesaplama verisini aynı anda içerebilir.
        <AppJsonTree
          shouldExpandNode={() => true}
          data={{
            incident: {
              id: '00000000-0000-4000-0000-000000000000',
              casualtyId: '00000000-0000-4000-0000-000000000000',
              dateOfIssue: '2019-01-01T00:00:00.000+0000',
              damageFileIdentifier: 'DOSYA-NUMARASI',
              courtFileIdentifier: 'HUKUK-DOSYA-NUMARASI',
              arbitrationFileIdentifier: 'TAHKIM-DOSYA-NUMARASI',
              timestamp: '2019-06-19T00:00:00.000+0000',
              dateOfIncident: '2018-01-01T00:00:00.000+0000',
              dateOfReport: '2019-01-01T00:00:00.000+0000',
              dateOfRetirement: '2060-01-01T00:00:00.000+0000',
              dateOfDeath: '2018-01-01T00:00:00.000+0000',
              isWorkAccident: false,
            },
            calculations: [
              {
                config: {
                  id: '00000000-0000-4000-0000-000000000000',
                  mortalityTable: 'trh',
                  lifeAnnuity: 'multiple',
                  shareRate: 'supreme',
                  faultRatio: 0.9,
                  disabilityRatio: 0.5,
                  interest: 0.018,
                  useAllowanceExcludedWageOnPassive: false,
                  useTerminalAge: false,
                  usePassiveEarningUntilAgeOfConsent: false,
                  useMinimumWage: false,
                  dontLimitCompensation: false,
                  withoutAllowance: false,
                },
                calculation: {
                  known: { active: 102.591544 },
                  unknown: {
                    active: 8147.928065744307,
                    passive: 1194.5145430871341,
                  },
                  compensationAmount: 9445.034152831442,
                  timestamp: '2019-01-01T00:00:00.000+0000',
                  paymentPerPerson: [
                    {
                      name: 'Injured Person Name',
                      surname: 'Injured Person Surname',
                      isShareHidden: false,
                      known: { active: 102.591544 },
                      unknown: { active: 102.591544 },
                      compensationAmount: 9445.034152831442,
                    },
                  ],
                },
              },
            ],
          }}
        />
        <Divider />
        <Subheader>Data Açıklaması</Subheader>
        <ul className={'desc-list'}>
          <li>
            <b className={'code'}>incident</b>
            <br />
            Dosya ile ilgili bilgleri içerir
            <br />
            <ul className={'desc-list'}>
              <li>
                <b className={'code'}>incidentId</b>
                <br />
                Raporun id'sidir. Rapor ile ilgili detaylı bilgi için aşağıdaki bağlantı <b>
                  GET
                </b>{' '}
                isteği ile kullanılmalıdır
                <br />
                <b>https://api-staging.inovaaktueryalyonetim.com/v1/incidents/:incidentId</b>
              </li>
              <li>
                <b className={'code'}>dateOfRetirement</b>
                <br />
                Mağdur/Müteveffanın emeklilik tarihidir.
              </li>
              <li>
                <b className={'code'}>damageFileIdentifier</b>
                <br />
                Dosya numarasıdır.
              </li>
              <li>
                <b className={'code'}>courtFileIdentifier</b>
                <br />
                Hukuk dosya numarasıdır. Opsiyoneldir.
              </li>
              <li>
                <b className={'code'}>arbitrationFileIdentifier</b>
                <br />
                Tahkim dosya numarasıdır. Opsiyoneldir.
              </li>
              <li>
                <b className={'code'}>casualtyId</b>
                <br />
                Mağdur/Müteveffa id'sidir
              </li>
              <li>
                <b className={'code'}>dateOfIssue</b>
                <br />
                Dosyanın oluşturulma tarihidir.
              </li>
              <li>
                <b className={'code'}>timestamp</b>
                <br />
                Dosyanın oluşturulma tarihidir.
              </li>
              <li>
                <b className={'code'}>dateOfIncident</b>
                <br />
                Kaza tarihidir.
              </li>
              <li>
                <b className={'code'}>dateOfReport</b>
                <br />
                Rapor tarihidir. Aktif/pasif ve Bilinen/bilinmeyen dönemler bu tarihe göre
                belirlenir.
              </li>
              <li>
                <b className={'code'}>dateOfDeath</b>
                <br />
                Müteveffanın ölüm tarihidir. Yanlızca Vefat raporlarında vardır.
              </li>
              <li>
                <b className={'code'}>isWorkAccident</b>
                <br />
                İş kazası olma durumunu belirtir.
              </li>
            </ul>
          </li>

          <li>
            <b className={'code'}>calculations</b>
            <br />
            Rapora ilişkin güncel hesaplama değerlerini içerir.
            <ul className={'desc-list'}>
              <li>
                <b className={'code'}>config</b>
                <br />
                Hesaplamayla ilgili paremetreleri içerir.
                <ul className={'desc-list'}>
                  <li>
                    <b className={'code'}>mortalityTable</b>
                    <br />
                    Kullanılan ölüm tablosunu belirtir. trh, pmf, cso değerlerini alabilir.
                  </li>
                  <li>
                    <b className={'code'}>lifeAnnuity</b>
                    <br />
                    Tek başlı / Çift başlı hesaplama olma durumunu belirtir. single, multiple
                    değerlerini alabilir.
                  </li>
                  <li>
                    <b className={'code'}>shareRate</b>
                    <br />
                    Pay dağıtım oranları için kullanılacak kararları belirtir. supreme (YARGITAY),
                    military (AYİM) değerlerini alabilir.
                  </li>
                  <li>
                    <b className={'code'}>faultRatio</b>
                    <br />
                    Kusur oranıdır.
                  </li>
                  <li>
                    <b className={'code'}>disabilityRatio</b>
                    <br />
                    Sakatlık oranıdır
                  </li>
                  <li>
                    <b className={'code'}>interest</b>
                    <br />
                    Faiz oranıdır. Pmf ve trh/cso da farklı değerler alır.
                  </li>
                  <li>
                    <b className={'code'}>useAllowanceExcludedWageOnPassive</b>
                    <br />
                    Pasif Maaş'ın Agi hariç asgari ücretten hesaplandığını belirtir.
                  </li>
                  <li>
                    <b className={'code'}>useTerminalAge</b>
                    <br />
                    Tam hayat kullanım durumunu belirtir
                  </li>
                  <li>
                    <b className={'code'}>usePassiveEarningUntilAgeOfConsent</b>
                    <br />
                    Çalışma yaşının kaldırılması durumunu belirtir. true için çalışma yaşı yok false
                    için var şeklindedir.
                  </li>
                  <li>
                    <b className={'code'}>useMinimumWage</b>
                    <br />
                    Mağdur/Müteveffanın bordrosu olsa bile asgari ücret kullanıldığını belirtir.
                  </li>
                  <li>
                    <b className={'code'}>dontLimitCompensation</b>
                    <br />
                    Poliçe limiti uygulanma durumunu belirtir. ture için poliçe limiti uygulanmaz
                    false için uygulanır.
                  </li>
                  <li>
                    <b className={'code'}>withoutAllowance</b>
                    <br />
                    Agi uygulaması olup olmadığını belirtir
                  </li>
                </ul>
              </li>
              <li>
                <b className={'code'}>calculation</b>
                <br />
                Hesaplamayla ilgili tazminat değerlerini içerir.
                <ul className={'desc-list'}>
                  <li>
                    <b className={'code'}>compensationAmount</b>
                    <br />
                    Hesaplamanın toplam tazminat miktarıdır.
                  </li>
                  <li>
                    <b className={'code'}>known</b>
                    <br />
                    Hesaplamanın bilinen dönemdeki miktarıdır.(Kaza tarihi ile Rapor tarihi arasında
                    kalan dönem)
                    <br />
                    İlgili dönemin mağdur/müteveffa için aktif/pasif dönemlerindeki tazminat
                    tutarları
                    <b> active</b> ve <b>passive</b> key'leri ile belirtilmiştir
                  </li>
                  <li>
                    <b className={'code'}>unknown</b>
                    <br />
                    Hesaplamanın bilinmeyen dönemdeki miktarıdır.(Rapor tarihi ile Muhtemel ölüm
                    tarihi arasında kalan dönem)
                    <br />
                    İlgili dönemin mağdur/müteveffa için aktif/pasif dönemlerindeki tazminat
                    tutarları
                    <b> active</b> ve <b>passive</b> key'leri ile belirtilmiştir
                  </li>
                  <li>
                    <b className={'code'}>timestamp</b>
                    <br />
                    Datanın gönderilme zamanıdır.
                  </li>
                  <li>
                    <b className={'code'}>paymentPerPerson</b>
                    <br />
                    Tüm pay sahiplerinin ayrı ayrı tazminatlarını içerir.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <Divider />
        <Subheader>Data Validasyonu</Subheader>
        İstek <b>X-Signature</b> headerında secret key ile şifrelenmiş bir imza gönderir. Signature{' '}
        <b>HMAC-SHA1</b> ile oluşturulmuştur. Datanın doğru kaynaktan geldiğini teyit etmek için
        datayı aynı şekilde şifreleyerek imzaları karşılaştırmak gerekir. İmza aşağıdaki gibi
        oluşturulur. Örnek kod Java dilinde yazılmıştır
        <div dangerouslySetInnerHTML={{ __html: generateHash }} />
      </div>
    );
  }
}

export default WebhookDocumentation;
