import * as React from 'react';
import { RaisedButton } from 'material-ui';
import Clear from './Clear';
import ColorPalette from '../../ColorPalette';

class SelectButton extends React.Component {
  render() {
    return (
      <div
        style={{
          position: 'relative',
          marginTop: 14,
          marginBottom: 4,
          ...this.props.style,
        }}
      >
        <label
          style={{
            display: 'inline-block',
            transform: 'scale(0.75) translate(-12.5%, 0)',
            color: ColorPalette.transparent5,
          }}
        >
          {this.props.label}
        </label>
        <Clear />
        {this.props.buttons.map((b) => (
          <RaisedButton
            label={b.label}
            style={{
              minWidth: 50,
              float: 'left',
              height: 28,
              lineHeight: '28px',
              ...this.props.buttonStyle,
            }}
            labelStyle={this.props.labelStyle}
            primary={this.props.value === b.value}
            onClick={() => this.props.onChange(b.value)}
          />
        ))}
        <Clear />
        <div
          style={{
            position: 'absolute',
            transform: 'scale(0.75) translate(-12.5%, 0)',
            color: ColorPalette.errorColor,
          }}
        >
          {this.props.errorText}
        </div>
      </div>
    );
  }
}

export default SelectButton;
