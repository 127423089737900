import { setAppData } from '../../actions/appdata';
import { TAGS } from '../../constants';
import Connection from './Connection';

export default class TagApi extends Connection {
  static list(dispatch) {
    return this._getSecurely(`/tags`)
      .then(...this.justData)
      .then((r) => dispatch(setAppData({ name: TAGS, value: r })))
      .catch(this.catchError);
  }
}
