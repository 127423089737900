/* eslint-disable react/prop-types,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';

export default function OptionalButton({ onClick, text, top = 64, right = 0, width = 'auto' }) {
  return (
    <div
      className={'optionalInputButton'}
      style={{
        position: 'absolute',
        right,
        top,
        zIndex: 1,
        width,
        textAlign: 'center',
      }}
      onClick={onClick}
    >
      {text}
    </div>
  );
}
