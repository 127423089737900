import { setAppData } from '../../actions/appdata';
import { ACCOUNTS, USER } from '../../constants';
import Connection from './Connection';
import { headers } from './ApiUtil';
import User from '../model/User';
import { toQuery } from '../../utils/javascript-object-util';

export default class AccountApi {
  static login(credentials) {
    return Connection.fetchWithMethod(
      'POST',
      `/${ACCOUNTS}/signin/credentials`,
      JSON.stringify(credentials),
      null,
      false,
    ).then(...Connection.justData);
  }

  static signOut() {
    return Connection._post(`/${ACCOUNTS}/signout`, {}).catch(Connection.catchError);
  }

  static register(data, params) {
    const filteredParams = {};
    if (params.hash) {
      filteredParams.hash = params.hash;
    }
    return Connection.fetchWithMethod(
      'POST',
      `/${ACCOUNTS}/register${toQuery(filteredParams)}`,
      JSON.stringify(data),
      null,
      false,
    );
  }

  static async getMe(dispatch) {
    return Connection._getSecurely(`/me`)
      .then(...Connection.justData)
      .then((response) => {
        const user = new User(response);
        localStorage.setItem(USER, JSON.stringify(user));
        dispatch(setAppData({ name: USER, value: user }));
      })
      .catch(Connection.catchError);
  }

  static resendConfirmationMail(email) {
    return Connection.fetchWithMethod(
      'POST',
      `/${ACCOUNTS}/resend-confirmation/${email}`,
      null,
      headers,
    );
  }

  static confirmMail(tokenId) {
    return Connection.fetchWithMethod(
      'POST',
      `/${ACCOUNTS}/activate?token=${tokenId}`,
      null,
      headers,
    );
  }

  static resetPasswordEmailRequest(email) {
    return Connection.fetchWithMethod(
      'POST',
      `/${ACCOUNTS}/password/reset/${email}`,
      null,
      headers,
    );
  }

  static resetPassword(data) {
    return Connection.fetchWithMethod(
      'POST',
      `/${ACCOUNTS}/password/reset`,
      JSON.stringify(data),
      headers,
      false,
    );
  }

  static changePassword(data) {
    return Connection._post(`/accounts/password/change`, data);
  }
}
