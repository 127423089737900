import React from 'react';

class ChangeLog extends React.Component {
  render() {
    const { html, style } = this.props;
    return (
      <div
        style={style}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  }
}

export default ChangeLog;
