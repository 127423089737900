import { setAppData } from '../../actions/appdata';
import { DOCUMENT_TYPES } from '../../constants';
import Connection from './Connection';

export default class DocumentTypeApi extends Connection {
  static list(dispatch) {
    return this._getSecurely(`/files/categories`)
      .then(...this.justData)
      .then((r) => dispatch(setAppData({ name: DOCUMENT_TYPES, value: r })))
      .catch(this.catchError);
  }
}
