import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Paper, RaisedButton, Subheader } from 'material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEmpty from 'lodash/isEmpty';
import { paperStyle } from '../../../utils/material-ui-style-util';
import { CSO, PMF, TRH, TRH_PROGRESSIVE } from '../../../data/model/MortalityTable';
import {
  renderAfter2020MortalityTables,
  renderCheckbox,
  renderLifeAnnuity,
  renderMortalityTables,
  renderNurseCategory,
  renderShareRateCategory,
  renderTextField,
} from '../../../utils/redux-metarial-ui-util';
import { DEATH, DISABLED } from '../../../data/model/IncidentType';
import Clear from '../../Functional/Clear';
import ConfigCreateRequest from '../../../data/model/request/create/ConfigCreateRequest';
import { hidePB, showPB } from '../../../actions/progressbarAction';
import { openSnackbar } from '../../../actions/snackbarActions';
import {
  CALCULATION_CONFIG,
  DETAILED_INCIDENT_CC,
  INIT_CALCULATION_CONFIG,
} from '../../../constants';
import RouteStory, { RouteLocationStory } from '../../../utils/RouteStory';
import { REQUIRED, validatePercentage } from '../../../utils/validation';
import ReportFlexInfo from '../ReportFlexInfo';
import CalculationConfigApi from '../../../data/api/CalculationConfigApi';
import Layout from '../../Layout/Layout';
import Authorization from '../../Authorization/Authorization';
import { WriteIncident } from '../../../data/model/Privilege';

class CalculationConfigCreateForm extends React.Component {
  onSubmit = (values) => {
    const { dispatch, detailedIncident } = this.props;
    const { incidentType, isBefore2020 } = detailedIncident.incident;
    values.incidentType = incidentType;
    values.isBefore2020 = isBefore2020;

    if (this.props.update) {
      const configCreateRequest = new ConfigCreateRequest(values);
      const { configId } = this.props;
      showPB(dispatch, 'Hesaplama Ayarı Güncelleniyor');
      return CalculationConfigApi.update(configId, configCreateRequest).then(
        () => {
          hidePB(dispatch);
          dispatch(openSnackbar('Hesaplama ayarı güncellendi'));
          this.props.history.goBack();
          this.props.history.push(`/calculation-configs/${configId}`);
        },
        (r) => {
          hidePB(dispatch);
          dispatch(openSnackbar('Hesaplama ayarı güncelleme sırasında hata oluştu'));
          return Promise.reject(r);
        },
      );
    }
    const configCreateRequest = new ConfigCreateRequest(values);
    const { incidentId } = this.props;

    showPB(dispatch, 'Hesaplama Ayarı Oluşturuluyor');
    return CalculationConfigApi.create(incidentId, configCreateRequest).then(
      () => {
        hidePB(dispatch);
        dispatch(openSnackbar('Hesaplama ayarı oluşturuldu'));
        this.props.history.goBack();
      },
      (r) => {
        hidePB(dispatch);
        dispatch(openSnackbar('Hesaplama ayarı oluşturma sırasında hata oluştu'));
        return Promise.reject(r);
      },
    );
  };

  componentDidMount() {
    if (!this.props.detailedIncident) {
      this.props.history.push('/reports');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.mortalityTable !== prevProps.mortalityTable) {
      const { dispatch, change, mortalityTable, detailedIncident } = this.props;
      const isBefore2021 = detailedIncident.incident.isBeforeCircular2021;
      if (mortalityTable === PMF || mortalityTable === TRH_PROGRESSIVE) {
        dispatch(change('interest', 10));
      } else {
        if (mortalityTable === TRH) {
          if (isBefore2021) dispatch(change('interest', 1.8));
          else dispatch(change('interest', 1.65));
        }
        if (mortalityTable === CSO) dispatch(change('interest', 2));
      }

      /*      if (!isBefore2021) {
        if (mortalityTable === TRH && detailedIncident.incident.incidentType === DISABLED)
          dispatch(change('useTerminalAge', true));
        else dispatch(change('useTerminalAge', false));
        
      } */
    }
  }

  render() {
    const { handleSubmit, mortalityTable, detailedIncident } = this.props;
    if (!detailedIncident) return <div />;
    const {
      incidentType,
      isBeforeCircular2020: isBefore2020,
      isBeforeCircular2021: isBefore2021,
    } = detailedIncident.incident;

    const labelStyle = {
      lineHeight: '16px',
      marginTop: '-5px',
      width: '100%',
      color: '#7f7f7f',
      fontWeight: '500',
    };
    return (
      <Layout>
        <Authorization allowedRoles={[WriteIncident]} force>
          <div className={'root'}>
            <div className={'container'} style={{ maxWidth: 1080, margin: 'auto' }}>
              <h1>Hesaplama Ayarı Oluştur</h1>
              <RouteLocationStory
                locations={[
                  RouteStory.reports(),
                  RouteStory.report({
                    text: detailedIncident.injuredParty.casualty.basic.fullName,
                  }),
                  this.props.configPage ? RouteStory.config() : null,
                  RouteStory.configCreateUpdate(this.props.update),
                ]}
              />
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <Paper style={paperStyle}>
                  <Subheader className={`basicReportSubheader`}>
                    Hesaplamaya İlişkin Bilgiler
                  </Subheader>
                  <div style={{ padding: 16 }}>
                    <div className={'basicReportFieldContainer'}>
                      <Field
                        fullWidth
                        name="mortalityTable"
                        title="Ölüm Tablosu"
                        component={
                          isBefore2020 !== false
                            ? renderMortalityTables
                            : renderAfter2020MortalityTables
                        }
                      />
                    </div>
                    <div className={'basicReportFieldContainer'}>
                      <Field
                        fullWidth
                        component={renderTextField}
                        name="faultRatio"
                        label="Kusur Oranı (%)"
                      />
                    </div>
                    {incidentType === DISABLED && (
                      <div className={'basicReportFieldContainer'}>
                        <Field
                          fullWidth
                          component={renderTextField}
                          name="disabilityRatio"
                          floatingLabelStyle={{ fontSize: '16px' }}
                          label="S.Sakatlık Oranı(%)"
                        />
                      </div>
                    )}
                    {isBefore2020 !== false && incidentType === DEATH && mortalityTable !== PMF && (
                      <div className={'basicReportFieldContainer'}>
                        <Field
                          fullWidth
                          component={renderLifeAnnuity}
                          name="lifeAnnuity"
                          title="Hesaplama Türü"
                        />
                      </div>
                    )}
                    {isBefore2020 !== false && incidentType === DEATH && (
                      <div className={'basicReportFieldContainer'}>
                        <Field
                          fullWidth
                          component={renderShareRateCategory}
                          name="shareRate"
                          label="Pay Dağıtım Tipi"
                        />
                      </div>
                    )}
                    <div className={'basicReportFieldContainer'}>
                      <Field
                        fullWidth
                        component={renderTextField}
                        name="interest"
                        label={
                          mortalityTable === PMF || mortalityTable === TRH_PROGRESSIVE
                            ? 'İskonto Faizi (%)'
                            : 'Teknik Faiz (%)'
                        }
                      />
                    </div>
                    {(
                      <div className={'basicReportFieldContainer'} style={{ position: 'relative' }}>
                        <div className={`tooltipv2 questionMark`}>
                          <FontAwesomeIcon fixedWidth icon="question-circle" />
                          <div className={'tooltipTextv2'}>
                            İşaretli olduğunda bakiye ömür yerine <b>99 yaş</b> kullanılır.
                          </div>
                        </div>
                        <Field
                          style={{ marginTop: 38 }}
                          fullWidth
                          labelStyle={labelStyle}
                          component={renderCheckbox}
                          name="useTerminalAge"
                          label="Tam Hayat"
                        />
                      </div>
                    )}
                    {isBefore2020 && (
                      <div className={'basicReportFieldContainer'} style={{ position: 'relative' }}>
                        <div className={`tooltipv2 questionMark`}>
                          <FontAwesomeIcon fixedWidth icon="question-circle" />
                          <div className={'tooltipTextv2'}>
                            <b>İşaretli Durumda:</b> Çocuk Mağdur/Müteveffa çalışmaya başlama yaşı
                            yerine kaza tarihinden itibaren Asgari eksi Agi üzerinden destek verir
                            olarak kabul edilir. <br />
                            <b>İşaretsiz Durumda:</b> Çalışmaya başlama yaşına kadar (Bordro/Sgk
                            hizmet dökümü veya GİG yoksa) destek veremez olarak kabul edilir.
                          </div>
                        </div>
                        <Field
                          style={{ marginTop: 38 }}
                          component={renderCheckbox}
                          labelStyle={labelStyle}
                          name="usePassiveEarningUntilAgeOfConsent"
                          label={
                            <span>
                              Çalışma Yaşını
                              <br />
                              Kaldır
                            </span>
                          }
                        />
                      </div>
                    )}
                    <div className={'basicReportFieldContainer'} style={{ position: 'relative' }}>
                      <div className={`tooltipv2 questionMark`}>
                        <FontAwesomeIcon fixedWidth icon="question-circle" />
                        <div className={'tooltipTextv2'}>
                          İşaretli olduğunda maaş bilgileri farketmeksizin asgari ücret üzerinden
                          hesaplama yapar.
                          <br />
                          <FontAwesomeIcon fixedWidth icon="exclamation" /> Özellikle asgari ücret
                          ile hesaplama yapılmak istendiğinde kullanılmalıdır.
                        </div>
                      </div>
                      <Field
                        style={{ marginTop: 38 }}
                        component={renderCheckbox}
                        labelStyle={labelStyle}
                        name="useMinimumWage"
                        label={
                          <span>
                            Asgari Ücreti
                            <br />
                            Kullan
                          </span>
                        }
                      />
                    </div>
                    <div className={'basicReportFieldContainer'} style={{ position: 'relative' }}>
                      <div className={`tooltipv2 questionMark`}>
                        <FontAwesomeIcon fixedWidth icon="question-circle" />
                        <div className={'tooltipTextv2'}>
                          Hesaplama Yapılırken Mağdur/Müteveffanın gelirine Asgari Geçim İndirimi
                          uygulanmaz.
                        </div>
                      </div>
                      <Field
                        style={{ marginTop: 38 }}
                        fullWidth
                        labelStyle={labelStyle}
                        component={renderCheckbox}
                        name="withoutAllowance"
                        label="AGİ uygulamasız"
                      />
                    </div>
                    <div className={'basicReportFieldContainer'} style={{ position: 'relative' }}>
                      <div className={`tooltipv2 questionMark`}>
                        <FontAwesomeIcon fixedWidth icon="question-circle" />
                        <div className={'tooltipTextv2'}>
                          Rapora poliçe limiti uygulanmaz. Genellikle iş kazalarında uygulanır.
                        </div>
                      </div>
                      <Field
                        style={{ marginTop: 38 }}
                        fullWidth
                        labelStyle={labelStyle}
                        component={renderCheckbox}
                        name="dontLimitCompensation"
                        label="Poliçe Limitsiz"
                      />
                    </div>
                    {incidentType !== DEATH && (
                      <div className={'basicReportFieldContainer'}>
                        <Field
                          fullWidth
                          name="nurseCategory"
                          title="Bakıcı Hesaplaması"
                          component={renderNurseCategory}
                        />
                      </div>
                    )}
                    {incidentType !== DEATH && (
                      <div className={'basicReportFieldContainer'}>
                        <Field
                          style={{ marginTop: 38 }}
                          fullWidth
                          labelStyle={labelStyle}
                          component={renderCheckbox}
                          name="isNurseDocumented"
                          label="Bakıcı Belgesi Var"
                        />
                      </div>
                    )}
                    {incidentType !== DEATH && (
                      <div className={'basicReportFieldContainer'}>
                        <Field
                          style={{ marginTop: 38 }}
                          fullWidth
                          labelStyle={labelStyle}
                          component={renderCheckbox}
                          name="skipTemporaryIncapacity"
                          label="GİG Hesaplanmasın"
                        />
                      </div>
                    )}

                    {!isBefore2021 && (
                      <div className={'basicReportFieldContainer'} style={{ position: 'relative' }}>
                        <div className={`tooltipv2 questionMark`}>
                          <FontAwesomeIcon fixedWidth icon="question-circle" />
                          <div className={'tooltipTextv2'}>
                            <b>İşaretli Durumda:</b> Pay dağılımında Hane Halkı kavramını kullanır.
                            <br />
                            <b>İşaretsiz Durumda:</b> Pay dağılımında Hane Halkı kavramını
                            <b> kaldırır.</b>
                          </div>
                        </div>
                        <Field
                          style={{ marginTop: 38 }}
                          component={renderCheckbox}
                          labelStyle={labelStyle}
                          name="useHouseholdPriorityShare"
                          label={
                            <span>
                              Hane Halkı
                              <br />
                              Kavramını Kullan
                            </span>
                          }
                        />
                      </div>
                    )}
                    <Clear />
                  </div>
                </Paper>
                <RaisedButton
                  style={{ float: 'right' }}
                  type="submit"
                  primary
                  label={this.props.update ? 'Güncelle' : 'Oluştur'}
                />
              </form>
              <ReportFlexInfo />
            </div>
          </div>
        </Authorization>
      </Layout>
    );
  }
}

const validate = (values) => {
  if (isEmpty(values)) return;
  const err = {};
  if (!values.mortalityTable) err.mortalityTable = REQUIRED;
  if (!values.lifeAnnuity) err.lifeAnnuity = REQUIRED;
  if (!values.shareRate) err.shareRate = REQUIRED;
  err.faultRatio = validatePercentage(values.faultRatio);
  err.disabilityRatio = validatePercentage(values.disabilityRatio);
  err.interest = validatePercentage(values.interest);
  return err;
};

CalculationConfigCreateForm = reduxForm({ form: 'configCreate', validate })(
  CalculationConfigCreateForm,
);
const selector = formValueSelector('configCreate');
export default connect((state) => ({
  mortalityTable: selector(state, 'mortalityTable'),
  interest: selector(state, 'interest'),
  lifeAnnuity: selector(state, 'lifeAnnuity'),
  initialValues: state.appData[INIT_CALCULATION_CONFIG],
  detailedIncident: state.appData[DETAILED_INCIDENT_CC],
  detailedConfig: state.appData[CALCULATION_CONFIG],
}))(CalculationConfigCreateForm);
