import React from 'react';
import { Paper } from 'material-ui';
import StandardLayout from '../../components/BaseLayout/StandardLayout';

function About() {
  return (
    <StandardLayout>
      <h1>Hakkımızda</h1>
      <Paper style={{ padding: '64px' }}>
        <div style={{ fontSize: '20px' }}>
          &emsp;Sigorta Sektöründe Aktüeryal hizmetler ve teknik karşılıklar ile ilgili
          faaliyetlerimizi 2012 yılından bu yana İnova çatısı altında sürdürmekteyiz. Şirketin
          kurucusu, Aktüer Dr. Esra Denizci Saint-Michel Fransız Lisesi ve müteakiben Boğaziçi
          Üniversitesi Temel Bilimler Matematik Bölümü mezunudur. Marmara Üniversitesi Bankacılık ve
          Sigortacılık Yüksek Okulunda, Sigortacılıkta Yüksek Lisans ve Bankacılıkta Doktora
          yapmıştır. Hazine Müsteşarlığı Başbakanlık Sigorta Denetleme Kurulunda Aktüer Yardımcısı
          ve Aktüer olarak görev yapmış, Aktüerliğini “Başbakanlık Hazine Müsteşarlığı Sigorta
          Denetleme Kurulu” nun açtığı sınavla alarak, Hazine Müsteşarlığının 31 numaralı Sicile
          kayıtlı Aktüeridir.
        </div>
      </Paper>
    </StandardLayout>
  );
}

export default About;
