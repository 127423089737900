import React from 'react';
import { connect } from 'react-redux';
import { FieldArray, reduxForm } from 'redux-form';
import { RaisedButton } from 'material-ui';
import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';
import { useHistory } from 'react-router';
import Clear from '../../Functional/Clear';
import { validateSalaries } from '../../../utils/validation';
import { objDiff } from '../../../utils/javascript-object-util';
import { hidePB } from '../../../actions/progressbarAction';
import { openSnackbar } from '../../../actions/snackbarActions';
import { clearAppData } from '../../../actions/appdata';
import { DETAILED_INCIDENT_CC, INIT_PAYMENTS, SALARY_LIST } from '../../../constants';
import SalaryListForm from '../SalaryListForm';
import {
  SalaryCreateRequest,
  SalaryUpdateRequest,
} from '../../../data/model/request/create/SalaryRequest';
import { SALARY_MINIMUM_WAGE } from '../../../data/model/IncomeCategory';
import RouteStory, { RouteLocationStory } from '../../../utils/RouteStory';
import ReportFlexInfo from '../ReportFlexInfo';
import SalaryApi from '../../../data/api/SalaryApi';

function SalaryCreateUpdateForm({
  handleSubmit,
  dispatch,
  incidentId,
  initialValues,
  pristine,
  submitting,
  detailedIncident,
}) {
  const history = useHistory();
  const onSubmit = (values) => {
    const requests = [];

    const { salaries } = values;
    const initialSalaries = initialValues.salaries;
    if (values.incomeCategory === SALARY_MINIMUM_WAGE && initialSalaries) {
      initialSalaries
        .map((s) => s.id)
        .forEach((id) => requests.push(SalaryApi.delete(incidentId, id)));
    } else {
      if (salaries) {
        salaries
          .filter((salary) => !salary.id)
          .forEach((salary) => {
            requests.push(SalaryApi.create(new SalaryCreateRequest(salary), incidentId));
          });
      }
      if (initialSalaries) {
        const oldList = initialSalaries.map((s) => s.id);
        const newList = salaries.filter((s) => s.id).map((s) => s.id);
        difference(oldList, newList).forEach((id) =>
          requests.push(SalaryApi.delete(incidentId, id)),
        );
      }
      if (initialSalaries && salaries) {
        salaries
          .filter((salary) => salary.id)
          .forEach((salary) => {
            const oldSalary = initialSalaries.find(
              (initialSalary) => initialSalary.id === salary.id,
            );
            const newItem = objDiff(salary, oldSalary);
            if (!isEmpty(newItem)) {
              newItem.salaryType = salary.salaryType;
              requests.push(
                SalaryApi.update(incidentId, salary.id, new SalaryUpdateRequest(newItem)),
              );
            }
          });
      }
    }

    Promise.all(requests).then(
      () => {
        hidePB(dispatch);
        dispatch(openSnackbar('Maaş Bilgisi Güncellendi'));
        dispatch(clearAppData(INIT_PAYMENTS));
        history.goBack();
      },
      (err) => {
        hidePB(dispatch);
        dispatch(openSnackbar(`Güncelleme sırasında hata oluştu. ${err.message}`));
      },
    );
  };

  if (!detailedIncident) return <div />;
  return (
    <div className={'root'}>
      <div className={`${'container'}`} style={{ maxWidth: 1024, margin: 'auto' }}>
        <h1>Maaşları Güncelle</h1>
        <RouteLocationStory
          locations={[
            RouteStory.reports(),
            RouteStory.report({
              text: detailedIncident.injuredParty.casualty.basic.fullName,
            }),
            RouteStory.salaryCreateUpdate(),
          ]}
        />
        <form style={{ position: 'relative' }} onSubmit={handleSubmit(onSubmit)}>
          <FieldArray name="salaries" component={SalaryListForm} />
          <RaisedButton
            label="Maaşları Güncelle"
            primary
            type="submit"
            disabled={pristine || submitting}
            style={{ float: 'right', marginRight: 12 }}
          />
          <Clear />
        </form>
        <ReportFlexInfo />
      </div>
    </div>
  );
}

const validate = (values) => {
  const errors = {};
  errors.salaries = validateSalaries(values.salaries);
  return errors;
};
SalaryCreateUpdateForm = reduxForm({
  form: 'salaryForm',
  validate,
  enableReinitialize: true,
})(SalaryCreateUpdateForm);

export default connect((state) => ({
  initialValues: {
    salaries: state.appData[SALARY_LIST],
  },
  detailedIncident: state.appData[DETAILED_INCIDENT_CC],
}))(SalaryCreateUpdateForm);
