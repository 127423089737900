import { setAppData } from '../../actions/appdata';
import { CALCULATION } from '../../constants';
import Connection from './Connection';
import CalculationResponse from '../model/response/CalculationResponse';

export default class CalculationApi extends Connection {
  static delete(id) {
    return this._delete(`/calculations/${id}`);
  }

  static find(dispatch, configId) {
    return this._getSecurely(`/calculation-configs/${configId}/calculation`)
      .then(...this.justData)
      .then((r) => {
        const data = new CalculationResponse(r);
        dispatch(setAppData({ name: CALCULATION, value: data }));
        return data;
      })
      .catch(this.catchError);
  }
}
