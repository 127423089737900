/* eslint-disable react/prop-types */
import React from 'react';
import Checkbox from 'material-ui/Checkbox';
import DatePicker from 'material-ui/DatePicker';
import TextField from 'material-ui/TextField';
import { RadioButtonGroup } from 'material-ui';
import StaticSelectField from '../components/ComboBox/StaticSelectField';
import SelectButton from '../components/Functional/SelectButton';
import MortalityTable, { After2020MortalityTable } from '../data/model/MortalityTable';
import IncidentType from '../data/model/IncidentType';
import InjuryType from '../data/model/InjuryType';
import LifeAnnuity from '../data/model/LifeAnnuity';
import Gender from '../data/model/Gender';
import YesNo from '../data/model/YesNo';
import MaritalStatus from '../data/model/MaritalStatus';
import EducationalStatus from '../data/model/EducationalStatus';
import EmployedStatus from '../data/model/EmployedStatus';
import PaymentType from '../data/model/PaymentType';
import SalaryType from '../data/model/SalaryType';
import ShareRateCategory from '../data/model/ShareRateCategory';
import IncomeCategory from '../data/model/IncomeCategory';
import SpecialPeriodType from '../data/model/SpecialPeriodType';
import DocumentTypeComboBox from '../components/ComboBox/DocumentTypeComboBox';
import ParentComboBox from '../components/ComboBox/ParentComboBox';
import ActionCategory, {
  ActionCategoryActuary,
  ActionCategoryCustomer,
} from '../data/model/ActionCategory';
import DisabilityType from '../data/model/DisabilityType';
import MultiSelectableInjuredPartyComponent from '../components/ComboBox/MultiSelectableInjuredPartyComponent';
import RoleComboBox from '../components/ComboBox/RoleComboBox';
import ApprovalType from '../data/model/ApprovalType';
import { DAYS, MONTHS, YEARS } from './MonthYear';
import TagComboBox from '../components/ComboBox/TagComboBox';
import CustomerAutoComplete from '../components/AutoComplete/CustomerAutoComplete';
import TransactionMessageTemplate from '../data/model/TransactionMessageTemplate';
import PaymentCategory from '../data/model/PaymentCategory';
import NurseCategory from '../data/model/NurseCategory';

export const baseFloatingLabelStyle = { fontWeight: 500 };

export const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  floatingLabelStyle,
  ...custom
}) => (
  <TextField
    floatingLabelText={label}
    floatingLabelStyle={{ ...baseFloatingLabelStyle, ...floatingLabelStyle }}
    errorText={touched && error}
    errorStyle={{
      float: 'left',
    }}
    {...input}
    {...custom}
  />
);
export const renderSelectedButton = ({ input, style, meta: { touched, error }, ...custom }) => (
  <SelectButton
    errorText={touched && error}
    floatingLabelStyle={baseFloatingLabelStyle}
    {...input}
    {...custom}
  />
);

export const renderDatePicker = ({
  input,
  label,
  style,
  hintText,
  meta: { touched, error },
  ...custom
}) => (
  <DatePicker
    style={{ display: 'inline-block', ...style }}
    hintText={hintText || label}
    floatingLabelStyle={baseFloatingLabelStyle}
    floatingLabelText={label}
    // locale="tr-TR" Todo Fix this
    errorStyle={{
      float: 'left',
    }}
    errorText={touched && error}
    onChange={(e, val) => input.onChange(val)}
    value={input.value}
    {...custom}
  />
);

export const renderCheckbox = ({ input, label, style, custom, labelStyle }) => (
  <Checkbox
    labelPosition="left"
    label={label}
    style={{ marginTop: 14, ...style }}
    labelStyle={labelStyle}
    checked={!!input.value}
    onCheck={input.onChange}
    {...custom}
  />
);
export const renderRadioGroup = ({ input, ...rest }) => (
  <RadioButtonGroup
    {...input}
    {...rest}
    valueSelected={input.value}
    onChange={(event, value) => input.onChange(value)}
  />
);
export const renderCustomerAutoComplete = ({ input, style, meta: { touched, error }, ...rest }) => (
  <CustomerAutoComplete
    {...input}
    {...rest}
    style={{ ...style }}
    floatingLabelStyle={{ ...baseFloatingLabelStyle }}
    value={input.value}
    onChange={(v) => input.onChange(v)}
    errorText={touched && error}
    errorStyle={{
      float: 'left',
    }}
  />
);
export const renderComboBox = (Node, data) => ({
  input,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <Node
    data={data}
    errorText={touched && error}
    {...input}
    autoWidth
    floatingLabelStyle={baseFloatingLabelStyle}
    children
    {...custom}
  />
);

export const renderWebhookUrl = ({
  input,
  label,
  className,
  placeholder,
  type,
  meta: { touched, error, warning },
}) => (
  <div className={className}>
    <label>{label}</label>
    <div style={{ width: '100%' }}>
      <input {...input} placeholder={placeholder} type={type} />
      {touched &&
        ((error && (
          <span
            style={{
              padding: '0 8px',
              fontSize: '14px',
              fontWeight: '400',
              color: '#C2185B',
            }}
          >
            {error}
          </span>
        )) ||
          (warning && (
            <span
              style={{
                padding: '0 8px',
                fontSize: '14px',
                fontWeight: '400',
                color: '#ff9800',
              }}
            >
              {warning}
            </span>
          )))}
    </div>
  </div>
);

export const renderStaticComboBox = (data) => renderComboBox(StaticSelectField, data);
export const renderDocumentType = renderComboBox(DocumentTypeComboBox);
export const renderTag = renderComboBox(TagComboBox);
export const renderRoleComboBox = renderComboBox(RoleComboBox);
export const renderParentComboBox = renderComboBox(ParentComboBox);
export const renderMortalityTables = renderStaticComboBox(MortalityTable);
export const renderAfter2020MortalityTables = renderStaticComboBox(After2020MortalityTable);
export const renderIncidentTypes = renderStaticComboBox(IncidentType);
export const renderInjuryTypes = renderStaticComboBox(InjuryType);
export const renderLifeAnnuity = renderStaticComboBox(LifeAnnuity);
export const renderGender = renderStaticComboBox(Gender);
export const renderYesNo = renderStaticComboBox(YesNo);
export const renderMaritalStatus = renderStaticComboBox(MaritalStatus);
export const renderEducationalStatus = renderStaticComboBox(EducationalStatus);
export const renderEmployedStatus = renderStaticComboBox(EmployedStatus);
export const renderPaymentType = renderStaticComboBox(PaymentType);
export const renderSalaryType = renderStaticComboBox(SalaryType);
export const renderShareRateCategory = renderStaticComboBox(ShareRateCategory);
export const renderIncomeCategory = renderStaticComboBox(IncomeCategory);
export const renderSpecialPeriod = renderStaticComboBox(SpecialPeriodType);
export const renderActionCategory = renderStaticComboBox(ActionCategory);
export const renderActionCategoryCustomer = renderStaticComboBox(ActionCategoryCustomer);
export const renderActionCategoryActuary = renderStaticComboBox(ActionCategoryActuary);
export const renderDisabilityType = renderStaticComboBox(DisabilityType);
export const renderApprovalType = renderStaticComboBox(ApprovalType);
export const renderPaymentCategory = renderStaticComboBox(PaymentCategory);
export const renderNurseCategory = renderStaticComboBox(NurseCategory);

export const renderMultiSelectableInjuredPartyComponent = renderComboBox(
  MultiSelectableInjuredPartyComponent,
);
export const renderYearComboBox = renderStaticComboBox(YEARS);
export const renderMonthComboBox = renderStaticComboBox(MONTHS);
export const renderDayComboBox = renderStaticComboBox(DAYS);
export const renderTransactionTemplate = renderStaticComboBox(TransactionMessageTemplate);
