import { setAppData } from '../../actions/appdata';
import { CALCULATION_FILE, CALCULATION_FILES } from '../../constants';
import Connection from './Connection';
import { fileToFileList } from '../../utils/file-upload-util';
import FileResponse from '../model/response/FileResponse';

export default class CalculationFileApi extends Connection {
  static list(dispatch, calculationId) {
    return this._getSecurely(`/calculations/${calculationId}/files`)
      .then(...this.justData)
      .then((r) => {
        const data = r ? r.bindList(FileResponse) : [];
        if (dispatch) dispatch(setAppData({ name: CALCULATION_FILES, value: data }));
        return data;
      })
      .catch(this.catchError);
  }

  static find(dispatch, calculationId, fileId) {
    return this._getSecurely(`/calculations/${calculationId}/files/${fileId}`)
      .then(...this.justData)
      .then((r) => {
        const data = r ? r.bindList(FileResponse) : [];
        if (dispatch) dispatch(setAppData({ name: CALCULATION_FILE, value: data }));
        return data;
      })
      .catch(this.catchError);
  }

  static getSignedUrl(calculationId, id) {
    return this._getSecurely(`/calculations/${calculationId}/files/${id}`)
      .then(...this.justData)
      .then((r) => (r ? r.bindClass(FileResponse) : {}))
      .catch(this.catchError);
  }

  static delete(calculationId, fileId) {
    return this._delete(`/calculations/${calculationId}/files/${fileId}`);
  }

  static create(fileList, calculationId) {
    const data = fileToFileList(fileList);
    return this._postFormData(`/calculations/${calculationId}/files`, data);
  }

  static update(calculationId, fileId, data) {
    return this._put(`/calculations/${calculationId}/files/${fileId}`, data);
  }
}
