import React from 'react';
import { Paper, Subheader } from 'material-ui';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import SalaryForm from './SalaryForm';
import { paperStyle } from '../../utils/material-ui-style-util';
import ListAddFAB from '../Functional/ListAddFAB';
import { FieldArrayEmptyView } from '../Functional/FieldArrayEmptyView';

class SalaryListForm extends React.Component {
  onClick = (fields) => {
    if (fields.length > 0) {
      const field = fields.get(fields.length - 1);
      const initValues = { ...field };
      const { month } = field;
      const { year } = field;
      if (month && year) {
        if (month === '12') {
          initValues.month = '1';
          initValues.year = `${parseInt(year) + 1}`;
        } else {
          initValues.month = `${parseInt(month) + 1}`;
          initValues.year = year;
        }
      }
      delete initValues.id;
      fields.push({ ...initValues, workingDays: '30' });
    } else fields.push({ workingDays: '30' });
    this.addButton.scrollIntoView();
  };

  render() {
    const { fields, salaries } = this.props;
    return (
      <Paper style={paperStyle}>
        <Subheader className={`basicReportSubheader`}>Dönemlik Maaş Bilgileri</Subheader>
        <div className={'fieldArrayContainer'}>
          {fields.map((name, index) => (
            <SalaryForm
              key={index}
              name={name}
              remove={() => fields.remove(index)}
              salary={salaries[index]}
            />
          ))}
        </div>
        <FieldArrayEmptyView fields={fields} />
        <ListAddFAB onClick={() => this.onClick(fields)} />
        <div style={{ float: 'left', clear: 'both' }} ref={(e) => (this.addButton = e)} />
      </Paper>
    );
  }
}

const selector = formValueSelector('salaryForm');
export default connect((state) => ({
  salaries: selector(state, 'salaries'),
}))(SalaryListForm);
