import { convertToDate } from '../../../../utils/javascript-object-util';
import { CLEANER_DATETIME, CLEANER_INTEGER } from '../../../../utils/CleanerValues';

export default class DivorceUpdateRequest {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.alimonyAmount = config.alimonyAmount || CLEANER_INTEGER;
    this.dateOfDivorce = config.dateOfDivorce
      ? convertToDate(config.dateOfDivorce)
      : CLEANER_DATETIME;
  }
}
