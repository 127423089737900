/* eslint-disable react/prop-types */
import React from 'react';
import Snackbar from 'material-ui/Snackbar';
import { connect } from 'react-redux';
import { closeSnackbar } from '../actions/snackbarActions';

class ReduxSnackbar extends React.Component {
  render() {
    const { snackbar } = this.props;
    const { open, message } = snackbar;
    return open && message ? (
      <Snackbar
        open={open}
        message={message.text}
        autoHideDuration={5000}
        onActionClick={message.action ? message.action.onClick : undefined}
        action={message.action ? message.action.text : undefined}
        onRequestClose={this.props.closeSnackbar}
      />
    ) : (
      <div />
    );
  }
}

function mapStateToProps(state) {
  return {
    snackbar: state.snackbar,
  };
}

export default connect(mapStateToProps, { closeSnackbar })(ReduxSnackbar);
