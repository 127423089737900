import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Paper, Subheader, FlatButton } from 'material-ui';
import { hidePB, showPB } from '../../actions/progressbarAction';
import EventsApi from '../../data/api/EventsApi';
import { EVENT, USER } from '../../constants';
import ReportInfoBasics from '../../components/Report/ReportDetail/ReportInfoBasics';
import AppJsonTree from '../../components/AppJsonTree';
import { openSnackbar } from '../../actions/snackbarActions';
import { isValidJson } from '../../utils/javascript-object-util';
import Authorization from '../../components/Authorization/Authorization';
import { ReadCompany, WriteCompany } from '../../data/model/Privilege';
import Layout from '../../components/Layout/Layout';

class EventDetail extends Component {
  fetchData = (props) => {
    const { dispatch, user, match } = props;
    const companyId = user.getCompanyId;
    if (!companyId) return;
    showPB(dispatch);

    EventsApi.find(dispatch, companyId, match.params.id).then(
      () => {
        hidePB(dispatch);
      },
      () => {
        hidePB(dispatch);
      },
    );
  };

  redeliver = () => {
    const { dispatch, user, match } = this.props;
    const companyId = user.getCompanyId;
    if (!companyId) return;
    showPB(dispatch);

    EventsApi.redeliver(dispatch, companyId, match.params.id).then(
      () => {
        hidePB(dispatch);
        dispatch(openSnackbar('Tekrar Gönderildi'));
      },
      () => {
        hidePB(dispatch);
        dispatch(openSnackbar('Bir Hata Oluştu'));
      },
    );
  };

  componentDidMount() {
    this.fetchData(this.props);
  }

  render() {
    const { detailedEvent } = this.props;
    if (!detailedEvent) return <div />;

    const { event, request, response } = detailedEvent;
    return (
      <Layout>
        <Authorization allowedRoles={[ReadCompany, WriteCompany]} force>
          <div className={`${'root'} ${'max1024'}`} style={{ marginBottom: 64 }}>
            <h1>Event Detayı</h1>
            {detailedEvent && (
              <Paper style={{ padding: '32px 16px' }}>
                <ReportInfoBasics incident={event.incident} casualty={event.casualty} />
              </Paper>
            )}

            <Paper
              style={{
                display: 'table',
                margin: 'auto',
                padding: '16px 32px',
                marginTop: '32px',
                textAlign: 'center',
              }}
            >
              <Subheader style={{ paddingLeft: 0 }}>Status</Subheader>
              <span
                style={{
                  padding: '16px 32px',
                  fontSize: '42px',
                  fontWeight: '400',
                  color: parseInt(event.responseStatus / 100) === 2 ? 'green' : 'red',
                }}
              >
                {event.responseStatus}
              </span>
              <br />
              <FlatButton label="Tekrar Gönder" primary onClick={() => this.redeliver()} />
            </Paper>

            <Paper style={{ padding: 16, marginTop: 32 }}>
              <h3>Request</h3>
              <Subheader>Header</Subheader>
              <AppJsonTree shouldExpandNode={() => true} data={JSON.parse(request.headers)} />
              <Subheader>Body</Subheader>
              <AppJsonTree shouldExpandNode={() => true} data={JSON.parse(request.body)} />
            </Paper>

            <Paper style={{ padding: 16, marginTop: 16 }}>
              <h3>Response</h3>
              <Subheader>Header</Subheader>
              <AppJsonTree shouldExpandNode={() => true} data={JSON.parse(response.headers)} />
              <Subheader>Body</Subheader>
              {isValidJson(response.body) ? (
                <AppJsonTree shouldExpandNode={() => true} data={JSON.parse(response.body)} />
              ) : (
                <pre>{response.body}</pre>
              )}
            </Paper>
          </div>
        </Authorization>
      </Layout>
    );
  }
}

export default connect((state) => ({
  user: state.appData[USER],
  detailedEvent: state.appData[EVENT],
}))(EventDetail);
