import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import { connect } from 'react-redux';
import { Field, FieldArray, FormSection, formValueSelector, reduxForm } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment/moment';
import { Paper, RadioButton, Subheader } from 'material-ui';
import { renderApprovalType, renderRadioGroup } from '../../utils/redux-metarial-ui-util';
import FileUploadList from '../../components/FileUpload/FileUploadList';
import Clear from '../../components/Functional/Clear';
import {
  clean,
  convertToDate,
  dateFormat,
  getUUIDFromUrl,
} from '../../utils/javascript-object-util';
import { fieldStyle } from '../../utils/material-ui-style-util';
import {
  REQUIRED,
  validateCalculationConfig,
  validateDate,
  validateFiles,
  validateIncidentLogical,
  validateInjuredParty,
} from '../../utils/validation';
import { fileCountValidation, fileUpload } from '../../utils/file-upload-util';
import { openSnackbar } from '../../actions/snackbarActions';
import { hidePB, showPB } from '../../actions/progressbarAction';
import DetailedIncidentCreateRequest from '../../data/model/request/create/DetailedIncidentCreateRequest';
import CalculationConfigListForm from '../../components/Report/CalculationConfig/CalculationConfigListForm';
import Authorization from '../../components/Authorization/Authorization';
import { WriteCalculation, WriteIncident } from '../../data/model/Privilege';
import { APPROVED } from '../../data/model/ApprovalType';
import IncidentForm from './IncidentForm';
import CasualtyForm from '../../components/Report/CasualtyForm';
import IncidentApi from '../../data/api/IncidentApi';
import { USER } from '../../constants';
import { DISABLED } from '../../data/model/IncidentType';
import { getOppositeGender, MALE } from '../../data/model/Gender';
import SpouseApi from '../../data/api/SpouseApi';
import SpouseCreateRequest from '../../data/model/request/create/SpouseCreateRequest';
import ChildApi from '../../data/api/ChildApi';
import ChildCreateRequest from '../../data/model/request/create/ChildCreateRequest';
import Layout from '../../components/Layout/Layout';

class BasicReport extends React.Component {
  state = {
    files: {},
  };

  handleFilesArrayValueChange = (files, index) => {
    const newFiles = {
      ...this.state.files,
      [index]: files,
    };
    this.setState({ files: newFiles });
  };

  onSubmit = (values) => {
    values.formType = 'basic';
    if (!values.isApprovalNeeded) values.isApprovalNeeded = APPROVED;
    const incidentCreateRequest = new DetailedIncidentCreateRequest(values);
    const { dispatch } = this.props;
    const { files } = this.state;
    const { incidentType } = values.incident;
    const { marriage } = values.injuredParty;
    const isMarried = marriage !== 'single';

    if (!fileCountValidation(dispatch, files, values.files)) return;

    const redirect = (id) => {
      this.props.history.push(`/reports`);
      this.props.history.push(`/reports/${id}`);
      hidePB(dispatch);
    };
    showPB(dispatch, 'Rapor Kaydı Oluşturuluyor');
    return IncidentApi.create(incidentCreateRequest)
      .then(
        async (r) => {
          if (!r.location) {
            hidePB(dispatch);
            dispatch(openSnackbar('Lütfen oluşturduğunuz rapora belge ekleyiniz.'));
            return;
          }
          if (values.files)
            await fileUpload(dispatch, files, values.files, getUUIDFromUrl(r.location));
          showPB(dispatch, 'Rapor Kaydı Oluşturuluyor');
          return r;
        },
        (r) => {
          hidePB(dispatch);
          dispatch(openSnackbar('Rapor Kaydı oluşturma sırasında hata oluştu'));
          return Promise.reject(r);
        },
      )
      .then(
        (r) => {
          const incidentId = getUUIDFromUrl(r.location);
          if (incidentType === DISABLED && isMarried) {
            IncidentApi.find(dispatch, incidentId).then((detailedIncident) => {
              const { incident } = detailedIncident;
              const { casualtyId } = incident;
              const { injuredParty } = values;
              const { casualty } = injuredParty;
              const casualtyDateOfBirth = casualty.basic.dateOfBirth;

              const requests = [];
              if (marriage === 'wife' || marriage === 'wifeWorking') {
                const spouseData = {
                  basic: {
                    gender: getOppositeGender(injuredParty.casualty.basic.gender),
                  },
                  employmentStatus: marriage === 'wife' ? 'unemployed' : 'employed',
                };
                const creatorOptionalData = {
                  dateOfIssue: convertToDate(incident.dateOfIssue),
                  casualtyDateOfBirth: convertToDate(casualtyDateOfBirth),
                };
                requests.push(
                  SpouseApi.create(
                    casualtyId,
                    new SpouseCreateRequest(spouseData, creatorOptionalData),
                  ),
                );

                const newChildCount = injuredParty.child[5];
                for (let i = 0; i < newChildCount; i += 1) {
                  requests.push(
                    ChildApi.create(
                      casualtyId,
                      new ChildCreateRequest({ basic: { gender: MALE } }, creatorOptionalData),
                    ),
                  );
                }
              }
              return Promise.all(requests).then(() => {
                redirect(incidentId);
              });
            });
          } else {
            redirect(incidentId);
          }
        },
        (err) => {
          hidePB(dispatch);
          dispatch(openSnackbar('Rapor Kaydı oluşturma sırasında hata oluştu'));
          return Promise.reject(err);
        },
      );
  };

  render() {
    const { handleSubmit, marriage, incidentType } = this.props;
    const isMarried = marriage !== 'single';
    return (
      <Layout>
        <Authorization allowedRoles={[WriteIncident]} force>
          <div className={'root'}>
            <div className={'container'}>
              <div className={'max1024'}>
                <h1>Rapor Oluştur</h1>
                <form style={{ padding: '0 16px 16px' }} onSubmit={handleSubmit(this.onSubmit)}>
                  <IncidentForm />
                  <FieldArray
                    name="calculationConfigs"
                    component={CalculationConfigListForm}
                    single
                    formType="basic"
                  />
                  <CasualtyForm incidentType={incidentType} />
                  {incidentType === DISABLED && (
                    <FormSection name="injuredParty">
                      <Paper style={{ fontWeight: '500', marginBottom: 16 }}>
                        <Subheader className={`${'basicReportSubheader'}`}>
                          Temel Aile Bilgileri
                        </Subheader>
                        <Clear />
                        <Field
                          name="marriage"
                          component={renderRadioGroup}
                          style={{ float: 'left', margin: 16 }}
                        >
                          <RadioButton value="single" label="Bekar" />
                          <RadioButton value="wife" label="Evli Eşi Çalışmayan" />
                          <RadioButton value="wifeWorking" label="Evli Eşi Çalışan" />
                        </Field>

                        <Field
                          name="child"
                          component={renderRadioGroup}
                          style={{ float: 'left', margin: 16 }}
                        >
                          <RadioButton value="child0" label="Çocuksuz" disabled={!isMarried} />
                          <RadioButton value="child1" label="1 Çocuklu" disabled={!isMarried} />
                          <RadioButton value="child2" label="2 Çocuklu" disabled={!isMarried} />
                          <RadioButton value="child3" label="3 Çocuklu" disabled={!isMarried} />
                          <RadioButton value="child4" label="4 Çocuklu" disabled={!isMarried} />
                          <RadioButton
                            value="child5"
                            label="5 veya fazla Çocuklu"
                            disabled={!isMarried}
                          />
                        </Field>
                        <Clear />
                      </Paper>
                    </FormSection>
                  )}
                  <FieldArray
                    name="files"
                    component={FileUploadList}
                    handleValueChange={this.handleFilesArrayValueChange}
                    style={{ margin: 0 }}
                  />
                  <Clear />
                  <div style={{ marginTop: 90 }}>
                    <RaisedButton
                      style={{ float: 'right' }}
                      type="submit"
                      label="Rapor Oluştur"
                      primary
                    />
                    <Authorization allowedRoles={[WriteCalculation]}>
                      <div
                        style={{
                          float: 'right',
                          marginRight: '24px',
                          marginTop: -28,
                        }}
                      >
                        <Field
                          style={fieldStyle}
                          component={renderApprovalType}
                          name="isApprovalNeeded"
                          label="Rapor Türü"
                        />
                      </div>
                    </Authorization>
                    <Clear />
                  </div>
                </form>
                <Clear />

                <Authorization allowedRoles={[WriteCalculation]}>
                  <div
                    className={'smallInfo'}
                    style={{ width: '-webkit-fill-available', margin: 16 }}
                  >
                    <h5 style={{ marginTop: 0 }}>Rapor Türü</h5>
                    <b>Aktüer Onaylı:</b> Rapor aktüer onayından geçirilir ve bilgilerin doğruluğu
                    kontrol edilir.
                    <br />
                    <b>Otomatik:</b> Aktüer onayı beklemeksizin hesaplama yapabilir ve rapor çıktısı
                    alabilirsiniz.
                  </div>
                </Authorization>
              </div>
            </div>
          </div>
        </Authorization>
      </Layout>
    );
  }
}

const validate = (values = { incident: {} }, events) => {
  if (isEmpty(values)) return;
  const errors = {
    incident: {},
    injuredParty: { casualty: {} },
    calculationConfigs: [],
  };

  const requiredFields = [
    'dateOfIssue',
    'dateOfReport',
    'dateOfIncident',
    'dateOfDeath',
    'companyId',
    'incidentType',
    'damageFileIdentifier',
  ];
  requiredFields.forEach((f) => {
    if (isEmpty(values.incident[f])) {
      errors.incident[f] = REQUIRED;
    }
  });
  if (!values.isApprovalNeeded) errors.isApprovalNeeded = REQUIRED;

  errors.incident.dateOfIssue = validateDate(values.incident.dateOfIssue);
  errors.incident.dateOfReport = validateDate(values.incident.dateOfReport);
  errors.incident.dateOfIncident = validateDate(values.incident.dateOfIncident);
  errors.incident.dateOfRetirement = validateDate(values.incident.dateOfRetirement, false);
  errors.incident.dateOfDeath = validateDate(values.incident.dateOfDeath);
  errors.injuredParty = validateInjuredParty(values.injuredParty);
  errors.calculationConfigs = validateCalculationConfig(values.calculationConfigs);
  errors.files = validateFiles(values);
  errors._error = validateIncidentLogical(values.incident, errors.incident);

  if (events.error) {
    errors._error = {
      ...errors._error,
      fileIdentifier: events.error.fileIdentifier,
    };
  }
  const cleanedError = clean(errors._error);
  errors._error = isEmpty(cleanedError) ? undefined : cleanedError;
  return errors;
};

BasicReport = reduxForm({
  form: 'basicReport',
  validate,
})(BasicReport);

const selector = formValueSelector('basicReport');
const mapStateToProps = (state) => ({
  files: selector(state, 'files'),
  incidentType: selector(state, 'incident.incidentType'),
  dateOfIncident: selector(state, 'dateOfIncident'),
  dateOfReport: selector(state, 'dateOfReport'),
  marriage: selector(state, 'injuredParty.marriage'),
  initialValues: {
    incident: {
      incidentType: DISABLED,
      dateOfReport: dateFormat(moment()),
      companyId: state.appData[USER]?.getCompanyId,
    },
    calculationConfigs: [{}],
    injuredParty: {
      marriage: 'single',
      child: 'child0',
    },
  },
});
export default connect(mapStateToProps)(BasicReport);
