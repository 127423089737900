import TagResponse from './TagResponse';
import InvoiceDraftResponse from './InvoiceDraftResponse';
import InvoiceItemResponse from './InvoiceItemResponse';

const Column = {
  createdAt: {
    title: 'Oluşturulma Tarihi',
    width: 120,
    converter: (b) => b.incident.summary.createdAt,
  },
  fileIdentifier: {
    title: 'Dosya Numarası',
    width: 120,
    converter: (b) => b.incident.summary.fileIdentifier,
  },
  casualtyName: {
    title: 'Mağdur/Müteveffa',
    width: 120,
    converter: (b) => b.incident.summary.fullName,
  },
  issuerName: {
    title: 'İşlemi Gerçekleştiren',
    width: 120,
    converter: (b) => b.incident.issuer.fullName,
  },
  price: { title: 'Tutar', width: 70, converter: (b) => b.price },
};

export default class DetailedInvoiceDraftResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.dueAmount = config.dueAmount;
    this.invoice = new InvoiceDraftResponse(config.invoice);
    this.tag = new TagResponse(config.tag);
    this.items = config.items ? config.items.bindList(InvoiceItemResponse) : [];
    this.buildReportData = this.buildReportData.bind(this);
  }

  buildReportData() {
    const columns = [];

    columns.push(Column.createdAt);
    columns.push(Column.fileIdentifier);
    columns.push(Column.casualtyName);
    columns.push(Column.issuerName);
    columns.push(Column.price);

    const data = [
      columns.map((c) => c.title),
      ...this.items.map((b) => columns.map((c) => c.converter(b))),
    ];

    // const ws = XLSX.utils.aoa_to_sheet(data);
    // ws['!cols'] = columns.map(c => ({ wpx: c.width }));
    // return ws;
  }
}
