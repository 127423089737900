import { toMoney } from '../../../utils/javascript-object-util';

export default class CalculatorResult {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.name = config.name;
    this.amount = config.amount;
  }

  get getAmount() {
    return toMoney(this.amount);
  }
}
