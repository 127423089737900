import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Paper, RaisedButton } from 'material-ui';
import { renderTextField } from '../../utils/redux-metarial-ui-util';
import { openSnackbar } from '../../actions/snackbarActions';
import { hidePB, showPB } from '../../actions/progressbarAction';
import { validateAmount } from '../../utils/validation';
import Clear from '../../components/Functional/Clear';
import CustomerPricingCreateRequest from '../../data/model/request/create/CustomerPricingCreateRequest';
import CustomerPricingApi from '../../data/api/CustomerPricingApi';
import { CUSTOMER_PRICING } from '../../constants';

class CustomerPricingCreateUpdateForm extends React.Component {
  onSubmit = (values, dispatch) => {
    const { companyId } = this.props;
    showPB(dispatch);
    CustomerPricingApi.upsert(companyId, new CustomerPricingCreateRequest(values)).then(
      (r) => {
        hidePB(dispatch);
        dispatch(openSnackbar('Başarılı'));
      },
      (r) => {
        hidePB(dispatch);
        dispatch(openSnackbar('Bir Hata Oluştu'));
      },
    );
  };

  render() {
    return (
      <Paper style={{ display: 'inline-block', padding: 16, marginTop: 16 }}>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Field
            fullWidth
            name="process"
            floatingLabelText="Otomatik İşlem Ücreti"
            component={renderTextField}
          />
          <Field
            fullWidth
            name="review"
            floatingLabelText="Onaylı İşlem Ücreti"
            component={renderTextField}
          />
          <Field
            fullWidth
            name="comparison"
            floatingLabelText="Bilir Kişi Karşılaştırma Ücreti"
            component={renderTextField}
          />
          <Field
            fullWidth
            name="revision"
            floatingLabelText="Revize Ücreti"
            component={renderTextField}
          />
          <Field
            fullWidth
            name="reviewRevision"
            floatingLabelText="Onaylı Revize Ücreti"
            component={renderTextField}
          />
          <Field
            fullWidth
            name="comparisonRevision"
            floatingLabelText="Bilir Kişi Revize Ücreti"
            component={renderTextField}
          />
          <div style={{ margin: 'auto', display: 'table', paddingTop: '16px' }}>
            <RaisedButton label="Güncelle" type="submit" primary />
          </div>
          <Clear />
        </form>
      </Paper>
    );
  }
}

const validate = (values) => {
  const errors = {};
  errors.process = validateAmount(values.process);
  errors.review = validateAmount(values.review);
  errors.comparison = validateAmount(values.comparison);
  errors.revision = validateAmount(values.revision);
  return errors;
};
CustomerPricingCreateUpdateForm = reduxForm({
  form: 'customerPricingForm',
  validate,
  enableReinitialize: true,
})(CustomerPricingCreateUpdateForm);
export default connect((state) => ({
  initialValues: state.appData[CUSTOMER_PRICING],
}))(CustomerPricingCreateUpdateForm);
