import React from 'react';
import { connect } from 'react-redux';
import BaseLayout from '../BaseLayout/BaseLayout';
import { USER } from '../../constants';
import AccountApi from '../../data/api/AccountApi';

class Layout extends React.Component {
  componentDidMount() {
    this.getMe(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.children !== prevProps.children) {
      this.getMe(this.props);
    }
  }

  getMe = (props) => {
    if (!props.user) AccountApi.getMe(props.dispatch);
  };

  render() {
    return <BaseLayout>{this.props.children}</BaseLayout>;
  }
}

export default connect((state) => ({
  user: state.appData[USER],
}))(Layout);
