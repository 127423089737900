import InvoiceItemResponse from './InvoiceItemResponse';
import InvoiceResponse from './InvoiceResponse';
import DetailedCancellationResponse from './DetailedCancellationResponse';

export default class DetailedInvoiceResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.invoice = new InvoiceResponse(config.invoice);
    this.cancellation = new DetailedCancellationResponse(config.cancellation);
    this.items = config.items ? config.items.bindList(InvoiceItemResponse) : [];
  }
}
