import { setAppData } from '../../actions/appdata';
import { SPOUSE, SPOUSES } from '../../constants';
import Connection from './Connection';
import SpouseResponse from '../model/response/SpouseResponse';
import { toQuery } from '../../utils/javascript-object-util';

export default class SpouseApi {
  static find(dispatch, spouseId) {
    return Connection._getSecurely(`/injured-parties/spouses/${spouseId}`)
      .then(...Connection.justData)
      .then((r) => {
        dispatch(setAppData({ name: SPOUSE, value: SpouseResponse(r) }));
      })
      .catch(Connection.catchError);
  }

  static list(dispatch, casualtyId) {
    return Connection._getSecurely(`/injured-parties/spouses/${toQuery({ casualtyId })}`)
      .then(...Connection.justData)
      .then((r) => {
        dispatch(setAppData({ name: SPOUSES, value: r.bindList(SpouseResponse) }));
      })
      .catch(Connection.catchError);
  }

  static update(spouseId, data) {
    return Connection._patch(`/injured-parties/spouses/${spouseId}`, data);
  }

  static create(casualtyId, data) {
    return Connection._post(`/injured-parties/casualties/${casualtyId}/spouses`, data);
  }

  static delete(spouseId) {
    return Connection._delete(`/injured-parties/spouses/${spouseId}`);
  }
}
