export default class SimpleUserResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.name = config.name;
    this.surname = config.surname;
    this.email = config.email;
  }

  get fullName() {
    if (!this.name && !this.surname) return 'İsimsiz';
    return `${this.name ? this.name.capitalizeAllWord() : ''} 
    ${this.surname ? this.surname.capitalizeAllWord() : ''}`;
  }
}
