import React from 'react';
import { Paper } from 'material-ui';
import StandardLayout from '../../components/BaseLayout/StandardLayout';
import Layout from '../../components/Layout/Layout';
import Authorization from '../../components/Authorization/Authorization';
import { BASIC } from '../../data/model/Privilege';

function Madde({ children }) {
  return <div style={{ paddingLeft: 28, marginTop: 20, marginBottom: 20 }}>{children}</div>;
}

function Circular2020_04_01() {
  return (
    <Layout>
      <div className={'root'}>
        <div className={`container`} style={{ maxWidth: 1024, margin: 'auto' }}>
          <Authorization allowedRoles={[BASIC]} force>
            <Paper style={{ padding: '64px', marginTop: '64px' }}>
              <div style={{ fontSize: '14px', textAlign: 'justify' }}>
                <div
                  style={{
                    fontWeight: 'bold',
                    fontSize: '16px',
                    textAlign: 'center',
                    marginBottom: 48,
                  }}
                >
                  <b>
                    01-04-2020 TANZİM ve ÖNCESİ POLİÇELER için
                    <br />
                    SİSTEM DEĞİŞİKLİKLERİ İLE İLGİLİ KONU BAŞLIKLARI
                  </b>
                </div>

                <div style={{ fontWeight: 'bold' }}>
                  Değerli İş Ortağımız,
                  <br />
                  <br />
                  01.04.2020 tarihinden itibaren yürürlüğe girmiş olan Karayolları Motorlu Araçlar
                  Zorunlu Mali Sorumluluk Sigortası Genel Şartları gereği, mevzuatta yapılan
                  değişikliklere uygun hazırlamış olduğumuz, Destek Hesaplarına ilişkin Sistem
                  hazırlıklarımız tamamlanmıştır.
                  <br />
                  <br />
                  Aşağıda konu başlıkları verilen hususlarda sistemimiz mevzuata uygun olarak
                  değiştirilmiştir.
                </div>

                <Madde>
                  1.&emsp;Evli vefatlarda pay dağılımı;
                  <br />
                  Yeni Genel Şartlardaki EK-2 Madde 10 gereği “Hane kavramı” ortadan kaldırılmıştır.
                  01.04.2020 öncesi ve sonrası hesaplamalarda Madde 10’da belirtilen pay dağılımı
                  kullanılacaktır.
                </Madde>

                <Madde>
                  2.&emsp;Bekar vefatlarda pay dağılımı ile muhtemel evlilik ve muhtemel çocuk
                  yaşları;
                  <br />
                  Yeni Genel Şartlardaki EK-2 Madde 10 gereği TÜİK tarafından yayımlanan evlenme ve
                  boşanma istatistiklerindeki ülke ortalaması dikkate alınarak belirlenir ve
                  evlendikten sonra ise 2 yıl ara ile toplam iki çocuk sahibi olacağı varsayımı
                  kullanılır.
                </Madde>

                <Madde>
                  3.&emsp;Vefat edenin çocukları var ise 01.04.2020 öncesi ve sonrası hesaplamalarda
                  Erkek 18, Kadın 22 yaşına kadar destek alacaktır. Lisans ve lisansüstü eğitim
                  düzeyinde bulunan çocuklar ise 25 yaşına kadar destek alacaktır. Yeni genel
                  şartlardaki EK-2 Madde 7 d bendi.
                </Madde>

                <Madde>
                  4.&emsp;Vefat edenin çocuk olduğu durumlarda 01.04.2020 öncesi ve sonrası
                  hesaplamalarda yetiştirme masrafı Erkek/Kadın fark etmeksizin 18 yaşa kadar %10
                  oranında hesaplanır ve hayatta olan ebeveynler için eşit şekilde bölünür. Yeni
                  genel şartlardaki EK-2 Madde 7 c bendi.
                </Madde>

                <Madde>
                  5.&emsp;Sürekli sakatlık (18 yaş altında olan kişiler için)
                  <br />
                  01.04.2020 öncesi hesaplamalarda Erkek/Kadın fark etmeksizin hesaplamalar 18 yaş
                  itibariyle başlatılacaktır. Kaza tarihinden 18 yaşa kadar olan kısım için herhangi
                  bir hesaplama yapılmayacaktır. Bu konuda değişen husus, kız çocuklarında 22 yaş
                  yerine 18 yaşın dikkate alınmasıdır.
                  <br />
                  <br />
                  01.04.2020 sonrası hesaplamalarda, Erkek/Kadın fark etmeksizin hesaplamalar kaza
                  tarihinden itibaren başlatılacaktır. Kaza tarihinden 18 yaşa kadar AGİ hariç net
                  asgari ücret üzerinden hesaplama yapılacaktır. 18 yaş itibariyle ise, AGİ dahil
                  net asgari ücret üzerinden hesaplama yapılacaktır. Yeni genel şartlardaki EK-3
                  Madde 7 a bendi.
                </Madde>

                <Madde>
                  6.&emsp;Vefat ve sakatlık dosyalarında emeklilik yaşı;
                  <br />
                  01.04.2020 öncesi hesaplamalarda emeklilik yaşı 60 olarak kabul edilecektir.
                  01.04.2020 sonrası hesaplamalarda ise 01.01.1990 öncesi doğumlular için 60,
                  01.01.1990 tarihi ve sonrası doğumlular için 65 olarak kabul edilecektir. EK-2
                  Madde 7 f bendi ve EK-3 Madde 7 a bendi.
                </Madde>

                <Madde>
                  7.&emsp;Askerlik durumu;
                  <br />
                  01.04.2020 öncesi ve sonrası hesaplamalarda kişiler ilgili dönemlerin Askerlik
                  Mevzuatları gereği askere gönderilmektedir.
                  <br />
                  <br />
                  Askerlikte Gelir Durumunda ise dönemsel mevzuatlara uygun şekilde;
                  <br />
                  <br />
                  01.04.2020 öncesi sürekli sakatlık ve vefat dosyalarında bu süre zarfında herhangi
                  bir gelir edinimi yansıtılmayacaktır.
                  <br />
                  01.04.2020 sonrası sürekli sakatlık dosyalarında Asgari-AGİ tutarı (pasif dönem
                  hesaplaması) kadar gelir yansıtılacak, vefat dosyalarında ise bu süre zarfında
                  herhangi bir gelir edinimi yansıtılmayacaktır. EK-2 Madde 7 ç bendi ve EK-3 Madde
                  10.
                  <br />
                </Madde>

                <Madde>
                  8.&emsp;Bakıcı gideri;
                  <br />
                  01.04.2020 öncesi Bakıcı gideri hesaplamaları değişmeyecektir.
                  <br />
                  01.04.2020 sonrası dosyalarda Sürekli sakatlığa bağlı bakıcı gideri, bakıcı
                  tutulduğunun belgelendirilememesi halinde bekâr ve çocuksuz kişiler için geçerli
                  AGİ dâhil net asgari ücret üzerinden, bakıcı tutulduğunun belgelendirilmesi
                  durumunda ise brüt asgari ücret üzerinden olmak üzere 20/2/2019 tarihli ve 30692
                  sayılı Resmî Gazete’ de yayımlanan Çocuklar için Özel Gereksinim Değerlendirmesi
                  Hakkında Yönetmelik ve Erişkinler İçin Engellilik Değerlendirmesi Hakkında
                  Yönetmelikte kısmî bağımlı olarak tanımlanan ve %50 ve üzerinde engel oranına
                  sahip kişiler için %50 oranında, tam bağımlı olarak tanımlanan kişiler için ise
                  %100 oranında hesaplanır. EK-3 Madde 6.
                </Madde>

                <Madde>
                  9.&emsp; Gelirin belirlenmesi;
                  <br />
                  01.04.2020 öncesi hesaplamalarda ilgili dönemlerin gelirleri kullanılacaktır.
                  Gelirlerin güncellemesi dönemsel asgari ücret artış oranları ile yapılacaktır.
                  <br />
                  01.04.2020 sonrası hesaplamalarda kaza tarihindeki net gelir ile net asgari ücret
                  oranlanarak, elde edilen katsayı ilgili dönemlere ait net asgari ücretler ile
                  çarpılacaktır. EK-2 Madde 5 ii bendi ve EK-3 Madde 5 ii bendi.
                </Madde>

                <div style={{ fontWeight: 'bold' }}>
                  01.04.2020 sonrası dosyalarda;
                  <br />
                  * Artan sürekli maluliyet oranı,
                  <br />
                  * Ödeme
                  <br />
                  Konu başlıklı dosyalar mevcut olduğu durumlarda sadece “Onaylı Rapor” şeklinde
                  hizmet verilecek olup, ihtiyatlılık ilkesi gereği “Otomatik Rapor” alımına
                  kapalıdır.
                </div>
                <br />
                <div>
                  Müteakip günlerde yapılacak değişimlerde yine tarafınıza bilgilendirme
                  geçilecektir.
                  <br />
                  <br />
                  Bilgilerinize sunar, ihtiyacınız olan konularda bize geri dönüş yapabileceğinizi
                  ve sizlere destek olmak üzere, ekip olarak hazır olduğumuzu bildirmek isteriz.
                </div>
              </div>
            </Paper>
          </Authorization>
        </div>
      </div>
    </Layout>
  );
}

export default Circular2020_04_01;
