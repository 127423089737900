import { setAppData } from '../../actions/appdata';
import {
  INVOICE,
  INVOICE_CONTENT_RANGE,
  INVOICE_ITEM_COUNT,
  INVOICE_LIST,
  INVOICES,
} from '../../constants';
import Connection from './Connection';
import { toQuery } from '../../utils/javascript-object-util';
import DetailedInvoiceResponse from '../model/response/DetailedInvoiceResponse';

export default class InvoiceApi extends Connection {
  static list(dispatch, companyId, page = 0, limit = 10) {
    return this._getSecurely(`/${INVOICES}${toQuery({ companyId, page, limit })}`)
      .then(...this.setPageInfo(dispatch, INVOICE_CONTENT_RANGE, INVOICE_ITEM_COUNT))
      .then(...this.justData)
      .then((r) =>
        dispatch(
          setAppData({
            name: INVOICE_LIST,
            value: r ? r.bindList(DetailedInvoiceResponse) : [],
          }),
        ),
      )
      .catch(this.catchError);
  }

  static find(dispatch, invoiceId) {
    return this._getSecurely(`/${INVOICES}/${invoiceId}`)
      .then(...this.justData)
      .then((r) =>
        dispatch(
          setAppData({
            name: INVOICE,
            value: new DetailedInvoiceResponse(r),
          }),
        ),
      )
      .catch(this.catchError);
  }

  static cancel(invoiceId, data) {
    return this._post(`/${INVOICES}/${invoiceId}/cancel`, data).catch(this.catchError);
  }
}
