export default class BasicCalculation {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.id = config.id;
    this.dateOfRetirement = config.dateOfRetirement;
    this.knownActiveAmount = config.knownActiveAmount;
    this.unknownActiveAmount = config.unknownActiveAmount;
    this.unknownPassiveAmount = config.unknownPassiveAmount;
    this.compensationAmount = config.compensationAmount;
    this.timestamp = config.timestamp;
  }
}
