/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import { dateFormat, toMoment } from '../utils/javascript-object-util';

export const EPOCH_DATE = moment.unix(0);
export const UNKNOWN = 'unknown';
export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const SET_APP_DATA = 'SET_APP_DATA';
export const DROP_APP_DATA = 'DROP_APP_DATA';
export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const INITIAL_NOW = 'initNow';
export const INIT_COMPANY = 'initCompany';
export const LOCALE = 'locale';
export const DIALOG_OPEN = 'isDialogOpen';
export const REPORT_LIST = 'reports';
export const REPORT_LIST_PAGE = 'reportListPage';
export const REPORT_ITEM = 'report';
export const ACTION_LIST = 'actions';
export const INCIDENT_LIST = 'incidents';
export const DETAILED_INCIDENT = 'detailedIncident';
export const DETAILED_INCIDENT_CC = 'detailedIncidentCC';
export const CASUALTY = 'casualty';
export const CASUALTIES = 'casualty';
export const SPOUSE = 'spouse';
export const SPOUSES = 'spouses';
export const PARENT = 'parent';
export const PARENTS = 'parents';
export const CHILD = 'child';
export const CHILDREN = 'children';
export const INJURED_PARTY = 'injuredParty';
export const CALCULATION = 'calculation';
export const CALCULATION_FILE = 'calculation_file';
export const CALCULATION_FILES = 'calculation_files';
export const DOCUMENT_TYPES = 'DocumentTypes';
export const TAGS = 'tags';
export const INIT_DETAILED_REPORT = 'initDetailedReport';
export const INIT_DETAILED_REPORT_DEFAULT = {
  useAllowanceExcludedWageOnPassive: false,
  incidentType: 'disabled',
  dateOfReport: dateFormat(moment()),
};
export const LAST_VISITED_TAB_REPORT_DETAIL = 'lastVisitedTabReportDetail';
export const SPECIAL_PERIOD_LIST = 'specialPeriods';
export const SPECIAL_PERIOD = 'specialPeriod';
export const SALARY_LIST = 'salaries';
export const SALARY = 'salary';
export const PAYMENT_LIST = 'payments';
export const PAYMENT = 'payment';
export const FILE_LIST = 'files';
export const FILE = 'file';
export const INVOICE_DRAFT_LIST = 'invoiceDrafts';
export const INVOICE_DRAFT = 'invoiceDraft';
export const INVOICE_LIST = 'invoices';
export const INVOICE_LIST_PAGE = 'invoicePage';
export const INVOICE_CONTENT_RANGE = 'invoiceContentRange';
export const INVOICE_ITEM_COUNT = 'invoiceItemCount';
export const INVOICE = 'invoice';
export const ROLE_LIST = 'roles';
export const ROLE = 'role';
export const PRICING_LIST = 'pricingList';
export const CUSTOMERS = 'customers';
export const COMPANY_USER_LIST = 'companyUsers';
export const COMPANY_USER = 'companyUser';
export const INVITATIONS_LIST = 'invitations';
export const CALCULATION_CONFIGS = 'calculationConfigs';
export const CALCULATION_CONFIG = 'calculationConfig';
export const CUSTOMER_PRICING = 'customerPricing';
export const WEBHOOKS = 'webhooks';
export const EVENTS = 'events';
export const EVENT = 'event';
export const INIT_CALCULATION_CONFIG = 'initCalculationConfig';
export const INIT_PAYMENTS = 'initPayments';
export const INIT_SALARIES = 'initSalaries';
export const INIT_BASIC_REPORT = 'initBasicReport';

export const PAGE_SIZE = 10;
export const REPORT_CONTENT_RANGE = 'reportContentRange';
export const REPORT_ITEM_COUNT = 'reportItemCount';
export const REPORT_FILTERS = 'reportFilter';

// API ------------------

export const ACCOUNTS = 'accounts';
export const INVOICES = 'invoices';

// USER -----------------

export const USER = 'user';
export const INIT_ME = 'initMe';
export const INIT_LOGIN = 'initLogin';

export const USER_EXPIRE = moment.duration(120, 'seconds');

// ----------------------
export const DATE_FORMAT = 'DD.MM.YYYY';

// FILTER ---------------

export const INIT_REPORT_FILTER = 'initReportFilter';
export const ACTIVE_FILTER_OPTIONS = 'activeFilterOptions';
export const FILTER_OPTIONS = 'filterOptions';

export const INIT_INCIDENT = 'initIncident';
export const INIT_CASUALTY = 'initCasualty';
export const INIT_INJURED_PARTY = 'initInjuredParty';
export const INIT_PAYMENT = 'initPayment';
export const INIT_SALARY = 'initSalary';
export const INIT_SPECIAL_PERIOD = 'initSpecialPeriod';
export const INIT_RESET_PASSWORD = 'initResetPassword';
export const INIT_FILE_UPDATE = 'initFileUpdate';

// DOC_TYPES ------------
export const DOC_TYPE_REPORT = '14556fa4-4610-41ff-8722-cf422345d1f3';
export const DOC_TYPE_ATTACH = 'cd1d3b52-da20-4304-80b5-53afb2dc55fb';
// TAGS
export const TAG_DAMAGE = '9ec2ab7a-cda7-4c25-a283-4feb0786d625';

// TIMING
export const DEBOUNCE_TEXT_INPUT = 300;

// CIRCULARS
export const CIRCULAR_2015 = '01.06.2015';
export const CIRCULAR_2015_MOMENT = toMoment(CIRCULAR_2015);
export const CIRCULAR_2020 = '01.04.2020';
export const CIRCULAR_2020_MOMENT = toMoment(CIRCULAR_2020);
export const CIRCULAR_2021 = '04.12.2021';
export const CIRCULAR_2021_MOMENT = toMoment(CIRCULAR_2021);
