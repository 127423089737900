import React from 'react';
import { connect } from 'react-redux';
import { Dialog, FlatButton, IconButton, Paper } from 'material-ui';
import Trash from 'material-ui/svg-icons/action/delete';
import { hidePB, showPB } from '../../actions/progressbarAction';
import { openSnackbar } from '../../actions/snackbarActions';
import Clear from '../../components/Functional/Clear';
import { USER } from '../../constants';
import DetailedRoleView from './DetailedRoleView';
import RoleApi from '../../data/api/RoleApi';

class RoleViewHolder extends React.Component {
  mouseOver = () => {
    this.setState({ hover: true });
  };

  mouseOut = () => {
    this.setState({ hover: false });
  };

  onClick = () => {
    this.setState({ expand: !this.state.expand });
  };

  handleDeleteOnClick = () => {
    const { dispatch, role, companyId } = this.props;
    showPB(dispatch);
    RoleApi.delete(companyId, role.id).then(() => {
      RoleApi.list(dispatch, companyId).then(() => {
        hidePB(dispatch);
        dispatch(openSnackbar('Rol Silindi.'));
        this.handleClose();
      });
    });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  actions = [
    <FlatButton label="İptal" keyboardFocused secondary onClick={this.handleClose} />,
    <FlatButton label="Sil" primary onClick={this.handleDeleteOnClick} />,
  ];

  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      expand: false,
      open: false,
    };
  }

  render() {
    const { role, companyId } = this.props;
    const { hover } = this.state;
    const { expand } = this.state;

    return (
      <Paper
        style={{
          marginBottom: 1,
          width: '100%',
          display: 'inline-block',
          position: 'relative',
        }}
        onMouseEnter={this.mouseOver}
        onMouseLeave={this.mouseOut}
      >
        <div>
          <div
            style={{
              padding: '8px 16px',
              borderBottom: '1px solid lightgray',
              fontSize: '20px',
              fontWeight: 400,
              background: hover ? '#f3f3f3' : 'white',
              cursor: 'pointer',
            }}
            onClick={this.onClick}
          >
            {role.name}
          </div>{' '}
          <div
            style={{
              visibility: hover ? 'visible' : 'hidden',
              position: 'absolute',
              top: -2,
              right: 0,
              transition: 'opacity 300ms, visibility 300ms',
              opacity: hover ? 1 : 0,
            }}
          >
            <IconButton tooltipposition="top" tooltip="Sil" onClick={() => this.handleOpen()}>
              <Trash color="#525252" />
            </IconButton>
          </div>
        </div>
        {expand && <DetailedRoleView role={role} companyId={companyId} />}
        <Dialog
          title="Role Silinsin Mi?"
          actions={this.actions}
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
        >
          {role.name} isimli rolü silmek istediğinize emin misiniz?
        </Dialog>
        <Clear />
      </Paper>
    );
  }
}

export default connect((state) => ({
  user: state.appData[USER],
}))(RoleViewHolder);
