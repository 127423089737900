import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Paper } from 'material-ui';
import { CUSTOMER_PRICING, PRICING_LIST, USER } from '../../constants';
import CustomerAutoComplete from '../../components/AutoComplete/CustomerAutoComplete';
import Clear from '../../components/Functional/Clear';
import Authorization from '../../components/Authorization/Authorization';
import { ACTUARY, ReadPricing } from '../../data/model/Privilege';
import { clearAppData } from '../../actions/appdata';
import { setMidnight } from '../../utils/javascript-object-util';
import CustomerPricingApi from '../../data/api/CustomerPricingApi';
import CustomerPricingCreateUpdateForm from './CustomerPricingCreateUpdateForm';
import { hidePB, showPB } from '../../actions/progressbarAction';
import { openSnackbar } from '../../actions/snackbarActions';
import Layout from '../../components/Layout/Layout';

class CustomerPricingPage extends React.Component {
  fetchData = (companyId) => {
    if (!companyId) {
      this.setState({ lastQueriedCompanyId: null });
      return;
    }
    if (companyId === this.state.lastQueriedCompanyId) {
      return;
    }
    const { dispatch } = this.props;
    showPB(dispatch);
    CustomerPricingApi.find(dispatch, companyId).then(
      (r) => {
        hidePB(dispatch);
        this.setState({ lastQueriedCompanyId: companyId });
      },
      () => dispatch(openSnackbar('Bir Hata Oluştu')),
    );
  };

  constructor(props) {
    super(props);
    const from = moment().set('date', 1);
    setMidnight(from);
    this.state = {
      companyId: null,
      lastQueriedCompanyId: null,
    };
  }

  componentWillUnmount() {
    this.props.dispatch(clearAppData(CUSTOMER_PRICING));
  }

  render() {
    const { lastQueriedCompanyId } = this.state;
    return (
      <Layout>
        <Authorization allowedRoles={[ReadPricing]} force>
          <div className={'root'}>
            <div className={`${'container'} ${'max380'}`}>
              <h1 style={{ fontSize: '30px', margin: '32px 0' }}>
                Sigorta Şirketi Fiyatlandırmaları
              </h1>
              <Paper style={{ position: 'relative', padding: '0 16px', zIndex: 2 }}>
                <Authorization allowedRoles={[ACTUARY]}>
                  <CustomerAutoComplete
                    title="Sigorta Şirketi"
                    fullWidth
                    onChange={(v) => this.fetchData(v)}
                  />
                </Authorization>
                <Clear />
              </Paper>
              {!this.props.isProgressbarOpen && lastQueriedCompanyId && (
                <CustomerPricingCreateUpdateForm companyId={lastQueriedCompanyId} />
              )}
              <Clear />
            </div>
          </div>
        </Authorization>
      </Layout>
    );
  }
}

export default connect((state) => ({
  pricing: state.appData[PRICING_LIST] || [],
  user: state.appData[USER],
  isProgressbarOpen: state.runtime.isProgressbarOpen,
}))(CustomerPricingPage);
