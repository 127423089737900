import { convertToDate } from '../../../../utils/javascript-object-util';

export default class SpecialPeriodCreateRequest {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.from = convertToDate(config.from);
    this.till = convertToDate(config.till);
    this.category = config.category;
  }
}
