import React from 'react';
import { Divider, Paper, Subheader } from 'material-ui';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import PaymentForm from './PaymentForm';
import { paperStyle } from '../../utils/material-ui-style-util';
import ListAddFAB from '../Functional/ListAddFAB';
import { FieldArrayEmptyView } from '../Functional/FieldArrayEmptyView';

function PaymentListForm({
  fields,
  meta: { error, submitFailed },
  payments,
  injuredParty,
  incidentType,
}) {
  return (
    <Paper style={paperStyle}>
      <Subheader className={'basicReportSubheader'}>Yapılan Ödemeler</Subheader>
      {fields.map((name, index) => (
        <div key={index}>
          <PaymentForm
            name={name}
            index={index}
            fields={fields}
            incidentType={incidentType}
            payment={payments[index]}
            injuredParty={injuredParty}
          />
          <Divider style={{ marginTop: 0 }} />
        </div>
      ))}
      <FieldArrayEmptyView fields={fields} />
      <ListAddFAB
        onClick={() =>
          fields.push({
            category: 'deductible',
            people: [],
          })
        }
      />
    </Paper>
  );
}
PaymentListForm = reduxForm({
  form: 'detailedReport',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(PaymentListForm);

const selector = formValueSelector('detailedReport');
export default connect((state) => ({
  payments: selector(state, 'payments'),
  injuredParty: selector(state, 'injuredParty'),
}))(PaymentListForm);
