import { dateFormat } from '../../../utils/javascript-object-util';

export default class IncidentSummary {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.id = config.id;
    this.createdAt = dateFormat(config.createdAt);
    this.fileIdentifier = config.fileIdentifier;
    this.casualtyName = config.casualtyName;
    this.casualtySurname = config.casualtySurname;
    this.archivedAt = dateFormat(config.archivedAt);
  }

  get fullName() {
    return `${this.casualtyName} ${this.casualtySurname}`;
  }
}
