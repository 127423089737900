import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import includes from 'lodash/includes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker, FlatButton, Paper, RaisedButton } from 'material-ui';
import isEmpty from 'lodash/isEmpty';
import { PRICING_LIST, TAG_DAMAGE, USER } from '../../constants';
import { hidePB, showPB } from '../../actions/progressbarAction';
import CustomerAutoComplete from '../../components/AutoComplete/CustomerAutoComplete';
import { baseFloatingLabelStyle } from '../../utils/redux-metarial-ui-util';
import Clear from '../../components/Functional/Clear';
import Authorization from '../../components/Authorization/Authorization';
import { ACTUARY, CUSTOMER, ReadPricing } from '../../data/model/Privilege';
import PricingViewHolder from './PricingViewHolder';
import PricingApi from '../../data/api/PricingApi';
import { openSnackbar } from '../../actions/snackbarActions';
import { clearAppData } from '../../actions/appdata';
import TagComboBox from '../../components/ComboBox/TagComboBox';
import InvoiceDraftApi from '../../data/api/InvoiceDraftApi';
import { setMidnight } from '../../utils/javascript-object-util';
import OptionalButton from '../../components/Functional/OptionalButton';
import EmptyView from '../../components/Functional/EmptyView';
import Layout from '../../components/Layout/Layout';

class PricingPage extends React.Component {
  fetchData = (companyId) => {
    const { dispatch } = this.props;
    const { from, till } = this.state;
    if (!companyId) {
      dispatch(openSnackbar('Şirket Seçilmedi'));
      return;
    }
    if (!from) {
      dispatch(openSnackbar('Başlangıç Zamanı Seçilmedi'));
      return;
    }
    if (!till) {
      dispatch(openSnackbar('Bitiş Zamanı Seçilmedi'));
      return;
    }
    showPB(dispatch);
    PricingApi.list(
      dispatch,
      companyId,
      moment(from).toISOString(),
      moment(till).toISOString(),
    ).then(
      (r) => {
        this.setState({ lastQueriedCompanyId: companyId });
        hidePB(dispatch);
      },
      (r) => {
        hidePB(dispatch);
      },
    );
  };

  fillDraft = (companyId) => {
    const { dispatch } = this.props;
    const { from, till, tagId } = this.state;
    if (!companyId) {
      dispatch(openSnackbar('Şirket Seçilmedi'));
      return;
    }
    if (!tagId) {
      dispatch(openSnackbar('Depeartman Seçilmedi'));
      return;
    }
    showPB(dispatch);
    InvoiceDraftApi.fill(
      companyId,
      tagId,
      from ? moment(from).toISOString() : undefined,
      till ? moment(till).toISOString() : undefined,
    ).then(
      (r) => {
        dispatch(openSnackbar('Dosyalar mutabakata eklendi'));
        hidePB(dispatch);
      },
      (r) => {
        dispatch(openSnackbar('Bir hata oluştu'));
        hidePB(dispatch);
      },
    );
  };

  setDateMonthly = (months) => {
    const till = moment();
    if (months !== 0) {
      till.subtract(months - 1, 'months').set('date', 1);
      setMidnight(till);
    }
    const from = moment().subtract(months, 'months').set('date', 1);
    setMidnight(from);
    this.setState({ from: from.toDate() });
    this.setState({ till: till.toDate() });
  };

  clearData = () => {
    this.setState({ companyId: null });
    this.setState({ lastQueriedCompanyId: null });
    this.setState({ tagId: TAG_DAMAGE });
    this.setState({ from: null });
    this.setState({ till: null });
    this.props.dispatch(clearAppData(PRICING_LIST));
  };

  constructor(props) {
    super(props);
    const from = moment().set('date', 1);
    setMidnight(from);
    this.state = {
      companyId: null,
      tagId: TAG_DAMAGE,
      from: from.toDate(),
      till: moment().toDate(),
      lastQueriedCompanyId: null,
    };
  }

  componentDidMount() {
    const { user } = this.props;
    if (includes(user.getRoles, CUSTOMER)) {
      this.setState({ companyId: user.getCompanyId });
      this.fetchData(user.getCompanyId);
    }
  }

  componentWillUnmount() {
    this.props.dispatch(clearAppData(PRICING_LIST));
  }

  render() {
    const { pricing } = this.props;
    const { lastQueriedCompanyId, tagId, from, till, companyId } = this.state;
    return (
      <Layout>
        <Authorization allowedRoles={[ReadPricing]} force>
          <div className={'root'}>
            <div className={`${'container'} ${'max1024'}`}>
              <h1>Ücretlendirmeler</h1>
              <Paper style={{ position: 'relative', paddingBottom: 16, zIndex: 2 }}>
                <div className={`${'basicReportFieldContainer'}`} style={{ position: 'relative' }}>
                  <DatePicker
                    fullWidth
                    style={{ display: 'inline-block' }}
                    floatingLabelStyle={baseFloatingLabelStyle}
                    floatingLabelText="Başlangıç Tarihi"
                    onChange={(e, v) => this.setState({ from: v })}
                    value={from}
                  />
                  <OptionalButton
                    onClick={() => this.setDateMonthly(0)}
                    text={moment().locale('tr').format('MMMM')}
                    width={50}
                  />
                  <OptionalButton
                    onClick={() => this.setDateMonthly(1)}
                    text={moment().subtract(1, 'months').locale('tr').format('MMMM')}
                    width={50}
                    right={58}
                  />
                  <OptionalButton
                    onClick={() => this.setDateMonthly(2)}
                    text={moment().subtract(2, 'months').locale('tr').format('MMMM')}
                    width={50}
                    right={116}
                  />
                </div>
                <div className={`${'basicReportFieldContainer'}`}>
                  <DatePicker
                    fullWidth
                    style={{ display: 'inline-block' }}
                    floatingLabelStyle={baseFloatingLabelStyle}
                    floatingLabelText="Bitiş Tarihi"
                    onChange={(e, v) => this.setState({ till: v })}
                    value={till}
                  />
                </div>
                <Authorization allowedRoles={[ACTUARY]}>
                  <div className={`${'basicReportFieldContainer'}`}>
                    <CustomerAutoComplete
                      title="Sigorta Şirketi"
                      onChange={(v) => this.setState({ companyId: v })}
                    />
                  </div>
                </Authorization>
                <Authorization allowedRoles={[ACTUARY]}>
                  <div className={`${'basicReportFieldContainer'}`}>
                    <TagComboBox
                      fullWidth
                      floatingLabelFixed
                      floatingLabelStyle={baseFloatingLabelStyle}
                      title="Departman"
                      onChange={(v) => this.setState({ tagId: v })}
                      value={tagId}
                    />
                  </div>
                </Authorization>

                <div
                  style={{
                    position: 'absolute',
                    right: 16,
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                >
                  <RaisedButton
                    style={{ float: 'right', marginLeft: 16 }}
                    primary
                    label="Filtrele"
                    onClick={() => this.fetchData(companyId)}
                  />
                  {tagId && (
                    <Authorization allowedRoles={[ACTUARY]}>
                      <div
                        style={{
                          float: 'right',
                        }}
                        className={`${'tooltipv2'}`}
                      >
                        <FlatButton
                          style={{
                            minWidth: 38,
                            maxWidth: 38,
                          }}
                          primary
                          onClick={() => this.fillDraft(companyId)}
                        >
                          <FontAwesomeIcon icon="file-export" />
                        </FlatButton>
                        <div className={'tooltipTextv2'}>Dosyaları Mutabakata Ekle</div>
                      </div>
                    </Authorization>
                  )}
                </div>
                <Clear />
              </Paper>
              <Clear />
              {isEmpty(pricing) ? (
                <EmptyView
                  icon="money-bill-wave"
                  style={{ marginTop: 64 }}
                  label="Ücretlendirme bulunamadı"
                />
              ) : (
                pricing.map((p) => (
                  <PricingViewHolder companyId={lastQueriedCompanyId} pricing={p} />
                ))
              )}
            </div>
          </div>
        </Authorization>
      </Layout>
    );
  }
}

export default connect((state) => ({
  pricing: state.appData[PRICING_LIST] || [],
  user: state.appData[USER],
}))(PricingPage);
