import React from 'react';
import Card from 'material-ui/Card';
import CardHeader from 'material-ui/Card/CardHeader';
import CardText from 'material-ui/Card/CardText';
import RaisedButton from 'material-ui/RaisedButton';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Clear from '../Functional/Clear';
import ReportFilterForm from './ReportFilterForm';
import { ACTIVE_FILTER_OPTIONS, INIT_REPORT_FILTER } from '../../constants';
import { setAppData } from '../../actions/appdata';
import { validateDate, validatePercentage } from '../../utils/validation';

class ReportFilter extends React.Component {
  onSubmit = (values) => {
    this.props.dispatch(setAppData({ name: ACTIVE_FILTER_OPTIONS, value: values }));
    this.props.onFilter(values);
  };

  render() {
    return (
      <Card
        style={{
          minWidth: 612,
          marginTop: 5,
          marginBottom: 6,
        }}
        containerStyle={{
          paddingBottom: 0,
        }}
      >
        <CardHeader
          title="Filtreler"
          titleStyle={{ fontWeight: 'bold' }}
          actAsExpander
          showExpandableButton
        />
        <CardText
          expandable
          style={{
            paddingTop: 0,
            padding: 0,
          }}
        >
          <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <ReportFilterForm onReset={() => this.props.onReset()} />
            <RaisedButton
              label="Filtrele"
              style={{ float: 'right', margin: '16px 16px 16px 0' }}
              primary
              type="submit"
              disabled={this.props.pristine}
            />
            <Clear />
          </form>
        </CardText>
      </Card>
    );
  }
}

const validate = (values) => {
  const errors = {};
  for (const k in values) {
    if (values.hasOwnProperty(k)) {
      if (k.includes('Ratio')) {
        errors[k] = validatePercentage(values[k], true);
      } else if (k.includes('date')) {
        errors[k] = validateDate(values[k], true);
      }
    }
  }
  return errors;
};
ReportFilter = reduxForm({ form: 'reportFilter', validate })(ReportFilter);

const mapStateToProps = (state) => {
  const initialValues = state.appData[INIT_REPORT_FILTER];
  const activeFilter = state.appData[ACTIVE_FILTER_OPTIONS];

  return {
    initialValues: activeFilter || initialValues,
    activeFilter: state.appData[ACTIVE_FILTER_OPTIONS],
  };
};
export default connect(mapStateToProps)(ReportFilter);
