import React from 'react';
import { connect } from 'react-redux';
import Left from 'material-ui/svg-icons/hardware/keyboard-arrow-left';
import Right from 'material-ui/svg-icons/hardware/keyboard-arrow-right';
import { IconButton } from 'material-ui';
import { PAGE_SIZE } from '../../constants/index';

class Pager extends React.Component {
  handleOnNextClick = () => {
    window.scrollTo(0, 0);
    this.props.onChange(this.props.currentPage + 1);
  };

  handleOnPrevClick = () => {
    window.scrollTo(0, 0);
    this.props.onChange(this.props.currentPage - 1);
  };

  render() {
    const { currentPageItemCount, currentPage = 0, style = {} } = this.props;
    return (
      <div
        style={{
          position: 'absolute',
          left: '50%',
          display: currentPage === 0 && currentPageItemCount === 0 ? 'none' : 'block',
          transform: 'translate(-50%,0)',
          ...style,
        }}
      >
        {currentPage !== 0 && (
          <IconButton
            style={{ minWidth: 36, float: 'left' }}
            disabled={currentPage === 0}
            onClick={this.handleOnPrevClick}
          >
            <Left />
          </IconButton>
        )}
        <span style={{ float: 'left', marginTop: 12 }}>
          Sayfa <strong>{currentPage + 1}</strong>
        </span>
        {!(currentPageItemCount < PAGE_SIZE) && (
          <IconButton style={{ minWidth: 36, float: 'left' }} onClick={this.handleOnNextClick}>
            <Right />
          </IconButton>
        )}
      </div>
    );
  }
}

export default connect()(Pager);
