import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import RaisedButton from 'material-ui/RaisedButton';
import { connect } from 'react-redux';
import { renderTextField } from '../../utils/redux-metarial-ui-util';
import { openSnackbar } from '../../actions/snackbarActions';
import { hidePB, showPB } from '../../actions/progressbarAction';
import AccountApi from '../../data/api/AccountApi';

class ForgotPasswordForm extends Component {
  render() {
    const { handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <p>Lütfen aşağıdaki alana kayıt olurken kullandığınız mail adresinizi girin.</p>
        <div>
          <Field
            name="email"
            style={{ margin: 0 }}
            component={renderTextField}
            floatingLabelText="Email"
            fullWidth
          />
        </div>
        <div style={{ marginTop: 32 }}>
          <RaisedButton
            type="submit"
            style={{ float: 'right', width: '50%' }}
            label="Şifre Sıfırlama Maili Yolla"
            primary
            fullWidth
          />
        </div>
      </form>
    );
  }
}

function onSubmit(values, dispatch) {
  showPB(dispatch, 'Şifre Sıfırlama Maili Gönderiliyor.');
  AccountApi.resetPasswordEmailRequest(values.email).then(
    (r) => {
      hidePB(dispatch);
      dispatch(openSnackbar('Başarılı. Mail kısa bir süre içinde gönderilecek.'));
    },
    (r) => {
      hidePB(dispatch);
      dispatch(openSnackbar(r.error));
    },
  );
}

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Zorunlu alan';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Geçersiz Email Adresi';
  }
  return errors;
};
ForgotPasswordForm = reduxForm({ form: 'forgotPassword', validate, onSubmit })(ForgotPasswordForm);

export default connect()(ForgotPasswordForm);
