/* eslint-disable prefer-destructuring,react/prop-types */
import React from 'react';
import { connect } from 'react-redux';

class ReportInfoBasics extends React.Component {
  infoText = (text, content) => (
    <div className={'fileInfoContainer'}>
      <span className={'fileInfoLabel'}>{text}</span>
      <span style={{ float: 'left' }}>:</span>
      <span className={'fileInfoText'}>{content}</span>
    </div>
  );

  render() {
    const { incident, casualty } = this.props;

    return (
      <div>
        <div
          style={{
            fontWeight: 400,
            display: 'flex',
            justifyContent: 'space-around',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
          }}
        >
          <div className={'subContentContainer'}>
            <span className={'subContentHeader'}> Poliçe Bilgileri </span>
            {incident.damageFileIdentifier &&
              this.infoText('Hasar Dosya No', incident.damageFileIdentifier)}
            {incident.courtFileIdentifier &&
              this.infoText('Hukuk Dosya No', incident.courtFileIdentifier)}
            {incident.arbitrationFileIdentifier &&
              this.infoText('Tahkim Dosya No', incident.arbitrationFileIdentifier)}
            {this.infoText('Poliçe Tanzim Tarihi', incident.dateOfIssue)}
            {this.infoText('Kaza Tarihi', incident.dateOfIncident)}
            {incident.dateOfDeath && this.infoText('Vefat Tarihi', incident.dateOfDeath)}
            {this.infoText('Rapor Tarihi', incident.dateOfReport)}
            {incident.dateOfRetirement &&
              this.infoText('Emeklilik Tarihi', incident.dateOfRetirement)}
          </div>

          <div className={'subContentContainer'}>
            <span className={'subContentHeader'}>Mağdur Bilgileri</span>
            {this.infoText('İsim', casualty.basic.fullName)}
            {casualty.basic.personalIdentificationNumber &&
              this.infoText('Kimlik No', casualty.basic.personalIdentificationNumber)}
            {this.infoText('Doğum Tarihi', casualty.basic.dateOfBirth)}
            {this.infoText('Cinsiyet', casualty.basic.getGender)}{' '}
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(ReportInfoBasics);
