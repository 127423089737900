import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import ConfirmationMailResendForm from './ConfirmationMailResendForm';
import Link from '../../components/Link/Link';
import BaseLayout from '../../components/BaseLayout/BaseLayout';

function ConfirmationMailResend() {
  return (
    <BaseLayout>
      <div className={'max380'} style={{ textAlign: 'center', marginTop: 64 }}>
        <h1>Mail Aktivasyon</h1>
        <ConfirmationMailResendForm form="loginForm" />
        <Link to="/login">
          <FlatButton
            style={{ marginTop: 16 }}
            labelStyle={{ textTransform: 'none' }}
            fullWidth
            label="Giriş Yap"
          />
        </Link>
      </div>
    </BaseLayout>
  );
}

export default ConfirmationMailResend;
