import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { FlatButton } from 'material-ui';
import { renderTextField } from '../../utils/redux-metarial-ui-util';
import { openSnackbar } from '../../actions/snackbarActions';
import { hidePB, showPB } from '../../actions/progressbarAction';
import { REQUIRED } from '../../utils/validation';
import Clear from '../../components/Functional/Clear';
import InvoiceApi from '../../data/api/InvoiceApi';

class InvoiceCancelForm extends React.Component {
  onSubmit = (values, dispatch) => {
    const { invoiceId } = this.props;
    showPB(dispatch);
    InvoiceApi.cancel(invoiceId, values).then(
      () => {
        dispatch(openSnackbar('Fatura İptal Edildi'));
        hidePB(dispatch);
        this.props.onSubmitSuccess();
        this.props.onRequestClose();
      },
      () => {
        dispatch(openSnackbar('Bir Hata Oluştu'));
        hidePB(dispatch);
        this.props.onRequestClose();
      },
    );
  };

  render() {
    return (
      <div style={{ display: 'inline-block' }}>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <Field name="reason" floatingLabelText="Açıklama" component={renderTextField} />
          <div style={{ margin: 'auto', display: 'table', paddingTop: '16px' }}>
            <FlatButton label="İptal" onClick={this.props.onRequestClose} />
            <FlatButton label="Tamam" type="submit" secondary />
          </div>
          <Clear />
        </form>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.reason) errors.reason = REQUIRED;
  return errors;
};
InvoiceCancelForm = reduxForm({ form: 'invoiceCancelForm', validate })(InvoiceCancelForm);
export default connect()(InvoiceCancelForm);
