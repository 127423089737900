import React from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  RaisedButton,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui';
import { useHistory } from 'react-router';
import { injuredPartyDegree } from '../../../data/model/response/InjuredPartyResponse';
import { DISABLED } from '../../../data/model/IncidentType';
import { setAppData } from '../../../actions/appdata';
import { INIT_INJURED_PARTY } from '../../../constants';
import { WriteIncident } from '../../../data/model/Privilege';
import Authorization from '../../Authorization/Authorization';

const PersonViewHolder = ({ person, incidentType, injuredParty, hasHideShare }) => (
  <TableRow>
    <TableRowColumn>{injuredPartyDegree(person, injuredParty, incidentType)}</TableRowColumn>
    <TableRowColumn>
      {(person.basic && person.basic.personalIdentificationNumber) || '-'}
    </TableRowColumn>
    <TableRowColumn>{(person.basic && person.basic.fullName) || '-'}</TableRowColumn>
    <TableRowColumn>{(person.basic && person.basic.getGender) || '-'}</TableRowColumn>
    <TableRowColumn>{(person.basic && person.basic.dateOfBirth) || '-'}</TableRowColumn>
    {hasHideShare && (
      <TableRowColumn>{person.basic && person.basic.hideShare ? 'Saklı' : '-'}</TableRowColumn>
    )}
  </TableRow>
);

function PersonViewList({ dispatch, detailedIncident }) {
  const history = useHistory();

  const handleOpenUpdatePage = () => {
    const initData = detailedIncident.injuredParty;
    initData.spouses = initData.spouses.filter((p) => !p.basic.simulatedAt);
    initData.parents = initData.parents.filter((p) => !p.basic.simulatedAt);
    initData.children = initData.children.filter((p) => !p.basic.simulatedAt);
    initData.child = `child${initData.children.length || '0'}`;

    if (initData.spouses.length > 0) {
      initData.marriage =
        initData.spouses[0].employmentStatus === 'employed' ? 'wifeWorking' : 'wife';
    } else {
      initData.marriage = 'single';
    }
    dispatch(setAppData({ name: INIT_INJURED_PARTY, value: { injuredParty: initData } }));
    history.push(`/reports/${detailedIncident.incident.id}/injured-party/update`);
  };

  const { injuredParty } = detailedIncident;
  const familyMembers = injuredParty.familyMembersWithoutSimulated;
  const hasHideShare = familyMembers.findIndex((p) => p.basic.hideShare) !== -1;
  return (
    <Paper>
      <div style={{ paddingBottom: 16 }}>
        <Table
          className={'incidentTables'}
          fixedHeader={false}
          fixedFooter={false}
          selectable={false}
        >
          <TableHeader adjustForCheckbox={false} displaySelectAll={false} enableSelectAll={false}>
            <TableRow>
              <TableHeaderColumn>Yakınlık</TableHeaderColumn>
              <TableHeaderColumn>Kimlik No</TableHeaderColumn>
              <TableHeaderColumn>İsim</TableHeaderColumn>
              <TableHeaderColumn>Cinsiyet</TableHeaderColumn>
              <TableHeaderColumn>Doğum Tarihi</TableHeaderColumn>
              {hasHideShare && <TableHeaderColumn>Pay Hakkı</TableHeaderColumn>}
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>
            <PersonViewHolder
              person={injuredParty.casualty}
              incidentType={DISABLED}
              hasHideShare={hasHideShare}
            />
            {familyMembers.map((p) => (
              <PersonViewHolder
                key={p.basic.id}
                injuredParty={injuredParty}
                person={p}
                hasHideShare={hasHideShare}
              />
            ))}
          </TableBody>
        </Table>
        <Authorization allowedRoles={[WriteIncident]}>
          <RaisedButton
            className={`hide upsertButton`}
            onClick={handleOpenUpdatePage}
            label="Ekle / Güncelle"
            primary
          />
        </Authorization>
      </div>
    </Paper>
  );
}

export default connect()(PersonViewList);
