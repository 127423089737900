export function LocalStorageError(message) {
  this.message = message;
  // Use V8's native method if available, otherwise fallback
  if ('captureStackTrace' in Error) Error.captureStackTrace(this, LocalStorageError);
  else this.stack = new Error().stack;
}

LocalStorageError.prototype = Object.create(Error.prototype);
LocalStorageError.prototype.name = 'LocalStorageError';
LocalStorageError.prototype.constructor = LocalStorageError;
