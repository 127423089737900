import { isLocalStorageAccessible, loginRequire } from '../../actions/authActions';
import { BASE_URL, getHeadersWithToken, handleError, headers } from './ApiUtil';
import { LocalStorageError } from '../../exceptions/LocalStorageError';
import { AuthenticationError } from '../../exceptions/AuthenticationError';
import { AuthorizationError } from '../../exceptions/AuthorizationError';
import { setAppData } from '../../actions/appdata';

export default class Connection {
  static justData = [
    (resolve) => Promise.resolve(resolve.data),
    (reject) => Promise.reject(reject),
  ];

  static _getSecurely(url) {
    if (!isLocalStorageAccessible()) {
      return Promise.reject(new LocalStorageError("Local storage'a erişilemiyor"));
    }
    if (!loginRequire()) {
      window.location = '/login';
      return Promise.reject(new AuthenticationError('Token bilgisi bulunamadı'));
    }
    return fetch(BASE_URL + url, {
      method: 'GET',
      headers: getHeadersWithToken(),
      credentials: 'include',
    }).then((response) => handleError(response));
  }

  static _get(url) {
    return fetch(BASE_URL + url, {
      method: 'GET',
      headers: {
        ...headers,
        'X-Forwarded-For': localStorage.getItem('ip'),
      },
    }).then((response) => handleError(response));
  }

  static fetchWithMethod(method, url, data, customHeaders, errorLogging) {
    return fetch(BASE_URL + url, {
      method,
      headers: customHeaders || headers,
      body: data,
    }).then((response) => handleError(response, errorLogging));
  }

  static fetchWithToken(method, url, data, errorLogging) {
    if (!isLocalStorageAccessible()) return;
    if (!loginRequire()) return;
    return this.fetchWithMethod(method, url, data, getHeadersWithToken(), errorLogging);
  }

  static _post(url, data, errorLogging) {
    return this.fetchWithToken('POST', url, JSON.stringify(data), errorLogging);
  }

  static _postFormData(url, data, errorLogging) {
    if (!isLocalStorageAccessible()) return;
    if (!loginRequire()) return;
    const customHeaders = getHeadersWithToken();
    delete customHeaders['Content-Type'];
    return this.fetchWithMethod('POST', url, data, customHeaders, errorLogging);
  }

  static _put(url, data, errorLogging) {
    return this.fetchWithToken('PUT', url, JSON.stringify(data), errorLogging);
  }

  static _patch(url, data, errorLogging) {
    return this.fetchWithToken('PATCH', url, JSON.stringify(data), errorLogging);
  }

  static _delete(url, data, errorLogging) {
    return this.fetchWithToken(
      'DELETE',
      url,
      data ? JSON.stringify(data) : undefined,
      errorLogging,
    );
  }

  static catchError(r) {
    if (r instanceof AuthorizationError) {
      console.log('AuthorizationError');
      return Promise.reject({ message: 'Bu işlemi yapmak için giriş yapınız' });
    }
    if (r instanceof AuthenticationError) {
      console.log('AuthenticationError');
      return Promise.reject({ message: 'Bu işlemi yapmak için giriş yapınız' });
    }
    if (r instanceof LocalStorageError) {
      return;
    }
    return Promise.reject(r);
  }

  static setPageInfo = (dispatch, contentRangeKey, itemCountKey) => [
    (resolve) => {
      if (resolve.contentRange) {
        const rawContentRange = resolve.contentRange;
        const justRange = rawContentRange.replace('items ', '').split('/');
        const contentRange = justRange[0].split('-').map((n) => parseInt(n));
        const itemCount = parseInt(justRange[1]);
        dispatch(setAppData({ name: itemCountKey, value: itemCount }));
        dispatch(setAppData({ name: contentRangeKey, value: contentRange }));
      }
      return Promise.resolve(resolve);
    },
    (reject) => Promise.reject(reject),
  ];
}
