import React from 'react';
import ReactDOM from 'react-dom';
import './components/index.scss';
import * as Sentry from '@sentry/react';
import App from './App';
import * as serviceWorker from './serviceWorker';

Sentry.init({ dsn: window.injectedEnv?.INOVA_SENTRY_URL || process.env.INOVA_SENTRY_URL });

const container = document.getElementById('root');
ReactDOM.render(<App />, container);

serviceWorker.unregister();
