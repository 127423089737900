export default {
  primary1Color: '#00738b',
  primary2Color: '#56b8d3',
  primary3Color: '#aed1d7',
  accent1Color: '#b91a1b',
  accent2Color: '#D1DBBD',
  accent3Color: '#555555',
  textColor: '#28231e',
  alternateTextColor: '#f5f5f5',
  transparent3: '#0000004d',
  transparent5: '#00000080',
  disabledColor: '#00000080',
  errorColor: '#f44336',
};
