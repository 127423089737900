import Connection from './Connection';
import { PRICING_LIST } from '../../constants';
import { toQuery } from '../../utils/javascript-object-util';
import { setAppData } from '../../actions/appdata';
import PricingResponse from '../model/response/PricingResponse';

export default class PricingApi extends Connection {
  static list(dispatch, companyId, from, till) {
    const query = toQuery({
      from,
      till,
    });
    return this._getSecurely(`/pricings/customers/${companyId}${query}`)
      .then(...this.justData)
      .then((r) =>
        dispatch(
          setAppData({
            name: PRICING_LIST,
            value: r ? r.bindList(PricingResponse) : [],
          }),
        ),
      )
      .catch(this.catchError);
  }

  static addCorrection(dispatch, incidentId, data) {
    return this._post(`/pricings/incidents/${incidentId}`, data).catch(this.catchError);
  }

  static addTransaction(dispatch, companyId, data) {
    return this._post(`/pricings/customers/${companyId}`, data).catch(this.catchError);
  }
}
