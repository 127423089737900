import find from 'lodash/find';
import uniqBy from 'lodash/uniqBy';
import Fraction from 'fraction.js';
import { injuredPartyDegree } from '../../../data/model/response/InjuredPartyResponse';

export default function ShareRateInfo() {
  const { incident } = this;
  const { dateOfIncident } = incident;
  const { incidentType } = incident;
  const { details } = this.calculation;
  const { injuredParty } = this;
  const { casualty } = injuredParty;
  details.buildEndOfSupportPeriods(injuredParty);
  const endOfSupportPeriods = uniqBy(details.endOfSupportPeriods, (o) => o.period).map(
    (p) => p.period,
  );

  const endOfSupportLength = endOfSupportPeriods.length;
  const tableHeader = [
    {
      text: 'KAZA TARİHİ',
      alignment: 'center',
      style: { bold: true },
      fillColor: '#c3c3c3',
    },
    {
      text: 'DESTEK SONU TARİHLERİ',
      alignment: 'center',
      style: { bold: true },
      colSpan: endOfSupportLength,
      fillColor: '#c3c3c3',
    },
  ];
  for (let i = 1; i < endOfSupportLength; i++) tableHeader.push('');
  const tableDateRow = [
    {
      text: dateOfIncident,
      alignment: 'center',
    },
  ];
  endOfSupportPeriods.forEach((p) => {
    tableDateRow.push({
      text: endOfSupportPeriods.length > 7 ? p.end.replaceAt(4, ' ') : p.end,
      alignment: 'center',
    });
  });

  const td = (text, style) => ({ text, alignment: 'center', style });

  const shareRateHeader = [
    {
      text: 'Pay Sahipleri',
      alignment: 'center',
      style: { bold: true },
      fillColor: '#c3c3c3',
    },
    {
      text: `Pay Oranları`,
      alignment: 'center',
      style: { bold: true },
      colSpan: endOfSupportLength,
      fillColor: '#c3c3c3',
    },
  ];
  for (let i = 1; i < endOfSupportLength; i++) shareRateHeader.push('');

  const shareRateRows = [];
  function rateSection(id, row, style) {
    endOfSupportPeriods.forEach((p) => {
      const claim = find(p.claimsByPerson, (f) => f.personId === id);
      row.push(td(claim ? new Fraction(claim.share).toFraction() : '-', style));
    });
  }
  const personMapper = (entity) => {
    const degree = injuredPartyDegree(entity, injuredParty, incidentType);
    const style = {
      color: entity.basic.simulatedAt ? '#555555' : '#000000',
      bold: !entity.basic.simulatedAt,
    };
    const row = [
      {
        text: `${degree}${entity.basic.simulatedAt ? '*' : ''}`,
        style,
      },
    ];
    rateSection(entity.basic.id, row, style);
    shareRateRows.push(row);
  };

  const row = [{ text: 'Müteveffa', style: { bold: true } }];
  rateSection(casualty.basic.id, row, { bold: true });
  shareRateRows.push(row);

  injuredParty.familyMembers.forEach(personMapper);

  const totalRow = [{ text: 'Toplam:', style: { bold: true } }];
  for (let i = 0; i < endOfSupportLength; i++) totalRow.push(td('1', { bold: true }));

  const widths = [70];
  for (let i = 0; i < endOfSupportLength; i++)
    if (endOfSupportLength > 7) widths.push(`${(83.2 / endOfSupportLength).toFixed(2)}%`);
    else widths.push('*');

  const hasSimulatedPerson = injuredParty.familyMembers.filter((p) => p.basic.simulatedAt).length;
  const simulatedPersonText = hasSimulatedPerson
    ? [
        {
          text: '*Var olması öngörülen aile bireyleri.',
          style: { fontSize: 10 },
          margin: [0, 2, 0, 10],
        },
      ]
    : [];

  return [
    {
      headlineLevel: 1,
      stack: [
        {
          text: 'MADDİ TAZMİNAT HESABI',
          style: 'h1',
          alignment: 'center',
          margin: [0, 20, 0, 5],
        },
        {
          text: 'Gelirin Pay Oranı Dağılımı',
          style: 'h2',
          margin: [0, 10, 0, 0],
        },
        {
          margin: [0, 10, 0, 0],
          style: { fontSize: 10 },
          table: {
            headerRows: 1,
            dontBreakRows: true,
            keepWithHeaderRows: 1,
            heights: 10,
            widths,
            body: [tableHeader, tableDateRow, shareRateHeader, ...shareRateRows, totalRow],
          },
        },
        ...simulatedPersonText,
      ],
    },
  ];
}
