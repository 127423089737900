import SimpleUserResponse from './SimpleUserResponse';
import RoleResponse from './RoleResponse';

export default class CompanyUserResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.id = config.id;
    this.user = new SimpleUserResponse(config.user);
    this.role = new RoleResponse(config.role);
  }
}
