import React from 'react';
import { Dialog, FlatButton, IconButton } from 'material-ui';
import ClearIcon from 'material-ui/svg-icons/content/clear';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Clear from '../../components/Functional/Clear';
import Authorization from '../../components/Authorization/Authorization';
import { ACTUARY } from '../../data/model/Privilege';
import Link from '../../components/Link/Link';

class InvoiceItemViewHolder extends React.Component {
  mouseOver = () => {
    this.setState({ hover: true });
  };

  mouseOut = () => {
    this.setState({ hover: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      open: false,
    };
  }

  render() {
    const { item, title, isInvoice } = this.props;
    const { incident, price } = item;
    const { summary, issuer } = incident;
    const { hover } = this.state;
    const actions = [
      <FlatButton keyboardFocused label="İptal" primary onClick={this.handleClose} />,
      <FlatButton
        label="Sil"
        primary
        onClick={() => {
          this.props.onRemove(summary.id);
          this.handleClose();
        }}
      />,
    ];
    return (
      <div
        style={{
          position: 'relative',
          fontWeight: title ? '600' : '400',
          padding: title ? '16px 8px' : '4px 8px',
        }}
        onMouseEnter={this.mouseOver}
        onMouseLeave={this.mouseOut}
      >
        <span
          style={{
            display: 'inline-block',
            minWidth: 140,
            fontWeight: 500,
          }}
        >
          {title ? (
            summary.fileIdentifier
          ) : (
            <Link to={`/reports/${summary.id}`} style={{ textDecoration: 'none' }}>
              {summary.fileIdentifier}
            </Link>
          )}
        </span>

        <span
          style={{
            display: 'inline-block',
            width: 216,
          }}
        >
          {summary.fullName}
        </span>
        <span
          style={{
            display: 'inline-block',
            width: 216,
          }}
        >
          {issuer.fullName}
        </span>
        <span
          style={{
            display: 'inline-block',
          }}
        >
          {summary.createdAt || 'Oluşturulma Tarihi'}
        </span>
        <span
          style={{
            fontWeight: 400,
            display: 'inline-block',
            float: 'right',
            textAlign: 'right',
          }}
        >
          {price}
          {!title && ' ₺'}
        </span>
        {!isInvoice && !title && (
          <Authorization allowedRoles={[ACTUARY]}>
            <IconButton
              iconStyle={{ width: 24, height: 24 }}
              style={{
                height: 20,
                padding: 0,
                position: 'absolute',
                transition: 'opacity 300ms, right 300ms',
                opacity: hover ? 1 : 0,
                right: hover ? -32 : -24,
                top: 2,
              }}
              tooltip={
                <span>
                  <FontAwesomeIcon fixedWidth icon="trash" />
                  Sil
                </span>
              }
              tooltipposition="top-right"
              onClick={this.handleOpen}
            >
              <ClearIcon />
            </IconButton>
          </Authorization>
        )}
        <Clear />
        <Dialog
          title="Dosya mutabakattan çıkarılsın mı?"
          actions={actions}
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
        >
          <strong>
            <strong>
              {summary.fileIdentifier}&nbsp;
              {summary.fullName}
            </strong>
            &nbsp;dosyası mutabakattan çıkarılacak
            <br />
            <br />
            Çıkarılan dosyalar tekrar mutabakata eklenebilir.
          </strong>
        </Dialog>
      </div>
    );
  }
}

export default InvoiceItemViewHolder;
