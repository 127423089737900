import React from 'react';
import { connect } from 'react-redux';
import sumBy from 'lodash/sumBy';
import {
  FlatButton,
  Paper,
  RaisedButton,
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui';
import { useHistory } from 'react-router';
import { toMoney } from '../../../utils/javascript-object-util';
import { INIT_PAYMENTS, LAST_VISITED_TAB_REPORT_DETAIL } from '../../../constants';
import { setAppData } from '../../../actions/appdata';
import { WriteIncident } from '../../../data/model/Privilege';
import Authorization from '../../Authorization/Authorization';
import EmptyView from '../../Functional/EmptyView';
import { DEATH } from '../../../data/model/IncidentType';
import Clear from '../../Functional/Clear';

const PaymentViewHolder = ({ payment, people }) => (
  <TableRow>
    <TableRowColumn>{payment.date}</TableRowColumn>
    <TableRowColumn style={{ textAlign: 'right', paddingRight: 20 }}>
      {toMoney(payment.amount)}
    </TableRowColumn>
    <TableRowColumn>{payment.getCategory}</TableRowColumn>
    <TableRowColumn>
      {payment.recipients.map((p) => people[p].basic.fullName).join(', ')}
    </TableRowColumn>
    <TableRowColumn>{payment.description}</TableRowColumn>
  </TableRow>
);

function PersonViewList({ dispatch, detailedIncident }) {
  const history = useHistory();
  const handleOpenUpdatePage = () => {
    dispatch(
      setAppData({
        name: INIT_PAYMENTS,
        value: {
          payments: detailedIncident.payments,
          injuredParty: detailedIncident.injuredParty,
        },
      }),
    );
    history.push(`/reports/${detailedIncident.incident.id}/payments/update`);
  };

  const { familyMembers } = detailedIncident.injuredParty;
  const people = detailedIncident.injuredParty.hashSet;
  const { incidentType } = detailedIncident.incident;
  const { payments } = detailedIncident;
  return (
    <Paper style={{ paddingBottom: 16, paddingTop: 1 }}>
      {payments.length > 0 ? (
        <Table
          className={'incidentTables'}
          fixedHeader={false}
          fixedFooter={false}
          selectable={false}
        >
          <TableHeader adjustForCheckbox={false} displaySelectAll={false} enableSelectAll={false}>
            <TableRow>
              <TableHeaderColumn>Tarih</TableHeaderColumn>
              <TableHeaderColumn style={{ textAlign: 'right', paddingRight: 20 }}>
                Ödenen Miktar
              </TableHeaderColumn>
              <TableHeaderColumn>Faiz Uygulaması</TableHeaderColumn>
              <TableHeaderColumn>Ödeme Yapılan Kişiler</TableHeaderColumn>
              <TableHeaderColumn>Açıklama</TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>
            {payments &&
              payments.map((p) => <PaymentViewHolder key={'id'} payment={p} people={people} />)}
            <TableRow>
              <TableRowColumn>
                <strong>Toplam</strong>
              </TableRowColumn>
              <TableRowColumn style={{ textAlign: 'right', paddingRight: 20 }}>
                {payments && toMoney(sumBy(payments, (p) => p.amount))}
              </TableRowColumn>
              <TableRowColumn />
              <TableRowColumn />
              <TableRowColumn />
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        <EmptyView
          className={'hide'}
          style={{ marginTop: 16 }}
          icon="money-bill-alt"
          label="Yapılan Ödeme Yok"
        />
      )}
      <Clear />
      <Authorization allowedRoles={[WriteIncident]}>
        <div className={'hide'}>
          {incidentType === DEATH && familyMembers.length === 0 ? (
            <div className={'warnInfo'} style={{ marginTop: 16 }}>
              <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                Pay sahipleri bilgilerini girdikten sonra ödeme bilgilerini doldurabilirsiniz.
              </span>
              <FlatButton
                className={'hide'}
                onClick={() =>
                  dispatch(
                    setAppData({
                      name: LAST_VISITED_TAB_REPORT_DETAIL,
                      value: 'persons',
                    }),
                  )
                }
                style={{ display: 'inline-block', float: 'right' }}
                label="Pay Sahibilerini Görüntüle"
              />
            </div>
          ) : (
            <RaisedButton
              className={`hide upsertButton`}
              onClick={handleOpenUpdatePage}
              label="Ekle / Güncelle"
              primary
            />
          )}
        </div>
      </Authorization>
    </Paper>
  );
}

export default connect()(PersonViewList);
