/* eslint-disable react/require-default-props,react/prop-types,prefer-destructuring */
import React from 'react';
import { connect } from 'react-redux';
import { List, Subheader } from 'material-ui';
import { CALCULATION_FILES, FILE_LIST } from '../../constants';
import { clearAppData } from '../../actions/appdata';
import FileViewHolder from './FileViewHolder';
import EmptyView from '../Functional/EmptyView';
import FileApi from '../../data/api/FileApi';

class FileList extends React.Component {
  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    const dispatch = this.props.dispatch;
    dispatch(clearAppData(FILE_LIST));
    dispatch(clearAppData(CALCULATION_FILES));
  }

  fetchData = () => {
    const incidentId = this.props.incidentId;
    const dispatch = this.props.dispatch;
    if (!incidentId) return;
    FileApi.list(dispatch, incidentId);
  };

  render() {
    const {
      unready,
      incidentId,
      calculationId,
      calculationFiles,
      files,
      readOnly,
      onDelete,
    } = this.props;

    const data = this.props.data || (unready ? calculationFiles : files);

    return (
      <List className={'fileList'}>
        <Subheader>Belgeler ve Raporlar</Subheader>
        {data && data.length ? (
          data.map((f) => (
            <FileViewHolder
              unready={unready}
              readOnly={readOnly}
              incidentId={incidentId}
              calculationId={calculationId}
              onSuccessUpdate={this.fetchData}
              file={f}
              onDelete={onDelete}
              key={f.id}
            />
          ))
        ) : this.props.isProgressbarOpen || this.props.hideWarn ? (
          <span />
        ) : (
          <EmptyView icon="file" label="Belge veya Rapor Yok" />
        )}
      </List>
    );
  }
}

export default connect((state) => ({
  calculationFiles: state.appData[CALCULATION_FILES],
  isProgressbarOpen: state.runtime.isProgressbarOpen,
  files: state.appData[FILE_LIST],
}))(FileList);
