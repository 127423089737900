import moment from 'moment/moment';
import sortBy from 'lodash/sortBy';
import IncidentResponse from './IncidentResponse';
import PaymentResponse from './PaymentResponse';
import InjuredPartyResponse from './InjuredPartyResponse';
import FileResponse from './FileResponse';
import SpecialPeriodResponse from './SpecialPeriodResponse';
import ConfigResponse from './ConfigResponse';
import SimpleCompanyResponse from './SimpleCompanyResponse';
import SimpleUserResponse from './SimpleUserResponse';
import ActionResponse from './ActionResponse';
import SalaryResponse from './SalaryResponse';
import IncomeCategory from '../IncomeCategory';
import TagResponse from './TagResponse';

export default class DetailedIncidentResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.issuer = new SimpleUserResponse(config.issuer);
    this.company = new SimpleCompanyResponse(config.company);
    this.configs = config.configs ? config.configs.bindList(ConfigResponse) : [];
    this.incident = new IncidentResponse(config.incident);
    this.injuredParty = new InjuredPartyResponse(config.injuredParty);
    this.payments = config.payments
      ? sortBy(config.payments.bindList(PaymentResponse), (p) => moment(p.date).unix())
      : [];
    this.specialPeriods = config.specialPeriods
      ? config.specialPeriods.bindList(SpecialPeriodResponse)
      : [];
    this.files = config.files ? config.files.bindList(FileResponse) : [];
    this.config = config.config ? config.config.bindList(ConfigResponse) : [];
    this.latestAction = config.latestAction
      ? new ActionResponse(config.latestAction)
      : new ActionResponse({ category: 'selfProcessed' });
    this.salaries = config.salaries ? config.salaries.bindList(SalaryResponse) : [];
    this.tag = new TagResponse(config.tag);
  }

  get getIncomeCategory() {
    return this.salaries.length > 0 ? IncomeCategory.other : IncomeCategory.minimumWage;
  }

  get hasPayment() {
    return this.payments && this.payments.length > 0;
  }

  set setSalaries(salaries) {
    this.salaries = salaries;
  }
}
