import { SubmissionError } from 'redux-form';
import { AuthenticationError } from '../../exceptions/AuthenticationError';
import { deepen, isValidJson } from '../../utils/javascript-object-util';
import { AuthorizationError } from '../../exceptions/AuthorizationError';
import { clearUserData } from '../../actions/authActions';

export const BASE_URL =
  window.injectedEnv?.INOVA_API_URL || process.env.INOVA_API_URL || 'http://localhost:9000/v1';

export const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const handleError = (response, errorLog = true) => {
  if (response.status >= 200 && response.status < 300) {
    return {
      location: response.headers.get('location'),
      contentRange: response.headers.get('content-range'),
      status: response.status,
      data: response.text().then((text) => (isValidJson(text) ? JSON.parse(text) : {})),
    };
  }
  const message = {
    url: response.url,
    status: response.status,
    statusText: response.statusText,
  };
  return response
    .json()
    .then((data) => ({
      data,
      status: response.status,
    }))
    .then((res) => {
      if (errorLog) {
        message.body = res;
      }
      if (res.status === 401) {
        window.location = '/login';
        clearUserData();
        return Promise.reject(new AuthenticationError(res.data.error));
      }
      if (res.status === 403) {
        return Promise.reject(new AuthorizationError(res.data.error));
      }
      if (res.status === 400) {
        const { data } = res;
        return Promise.reject(new SubmissionError(deepen(data.details ? data.details : data)));
      }
      if (res.status === 409) {
        const { data } = res;
        return Promise.reject(new SubmissionError(deepen(data.details ? data.details : data)));
      }
      if (res.status >= 500) {
        return Promise.reject({ message: 'Sunucu İşlemi Tamamlayamadı' });
      }
      // Todo Exception oluştur ApiException gibi.
      return Promise.reject(res.data.error);
    });
};

export const getHeadersWithToken = () => ({
  ...headers,
  'X-Forwarded-For': localStorage.getItem('ip'),
  Authorization: localStorage.getItem('authToken'),
});
