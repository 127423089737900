import { dateFormat } from '../../../utils/javascript-object-util';

export default class InvoiceResponse {
  constructor(config) {
    if (typeof config !== 'object') return;
    this.id = config.id;
    this.companyId = config.companyId;
    this.from = dateFormat(config.from);
    this.till = dateFormat(config.till);
    this.dueDate = dateFormat(config.dueDate);
    this.dueAmount = config.dueAmount * -1;
    this.createdAt = dateFormat(config.createdAt);
    this.taxAmount = config.taxAmount * -1;
    this.stoppage = (config.stoppage || 0) * -1;
  }
}
